import {
  ArrowLineUpRight,
  DotsThreeVertical,
  ChatDots,
  CheckSquareOffset,
  CheckCircle,
  Trash,
  PencilSimpleLine,
} from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import dayjs, { Dayjs, isDayjs } from "dayjs";
import { debounce } from "debounce";
import equal from "fast-deep-equal";
import { lastTransferDescription } from "pages/SendMoneyPage/utils";
import {
  FC,
  PropsWithChildren,
  ReactNode,
  FocusEvent,
  FormEventHandler,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";
import { Control, useController } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import BillRep from "reps/BillRep";
import BillSummaryRep from "reps/BillSummaryRep";
import { Money } from "reps/Money";
import BillApprovers from "resources/bill-approvals/components/BillApprovers";
import useGrantBillApprovalMutation from "resources/bill-approvals/mutations/useGrantBillApprovalMutation";
import useBillApprovals from "resources/bill-approvals/queries/useBillApprovals";
import BillLineItemsMobile from "resources/bill-line-items/components/BillLineItemsMobile";
import BillRelatedPaymentsTable from "resources/bills/components/BillRelatedPaymentsTable";
import DeleteBillModal from "resources/bills/components/DeleteBillModal";
import useSaveAsBillMutation from "resources/bills/mutations/useSaveAsBillMutation";
import useUpdateBillMutation from "resources/bills/mutations/useUpdateBillMutation";
import useUpdateBillStateMutation from "resources/bills/mutations/useUpdateBillStateMutation";
import { useRefreshBillQuery } from "resources/bills/queries/useBill";
import useBillRelatedUnitCoPayments from "resources/bills/queries/useBillRelatedUnitCoPayments";
import useBillSuggestedPayee from "resources/bills/queries/useBillSuggestedPayee";
import billDetailsAutosaveState from "resources/bills/state/billDetailsAutosaveState";
import getBillPercentPaid from "resources/bills/utils/getBillPercentPaid";
import getIsBillFullyPaid from "resources/bills/utils/getIsBillFullyPaid";
import useBusinessMember from "resources/business-members/queries/useBusinessMember";
import { useActivePayeesQueryOptions } from "resources/payees/queries/usePayees";
import filterAndSortPayeesByLastTransferAt from "resources/payees/utils/filterAndSortPayeesByLastTransferAt";
import Banner from "ui/data-display/Banner";
import DetailsSidebarBody from "ui/data-display/DetailsSidebarBody";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import DatePicker from "ui/inputs/DatePicker";
import DropdownV2, { convertStringValueToDropdownOption } from "ui/inputs/DropdownV2";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import Helper from "ui/inputs/Helper";
import MoneyInputs from "ui/inputs/MoneyInputs";
import TextInput from "ui/inputs/TextInputV2";
import Menu from "ui/overlay/Menu";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import toDayjsOrNull from "utils/date/toDayjsOrNull";
import { useIsMobile } from "utils/device/useMediaQuery";
import useHasPermission from "utils/permissions/useHasPermission";

import styles from "./BillDetailsForm.module.scss";
import DuplicateBillBanner from "./DuplicateBillBanner";
import useBillDetailsForm, { BillDetailsFormInputs } from "./useBillDetailsForm";

const AUTOSAVE_DEBOUNCE_DELAY = 750;
const SAVE_INDICATOR_IDLE_DELAY = 3000;

type AuxiliarySectionProps = PropsWithChildren & {
  title: ReactNode;
  cornerContent?: ReactNode;
};

const AuxiliarySection: FC<AuxiliarySectionProps> = ({ children, title, cornerContent }) => (
  <DetailsSidebarBody.Section className={styles.auxiliarySectionContainer}>
    <div className={styles.auxiliarySectionHeader}>
      <Text as="h3" className={styles.auxiliarySectionHeaderTitle}>
        {title}
      </Text>
      {cornerContent && <div className={styles.auxiliarySectionHeaderCorner}>{cornerContent}</div>}
    </div>
    {children}
  </DetailsSidebarBody.Section>
);

type BillDetailsFormInputProps = {
  control: Control<BillDetailsFormInputs>;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
};

type BillDetailsFormPayeeDropdownProps = BillDetailsFormInputProps & {
  bill: BillSummaryRep.Complete;
  onShowPaymentDetails: () => void;
  onCreateNewPayee: (defaultPayeeName: string) => void;
};

const BillDetailsFormPayeeDropdown: FC<BillDetailsFormPayeeDropdownProps> = ({
  bill,
  control,
  onBlur,
  onShowPaymentDetails,
  onCreateNewPayee,
}) => {
  const { field, fieldState } = useController({ name: "payee", control });
  const fieldHasValue = Boolean(field.value);
  const [inputValue, setInputValue] = useState("");

  const { data: payees } = useQuery({
    ...useActivePayeesQueryOptions(),
    select: (payees) => {
      if (inputValue) {
        return payees;
      } else {
        return filterAndSortPayeesByLastTransferAt(payees).slice(0, 5);
      }
    },
  });

  const billInvoiceNumber = bill.invoiceNumber;
  const suggestedPayee = useBillSuggestedPayee(bill.id);
  const suggestedPayeeName = suggestedPayee?.name;

  return (
    <>
      {suggestedPayee && !fieldHasValue && (
        <Banner
          icon={<PencilSimpleLine />}
          body={
            <Text as="p" size={14}>
              We have recognized a new payee
              {suggestedPayeeName ? (
                <>
                  , <Text as="strong">{suggestedPayeeName}</Text>,
                </>
              ) : (
                ""
              )}{" "}
              from{" "}
              {billInvoiceNumber ? (
                <>
                  invoice <Text as="strong">{billInvoiceNumber}</Text>
                </>
              ) : (
                <>this invoice</>
              )}
              .
            </Text>
          }
          footer={
            <Button size="sm" variant="secondary" onClick={() => onCreateNewPayee("")}>
              {suggestedPayeeName ? <>Create payee {suggestedPayeeName}</> : <>Create payee</>}
            </Button>
          }
        />
      )}
      <div className={styles.payeeDropdownContainer}>
        {fieldHasValue && (
          <div className={styles.paymentDetailsButtonContainer}>
            <button
              className={styles.paymentDetailsButton}
              type="button"
              onClick={onShowPaymentDetails}
            >
              Payment details
            </button>
          </div>
        )}

        <DropdownV2
          {...field}
          inputValue={inputValue}
          onInputChange={setInputValue}
          options={payees}
          label="Payee’s legal name"
          isClearable
          hideClearIndicator
          onBlur={(e) => {
            field.onBlur();
            onBlur?.(e);
          }}
          // NB(alex): `onCreateOption` work-around explainer:
          //
          // When adding the prop `onCreateOption`, the dropdown component switches to using `react-select`'s `CreatableSelect` under the hood.
          // Unfortunately, `react-select` does not set the correct typescript types when passing in custom data, so the following work-around is needed.
          // Specifying `onCreateOption` injects a special "__isNew__" option into the options array, but it doesn't update the typescript types of `(option) => ...` to reflect this.
          // This causes the dropdown to crash when trying to access fields on `option.data` that don't exist on this creatable ("__isNew__") option.
          // eg: `filterOption` below calls `option.data.name.toLowerCase`, but the injected option doesn't have the property `option.data.name`,
          // so the component crashes when trying to access this property on the injected option.
          //
          // That said, the injected option does have a field "__isNew__" (which isn't exposed in the typescript type),
          // which we can use to check to see if that field exists to know if the option we're dealing with is this custom injected option.
          //
          // Also worth noting:
          // - This work-around is only needed if we want to use custom data, (in this case we pass in `options: PayeeRep.Complete[]`) the way we do in this dropdown.
          // - `__isNew__` is an internal value in `react-select` so upgrading the package may break this.
          // - There may be a better solution using the `getNewOptionData` prop, but I was unable to figure out a better solution that what I landed with here.
          onCreateOption={(inputValue) => {
            onCreateNewPayee(inputValue);
          }}
          getOptionLabel={(option) => {
            // NB(alex): `onCreateOption` work-around.
            // @ts-expect-error
            if ("__isNew__" in option) return option.label;

            return option.name;
          }}
          getOptionValue={(option) => {
            return option.guid;
          }}
          blurInputOnSelect
          isOptionSelected={(option) => {
            return option?.guid === field.value?.guid;
          }}
          filterOption={(option, inputValue) => {
            if (!option.data) {
              return false;
            }

            // NB(alex): `onCreateOption` work-around.
            if ("__isNew__" in option.data) return true;

            return option.data.name.toLowerCase().includes(inputValue.toLowerCase());
          }}
          renderOption={(optionProps) => {
            if (!optionProps.data) {
              return null;
            }

            // NB(alex): `onCreateOption` work-around.
            if ("__isNew__" in optionProps.data) {
              return (
                <DropdownV2.CreateLabel onClick={() => onCreateNewPayee(inputValue)}>
                  Create payee "{inputValue}"
                </DropdownV2.CreateLabel>
              );
            }

            return (
              <DropdownV2.Option
                {...optionProps}
                description={
                  optionProps.data.lastTransferAt
                    ? `Last transferred: ${lastTransferDescription(optionProps.data)}`
                    : "No payments"
                }
              >
                {optionProps.label}
              </DropdownV2.Option>
            );
          }}
          renderMenu={({ children, ...menuProps }, selectRef) => {
            const inputValue = selectRef.current?.inputRef?.value;

            return (
              <DropdownV2.Menu
                {...menuProps}
                selectRef={selectRef}
                appendElement={
                  !inputValue && (
                    <DropdownV2.CreateLabel onClick={() => onCreateNewPayee("")}>
                      {suggestedPayeeName && !fieldHasValue
                        ? `Create payee "${suggestedPayeeName}"`
                        : "Create payee"}
                    </DropdownV2.CreateLabel>
                  )
                }
              >
                {children}
              </DropdownV2.Menu>
            );
          }}
        />
        {fieldState.error && (
          <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
        )}
      </div>
    </>
  );
};

const BillDetailsFormMoneyAmountInput: FC<BillDetailsFormInputProps> = ({ control, onBlur }) => {
  const amountController = useController({
    name: "amount.amount",
    control: control,
  });

  const currencyController = useController({
    name: "amount.currency",
    control: control,
  });

  return (
    <div>
      <MoneyInputs
        amountInput={
          <MoneyInputs.AmountInput
            {...amountController.field}
            label="Amount"
            currency={currencyController.field.value?.value ?? null}
            onBlur={(e) => {
              amountController.field.onBlur();
              onBlur?.(e);
            }}
          />
        }
        currencyDropdown={
          <MoneyInputs.CurrencyDropdown
            {...currencyController.field}
            onBlur={(e) => {
              currencyController.field.onBlur();
              onBlur?.(e);
            }}
          />
        }
      />
      {amountController.fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>
          {amountController.fieldState.error.message}
        </Helper>
      )}
      {currencyController.fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>
          {currencyController.fieldState.error.message}
        </Helper>
      )}
    </div>
  );
};

type BillDetailsFormDateInputProps = Omit<BillDetailsFormInputProps, "onBlur"> & {
  // NB(alex): the underlying date picker is buggy so `onBlur` doesn't work as-expected.
  onBlur?: () => void;
};

const BillDetailsFormInvoiceDateInput: FC<BillDetailsFormDateInputProps> = ({
  control,
  onBlur: onBlurProp,
}) => {
  const {
    field: { ref: _ref, value, onBlur: onBlurControl, onChange, ...field },
    fieldState,
  } = useController({
    name: "invoiceDate",
    control: control,
  });

  return (
    <div>
      <DatePicker
        label="Invoice date"
        {...field}
        value={value ? value.toDate() : null}
        onChange={(selected) => {
          onChange(selected ? dayjs(selected) : selected);
        }}
        isClearable
        variant="no-date"
        onCalendarClose={() => {
          onBlurControl();
          onBlurProp?.();
        }}
      />
      {fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
      )}
    </div>
  );
};

const BillDetailsFormDueDateInput: FC<BillDetailsFormDateInputProps> = ({
  control,
  onBlur: onBlurProp,
}) => {
  const {
    field: { ref: _ref, value, onBlur: onBlurControl, onChange, ...field },
    fieldState,
  } = useController({
    name: "invoiceDueDate",
    control: control,
  });

  return (
    <div>
      <DatePicker
        label="Due date"
        {...field}
        value={value ? value.toDate() : null}
        onChange={(selected) => {
          onChange(selected ? dayjs(selected) : selected);
        }}
        isClearable
        variant="no-date"
        onCalendarClose={() => {
          onBlurControl();
          onBlurProp?.();
        }}
      />
      {fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
      )}
    </div>
  );
};

const BillDetailsFormInvoiceNumberInput: FC<BillDetailsFormInputProps> = ({ control, onBlur }) => {
  const { field, fieldState } = useController({
    name: "invoiceNumber",
    control: control,
    defaultValue: "",
  });

  return (
    <div>
      <TextInput
        label="Invoice number"
        {...field}
        onBlur={(e) => {
          field.onBlur();
          onBlur?.(e);
        }}
      />
      {fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
      )}
    </div>
  );
};

const BillDetailsFormPaymentTermsInput: FC<BillDetailsFormInputProps> = ({ control, onBlur }) => {
  const {
    field: { onChange, value, ...field },
    fieldState,
  } = useController({ name: "paymentTerms", control });

  return (
    <div>
      <DropdownV2
        label="Payment terms (optional)"
        options={[
          "Net-15",
          "Net-30",
          "Net-45",
          "Net-60",
          "Net-90",
          "Due on Receipt",
          "1/10 Net-30",
          "2/10 Net-30",
        ].map(convertStringValueToDropdownOption)}
        value={value ? convertStringValueToDropdownOption(value) : null}
        onChange={(val) => onChange(val ? val.value : null)}
        onCreateOption={(val) => onChange(val)}
        isClearable
        blurInputOnSelect
        {...field}
        onBlur={(e) => {
          field.onBlur();
          onBlur?.(e);
        }}
      />
      {fieldState.error && (
        <Helper icon={<Helper.Icon variant="error" />}>{fieldState.error.message}</Helper>
      )}
    </div>
  );
};

const BillDetailsFormPurchaseOrderNumberInput: FC<BillDetailsFormInputProps> = ({
  control,
  onBlur,
}) => {
  const { field } = useController({
    name: "purchaseOrderNumber",
    control: control,
    defaultValue: "",
  });

  return (
    <TextInput
      label="PO number (optional)"
      {...field}
      onBlur={(e) => {
        field.onBlur();
        onBlur?.(e);
      }}
    />
  );
};

const BillDetailsFormMemoInput: FC<BillDetailsFormInputProps> = ({ control, onBlur }) => {
  const { field } = useController({
    name: "memo",
    control: control,
    defaultValue: "",
  });

  return (
    <TextInput
      label="Internal memo (optional)"
      {...field}
      onBlur={(e) => {
        field.onBlur();
        onBlur?.(e);
      }}
    />
  );
};

const shouldUpdateDateValue = (prevDate: Dayjs | null, nextDate: Dayjs | null): boolean => {
  if (isDayjs(prevDate) && isDayjs(nextDate)) {
    return !prevDate.isSame(nextDate);
  }
  return prevDate !== nextDate;
};

const shouldUpdateMoneyValue = (prevValue: Money | null, nextValue: Money | null): boolean => {
  if (!prevValue || !nextValue) {
    return prevValue !== nextValue;
  }
  return !equal(prevValue, nextValue);
};

// NB(alex): Feel free to move this to a shared util if it's needed in more places.
const getBillUpdater = (
  bill: BillSummaryRep.Complete,
  data: BillDetailsFormInputs
): BillRep.Updater => {
  const amount: Money | null =
    data.amount.amount && data.amount.currency
      ? {
          amount: data.amount.amount,
          currency: data.amount.currency.value,
        }
      : null;

  return {
    ...(bill.payeeGuid !== (data.payee?.guid ?? null) && {
      payeeGuid: data.payee?.guid ?? null,
    }),
    ...(shouldUpdateMoneyValue(bill.amount ?? null, amount ?? null) && {
      amount: amount,
    }),
    ...(shouldUpdateDateValue(toDayjsOrNull(bill.invoiceDate), data.invoiceDate) && {
      invoiceDate: data.invoiceDate?.format("YYYY-MM-DD") ?? null,
    }),
    ...(shouldUpdateDateValue(toDayjsOrNull(bill.invoiceDueDate), data.invoiceDueDate) && {
      invoiceDueDate: data.invoiceDueDate?.format("YYYY-MM-DD") ?? null,
    }),

    // NB(alex): We need to default `""` to `null` or else changes will be registered & submitted. Would be nice to clean this logic up somehow, this feels like a hack.
    ...(bill.invoiceNumber !== (data.invoiceNumber || null) && {
      invoiceNumber: data.invoiceNumber || null,
    }),
    ...(bill.paymentTerms !== (data.paymentTerms || null) && {
      paymentTerms: data.paymentTerms || null,
    }),
    ...(bill.purchaseOrderNumber !== (data.purchaseOrderNumber || null) && {
      purchaseOrderNumber: data.purchaseOrderNumber || null,
    }),
    ...(bill.memo !== (data.memo || null) && {
      memo: data.memo || null,
    }),
  };
};

type BillDetailsFormProps = {
  bill: BillSummaryRep.Complete;
  form: ReturnType<typeof useBillDetailsForm>;
  onCreatePayment: (formValues: BillDetailsFormInputs) => void;
  onDeleteSuccessful: () => void;
  onMarkAsPaid: () => void;
  onDraftBillSavedAsOpenSuccess: () => void;
  onShowPaymentDetails: () => void;
  onCreateNewPayee: (defaultPayeeName: string) => void;
  onLeaveComment: () => void;
};

const BillDetailsForm: FC<BillDetailsFormProps> = ({
  bill,
  form,
  onCreatePayment,
  onDeleteSuccessful,
  onMarkAsPaid,
  onDraftBillSavedAsOpenSuccess,
  onShowPaymentDetails,
  onCreateNewPayee,
  onLeaveComment,
}) => {
  const billPayLineItemsEnabled = useFeatureFlag("BILL_PAY_LINE_ITEMS_UI");

  const { approvalSummary } = bill;
  const { numberOfApprovals, numberOfApprovalsRequested, status: approvalStatus } = approvalSummary;
  const businessMember = useBusinessMember();
  const billApprovals = useBillApprovals(bill.id);
  const billApprovalIdForCurrentUserToGrant = billApprovals.find(
    (billApproval) =>
      billApproval.status === "Requested" &&
      billApproval.businessMemberGuid === businessMember?.guid
  )?.id;
  const isApprovalFromCurrentUserRequested =
    approvalStatus === "Partial" && Boolean(billApprovalIdForCurrentUserToGrant);
  const showApprovalGrantControls =
    isApprovalFromCurrentUserRequested && !(bill.state === BillRep.State.Draft);
  const { mutate: grantBillApproval, isPending: isGrantingBillApproval } =
    useGrantBillApprovalMutation(bill.id, {
      onSuccess: () => {
        notify("success", "Bill approved");
      },
    });

  const {
    control,
    formState: { isValid: isFormValid },
  } = form;

  const navigate = useNavigate();
  const { search } = useLocation();

  const refreshBillQuery = useRefreshBillQuery(bill.id, {
    type: "all", // Ensures bill query gets refreshed even if the page has been unmounted because some of our updates are debounced.
  });

  const { mutate: saveBill, isPending: isUpdatingBillState } = useSaveAsBillMutation({
    onSuccess: (bill) => {
      notify("success", "Bill saved", {
        action: {
          text: "View bill",
          onClick: () => {
            navigate(`/payments/bills/${bill.id}${search}`);
          },
        },
      });
      onDraftBillSavedAsOpenSuccess();
      refreshBillQuery();
    },
  });

  const { mutate: updateBillStateToPaidMutation, isPending: isUpdatingBillStateToPaid } =
    useUpdateBillStateMutation({
      onSuccess: (bill) => {
        notify("success", "Bill marked as paid", {
          action: {
            text: "View bill",
            onClick: () => {
              navigate(`/payments/bills/${bill.id}${search}`);
            },
          },
        });
        onMarkAsPaid();
        refreshBillQuery();
      },
    });

  const setAutosaveState = useSetRecoilState(billDetailsAutosaveState);
  const resetAutosaveStatus = useCallback(
    () =>
      setAutosaveState((current) =>
        current.saveStatus === "saved" ? { ...current, saveStatus: "idle" } : current
      ),
    [setAutosaveState]
  );
  const saveIndicatorIdleTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Clear the save indicator timeout (and reset the autosave status) if the component unmounts before it fires.
  useEffect(
    () => () => {
      const saveIndicatorIdleTimeout = saveIndicatorIdleTimeoutRef.current;
      if (saveIndicatorIdleTimeout) {
        resetAutosaveStatus();
        clearTimeout(saveIndicatorIdleTimeout);
      }
    },
    [resetAutosaveStatus]
  );

  const { mutateAsync: updateBill } = useUpdateBillMutation(bill.id, {
    onError: () => {
      notify("error", "Something went wrong! Please try again.");
      setAutosaveState((current) => ({ ...current, saveStatus: "idle" }));
    },
    onSuccess: () => {
      setAutosaveState((current) => ({ ...current, saveStatus: "saved" }));
      saveIndicatorIdleTimeoutRef.current = setTimeout(() => {
        resetAutosaveStatus();
        saveIndicatorIdleTimeoutRef.current = null;
      }, SAVE_INDICATOR_IDLE_DELAY);
      refreshBillQuery();
    },
  });

  const onInputBlur = debounce(
    useCallback(async () => {
      const data = form.getValues();
      const updater = getBillUpdater(bill, data);

      if (Object.keys(updater).length > 0) {
        setAutosaveState((current) => ({ ...current, saveStatus: "saving" }));
        await updateBill(updater);
      }
    }, [bill, form, setAutosaveState, updateBill]),
    AUTOSAVE_DEBOUNCE_DELAY
  );

  // We assume the bill's state is either: Draft | Open
  const onFormSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      if (showApprovalGrantControls) {
        e.preventDefault();
      } else if (bill.state === BillRep.State.Draft) {
        e.preventDefault();
        saveBill({ billId: bill.id });
      } else {
        return form.handleSubmit(async (data) => {
          const updater = getBillUpdater(bill, data);
          if (Object.keys(updater).length > 0) {
            await updateBill(updater);
          }
          onCreatePayment(data);
        })(e);
      }
    },
    [showApprovalGrantControls, bill, saveBill, form, onCreatePayment, updateBill]
  );

  const [isDeleteBillOpen, setIsDeleteBillOpen] = useState(false);

  const isBillFullyPaid = getIsBillFullyPaid(bill);

  const billPercentPaid = getBillPercentPaid(bill);

  const relatedPayments = useBillRelatedUnitCoPayments({ billIds: [bill.id] });
  const hasRelatedPayments = relatedPayments.length > 0;

  const isAllowedToPayBill = useHasPermission("payment:create");
  const isSubmitBtnEnabled =
    bill.state === BillRep.State.Draft
      ? isFormValid
      : isFormValid && !isBillFullyPaid && isAllowedToPayBill && approvalStatus !== "Partial";

  const submitBtnTooltipContent = (() => {
    if (isSubmitBtnEnabled) {
      return;
    }
    if (!isFormValid) {
      return "Please fill in all required fields.";
    }
    if (isBillFullyPaid) {
      return "This bill has already been paid.";
    }
    if (approvalStatus === "Partial") {
      return "This bill is pending approval.";
    }
    if (!isAllowedToPayBill) {
      return "You don’t have permission to pay this bill.";
    }
  })();

  const isMobile = useIsMobile();

  return (
    <>
      <form className={styles.form} onSubmit={onFormSubmit}>
        <DetailsSidebarBody
          main={
            <DetailsSidebarBody.Main className={styles.main}>
              <DetailsSidebarBody.Section>
                <FieldsetV2>
                  {bill.duplicateOfBillId && (
                    <DuplicateBillBanner duplicateBillId={bill.duplicateOfBillId} />
                  )}
                  <FieldsetV2.Legend size={16} weight="bold">
                    Bill info
                  </FieldsetV2.Legend>

                  <BillDetailsFormPayeeDropdown
                    bill={bill}
                    control={control}
                    onBlur={onInputBlur}
                    onShowPaymentDetails={onShowPaymentDetails}
                    onCreateNewPayee={onCreateNewPayee}
                  />

                  <BillDetailsFormMoneyAmountInput control={control} onBlur={onInputBlur} />

                  <FieldsetV2.Row columns={2}>
                    <BillDetailsFormInvoiceDateInput control={control} onBlur={onInputBlur} />

                    <BillDetailsFormDueDateInput control={control} onBlur={onInputBlur} />
                  </FieldsetV2.Row>

                  <FieldsetV2.Row columns={2}>
                    <BillDetailsFormInvoiceNumberInput control={control} onBlur={onInputBlur} />

                    <BillDetailsFormPaymentTermsInput control={control} onBlur={onInputBlur} />
                  </FieldsetV2.Row>

                  <BillDetailsFormPurchaseOrderNumberInput control={control} onBlur={onInputBlur} />

                  <BillDetailsFormMemoInput control={control} onBlur={onInputBlur} />
                </FieldsetV2>
              </DetailsSidebarBody.Section>

              {hasRelatedPayments && (
                <AuxiliarySection
                  title="Payments"
                  cornerContent={
                    billPercentPaid !== undefined ? <Text>Bill {billPercentPaid}% paid</Text> : null
                  }
                >
                  <BillRelatedPaymentsTable bill={bill} />
                </AuxiliarySection>
              )}

              <AuxiliarySection
                title="Approvers"
                cornerContent={
                  numberOfApprovalsRequested > 0 && bill.state !== BillRep.State.Draft ? (
                    <Text>
                      {numberOfApprovals} of {numberOfApprovalsRequested} approved
                    </Text>
                  ) : null
                }
              >
                <BillApprovers billId={bill.id} />
              </AuxiliarySection>
            </DetailsSidebarBody.Main>
          }
          footer={
            <DetailsSidebarBody.Footer
              className={classNames(showApprovalGrantControls && styles["footer-forApproval"])}
            >
              {billPayLineItemsEnabled && isMobile && <BillLineItemsMobile billId={bill.id} />}

              <div className={styles.footerInner}>
                {showApprovalGrantControls && (
                  <>
                    <Button
                      variant="primary"
                      fullWidth
                      isLoading={isGrantingBillApproval}
                      onClick={() =>
                        grantBillApproval({ billApprovalId: billApprovalIdForCurrentUserToGrant! })
                      }
                    >
                      <CheckCircle size={20} />
                      Approve bill
                    </Button>
                    <Button variant="tertiary" fullWidth onClick={() => onLeaveComment()}>
                      <ChatDots size={20} />
                      Leave a comment
                    </Button>
                  </>
                )}

                {!showApprovalGrantControls && (
                  <>
                    <Button
                      variant="primary"
                      fullWidth
                      type="submit"
                      // NB(lev): We don't use the update bill mutation pending state to control the loading state
                      // here, because we don't want to show the loading state when the bill is being auto-saved.
                      // We use the form state instead, to show the loading state when the form is explicitly submitted.
                      isLoading={
                        isUpdatingBillState ||
                        isUpdatingBillStateToPaid ||
                        form.formState.isSubmitting
                      }
                      disabled={!isSubmitBtnEnabled}
                      tooltip={submitBtnTooltipContent}
                    >
                      {bill.state === BillRep.State.Draft ? (
                        <>Save as bill</>
                      ) : (
                        <>
                          Create payment <ArrowLineUpRight size={20} />
                        </>
                      )}
                    </Button>

                    <Menu
                      button={
                        <Button variant="tertiary" isSquare>
                          <DotsThreeVertical size={20} />
                        </Button>
                      }
                      placement={{ bottom: 48 }}
                    >
                      {bill.state !== BillRep.State.Paid && approvalStatus !== "Partial" && (
                        <Menu.Item
                          icon={<CheckSquareOffset />}
                          onClick={async () => {
                            await updateBillStateToPaidMutation({
                              billId: bill.id,
                              state: BillRep.State.Paid,
                            });
                          }}
                        >
                          Mark bill as paid
                        </Menu.Item>
                      )}
                      <Menu.Item
                        icon={<Trash />}
                        variant="danger"
                        onClick={() => setIsDeleteBillOpen(true)}
                      >
                        Delete bill
                      </Menu.Item>
                    </Menu>
                  </>
                )}
              </div>
            </DetailsSidebarBody.Footer>
          }
        />
      </form>

      {isDeleteBillOpen && (
        <DeleteBillModal
          bill={bill}
          onClose={() => setIsDeleteBillOpen(false)}
          onDeleteSuccessful={onDeleteSuccessful}
        />
      )}
    </>
  );
};

export default BillDetailsForm;
