import { FC } from "react";
import useRemoveUserInvitationMutation from "resources/user-invitations/mutations/useRemoveUserInvitationMutation";
import ModalV4 from "ui/overlay/ModalV4";

export type RemoveInvitationModalState = {
  userInvitationGuid: string;
  displayName: string;
};

type Props = RemoveInvitationModalState & {
  onClose: () => void;
};

const RemoveInvitationModal: FC<Props> = ({ userInvitationGuid, displayName, onClose }) => {
  const { mutateAsync: removeUserInvitation, isPending } = useRemoveUserInvitationMutation();

  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Form
        onSubmit={async (_e, { onClose }) => {
          await removeUserInvitation(userInvitationGuid);
          onClose();
        }}
      >
        <ModalV4.Header>Cancel {displayName}’s invite?</ModalV4.Header>

        <ModalV4.Body>
          <ModalV4.Body.Text>
            Removing this invite will make the invite link {displayName} received invalid and they
            will no longer be able to join you on Highbeam.
          </ModalV4.Body.Text>
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.Footer.SubmitButton variant="danger" autoFocus isLoading={isPending}>
            Cancel invite
          </ModalV4.Footer.SubmitButton>

          <ModalV4.Footer.CloseButton />
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default RemoveInvitationModal;
