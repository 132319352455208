import React, { useState } from "react";

import styles from "./DashboardUserMenu.module.scss";
import DashboardUserMenuButton from "./DashboardUserMenuButton";
import DashboardUserMenuDropdown from "./DashboardUserMenuDropdown";

const DashboardUserMenu: React.FC = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const handleOpenUserMenu = () => {
    setIsUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setIsUserMenuOpen(false);
  };

  return (
    <div className={styles.menu}>
      <DashboardUserMenuButton onOpenUserMenu={handleOpenUserMenu} />
      {isUserMenuOpen ? <DashboardUserMenuDropdown onCloseUserMenu={handleCloseUserMenu} /> : null}
    </div>
  );
};

export default DashboardUserMenu;
