import classNames from "classnames";
import styles from "modules/plaid/components/connection-cards/PlaidConnectionCard/PlaidConnectionCard.module.scss";
import { CSSProperties } from "react";
import Card from "ui/data-display/Card";

export type PlaidConnectionCardSize = "small" | "normal";

type Props = {
  size?: PlaidConnectionCardSize;
  children: React.ReactNode;
  style?: CSSProperties;
};

const PlaidConnectionCard: React.FC<Props> = ({ size = "normal", children, style }) => {
  return (
    <>
      <Card
        className={classNames(styles.plaidConnectionCard, { [styles.small]: size === "small" })}
        style={style}
        shadow="xs"
      >
        {children}
      </Card>
    </>
  );
};

export default PlaidConnectionCard;
