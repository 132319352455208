import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { FC, Suspense, useEffect, useMemo, useState } from "react";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import getBankAccountDropdownOptions from "resources/bank-accounts/utils/getBankAccountDropdownOptions";
import getBankAccountOptionsByUnitId from "resources/bank-accounts/utils/getBankAccountOptionsByUnitId";
import Shimmer from "ui/feedback/Shimmer";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import styles from "./AccountsStatements.module.scss";
import AccountsStatementsLoading from "./AccountsStatementsLoading";
import AccountStatements from "./AccountStatements";
import AccountStatementsCount from "./AccountStatementsCount";
import AccountTaxForms from "./AccountTaxForms/AccountTaxForms";
import AccountTaxFormsCount from "./AccountTaxForms/AccountTaxFormsCount";
import {
  DocumentsDropdownOption,
  useActiveDocumentTypeSearchParam,
} from "./utils/useActiveDocumentTypeSearchParam";

const ALL_BANK_ACCOUNTS_OPTION = {
  label: "All accounts",
  value: "all",
};

const ALL_DOCUMENT_TYPES: Option[] = [
  {
    label: "Statements",
    value: "statements",
  },
  {
    label: "Tax forms",
    value: "tax",
  },
];

const DOCUMENT_TYPES_BY_VALUE = ALL_DOCUMENT_TYPES.reduce(
  (map: Record<string, Option>, option: Option) => ({
    ...map,
    [option.value]: option,
  }),
  {}
);

type ContentProps = {
  selectedUnitCoAccountId: string;
};

const StatementsContent: FC<ContentProps> = ({ selectedUnitCoAccountId }) => {
  return (
    <Suspense fallback={<AccountsStatementsLoading />}>
      {selectedUnitCoAccountId === ALL_BANK_ACCOUNTS_OPTION.value ? (
        <AccountStatements />
      ) : (
        <AccountStatements accountId={selectedUnitCoAccountId} />
      )}
    </Suspense>
  );
};

const TaxFormsContent: FC<ContentProps> = ({ selectedUnitCoAccountId }) => {
  return (
    <Suspense fallback={<AccountsStatementsLoading />}>
      {selectedUnitCoAccountId === ALL_BANK_ACCOUNTS_OPTION.value ? (
        <AccountTaxForms />
      ) : (
        <AccountTaxForms accountId={selectedUnitCoAccountId} />
      )}
    </Suspense>
  );
};

type DocumentCountProps = {
  activeDocumentType: DocumentsDropdownOption;
  selectedUnitCoAccountId: string;
};

const DocumentCount: FC<DocumentCountProps> = ({ activeDocumentType, selectedUnitCoAccountId }) => {
  return (
    <Suspense fallback={<Shimmer />}>
      {activeDocumentType === "statements" ? (
        selectedUnitCoAccountId === ALL_BANK_ACCOUNTS_OPTION.value ? (
          <AccountStatementsCount />
        ) : (
          <AccountStatementsCount accountId={selectedUnitCoAccountId} />
        )
      ) : null}
      {activeDocumentType === "tax" ? (
        selectedUnitCoAccountId === ALL_BANK_ACCOUNTS_OPTION.value ? (
          <AccountTaxFormsCount />
        ) : (
          <AccountTaxFormsCount accountId={selectedUnitCoAccountId} />
        )
      ) : null}
    </Suspense>
  );
};

const AccountsStatementsPage = () => {
  const [activeDocumentType, setActiveDocumentType] = useActiveDocumentTypeSearchParam();
  const accountOptions = useBankAccounts({
    status: "all",
    select: (bankAccounts) => getBankAccountDropdownOptions(bankAccounts),
  });

  const accountOptionsById = useMemo(() => {
    return getBankAccountOptionsByUnitId(accountOptions);
  }, [accountOptions]);

  const [selectedUnitCoAccountId, setSelectedUnitCoAccountId] = useState("");

  useEffect(() => {
    if (selectedUnitCoAccountId === "") {
      setSelectedUnitCoAccountId(accountOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Documents</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header>
          <DashboardPage.Header.BackCaret to="/accounts" />
          <DashboardPage.Header.Title>Documents</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <DashboardPage.Section>
          <div className={styles.content}>
            <div className={styles.infoBar}>
              <div className={styles.actions}>
                <Dropdown
                  label="Document type"
                  onChange={(e) => {
                    setActiveDocumentType(e.value as DocumentsDropdownOption);
                  }}
                  value={DOCUMENT_TYPES_BY_VALUE[activeDocumentType]}
                  options={ALL_DOCUMENT_TYPES}
                  className={styles.dropdown}
                  hideCursor
                />
                {accountOptions.length > 1 && (
                  <Dropdown
                    label="Show statements for"
                    onChange={(e) => {
                      setSelectedUnitCoAccountId(e.value);
                    }}
                    value={accountOptionsById[selectedUnitCoAccountId]}
                    options={accountOptions}
                    className={styles.dropdown}
                    hideCursor
                  />
                )}
              </div>
              <DocumentCount
                activeDocumentType={activeDocumentType}
                selectedUnitCoAccountId={selectedUnitCoAccountId}
              />
            </div>

            {activeDocumentType === "statements" ? (
              <StatementsContent selectedUnitCoAccountId={selectedUnitCoAccountId} />
            ) : null}
            {activeDocumentType === "tax" ? (
              <TaxFormsContent selectedUnitCoAccountId={selectedUnitCoAccountId} />
            ) : null}
          </div>
        </DashboardPage.Section>
      </DashboardPage>
    </>
  );
};

export default AccountsStatementsPage;
