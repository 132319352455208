import classNames from "classnames";
import { CSSProperties, FC } from "react";

import styles from "./Shimmer.module.scss";

export type ShimmerHeight = 8 | 20;
export type ShimmerWidth = "full" | 128 | 64;

type Props = {
  className?: string;
  style?: CSSProperties;
  height?: ShimmerHeight;
  width?: ShimmerWidth;
};

const Shimmer: FC<Props> = ({ className, style, height = 8, width = "full" }) => {
  return (
    <div
      className={classNames(
        styles.shimmer,
        className,
        height && styles[`height--${height}`],
        width && styles[`width--${width}`]
      )}
      style={style}
    />
  );
};

export default Shimmer;
