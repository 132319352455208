import React, { type PropsWithChildren } from "react";
import useBusinessMember from "resources/business-members/queries/useBusinessMember";
import useBusiness from "resources/business/queries/useBusiness";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useUserRoleMembershipDisplayName from "resources/user-roles/queries/useUserRoleMembershipDisplayName";
import colors from "styles/colors";
import Avatar from "ui/data-display/Avatar";
import Pill from "ui/data-display/Pill";
import Text from "ui/typography/Text";
import getBusinessSafeDisplayName from "utils/business/getBusinessSafeDisplayName";

import styles from "./DashboardUserMenuDropdownHeader.module.scss";

const DashboardUserMenuDropdownHeader: React.FC = () => {
  const business = useBusiness();
  const businessMember = useBusinessMember();
  const userRoleMembershipDisplayName = useUserRoleMembershipDisplayName(
    business.guid,
    useUserGuid()
  );

  const businessDisplayName = getBusinessSafeDisplayName(business);
  const businessMemberDisplayName = businessMember?.displayName;
  const businessMemberInitials = businessMember?.initials ?? "";

  return (
    <div className={styles.header}>
      <div className={styles.text}>
        <div className={styles.primary}>
          <BusinessMemberDisplayName>{businessMemberDisplayName}</BusinessMemberDisplayName>
          {userRoleMembershipDisplayName === null ? null : (
            <UserRoleMembershipDisplayName>
              {userRoleMembershipDisplayName}
            </UserRoleMembershipDisplayName>
          )}
        </div>
        <BusinessDisplayName>{businessDisplayName}</BusinessDisplayName>
      </div>
      <Avatar color="purple-light" size={32} initials={businessMemberInitials} />
    </div>
  );
};

export default DashboardUserMenuDropdownHeader;

const BusinessMemberDisplayName: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size={14} weight="bold">
      {children}
    </Text>
  );
};

const UserRoleMembershipDisplayName: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Pill size={20} color="grey-light" className={styles.roleMembershipDisplayName}>
      {children}
    </Pill>
  );
};

const BusinessDisplayName: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size={12} weight="regular" color={colors.grey[600]}>
      {children}
    </Text>
  );
};
