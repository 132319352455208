import { useReceivedPaymentInfoFlexpaneContext } from "dialogs/ReceivedPaymentInfoFlexpane/context/ReceivedPaymentInfoFlexpaneProvider";
import RetryAchReceivedPaymentModal from "modules/failed-debits/RetryAchReceivedPaymentModal";
import { useRetryAchReceivedPaymentModal } from "modules/failed-debits/RetryAchReceivedPaymentModal/state/RetryAchReceivedPaymentModalState";
import useBankAccountByUnitCoDepositAccountId from "resources/bank-accounts/queries/useBankAccountByUnitCoDepositAccountId";
import Button from "ui/inputs/Button";
import Flexpane from "ui/overlay/Flexpane";
import Text from "ui/typography/Text";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";

const ReceivedPaymentInfoFlexpaneRetryButton = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();

  //We can leverage the existing payment create scope until we get a request to tighten the permissions
  const isAllowedToRetryPayment = useIsAllowedToApprovePayments();
  const { open: openRetryAchReceivedPaymentModal } = useRetryAchReceivedPaymentModal();
  const bankAccount = useBankAccountByUnitCoDepositAccountId(
    receivedPayment.relationships.account.data.id
  );
  const needsFunding =
    receivedPayment.attributes.returnReason === "InsufficientFunds" &&
    (bankAccount?.availableBalance || 0) < receivedPayment.attributes.amount;

  return (
    <>
      <RetryAchReceivedPaymentModal />
      <Flexpane.Section>
        <div>
          <Button
            variant="primary"
            size="md"
            onClick={() => {
              openRetryAchReceivedPaymentModal({ receivedPaymentId: receivedPayment.id });
            }}
            disabled={!isAllowedToRetryPayment || needsFunding}
            tooltip={
              (!isAllowedToRetryPayment && (
                <Text size={14}>You don’t have permission to retry payments.</Text>
              )) ||
              (needsFunding && (
                <Text size={14}>
                  You don’t have enough funds in the account to retry this payment.
                </Text>
              ))
            }
          >
            Retry debit
          </Button>
        </div>
      </Flexpane.Section>
    </>
  );
};

export default ReceivedPaymentInfoFlexpaneRetryButton;
