import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const makeQueryKey = (businessGuid: string) => ["accountingAccounts", { businessGuid }];

const useAccountingAccountsQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: makeQueryKey(businessGuid),
    queryFn: () => highbeamApi.accountingAccount.search(businessGuid),
  });
};

const useAccountingAccounts = () => {
  const { data } = useSuspenseQuery(useAccountingAccountsQueryOptions());
  return data;
};

// TODO(lev): This is not used anywhere yet, but we probably want to
// invalidate the query when a new accounting platform is connected.
export const useRefreshAccountingAccountsQuery = () => {
  const businessGuid = useBusinessGuid();
  return useRefreshQuery(makeQueryKey(businessGuid));
};

export default useAccountingAccounts;
