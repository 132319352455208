import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getInternationalWireCountry } from "pages/SendMoneyPage/internationalWires";
import {
  achTransferAddenda,
  achTransferDescription,
  SWIFT_FEE,
  wireTransferDescription,
} from "pages/SendMoneyPage/utils";
import { FC, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate } from "react-router-dom";
import PaymentDetailCreatorRep from "reps/payments-v2/PaymentDetailCreatorRep";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import BillPaymentPayloadForm from "resources/bills/forms/BillPaymentPayloadForm";
import useBillPaymentPayloadForm from "resources/bills/forms/BillPaymentPayloadForm/useBillPaymentPayloadForm";
import useSendBillPaymentMutation from "resources/bills/mutations/useSendBillPaymentMutation";
import useBillPaymentMethods from "resources/bills/queries/useBillPaymentMethods";
import useReadyForPaymentBill from "resources/bills/queries/useReadyForPaymentBill";
import useRefreshBillsQueries from "resources/bills/queries/useRefreshBillsQueries";
import getBillFixedSide from "resources/bills/utils/getBillFixedSide";
import useInternationalWireQuoteQueryOptions from "resources/international-wires/queries/useInternationalWireQuoteQueryOptions";
import useUpdatePayeeMutation from "resources/payees/mutations/useUpdatePayeeMutation";
import usePayee from "resources/payees/queries/usePayee";
import colors from "styles/colors";
import CountBadge from "ui/data-display/CountBadge";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Pill from "ui/data-display/Pill";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import FullPageModal from "ui/overlay/FullPageModal";
import useFullPageModalContext from "ui/overlay/FullPageModal/context/useFullPageModalContext";
import Text from "ui/typography/Text";
import useElementSize from "utils/device/useElementSize";
import { roundEpsilon } from "utils/math";
import { getCentsFromDollars } from "utils/money";
import IncorrectDataReceivedError from "utils/react-query/IncorrectDataReceivedError";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import { pluralize } from "utils/string";
import { v4 as uuidv4 } from "uuid";

import styles from "./BillPaymentFullPageModal.module.scss";

type BillPaymentPageProps = {
  billIds: string[];
  onCancel: () => void;
};

const BillPaymentPage: FC<BillPaymentPageProps> = ({ billIds, onCancel }) => {
  const { elementRef: headerContainerRef, height: headerContainerHeight } = useElementSize();
  const { onClose } = useFullPageModalContext();

  const bill = useReadyForPaymentBill(billIds[0]);
  const payee = usePayee(bill.payeeGuid, { required: true });

  const isBillAmountInUsd = bill.amount.currency === "USD";

  const bankAccounts = useBankAccounts({ status: "open" });

  const billPaymentMethods = useBillPaymentMethods({
    currencyType: isBillAmountInUsd ? "usd" : "international",
    payee: payee,
  });

  const defaultBillPaymentMethod = useMemo(() => {
    const firstBillPaymentMethod = billPaymentMethods[0];
    const firstBillBillPaymentMethodWithPaymentMethod = billPaymentMethods.find(
      (billPaymentMethod) => billPaymentMethod.payeePaymentMethod
    );

    if (
      !firstBillBillPaymentMethodWithPaymentMethod ||
      // We still would rather show an undefined ACH payment method as a default if the first defined payment method is wire.
      firstBillBillPaymentMethodWithPaymentMethod?.billPaymentMethodType === "domestic-wire"
    ) {
      return firstBillPaymentMethod;
    } else {
      return firstBillBillPaymentMethodWithPaymentMethod;
    }
  }, [billPaymentMethods]);

  // NB(alex): I actually have no idea what pattern we're going to use for handling an array of forms.
  const form = useBillPaymentPayloadForm({
    bill: bill,
    defaultValues: {
      sendAmount: isBillAmountInUsd ? bill.remainingAmount.amount : "",
      receiveAmount: isBillAmountInUsd ? "" : bill.remainingAmount.amount,
      purposeCode: null,
      billPaymentMethod: defaultBillPaymentMethod,
      paymentDate: dayjs(),
      fromBankAccount:
        bankAccounts.find(
          (bankAccount) => bankAccount.guid === payee.lastTransferBankAccountGuid
        ) ?? bankAccounts[0],
      paymentDescription: `Invoice ${bill.invoiceNumber} payment via Highbeam`,
      sendConfirmationEmailToPayee: Boolean(payee.emailAddress),
      payeeEmail: payee.emailAddress,
    },
  });

  const billPaymentMethodType = form.watch("billPaymentMethod.billPaymentMethodType");
  const selectedBillPaymentMethodCurrency = form.watch(
    "billPaymentMethod.payeePaymentMethod.currency"
  );
  const fixedSide = getBillFixedSide(bill);

  const { data: quote } = useQuery({
    ...useInternationalWireQuoteQueryOptions(selectedBillPaymentMethodCurrency),
    enabled:
      Boolean(selectedBillPaymentMethodCurrency) &&
      billPaymentMethodType === "international-wire-local-currency",
  });

  // Set initial converted receive or send amount for international wires.
  useEffect(() => {
    if (quote) {
      const formValues = form.getValues();

      if (fixedSide === "Send") {
        const receiveAmount = roundEpsilon(Number(bill.amount.amount) * quote.rate, 2).toString();

        if (formValues.receiveAmount !== receiveAmount) {
          form.reset({ ...formValues, receiveAmount: receiveAmount });
        }
      } else {
        const sendAmount = roundEpsilon(Number(bill.amount.amount) * quote.inverse, 2).toString();

        if (formValues.sendAmount !== sendAmount) {
          form.reset({ ...formValues, sendAmount: sendAmount });
        }
      }
    }
  }, [form, quote, isBillAmountInUsd, bill, fixedSide]);

  const sendAmount = Number(form.watch("sendAmount") || 0);
  const sendAmountInputInCents = roundEpsilon(sendAmount * 100, 2);
  const totalSendAmountInCents =
    billPaymentMethodType === "international-wire-usd-swift"
      ? sendAmountInputInCents + SWIFT_FEE
      : sendAmountInputInCents;
  const paymentAmountTotalInCents = totalSendAmountInCents; // Explicitly showing we actually want to add these together once we support paying multiple bills.

  const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());

  const refreshBillSummaries = useRefreshBillsQueries();

  const { mutate: sendBillPayment, isPending: isSendingPayment } = useSendBillPaymentMutation({
    onSuccess: async (data) => {
      setIdempotencyKey(uuidv4());

      // NB(alex): We should handle other statuses soon as well.
      if (data.status === "Sent") {
        await refreshBillSummaries();
        notify("success", "Payment sent");
        onClose();
      }
    },
    onError: () => {
      notify("error", "Something went wrong. Please try again or contact support.");
    },
  });

  const { mutate: updatePayee } = useUpdatePayeeMutation();

  const onSubmit = form.handleSubmit((data) => {
    const notificationEmailAddress = data.sendConfirmationEmailToPayee
      ? (data.payeeEmail ?? null) // NB(alex): `data.payeeEmail` should always be defined if `data.sendConfirmationEmailToPayee` is `true`, but I'm not sure how to generate that type with zod.
      : null;

    const tags = {
      billIds: bill.id,
      payeeGuid: payee.guid,
      ...(notificationEmailAddress ? { payeeEmail: notificationEmailAddress } : {}),
    };

    switch (data.billPaymentMethod.payeePaymentMethod.method) {
      case "ach":
        sendBillPayment({
          Ach: {
            accountNumber: data.billPaymentMethod.payeePaymentMethod.accountNumber,
            addenda: achTransferAddenda(data.paymentDescription) ?? "", // TODO(alex): Check if we can update the underlying type to undefined or if we should change the util to return `""`.
            amount: paymentAmountTotalInCents,
            description: achTransferDescription(data.paymentDescription),
            direction: PaymentDetailCreatorRep.MoneyDirection.Credit,
            fromBankAccountGuid: data.fromBankAccount.guid,
            idempotencyKey: idempotencyKey,
            payeeName: payee.name,
            routingNumber: data.billPaymentMethod.payeePaymentMethod.routingNumber,
            sameDay: true,
            tags: tags,
          },
        });
        break;
      case "domestic-wire":
        if (!payee.address) {
          notify("error", "Payee address is missing.");
          return;
        }

        sendBillPayment({
          DomesticWire: {
            amount: paymentAmountTotalInCents,
            accountNumber: data.billPaymentMethod.payeePaymentMethod.accountNumber,
            routingNumber: data.billPaymentMethod.payeePaymentMethod.routingNumber,
            address: {
              street: payee.address.addressLine1,
              street2: payee.address.addressLine2,
              city: payee.address.city,
              state: payee.address.state,
              postalCode: payee.address.zipCode,
              country: "US",
            },
            description: wireTransferDescription(data.paymentDescription),
            direction: PaymentDetailCreatorRep.MoneyDirection.Credit,
            fromBankAccountGuid: data.fromBankAccount.guid,
            payeeName: payee.name,
            idempotencyKey: idempotencyKey,
            tags: tags,
          },
        });
        break;
      case "international-wire":
        const sendAmountInCents = getCentsFromDollars(data.sendAmount); // Always USD

        sendBillPayment({
          InternationalWire: {
            bankAccountGuid: data.fromBankAccount.guid,
            description: data.paymentDescription, // TODO(alex): Not sure how to reconcile `description` and `reason`.
            fixedSide: fixedSide,
            generalPaymentMetadataGuid: null,
            idempotencyKey: idempotencyKey,
            invoiceNumber: bill.invoiceNumber,
            invoiceDate: bill.invoiceDate,
            notificationEmailAddress: notificationEmailAddress,
            payeeGuid: payee.guid,
            // NB(alex): This logic is copied over from send money. I don't think it's great, but we'll need to refactor `supportedInternationalWireCountries` to fix this properly.
            // Part of why this is sketchy is `getInternationalWireCountry` might not necessiarly have a matching `InternationalWireOption`. It works for send money though, so I'm going to assume it works here.
            paymentType: isBillAmountInUsd
              ? "Priority"
              : getInternationalWireCountry(
                  data.billPaymentMethod.payeePaymentMethod.address.country ?? ""
                ).local.deliveryMethod,
            purposeCode: data.purposeCode?.value ?? null,
            reason: data.paymentDescription, // TODO(alex): Not sure how to reconcile `description` and `reason`.
            receiveAmount:
              data.billPaymentMethod.payeePaymentMethod.currency === "USD"
                ? sendAmountInCents
                : Number(data.receiveAmount) *
                  Math.pow(
                    10,
                    2 // NB(alex): This is literally wrong but we need to do this for now to match the backend. The correct thing to do is `getCurrencyDecimalPlaces(data.billPaymentMethod.payeePaymentMethod.currency)`. https://highbeamco.slack.com/archives/C07HGFN06PR/p1726154406956999
                  ),
            receiveCurrency: data.billPaymentMethod.payeePaymentMethod.currency,
            sendAmount: sendAmountInCents,
            tags: tags,
          },
        });
        break;
    }

    // Update the payee's email address if it doesn't match the one entered.
    // NB(alex): I'm not 100% sure if this is what we want, but this is what we do in send money currently.
    if (notificationEmailAddress && payee.emailAddress !== notificationEmailAddress) {
      updatePayee({
        payeeGuid: payee.guid,
        emailAddress: notificationEmailAddress,
      });
    }
  });

  return (
    <div className={styles.container}>
      <div ref={headerContainerRef}>
        <FullPageModal.Header
          onBack={onCancel}
          title={
            <div className={styles.headerTitle}>
              Pay bill <Pill color="grey-light">{pluralize(billIds.length, "bill")}</Pill>
            </div>
          }
        />
      </div>

      <form
        onSubmit={onSubmit}
        className={styles.bodyContainer}
        style={{ height: `calc(100% - ${headerContainerHeight}px)` }}
      >
        <div className={styles.formsListContainer}>
          <div className={styles.formContainer}>
            <BillPaymentPayloadForm
              form={form}
              billId={billIds[0]}
              bankAccounts={bankAccounts}
              billPaymentMethods={billPaymentMethods}
            />
          </div>
        </div>

        <div className={styles.paymentOverviewContainer}>
          <div className={styles.paymentOverviewBody}>
            <div className={styles.paymentOverviewTitle}>
              <Text size={16} weight="bold" color={colors.grey[800]}>
                Payments
              </Text>
              <CountBadge color="grey-200" count={billIds.length} />
            </div>

            <div className={styles.paymentOverviewList}>
              <div className={styles.paymentOverviewListItem}>
                <Text size={14} color={colors.grey[600]}>
                  Bill #{bill.invoiceNumber}
                </Text>
                <Text size={14} color={colors.grey[800]} weight="medium">
                  <MoneyAmount cents={paymentAmountTotalInCents} />
                </Text>
              </div>
            </div>

            <Divider margin={0} className={styles.paymentOverviewDivider} />

            <div className={styles.paymentOverviewTotal}>
              <Text size={16} color={colors.grey[600]}>
                Total
              </Text>
              <Text size={18} color={colors.grey[800]} weight="medium">
                <MoneyAmount cents={paymentAmountTotalInCents} />
              </Text>
            </div>
          </div>

          <div className={styles.paymentOverviewFooter}>
            <Button variant="primary" type="submit" isLoading={isSendingPayment}>
              Complete payment
            </Button>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

type Props = {
  billIds: string[];
  onClose: () => void;
  onCancel: () => void;
};

const BillPaymentFullPageModal: FC<Props> = ({ billIds, onClose, onCancel }) => {
  if (billIds.length !== 1) {
    // NB(alex): Temporary. Will remove this constraint once we support paying multiple bills.
    throw new Error(`billIds.length should be exactly 1, but it was ${billIds.length}.`);
  }

  return (
    <FullPageModal onClose={onClose}>
      <ErrorBoundary
        fallbackRender={({ error }) => {
          if (error instanceof RequiredButNotFoundError) {
            notify("error", "Bill not found.");
            return <Navigate to={"/payments/bills"} />;
          }
          if (error instanceof IncorrectDataReceivedError) {
            notify("error", "Required fields are missing.");
            return <Navigate to={`/payments/bills/${billIds[0]}`} />;
          }
          throw error;
        }}
      >
        <BillPaymentPage billIds={billIds} onCancel={onCancel} />
      </ErrorBoundary>
    </FullPageModal>
  );
};

export default BillPaymentFullPageModal;
