import { FC } from "react";
import { Controller, FormProvider } from "react-hook-form";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import useEditCapitalAccountNameMutation from "resources/capital-accounts/mutations/useEditCapitalAccountNameMutation";
import TextInput from "ui/inputs/TextInput";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/context/useModalContext";

import useEditCapitalAccountNameForm from "./hooks/useEditCapitalAccountNameForm";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const EditCapitalAccountNameForm: FC<Props> = ({ capitalAccountSummary }) => {
  const form = useEditCapitalAccountNameForm({
    defaultValues: {
      name: capitalAccountSummary.name,
    },
  });
  const modalContext = useModalContext();

  const { mutateAsync: editCapitalAccountName, isPending } = useEditCapitalAccountNameMutation({
    capitalAccountGuid: capitalAccountSummary.guid,
  });

  const onSubmit = form.handleSubmit(async (data) => {
    await editCapitalAccountName({
      name: data.name,
    });

    form.reset();
    modalContext.onClose();
  });

  return (
    <FormProvider {...form}>
      <ModalV4.Form onSubmit={onSubmit}>
        <ModalV4.Header>Edit capital account name</ModalV4.Header>

        <ModalV4.Body>
          <Controller
            name="name"
            control={form.control}
            render={({ field, formState }) => (
              <TextInput
                label="Capital account name"
                hasError={Boolean(formState.errors.name)}
                errorMessage={formState.errors.name?.message}
                {...field}
              />
            )}
          />
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.Footer.SubmitButton isLoading={isPending} disabled={!form.formState.isValid}>
            Save changes
          </ModalV4.Footer.SubmitButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </FormProvider>
  );
};

export default EditCapitalAccountNameForm;
