import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFormContext } from "react-hook-form";
import { HighbeamBankAccountTransferOption } from "utils/transfers";
import { z } from "zod";

const schema = z.object({
  amount: z.number().positive(),
  // NB(alex): Once we have a composable `Dropdown`, we should use `repaymentBankAccount: z.custom<BankAccountRep.Complete>` here.
  repaymentBankAccountTransferOption: z.custom<HighbeamBankAccountTransferOption>(
    (value) => Boolean(value),
    "Please select a bank account."
  ),
});

export type RepayHighbeamCardsFormInputs = z.infer<typeof schema>;

type Params = {
  defaultValues: RepayHighbeamCardsFormInputs;
};

const useRepayHighbeamCardsForm = ({ defaultValues }: Params) => {
  return useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });
};

export default useRepayHighbeamCardsForm;

export const useRepayHighbeamCardsFormContext = () => {
  return useFormContext<RepayHighbeamCardsFormInputs>();
};
