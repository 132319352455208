import { captureException } from "@sentry/react";
import BillLineItemRep from "reps/BillLineItemRep";
import { useRefreshBillLineItemsQuery } from "resources/bill-line-items/queries/useBillLineItems";
import { useRefreshBillQuery } from "resources/bills/queries/useBill";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useUpdateBillLineItemMutation = (
  billLineItemId: string,
  billId: string,
  additionalOptions?: MutationAdditionalOptions<BillLineItemRep.Complete, BillLineItemRep.Updater>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        return highbeamApi.billLineItem.update(billLineItemId, variables);
      },

      onSuccess: async () => {
        await Promise.all([refreshBillQuery(), refreshBillLineItemsQuery()]);
      },
      onError: (error) => {
        captureException(error);
      },
    },
    additionalOptions || {}
  );
};

export default useUpdateBillLineItemMutation;
