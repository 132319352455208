import classNames from "classnames";
import { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from "react";

import styles from "./ScrollWithInsetShadow.module.scss";

type Props = {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
};

const ScrollWithInsetShadow: FC<Props> = ({ children, className, style }) => {
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const topMarkerRef = useRef<HTMLDivElement | null>(null);
  const bottomMarkerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const topMarker = topMarkerRef.current;
    const bottomMarker = bottomMarkerRef.current;

    const observerOptions: IntersectionObserverInit = {
      root: scrollRef.current,
      threshold: 0.1, // Fires when at least 10% of the marker is visible
    };

    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.target === topMarker) {
          setIsAtTop(entry.isIntersecting);
        } else if (entry.target === bottomMarker) {
          setIsAtBottom(entry.isIntersecting);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (topMarker) observer.observe(topMarker);
    if (bottomMarker) observer.observe(bottomMarker);

    // Cleanup the observer on component unmount
    return () => {
      if (topMarker) observer.unobserve(topMarker);
      if (bottomMarker) observer.unobserve(bottomMarker);
    };
  }, []);

  return (
    <div
      ref={scrollRef}
      className={classNames(
        className,
        styles.container,
        isAtTop && styles.isAtTop,
        isAtBottom && styles.isAtBottom,
        isAtTop && isAtBottom && styles.isAtTopAndBottom
      )}
      style={style}
    >
      <div ref={topMarkerRef} aria-hidden="true" />
      {children}
      <div ref={bottomMarkerRef} aria-hidden="true" />
    </div>
  );
};

export default ScrollWithInsetShadow;
