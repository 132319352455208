import { Bank, CreditCard, PlusCircle } from "@phosphor-icons/react";
import { getIcon } from "components/Accounts/AccountLabel";
import { comparePlaidBankAccounts } from "modules/plaid/components/connection-cards/PlaidConnectionCardContents/util";
import { ConnectedIntegrationIndicator } from "pages/settings/SettingsPage/ConnectedIntegrationIndicator";
import { ConnectPlaidButton } from "pages/settings/SettingsPage/ConnectPlaidButton";
import DeletePlaidConnectionModal from "pages/settings/SettingsPage/DeletePlaidConnectionModal";
import { useCallback, useState } from "react";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Button from "ui/inputs/Button";
import ScrollWithInsetShadow from "ui/layout/ScrollWithInsetShadow";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { getPlaidBankAccountDisplayName } from "utils/account";

import styles from "./PlaidConnectionCardContents.module.scss";

type Props = {
  connection: PlaidConnectionRep.Complete;
  openPlaidLinkModal: () => void;
};

const PlaidConnectionCardContents: React.FC<Props> = ({ connection, openPlaidLinkModal }) => {
  const [showDeleteConnectionModal, setShowDeleteConnectionModal] = useState(false);
  const isSuperusering = useIsSuperusering();
  const openItemInPlaidDashboard = useCallback(() => {
    const url = `https://dashboard.plaid.com/activity/debugger/${connection.plaidItemId}`;
    window.open(url, "_blank")?.focus();
  }, [connection]);

  return (
    <>
      {showDeleteConnectionModal && (
        <DeletePlaidConnectionModal
          onClose={() => {
            setShowDeleteConnectionModal(false);
          }}
          onDelete={() => {
            setShowDeleteConnectionModal(false);
          }}
          connectionGuid={connection.guid}
          name={connection.institutionName}
        />
      )}
      <div className={styles.plaidConnectionCardContents}>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            {getIcon(connection.institutionId)}
            <Text size={14} weight="bold">
              {connection.institutionName}
            </Text>
          </div>

          <ConnectedIntegrationIndicator isActive={connection.isActive} />
        </div>
        <ScrollWithInsetShadow className={styles.plaidAccountList}>
          {connection.accounts.sort(comparePlaidBankAccounts).map((account) => (
            <div key={account.plaidAccountId} className={styles.plaidAccountRow}>
              {account.accountType === "CREDIT" ? <CreditCard size={20} /> : <Bank size={20} />}
              <Text size={14} weight="medium">
                {getPlaidBankAccountDisplayName(account)}
              </Text>
            </div>
          ))}
        </ScrollWithInsetShadow>
        <ConnectPlaidButton
          variant={connection.isActive ? "tertiary" : "primary"}
          className={styles.addAccountButton}
          openPlaidLinkModal={openPlaidLinkModal}
        >
          {connection.isActive ? (
            <>
              <PlusCircle size={16} /> Add from login
            </>
          ) : (
            "Reconnect"
          )}
        </ConnectPlaidButton>
        {isSuperusering ? (
          <div className={styles.superuserArea}>
            <Heading3>Superuser</Heading3>
            <Button variant="secondary" onClick={openItemInPlaidDashboard}>
              Open in Plaid
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConnectionModal(true);
              }}
            >
              Delete connection
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PlaidConnectionCardContents;
