import { FC } from "react";
import CheckDepositImagesFlexpaneSection, {
  checkDepositImagesTabs,
} from "resources/unit-co-check-deposits/components/CheckDepositImagesFlexpaneSection";
import Flexpane from "ui/overlay/Flexpane";
import useSearchParamOption from "utils/searchParams/useSearchParamOption";
import getObjectKeys from "utils/ts/getObjectKeys";

import CheckDepositFlexpaneAmountSection from "./components/CheckDepositFlexpaneAmountSection";
import CheckDepositFlexpaneContactSection from "./components/CheckDepositFlexpaneContactSection";
import CheckDepositFlexpaneToFromSection from "./components/CheckDepositFlexpaneToFromSection";
import CheckDepositFlexpaneProvider from "./context/CheckDepositFlexpaneProvider";

type Props = {
  checkDepositId?: string;
  onClose: () => void;
};

const CheckDepositFlexpane: FC<Props> = ({ checkDepositId, onClose }) => {
  const [activeTab, setActiveTab] = useSearchParamOption(
    "side",
    getObjectKeys(checkDepositImagesTabs),
    "front-image"
  );

  return (
    <Flexpane isOpen={Boolean(checkDepositId)} onClose={onClose}>
      {checkDepositId && (
        <CheckDepositFlexpaneProvider checkDepositId={checkDepositId} onClose={onClose}>
          <Flexpane.Header onClose={onClose}>Payment info</Flexpane.Header>
          <CheckDepositFlexpaneToFromSection />
          <CheckDepositImagesFlexpaneSection
            checkDepositId={checkDepositId}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <CheckDepositFlexpaneAmountSection />
          <CheckDepositFlexpaneContactSection />
        </CheckDepositFlexpaneProvider>
      )}
    </Flexpane>
  );
};

export default CheckDepositFlexpane;
