import { PlusCircle } from "@phosphor-icons/react";
import { captureException } from "@sentry/react";
import emptyExternalBankAccounts from "assets/empty-external-bank-accounts.svg";
import PlaidConnectionCard, {
  PlaidConnectionCardSize,
} from "modules/plaid/components/connection-cards/PlaidConnectionCard";
import PlaidConnectionCardContents from "modules/plaid/components/connection-cards/PlaidConnectionCardContents";
import PlaidNewConnectionCardContents from "modules/plaid/components/connection-cards/PlaidNewConnectionCardContents";
import PlaidPendingConnectionCardContents, {
  PlaidPendingConnection,
} from "modules/plaid/components/connection-cards/PlaidPendingConnectionCardContents";
import compareConnections from "modules/plaid/components/PlaidConnectionsList/compareConnections";
import PlaidLinkModal from "modules/plaid/components/PlaidLinkModal";
import { FC, useCallback, useMemo, useState } from "react";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useSetRecoilState } from "recoil";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import { useMilestoneActionItems } from "resources/action-items/hooks/useMilestoneActionItems";
import { GetStartedSetupGuideStep, Milestone } from "resources/action-items/types";
import usePlaidAccountsQueryOptions from "resources/plaid-connections/queries/usePlaidAccountsQueryOptions";
import usePlaidConnections from "resources/plaid-connections/queries/usePlaidConnections";
import usePlaidConnectionsQueryOptions from "resources/plaid-connections/queries/usePlaidConnectionsQueryOptions";
import duplicatePlaidConnectionGuidState from "state/plaid/duplicateConnectionGuid";
import isPlaidExistingAccountModalOpenState from "state/plaid/isExistingAccountModalOpen";
import colors from "styles/colors";
import EmptyCardsList from "ui/data-display/EmptyCardsList";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { HighbeamApiError } from "utils/ajax";
import { HighbeamPlaidLinkOnSuccess } from "utils/customHooks/useHighbeamPlaidLink";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import styles from "./PlaidConnectionsList.module.scss";

type NoPlaidConnectionsDisplayProps = {
  openPlaidLinkModal: () => void;
};

const NoPlaidConnectionsDisplay: FC<NoPlaidConnectionsDisplayProps> = ({ openPlaidLinkModal }) => {
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();

  return (
    <>
      <EmptyCardsList>
        <img src={emptyExternalBankAccounts} alt="No connected bank accounts" />
        <Button
          disabled={!isAllowedToConnectBankAccounts}
          variant="secondary"
          onClick={openPlaidLinkModal}
          tooltip={
            !isAllowedToConnectBankAccounts && (
              <Text size={14}>You don’t have permission to connect bank accounts.</Text>
            )
          }
        >
          <PlusCircle />
          Connect an account
        </Button>
      </EmptyCardsList>
    </>
  );
};

type PlaidLinkModalState = null | {
  existingConnection?: PlaidConnectionRep.Complete;
};

const showConnectionSuccessToast = (
  metadata: PlaidLinkOnSuccessMetadata,
  existingConnectionBeingUpdated?: PlaidConnectionRep.Complete
) => {
  const name = metadata.institution?.name ?? "external";
  if (existingConnectionBeingUpdated) {
    notify("success", `Your ${name} account is connected 🎉.`);
  } else {
    notify("success", `Your ${name} account has been updated 🎉.`);
  }
};

type Props = {
  cardSize?: PlaidConnectionCardSize;
};

const PlaidConnectionsList: FC<Props> = ({ cardSize }) => {
  const plaidConnections = usePlaidConnections();
  const [plaidLinkModalState, setPlaidLinkModalState] = useState<PlaidLinkModalState>(null);
  const [pendingConnection, setPendingConnection] = useState<PlaidPendingConnection | null>(null);
  const connectionsIncludingPending = useMemo(() => {
    if (!pendingConnection) {
      return plaidConnections.sort(compareConnections);
    }
    // If a pending connection exists which is modifying an existing one, exclude that existing connection
    // in favor of the pending version.
    const unmodifiedConnections = plaidConnections.filter(
      (connection) => connection.guid !== pendingConnection.modifyingExistingConnection?.guid
    );
    return [...unmodifiedConnections, pendingConnection].sort(compareConnections);
  }, [plaidConnections, pendingConnection]);

  const refreshPlaidConnectionsQuery = useRefreshQuery(usePlaidConnectionsQueryOptions().queryKey);
  const refreshPlaidAccountsQuery = useRefreshQuery(usePlaidAccountsQueryOptions().queryKey);

  const setIsPlaidExistingAccountModalOpen = useSetRecoilState(
    isPlaidExistingAccountModalOpenState
  );
  const setDuplicatePlaidConnectionGuid = useSetRecoilState(duplicatePlaidConnectionGuidState);
  const { finishItemByStep } = useMilestoneActionItems(Milestone.GetStarted);
  const { segmentTrack } = useSegment();
  const onLinkSuccess: HighbeamPlaidLinkOnSuccess = useCallback(
    (
      metadata: PlaidLinkOnSuccessMetadata,
      finalizationPromise: Promise<void>,
      existingConnectionBeingUpdated?: PlaidConnectionRep.Complete
    ) => {
      setPendingConnection({
        linkSuccessMetadata: metadata,
        modifyingExistingConnection: existingConnectionBeingUpdated,
      });
      finalizationPromise
        .then(() => {
          refreshPlaidConnectionsQuery();
          refreshPlaidAccountsQuery();
          finishItemByStep(GetStartedSetupGuideStep.ConnectBank, "Complete");
          showConnectionSuccessToast(metadata, existingConnectionBeingUpdated);
        })
        .catch((e: Error) => {
          // TODO move PlaidConnectionExistsModal from global router into this component,
          //   and use state to launch it here. -ayoon
          if (e instanceof HighbeamApiError && e.error === "PlaidConnectionAlreadyExists") {
            setDuplicatePlaidConnectionGuid(e.properties.connectionGuid);
            setIsPlaidExistingAccountModalOpen(true);
            segmentTrack(SEGMENT_EVENTS.PLAID_LINK_DUPLICATE, metadata);
            return;
          } else {
            captureException(e);
          }

          notify("error", "We were unable to connect the account.");
          segmentTrack(SEGMENT_EVENTS.PLAID_LINK_FAILED, metadata);
        })
        .finally(() => setPendingConnection(null));
    },
    [
      finishItemByStep,
      refreshPlaidAccountsQuery,
      refreshPlaidConnectionsQuery,
      segmentTrack,
      setDuplicatePlaidConnectionGuid,
      setIsPlaidExistingAccountModalOpen,
    ]
  );

  return (
    <>
      {plaidLinkModalState && (
        <PlaidLinkModal
          onClose={() => setPlaidLinkModalState(null)}
          existingConnection={plaidLinkModalState.existingConnection}
          onLinkSuccess={onLinkSuccess}
        />
      )}
      {connectionsIncludingPending.length === 0 ? (
        <NoPlaidConnectionsDisplay openPlaidLinkModal={() => setPlaidLinkModalState({})} />
      ) : (
        <div className={styles.plaidConnectionsList}>
          {connectionsIncludingPending.map((connection) => {
            if ("guid" in connection) {
              return (
                <PlaidConnectionCard key={connection.guid} size={cardSize}>
                  <PlaidConnectionCardContents
                    connection={connection}
                    openPlaidLinkModal={() =>
                      setPlaidLinkModalState({ existingConnection: connection })
                    }
                  />
                </PlaidConnectionCard>
              );
            } else {
              return (
                <PlaidConnectionCard key={"PENDING"} size={cardSize}>
                  <PlaidPendingConnectionCardContents pendingConnection={connection} />
                </PlaidConnectionCard>
              );
            }
          })}

          <PlaidConnectionCard size={cardSize} style={{ backgroundColor: colors.grey[50] }}>
            <PlaidNewConnectionCardContents openPlaidLinkModal={() => setPlaidLinkModalState({})} />
          </PlaidConnectionCard>
        </div>
      )}
    </>
  );
};

export default PlaidConnectionsList;
