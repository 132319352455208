import { useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const makeQueryKey = (billId: string) => ["billLineItems", { billId }];

const useBillLineItemsQueryOptions = (billId: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: makeQueryKey(billId),
    queryFn: () => highbeamApi.billLineItem.getByBillId(billId),
  });
};

const useBillLineItems = (billId: string) => {
  const { data } = useSuspenseQuery(useBillLineItemsQueryOptions(billId));
  return data;
};

export const useRefreshBillLineItemsQuery = (billId: string) =>
  useRefreshQuery(makeQueryKey(billId));

export default useBillLineItems;
