namespace ChargeCardOfferRep {
  export enum ChargeCardCreditTerm {
    Production = "Production", // DEPRECATED
    Sandbox = "Sandbox", // DEPRECATED

    ProductionExtend = "ProductionExtend",
    ProductionFlex = "ProductionFlex",
    ProductionCash = "ProductionCash",

    SandboxExtend = "SandboxExtend",
    SandboxFlex = "SandboxFlex",
    SandboxCash = "SandboxCash",
  }

  interface BaseComplete {
    readonly businessGuid: string;
    readonly maxCreditLimit: number | null; // Backend v1 Money
    readonly minCashback: number; // BigDecimal
    readonly maxCashback: number; // BigDecimal
    readonly creditTerms: ChargeCardCreditTerm;
    readonly latePaymentFees: number | null; // BigDecimal
  }

  export interface LegacyHighbeamCardComplete extends BaseComplete {
    readonly creditTerms: ChargeCardCreditTerm.Production | ChargeCardCreditTerm.Sandbox;
  }

  export interface ExtendComplete extends BaseComplete {
    readonly creditTerms:
      | ChargeCardCreditTerm.ProductionExtend
      | ChargeCardCreditTerm.SandboxExtend;
  }

  export interface FlexComplete extends BaseComplete {
    readonly creditTerms: ChargeCardCreditTerm.ProductionFlex | ChargeCardCreditTerm.SandboxFlex;
  }

  export interface CashComplete extends BaseComplete {
    readonly creditTerms: ChargeCardCreditTerm.ProductionCash | ChargeCardCreditTerm.SandboxCash;
  }

  export type Complete = LegacyHighbeamCardComplete | ExtendComplete | FlexComplete | CashComplete;
}

export default ChargeCardOfferRep;
