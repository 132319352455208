import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import useMfa from "modules/mfa/useMfa";
import { useRefreshBankAccountsQueries } from "resources/bank-accounts/queries/useBankAccounts";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

import { useCloseBankAccountModal } from "../state/closeBankAccountModalState";

const useCloseBankAccountMutation = () => {
  const highbeamApi = useHighbeamApi();
  const { mfa } = useMfa();
  const { close: closeBankAccountModal } = useCloseBankAccountModal();

  const refreshBankAccountsQuery = useRefreshBankAccountsQueries();

  return useMutation({
    mutationFn: async (accountGuid: string) => {
      await mfa();
      return highbeamApi.bankAccount.close(accountGuid);
    },
    onError: (err) => {
      notify("error", "Something went wrong! Please try again.");
      captureException(err);
    },
    onSuccess: async () => {
      notify("success", "Account closed");
      refreshBankAccountsQuery();
      closeBankAccountModal();
    },
  });
};

export default useCloseBankAccountMutation;
