import { FormProvider } from "react-hook-form";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import AvatarCard from "ui/data-display/AvatarCard";
import Divider from "ui/data-display/Divider";
import ModalV4 from "ui/overlay/ModalV4";
import useModalContext from "ui/overlay/ModalV4/context/useModalContext";

import { useEditUserSuccessfulModal } from "../EditUserSuccessfulModal/state/editUserSuccessfulModalState";

import styles from "./EditUserRoleForm.module.scss";
import EditUserRoleSelect from "./EditUserRoleSelect";
import useEditUserRoleForm from "./hooks/useEditUserRoleForm";
import useEditUserRoleMutation from "./hooks/useEditUserRoleMutation";
import { useEditUserRoleModalOpenState } from "./state/editUserRoleModalState";

const EditUserRoleForm = () => {
  const currentUserGuid = useUserGuid();

  const form = useEditUserRoleForm();
  const { mutateAsync: editUserRole, isPending } = useEditUserRoleMutation();
  const { userGuid, initials, fullName, userRoleName } = useEditUserRoleModalOpenState();
  const { open: openEditUserSuccessfulModal } = useEditUserSuccessfulModal();
  const modalContext = useModalContext();

  const onSubmit = form.handleSubmit(async (data) => {
    await editUserRole({
      userRoleGuid: data.userRole.guid,
    });

    /**
     * If the user changes their own role, we refresh the page.
     * It would be better to invalidate the JWT state, but we currently (as of October 2024)
     * have multiple sources of truth (both react-query and Recoil) for the JWT, so this is impractical.
     */
    if (userGuid === currentUserGuid) {
      window.location.reload(); // Intended to force a refresh.
      return;
    }

    openEditUserSuccessfulModal({
      displayName: fullName,
      roleName: data.userRole.name,
    });
    modalContext.onClose();
  });

  return (
    <FormProvider {...form}>
      <ModalV4.Form onSubmit={onSubmit}>
        <ModalV4.Header>Change user role</ModalV4.Header>

        <ModalV4.Body>
          <div className={styles.avatarCardContainer}>
            <AvatarCard fullName={fullName} initials={initials} userRoleName={userRoleName} />
          </div>

          <Divider className={styles.divider} />

          <EditUserRoleSelect />
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.Footer.SubmitButton
            isLoading={isPending}
            disabled={!form.formState.isValid || !form.formState.isDirty}
          >
            Change user role
          </ModalV4.Footer.SubmitButton>

          <ModalV4.Footer.CloseButton />
        </ModalV4.Footer>
      </ModalV4.Form>
    </FormProvider>
  );
};

export default EditUserRoleForm;
