// This component implements a newer variant of an EmptyState component
// than the one found in ui/table/EmptyState. While this variant may also
// be used in tandem with table-related components, it is not specific to
// tables and may be used in other contexts as well. In contrast to the
// table-specific EmptyState, this variant does not include an image, but
// *does* include a call-to-action button.

import classNames from "classnames";
import { FC, ReactNode, PropsWithChildren } from "react";
import Button, { ButtonProps } from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./EmptyState.module.scss";

type EmptyStateVariant = "card" | "inset-card";

type Props = {
  body?: ReactNode;
  footer?: ReactNode;
  variant?: EmptyStateVariant;
  className?: string;
};

const EmptyState: FC<Props> = ({ body, footer, variant, className }) => (
  <div
    className={classNames(styles.container, variant && styles[`container--${variant}`], className)}
  >
    {body && <div className={styles.bodyContainer}>{body}</div>}
    {footer && <div>{footer}</div>}
  </div>
);

const PrimaryText: FC<PropsWithChildren> = ({ children }) => (
  <Text as="p" weight="medium">
    {children}
  </Text>
);

const SecondaryText: FC<PropsWithChildren> = ({ children }) => <Text as="p">{children}</Text>;

type CTAProps = Omit<ButtonProps, "size">;

const CTA: FC<CTAProps> = ({ className, children, ...restProps }) => (
  <Button size="sm" className={classNames(styles.cta, className)} {...restProps}>
    {children}
  </Button>
);

export default Object.assign(EmptyState, {
  PrimaryText: PrimaryText,
  SecondaryText: SecondaryText,
  CTA: CTA,
});
