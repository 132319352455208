import classNames from "classnames";
import { cloneElement, ReactElement } from "react";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { TextSize, TextWeight } from "ui/typography/Text/TextTypes";
import { maskAccountNumber } from "utils/account";

import styles from "./BankAccountV2.module.scss";

export type BankAccountIconSize = 16 | 18 | 20 | 24;

const getTextSizeFromIconSize = (size: BankAccountIconSize): TextSize => {
  switch (size) {
    case 16:
      return 12;
    case 18:
      return 14;
    case 20:
      return 16;
    case 24:
      return 18;
  }
};

const getColumnGapFromIconSize = (size: BankAccountIconSize): number => {
  if (size >= 18) return 8;
  else return 4;
};

// NB(alex): This is a hack but it makes the text look a lot nicer in the context of a paragraph. It works :shrug:.
const getTextMarginTopFromIconSize = (size: BankAccountIconSize): number => {
  if (size === 16) return 2;
  else return 0;
};

type Props = {
  iconSize?: BankAccountIconSize;
  bankIcon?: ReactElement<{ size: number }>;
  accountName: string;
  accountNumber?: string;
  shortMethodName?: string;
  style?: React.CSSProperties;
  className?: string;
  textClassName?: string;
  textSize?: TextSize;
  textMarginTop?: number;
  weight?: TextWeight;
  as?: React.ElementType;
};

const BankAccountV2: React.FC<Props> = ({
  iconSize = 18,
  bankIcon,
  accountName,
  accountNumber,
  shortMethodName,
  weight = "medium",
  style,
  className,
  textClassName,
  textSize,
  textMarginTop, // NB(alex): This is a silly hack to override the other silly hack...
  as = "div",
}) => {
  return (
    <div
      className={classNames(styles.container, className)}
      style={{
        columnGap: getColumnGapFromIconSize(iconSize),
        ...style,
      }}
    >
      {bankIcon && cloneElement(bankIcon, { size: iconSize })}

      <Text
        style={{ marginTop: textMarginTop ?? getTextMarginTopFromIconSize(iconSize) }}
        size={textSize ?? getTextSizeFromIconSize(iconSize)}
        weight={weight}
        as={as}
        color={colors.grey[800]}
        className={classNames(styles.text, textClassName)}
      >
        {accountName}
        {accountNumber && ` ${maskAccountNumber(accountNumber)}`}

        {shortMethodName && <Text color={colors.grey[500]}>via {shortMethodName}</Text>}
      </Text>
    </div>
  );
};

export default BankAccountV2;
