import { useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

import { BILLS_QUERY_KEY } from "./useRefreshBillsQueries";

const makeQueryKey = (billId: string) => [BILLS_QUERY_KEY, "suggestedPayee", { billId }];

const useBillSuggestedPayeeQueryOptions = (billId: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: makeQueryKey(billId),
    queryFn: () => {
      return highbeamApi.billSuggestedPayee.get(billId);
    },
  });
};

export const useRefreshBillSuggestedPayeeQuery = (
  billId: string,
  options: UseRefreshQueryOptions = {}
) => {
  return useRefreshQuery(makeQueryKey(billId), options);
};

const useBillSuggestedPayee = (billId: string) => {
  const { data } = useSuspenseQuery(useBillSuggestedPayeeQueryOptions(billId));

  return data;
};

export default useBillSuggestedPayee;
