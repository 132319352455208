import { CalendarCheck, Coin, CurrencyCircleDollar, Prohibit } from "@phosphor-icons/react";
import React from "react";
import CardRep from "reps/CardRep";
import CardIcon from "resources/cards/components/CardIcon";
import { CardVirtualOrPhysical } from "resources/cards/types";

import CardTag from "./CardTag";
import styles from "./SelectCard.module.scss";
import SelectCardOption from "./SelectVirtualCardOption";

type SelectCardProps = {
  createCardType: CardVirtualOrPhysical;
  handleNext: () => void;
  setCardType: (cardType: CardRep.CardType) => void;
};

const SelectCard: React.FC<SelectCardProps> = ({ createCardType, handleNext, setCardType }) => (
  <div className={styles.chooseCardContainer}>
    {createCardType === "virtual" && (
      <VirtualCardOptions handleNext={handleNext} setCardType={setCardType} />
    )}
    {createCardType === "physical" && (
      <PhysicalCardOptions handleNext={handleNext} setCardType={setCardType} />
    )}
  </div>
);

const VirtualCardOptions: React.FC<{
  handleNext: (cardType?: CardRep.CardType) => void;
  setCardType: (cardType: CardRep.CardType) => void;
}> = ({ handleNext, setCardType }) => (
  <>
    <SelectCardOption
      cardType={"VIRTUAL_CREDIT"}
      handleNext={handleNext}
      setCardType={setCardType}
      img={<CardIcon cardType="VIRTUAL_CREDIT" />}
      tags={
        <div className={styles.highbeamCardTagContainer}>
          <div className={styles.cardTagContainer}>
            <CardTag icon={<CurrencyCircleDollar size={16} />} text="1.5% cash back on ad spend" />
            <CardTag icon={<Coin size={16} />} text="1% cashback on everything else" />
          </div>
          <div className={styles.cardTagContainer}>
            <CardTag icon={<Prohibit mirrored size={16} />} text="Interest free" />
            <CardTag icon={<CalendarCheck size={16} />} text="Up to 45 days payback" />
          </div>
        </div>
      }
    />
    <SelectCardOption
      handleNext={handleNext}
      setCardType={setCardType}
      cardType={"VIRTUAL"}
      img={<CardIcon cardType="VIRTUAL" />}
      tags={
        <div className={styles.cardTagContainer}>
          <CardTag icon={<CurrencyCircleDollar size={16} />} text="2% cash back on ad spend" />
          <CardTag icon={<Coin size={16} />} text="1% cash back on everything else" />
        </div>
      }
    />
  </>
);

const PhysicalCardOptions: React.FC<{
  handleNext: () => void;
  setCardType: (cardType: CardRep.CardType) => void;
}> = ({ handleNext, setCardType }) => (
  <>
    <SelectCardOption
      cardType={"PHYSICAL_CREDIT"}
      handleNext={handleNext}
      setCardType={setCardType}
      img={<CardIcon cardType="PHYSICAL_CREDIT" />}
      tags={
        <div className={styles.highbeamCardTagContainer}>
          <div className={styles.cardTagContainer}>
            <CardTag icon={<CurrencyCircleDollar size={16} />} text="1.5% cash back on ad spend" />
            <CardTag icon={<Coin size={16} />} text="1% cashback on everything else" />
          </div>
          <div className={styles.cardTagContainer}>
            <CardTag icon={<Prohibit mirrored size={16} />} text="Interest free" />
            <CardTag icon={<CalendarCheck size={16} />} text="Up to 45 days payback" />
          </div>
        </div>
      }
    />
    <SelectCardOption
      handleNext={handleNext}
      setCardType={setCardType}
      cardType={"PHYSICAL"}
      img={<CardIcon cardType="PHYSICAL" />}
      tags={
        <div className={styles.cardTagContainer}>
          <CardTag icon={<CurrencyCircleDollar size={16} />} text="2% cash back on ad spend" />
          <CardTag icon={<Coin size={16} />} text="1% cash back on everything else" />
        </div>
      }
    />
  </>
);
export default SelectCard;
