import { ErrorBoundary } from "@sentry/react";
import classNames from "classnames";
import ErrorText from "components/error/ErrorText";
import { cloneElement, FC, ReactElement, ReactNode, Suspense } from "react";
import SectionLoading from "ui/feedback/SectionLoading";
import Text, { TextProps } from "ui/typography/Text";

import styles from "./Section.module.scss";
import SectionHeaderEnd from "./SectionHeaderEnd";

type SectionContainerProps = JSX.IntrinsicElements["div"] & {
  suspenseFallback?: React.ReactNode;
};

export const SectionContainer: FC<SectionContainerProps> = ({
  className,
  children,
  suspenseFallback = <SectionLoading />,
  ...divProps
}) => {
  return (
    <Suspense fallback={suspenseFallback}>
      <div className={classNames(styles.container, className)} {...divProps}>
        {children}
      </div>
    </Suspense>
  );
};

type SectionHeaderProps = JSX.IntrinsicElements["div"] & {
  heading?: ReactNode;
  subheading?: ReactNode;
  actions?: ReactNode;
};

export const SectionHeader: FC<SectionHeaderProps> = ({
  className,
  children,
  heading,
  subheading,
  actions,
  ...divProps
}) => {
  return (
    <>
      <div
        className={classNames(
          className,
          styles.header,
          subheading && styles.reducedMarginForSubheading
        )}
        {...divProps}
      >
        {typeof heading === "string" ? (
          <SectionHeaderHeading>{heading}</SectionHeaderHeading>
        ) : (
          heading
        )}

        {children}

        {actions && <SectionHeaderEnd>{actions}</SectionHeaderEnd>}
      </div>

      {typeof subheading === "string" ? (
        <SectionHeaderSubheading>{subheading}</SectionHeaderSubheading>
      ) : (
        subheading
      )}
    </>
  );
};

const DEFAULT_ICON_SIZE = 24;

type SectionHeaderHeadingProps = TextProps & {
  icon?: ReactElement<{ size?: number }>;
  iconSize?: number;
};

export const SectionHeaderHeading: FC<SectionHeaderHeadingProps> = ({
  className,
  icon,
  iconSize = DEFAULT_ICON_SIZE,
  children,
  ...textProps
}) => {
  return (
    <Text size={18} weight="bold" className={classNames(className, styles.heading)} {...textProps}>
      {icon && cloneElement(icon, { size: iconSize })}
      {children}
    </Text>
  );
};

type SectionHeaderSubheadingProps = TextProps;

export const SectionHeaderSubheading: FC<SectionHeaderSubheadingProps> = ({
  className,
  ...textProps
}) => {
  return (
    <Text
      size={14}
      weight="regular"
      className={classNames(className, styles.subheading)}
      {...textProps}
    />
  );
};

type SectionBodyProps = JSX.IntrinsicElements["div"] & {
  suspenseFallback?: React.ReactNode;
};

export const SectionBody: FC<SectionBodyProps> = ({
  className,
  suspenseFallback = <SectionLoading />,
  children,
  ...divProps
}) => {
  return (
    <div className={classNames(className, styles.body)} {...divProps}>
      <ErrorBoundary fallback={({ error }) => <ErrorText error={error} />}>
        <Suspense fallback={suspenseFallback}>{children}</Suspense>
      </ErrorBoundary>
    </div>
  );
};

const Section = Object.assign(SectionContainer, {
  HeaderEnd: SectionHeaderEnd,
  Header: SectionHeader,
  HeaderHeading: SectionHeaderHeading,
  HeaderSubheading: SectionHeaderSubheading,
  Body: SectionBody,
});

export default Section;
