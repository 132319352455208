import { useQuery } from "@tanstack/react-query";
import { DependencyList, useCallback } from "react";
import { useRecoilValue } from "recoil";
import BusinessRep from "reps/BusinessRep";
import useBusinessesQueryOptions from "resources/businesses/queries/useBusinessesQueryOptions";
import Text from "ui/typography/Text";
import { useFuse } from "utils/customHooks/useFuse";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";
import { focusNextElement, focusPreviousElement } from "utils/focus/focus-element";

import BusinessSwitcherResult from "./BusinessSwitcherResult";
import BusinessSwitcherResultCell from "./BusinessSwitcherResultCell";
import styles from "./BusinessSwitcherResults.module.scss";
import superuserInputState from "./state/superuserInputState";

const BusinessSwitcherResults = () => {
  const query = useRecoilValue(superuserInputState);

  const { data: businesses = [], isFetching, error } = useQuery(useBusinessesQueryOptions());
  const results = useSearch(businesses, query);

  useArrowUpKeyboardEvent(() => {
    focusPreviousElement();
  }, []);

  useArrowDownKeyboardEvent(() => {
    focusNextElement();
  }, []);

  if (isFetching) {
    return <Text size={14}>Loading...</Text>;
  }

  if (error) {
    return (
      <>
        <Text size={14}>Error.</Text>
        {error instanceof Error && <Text size={12}>{error.message}</Text>}
      </>
    );
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <BusinessSwitcherResultCell header>Name</BusinessSwitcherResultCell>
          <BusinessSwitcherResultCell header>Guid</BusinessSwitcherResultCell>
          <BusinessSwitcherResultCell header>Unit.co id</BusinessSwitcherResultCell>
          <BusinessSwitcherResultCell header>Status</BusinessSwitcherResultCell>
        </tr>
      </thead>
      <tbody>
        {results.map((result) => (
          <BusinessSwitcherResult key={result.guid} business={result} />
        ))}
      </tbody>
    </table>
  );
};

export default BusinessSwitcherResults;

const useSearch = (businesses: BusinessRep.Complete[], query: string): BusinessRep.Complete[] => {
  const results = useFuse(businesses, query || "*", {
    shouldSort: true,
    threshold: query ? 0.3 : 1,
    keys: ["dba", "guid", "name", "unitCoCustomerId"],
  }).map((result) => result.item);
  if (!query) {
    return businesses.sort((a, b) => {
      const displayNameA = a.internalName;
      const displayNameB = b.internalName;
      if (!displayNameA) {
        if (!displayNameB) return a.guid.localeCompare(b.guid);
        return 1;
      }
      if (!displayNameB) return -1;
      return displayNameA.localeCompare(displayNameB);
    });
  }
  return results;
};

const useArrowUpKeyboardEvent = (handler: () => void, deps: DependencyList) => {
  const filter = useCallback((event: KeyboardEvent) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      return true;
    }
    return false;
  }, []);
  useKeyboardEvent(filter, handler, deps);
};

const useArrowDownKeyboardEvent = (handler: () => void, deps: DependencyList) => {
  const filter = useCallback((event: KeyboardEvent) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      return true;
    }
    return false;
  }, []);
  useKeyboardEvent(filter, handler, deps);
};
