import { atom, selector } from "recoil";
import jwtState from "state/auth/jwt";
import { stringLocalStorageEffect } from "utils/recoil";

const defaultBusinessGuidState = selector<string>({
  key: "auth/defaultBusinessGuid",
  get: async ({ get }) => {
    const jwt = get(jwtState);
    const businesses = jwt.businesses;
    const businessGuids = Object.keys(businesses);
    if (businessGuids.length <= 1) return businessGuids[0] ?? "";
    return "";
  },
});

const businessGuidState = atom<string>({
  key: "auth/businessGuid",
  default: defaultBusinessGuidState,
  effects: [stringLocalStorageEffect("businessGuid")],
});

export default businessGuidState;
