import React, { useRef } from "react";
import useOnClickOutside from "use-onclickoutside";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";

import styles from "./DashboardUserMenuDropdown.module.scss";
import DashboardUserMenuDropdownBusinesses from "./DashboardUserMenuDropdownBusinesses";
import DashboardUserMenuDropdownHeader from "./DashboardUserMenuDropdownHeader";
import DashboardUserMenuDropdownSignOut from "./DashboardUserMenuDropdownSignOut";

type Props = {
  onCloseUserMenu: () => void;
};

const DashboardUserMenuDropdown: React.FC<Props> = ({ onCloseUserMenu: handleCloseUserMenu }) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, handleCloseUserMenu);

  useKeyboardEvent((event) => event.key === "Escape", handleCloseUserMenu, []);

  return (
    <div className={styles.dropdown} ref={ref}>
      <DashboardUserMenuDropdownHeader />
      <DashboardUserMenuDropdownBusinesses />
      <DashboardUserMenuDropdownSignOut />
    </div>
  );
};

export default DashboardUserMenuDropdown;
