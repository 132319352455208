import chargeCardCalendar from "assets/charge-card-calendar.svg";
import React, { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import useActiveCapitalAccountsWithChargeCard from "resources/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import PlatformIcon from "ui/icons/PlatformIcon";
import DropdownV2 from "ui/inputs/DropdownV2";
import ModalV2 from "ui/overlay/ModalV2";
import Heading2 from "ui/typography/Heading2";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

import { Step } from "../utils";

import CapitalAvailableBarChart from "./CapitalAvailableBarChart";
import ChargeCardCreationProgress from "./ChargeCardCreationProgress";
import styles from "./ChargeCardOnboarding.module.scss";

// TODO(justin): Allow for net-1 and net-30 charge cards
const AUTO_DEBIT_DATE = "15th";

const ChargeCardOnboardingContent = () => {
  const primaryAccount = usePrimaryBankAccount({ required: true });
  return (
    <div className={styles.onboardingContainer}>
      <img src={chargeCardCalendar} alt="" />
      <div className={styles.description}>
        <Text size={16} weight="bold">
          Auto-pay back every month
        </Text>

        <Text size={14}>
          On the {AUTO_DEBIT_DATE} of every month, the outstanding balance due from the prior month
          will get automatically debited from
          <BankAccountV2
            as="span"
            iconSize={16}
            style={{ verticalAlign: "bottom", marginLeft: 4 }}
            weight="bold"
            bankIcon={<PlatformIcon platformName="highbeam" />}
            accountName={primaryAccount.name}
            accountNumber={primaryAccount.accountNumber}
          />
          .
        </Text>

        <Text size={14}>
          There are no late fees with the Highbeam Card. In case{" "}
          <BankAccountV2
            as="span"
            iconSize={16}
            weight="bold"
            style={{ verticalAlign: "bottom", marginLeft: 4, marginRight: 4 }}
            bankIcon={<PlatformIcon platformName="highbeam" />}
            accountName={primaryAccount.name}
            accountNumber={primaryAccount.accountNumber}
          />
          account balance is insufficient, the balance will become interest bearing.
        </Text>
      </div>
    </div>
  );
};

type SelectedCapitalAccountProps = {
  selectedCapitalAccount: CapitalAccountRep.Complete | null;
  setSelectedCapitalAccount: (selectedCapitalAccount: CapitalAccountRep.Complete | null) => void;
};

const ChargeCardMultipleCapitalAccountsOnboardingContent: FC<SelectedCapitalAccountProps> = ({
  selectedCapitalAccount,
  setSelectedCapitalAccount,
}) => {
  const activeCapitalAccountsWithChargeCard = useActiveCapitalAccountsWithChargeCard();

  return (
    <div className={styles.onboardingContainerMultipeCapitalAccounts}>
      <Heading2>Capital account</Heading2>
      <Text size={14}>All spend on this card will be made from the associated account.</Text>
      <DropdownV2
        className={styles.dropdown}
        label="Account"
        disabled={activeCapitalAccountsWithChargeCard.length <= 1}
        options={activeCapitalAccountsWithChargeCard}
        renderOption={(optionProps) => {
          return (
            <DropdownV2.Option {...optionProps}>
              <CapitalAccountLabel capitalAccount={optionProps.data} />
            </DropdownV2.Option>
          );
        }}
        value={selectedCapitalAccount}
        onChange={(val) => setSelectedCapitalAccount(val)}
        components={{
          SingleValue: (props) => (
            <DropdownV2.SingleValue {...props}>
              <CapitalAccountLabel capitalAccount={props.data} />
            </DropdownV2.SingleValue>
          ),
        }}
      />
      {selectedCapitalAccount && (
        <CapitalAvailableBarChart capitalAccountGuid={selectedCapitalAccount?.guid} />
      )}
    </div>
  );
};

type CapitalAccountLabelProps = {
  capitalAccount: CapitalAccountRep.Complete;
};

const CapitalAccountLabel: FC<CapitalAccountLabelProps> = ({ capitalAccount }) => (
  <div className={styles.capitalAccountLabel}>
    <CapitalAccountIcon capitalAccountType={capitalAccount.type} />
    <Text size={14} weight="medium">
      {capitalAccount.name}
    </Text>
  </div>
);

type Props = SelectedCapitalAccountProps & {
  step: Step;
};

const ChargeCardOnboarding: React.FC<Props> = ({
  step,
  selectedCapitalAccount,
  setSelectedCapitalAccount,
}) => {
  const chargeCardMultipleCapitalAccountsCardCreationModalEnabled = useFeatureFlag(
    "CHARGE_CARD_MULTIPLE_CAPITAL_ACCOUNTS_CARD_CREATION_MODAL"
  );

  return (
    <>
      <ChargeCardCreationProgress step={step} />
      <ModalV2.Content.Body>
        {chargeCardMultipleCapitalAccountsCardCreationModalEnabled ? (
          <ChargeCardMultipleCapitalAccountsOnboardingContent
            selectedCapitalAccount={selectedCapitalAccount}
            setSelectedCapitalAccount={setSelectedCapitalAccount}
          />
        ) : (
          <ChargeCardOnboardingContent />
        )}
      </ModalV2.Content.Body>
    </>
  );
};

export default ChargeCardOnboarding;
