import { FC } from "react";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useChargeCardOffer from "resources/charge-card-offer/queries/useChargeCardOffer";
import getChargeCardOfferRepaymentUpToDays from "resources/charge-card-offer/utils/getChargeCardOfferRepaymentUpToDays";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardOfferRepaymentPeriodContent: FC<Props> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  const upToDays = getChargeCardOfferRepaymentUpToDays(chargeCardOffer);

  return <>{upToDays === 1 ? "Daily" : `Up to ${upToDays} days`}</>;
};

export default ChargeCardOfferRepaymentPeriodContent;
