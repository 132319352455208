import { Unit } from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import moneyInOut from "assets/money-in-out.svg";
import { useReceivedPaymentInfoFlexpaneContext } from "dialogs/ReceivedPaymentInfoFlexpane/context/ReceivedPaymentInfoFlexpaneProvider";
import env from "env";
import { FC, useState } from "react";
import { useRecoilValue } from "recoil";
import useBankAccountByUnitCoDepositAccountId from "resources/bank-accounts/queries/useBankAccountByUnitCoDepositAccountId";
import useGetUnitCoSensitiveTokenWithMfa from "resources/unit-co-customer-token/hooks/useGetUnitCoSensitiveTokenWithMfa";
import { DEFAULT_UNIT_CONFIG } from "resources/unit-co-customer-token/queries/useUnitApi";
import { UNIT_RECEIVED_PAYMENTS_QUERY_KEY } from "resources/unit-co-received-payments/queries/useUnitCoReceivedPaymentsQueryOptions";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import { notify } from "ui/feedback/Toast";
import ModalV3 from "ui/overlay/ModalV3";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";
import { maskAccountNumber } from "utils/account";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import styles from "./RetryAchReceivedPaymentModal.module.scss";
import retryAchReceivedPaymentModalState, {
  useRetryAchReceivedPaymentModal,
} from "./state/RetryAchReceivedPaymentModalState";

type RetrySummaryProps = {
  counterpartyName: string;
  paymentAmountInCents: number;
  accountName: string;
  accountNumber: string;
};

const RetrySummary: FC<RetrySummaryProps> = ({
  accountName,
  accountNumber,
  counterpartyName,
  paymentAmountInCents,
}) => {
  return (
    <>
      <div className={styles.paymentRetryCard}>
        <div className={styles.paymentRetryCardSummary}>
          <img src={moneyInOut} alt="money icon" />
          <Text size={16} color={colors.grey[600]}>
            <MoneyAmount weight={"medium"} cents={paymentAmountInCents} /> debit to{" "}
            <Text as="span" size={16} weight="medium" color={colors.grey[600]}>
              {counterpartyName}
            </Text>
          </Text>
        </div>
      </div>
      <Text color={colors.grey[600]} size={16} weight="regular" className={styles.disclaimer}>
        Selecting “Retry debit” will debit{" "}
        <MoneyAmount weight={"medium"} cents={paymentAmountInCents} /> from
        <Text weight={"medium"}>
          {accountName}
          {maskAccountNumber(accountNumber)}.
        </Text>
        The vendor will see the debit as successful and will receive the funds the next business
        day.
      </Text>
    </>
  );
};

const RetryAchReceivedPaymentModal: FC = () => {
  const getUnitCoSensitiveTokenWithMfa = useGetUnitCoSensitiveTokenWithMfa({
    scopes: ["RECEIVED_PAYMENTS_WRITE"],
  });
  const [isLoading, setIsLoading] = useState(false);
  const reprocessPayment = async (receivedPaymentId: string) => {
    const sensitiveToken = await getUnitCoSensitiveTokenWithMfa();
    const unitApi = new Unit(sensitiveToken, env.UNIT_API_ORIGIN, DEFAULT_UNIT_CONFIG);
    await unitApi.receivedPayments.reprocess(receivedPaymentId);
  };
  const refreshReceivedAchPayments = useRefreshQuery([UNIT_RECEIVED_PAYMENTS_QUERY_KEY]);

  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();
  const bankAccount = useBankAccountByUnitCoDepositAccountId(
    receivedPayment.relationships.account.data.id,
    {
      required: true,
    }
  );
  const { close: onClose } = useRetryAchReceivedPaymentModal();
  const { onClose: closeFlexpane } = useReceivedPaymentInfoFlexpaneContext();

  const state = useRecoilValue(retryAchReceivedPaymentModalState);
  if (!state.isOpen) {
    return null;
  }

  return (
    <ModalV4 onClose={onClose}>
      <ModalV4.Form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            setIsLoading(true);
            await reprocessPayment(state.receivedPaymentId);
            await refreshReceivedAchPayments();
            notify("success", "Debit completed");
            closeFlexpane();
            onClose();
          } catch (error) {
            captureException(error);
            notify("error", "Debit failed");
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <ModalV4.Header>Retry debit?</ModalV4.Header>

        <ModalV4.Body>
          <RetrySummary
            accountName={bankAccount.name}
            accountNumber={bankAccount.accountNumber}
            counterpartyName={receivedPayment.attributes.companyName}
            paymentAmountInCents={receivedPayment.attributes.amount}
          />
        </ModalV4.Body>

        <ModalV4.Footer>
          <ModalV4.Footer.SubmitButton variant="primary" isLoading={isLoading}>
            Retry debit
          </ModalV4.Footer.SubmitButton>
          <ModalV3.Footer.CloseButton onClick={onClose}>Close</ModalV3.Footer.CloseButton>
        </ModalV4.Footer>
      </ModalV4.Form>
    </ModalV4>
  );
};

export default RetryAchReceivedPaymentModal;
