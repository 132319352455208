import getBankAccountsByUnitCoDepositAccountId from "../utils/getBankAccountsByUnitCoDepositAccountId";

import useBankAccounts from "./useBankAccounts";

const useOpenBankAccountsByUnitCoDepositAccountId = () => {
  return useBankAccounts({
    status: "open",
    select: (bankAccounts) => {
      return getBankAccountsByUnitCoDepositAccountId(bankAccounts);
    },
  });
};

export default useOpenBankAccountsByUnitCoDepositAccountId;
