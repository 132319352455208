import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { Navigate } from "react-router-dom";
import Button from "ui/inputs/Button";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Text from "ui/typography/Text";
import useIsAllowedToNavigateToBusinessMembersSettingsPage from "utils/permissions/navigation/useIsAllowedToNavigateToBusinessMembersSettingsPage";
import useIsAllowedToChangeOrganizationOwner from "utils/permissions/useIsAllowedToChangeOrganizationOwner";
import useIsAllowedToInviteUsers from "utils/permissions/useIsAllowedToInviteUsers";

import ChangeOrganizationOwnerModal from "./ChangeOrganizationOwnerModal";
import EditUserRoleModal from "./EditUserRoleModal";
import EditUserSuccessfulModal from "./EditUserSuccessfulModal";
import InvitationDetailsModal from "./InvitationDetailsModal";
import InvitationSuccessfulModal from "./InvitationSuccessfulModal";
import InviteUserModal from "./InviteUserModal";
import { useInviteUserModal } from "./InviteUserModal/state/inviteUserModalState";
import UsersTable from "./UsersTable";

const InviteUserButton = () => {
  const { open: openInviteUserModal } = useInviteUserModal();
  const isAllowedToInviteUsers = useIsAllowedToInviteUsers();

  return (
    <Button
      variant="secondary"
      onClick={() => openInviteUserModal()}
      disabled={!isAllowedToInviteUsers}
      tooltip={
        !isAllowedToInviteUsers && (
          <Text size={14}>You don’t have permission to invite a user.</Text>
        )
      }
    >
      Invite user
    </Button>
  );
};

const SettingsUsersPageContent = () => {
  const isAllowedToChangeOrganizationOwner = useIsAllowedToChangeOrganizationOwner();

  return (
    <>
      <InviteUserModal />
      <InvitationSuccessfulModal />
      <InvitationDetailsModal />

      {isAllowedToChangeOrganizationOwner && <ChangeOrganizationOwnerModal />}
      <EditUserRoleModal />
      <EditUserSuccessfulModal />

      <DashboardPage.Header actions={<InviteUserButton />}>
        <DashboardPage.Header.BackCaret to="/settings" />
        <DashboardPage.Header.Title>Users</DashboardPage.Header.Title>
      </DashboardPage.Header>

      <DashboardPage.Section>
        <UsersTable />
      </DashboardPage.Section>
    </>
  );
};

const SettingsUsersPage = () => {
  const isAllowedToNavigateToBusinessMembersSettingsPage =
    useIsAllowedToNavigateToBusinessMembersSettingsPage();

  if (!isAllowedToNavigateToBusinessMembersSettingsPage) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/settings">Settings</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Users</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <SettingsUsersPageContent />
      </DashboardPage>
    </>
  );
};

export default SettingsUsersPage;
