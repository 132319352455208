import AccountLabel from "components/Accounts/AccountLabel";
import TransferBalance from "pages/TransferMoneyPage/TransferBalance";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { components as defaultComponents } from "react-select";
import BankAccountRep from "reps/BankAccountRep";
import DropdownV2, { DropdownProps } from "ui/inputs/DropdownV2";
import { getAccountDisplayName } from "utils/account";

type Props = DropdownProps<BankAccountRep.Complete, false>;

const BankAccountsDropdown: ForwardRefRenderFunction<HTMLInputElement, Props> = (props, ref) => {
  return (
    <DropdownV2
      ref={ref}
      isSearchable
      getOptionLabel={(option) => getAccountDisplayName(option)}
      getOptionValue={(option) => getAccountDisplayName(option)}
      renderOption={(optionProps) => {
        return (
          <DropdownV2.Option {...optionProps}>
            <AccountLabel bankAccount={optionProps.data} />
            <TransferBalance balance={optionProps.data.availableBalance} />
          </DropdownV2.Option>
        );
      }}
      components={{
        SingleValue: (props) => (
          <defaultComponents.SingleValue {...props}>
            <AccountLabel bankAccount={props.data} />
          </defaultComponents.SingleValue>
        ),
      }}
      {...props}
    />
  );
};

export default forwardRef(BankAccountsDropdown);
