import { Info } from "@phosphor-icons/react";
import colors from "styles/colors";
import Banner from "ui/data-display/Banner";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

const LargeDrawdownBanner = () => {
  const largeDrawdownBannerText = useFeatureFlag("CAPITAL_DRAWDOWN_LARGE_AMOUNT_BANNER_TEXT");

  return (
    <Banner
      icon={<Info color={colors.purple[500]} />}
      title={
        <Text size={14} weight="medium">
          {largeDrawdownBannerText}
        </Text>
      }
    />
  );
};

export default LargeDrawdownBanner;
