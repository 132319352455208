import { Card } from "@highbeam/unit-node-sdk";
import { Archive } from "@phosphor-icons/react";
import ModalV2 from "ui/overlay/ModalV2";
import Text from "ui/typography/Text";

import CardModalInfo from "../CardModalInfo";

import styles from "./ArchiveCardModal.module.scss";
import { useArchiveCardMutation } from "./hooks/useArchiveCardMutation";

type Props = {
  card: Card;
  isOpen: boolean;
  onClose: () => void;
};

const ArchiveCardModal: React.FC<Props> = ({ card, isOpen, onClose }) => {
  const { mutate: archiveCard, isPending } = useArchiveCardMutation();
  return (
    <ModalV2 isOpen={isOpen} onClose={onClose}>
      <ModalV2.Content>
        <ModalV2.Content.Header
          heading="Close this card?"
          icon={<Archive size={24} />}
          onClickClose={onClose}
        />
        <ModalV2.Content.Body>
          <div className={styles.archiveCardForm}>
            <CardModalInfo card={card} />
            <Text size={14}>Once closed, you won’t be able to use this card again.</Text>
          </div>
        </ModalV2.Content.Body>
        <ModalV2.Content.Footer>
          <ModalV2.Content.Footer.CancelButton variant="ghost" onClick={onClose}>
            Cancel
          </ModalV2.Content.Footer.CancelButton>
          <ModalV2.Content.Footer.SubmitButton
            form="archive-card"
            variant="danger"
            isLoading={isPending}
            onClick={() => archiveCard(card.id)}
          >
            Close card
          </ModalV2.Content.Footer.SubmitButton>
        </ModalV2.Content.Footer>
      </ModalV2.Content>
    </ModalV2>
  );
};

export default ArchiveCardModal;
