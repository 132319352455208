import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import { BankingPartner } from "resources/bank-accounts/types";
import filterBankAccountsByPartnerBank from "resources/bank-accounts/utils/filterBankAccountsByPartnerBank";
import useQueryOptions from "utils/react-query/useQueryOptions";

import useCardsQueryOptions from "./useCardsQueryOptions";

const useCardsByPartnerBankQueryOptions = (bankingPartner: BankingPartner) => {
  const filteredBankAccounts = useBankAccounts({
    status: "all",
    select: (bankAccounts) => filterBankAccountsByPartnerBank(bankAccounts, bankingPartner),
  });

  const filteredBankAccountIds = filteredBankAccounts.map(
    (bankAccount) => bankAccount.unitCoDepositAccountId
  );

  const cardsQueryOptions = useCardsQueryOptions();

  return useQueryOptions({
    queryKey: [...cardsQueryOptions.queryKey, { filteredBankAccountIds }],
    queryFn: async () => {
      // Exemplar(alex): How to extend the `queryFn`.
      const cards = await cardsQueryOptions.queryFn();

      return cards.filter((card) => {
        return filteredBankAccountIds.includes(card.relationships.account.data.id);
      });
    },
  });
};

export default useCardsByPartnerBankQueryOptions;
