import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import ModalV4 from "ui/overlay/ModalV4";

import EditCapitalAccountNameForm from "./EditCapitalAccountNameForm";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  onClose: () => void;
};

const EditCapitalAccountNameModal: FC<Props> = ({ capitalAccountSummary, onClose }) => {
  return (
    <ModalV4 onClose={onClose}>
      <EditCapitalAccountNameForm capitalAccountSummary={capitalAccountSummary} />
    </ModalV4>
  );
};

export default EditCapitalAccountNameModal;
