import DashboardPage from "components/layouts/DashboardPage";
import { useRecoilValue } from "recoil";
import { Milestone } from "resources/action-items/types";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import getTotalBalanceOfBankAccounts from "resources/bank-accounts/utils/getTotalBalanceOfBankAccounts";
import actionItemsState from "state/actionItems/actionItems";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";

import FundedAccount from "./FundedAccount";
import FundingAccount from "./FundingAccount";
import FundYourAccount from "./FundYourAccount";

const BalanceGraph = () => {
  const actionItems = useRecoilValue(actionItemsState(Milestone.FundYourAccount));
  const bankAccounts = useBankAccounts({ status: "open" });
  const bankAccountsTotalBalance = getTotalBalanceOfBankAccounts(bankAccounts);
  const actionableStepsAvailable = actionItems.some((aI) => !aI.finishedActionItemRep);
  const accountsHaveBeenFunded = bankAccountsTotalBalance > 0;
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();
  const showFundYourAccount =
    isAllowedToConnectBankAccounts && actionableStepsAvailable && !accountsHaveBeenFunded;

  const isAllowedToConnectStores = useIsAllowedToConnectStores();
  const showPayoutInstruction =
    isAllowedToConnectStores && !showFundYourAccount && !accountsHaveBeenFunded;

  return (
    <>
      {showFundYourAccount && <FundYourAccount />}
      {showPayoutInstruction && <FundingAccount />}
      {!showFundYourAccount && !showPayoutInstruction && <FundedAccount />}
    </>
  );
};

const AccountsOverviewBalanceSection = () => {
  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Body>
        <BalanceGraph />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default AccountsOverviewBalanceSection;
