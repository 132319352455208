import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import isGuidNotFoundError from "modules/errors/utils/isGuidNotFoundError";
import { FC } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { Navigate, useParams } from "react-router-dom";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalAccountBreadcrumbItem from "resources/capital-accounts/components/CapitalAccountBreadcrumbItem";
import { useCapitalAccountSummaryQuery } from "resources/capital-accounts/queries/useCapitalAccountSummary";
import isCapitalAccountSummaryWithChargeCard from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import Breadcrumbs from "ui/navigation/Breadcrumbs";

import ChargeCardView from "./views/ChargeCardView";
import LineOfCreditView from "./views/LineOfCreditView";
import OfferedCapitalView from "./views/OfferedCapitalView";
import UpsellCapitalView from "./views/UpsellCapitalView";

type CapitalAccountPageContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountPageContent: FC<CapitalAccountPageContentProps> = ({
  capitalAccountSummary,
}) => {
  switch (capitalAccountSummary.state) {
    case CapitalAccountRep.State.Active:
    case CapitalAccountRep.State.OfferAccepted:
      return isCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
        <ChargeCardView capitalAccountSummaryWithChargeCard={capitalAccountSummary} />
      ) : (
        <LineOfCreditView capitalAccountSummaryWithCashAccessOnly={capitalAccountSummary} />
      );
    case CapitalAccountRep.State.Offered:
      return <OfferedCapitalView capitalAccountSummary={capitalAccountSummary} />;
    case CapitalAccountRep.State.Terminated:
      return <UpsellCapitalView />;
  }
};

const CapitalAccountPage = () => {
  const capitalAccountGuid = useParams().capitalAccountGuid!; // NB(alex): This is non-nullable but react-router-dom doesn't let us specify this. Might make sense to pass it in as a prop?
  const { data: capitalAccountSummary } = useCapitalAccountSummaryQuery(capitalAccountGuid);

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>

          <CapitalAccountBreadcrumbItem
            capitalAccountSummary={capitalAccountSummary}
            isCurrentItem
          />
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        {capitalAccountSummary ? (
          <CapitalAccountPageContent capitalAccountSummary={capitalAccountSummary} />
        ) : (
          <DashboardPage.DotsLoader />
        )}
      </DashboardPage>
    </>
  );
};

export default withErrorBoundary(CapitalAccountPage, {
  fallbackRender: ({ error }) => {
    if (isGuidNotFoundError(error)) {
      return <Navigate to="/capital" />;
    }
  },
});
