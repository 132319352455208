import MultiStep from "components/layouts/MultiStep";
import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import CreditApplicationRep from "reps/CreditApplicationRep";
import colors from "styles/colors";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import RadioWithLabel from "ui/inputs/RadioWithLabel";
import TextInput from "ui/inputs/TextInput";
import Text from "ui/typography/Text";

import CreditApplicationDocumentExampleButton from "../../components/CreditApplicationDocumentExampleButton";
import UploadDocumentsSection from "../../components/UploadDocumentsSection";

import styles from "./UploadFinancialDocumentsView.module.scss";

type SkipFinancialsRadioValueType = {
  reason: CreditApplicationRep.SkipFinancialsRequirementReasonRep;
  explanation: string;
};

const skipFanancialsRadioValues: SkipFinancialsRadioValueType[] = [
  { reason: "FinancialsNotUpToDate", explanation: "My financials aren’t up-to-date" },
  { reason: "DoNotTrackFinancials", explanation: "We don’t track these financials" },
  {
    reason: "CombinedDocuments",
    explanation: "I’ve included a combined Excel sheet of all of these documents",
  },
  { reason: "Other", explanation: "Other" },
];

type UploadDocumentsTabProps = {
  skipFinancialsReason: CreditApplicationRep.SkipFinancialsRequirementReasonRep | undefined;
  skipFinancialsExplanation: string | undefined;
  noDocumentsToUpload: boolean;
  onSetSkipFinancialsReason: (
    reason: CreditApplicationRep.SkipFinancialsRequirementReasonRep
  ) => void;
  onSetSkipFinancialsExplanation: (explanation: string) => void;
  onNoDocumentsToUpload: () => void;
};

const UploadDocumentsTab: React.FC<UploadDocumentsTabProps> = ({
  skipFinancialsReason,
  skipFinancialsExplanation,
  noDocumentsToUpload,
  onSetSkipFinancialsReason,
  onSetSkipFinancialsExplanation,
  onNoDocumentsToUpload,
}) => {
  return (
    <>
      <div className={styles.container}>
        <UploadDocumentsSection
          subheading={
            <div className={styles.uploadDocumentsWithExampleButton}>
              <Text size={14} color={colors.grey[900]} weight="medium">
                24-month <strong>monthly</strong> exports of your profit & loss statement
              </Text>
              <CreditApplicationDocumentExampleButton
                documentType={CreditApplicationDocumentRep.DocumentType.ProfitAndLossStatement}
              />
            </div>
          }
          type={CreditApplicationDocumentRep.DocumentType.ProfitAndLossStatement}
        />
        <UploadDocumentsSection
          subheading={
            <div className={styles.uploadDocumentsWithExampleButton}>
              <Text size={14} color={colors.grey[900]} weight="medium">
                24-month <strong>monthly</strong> exports of your balance sheet
              </Text>
              <CreditApplicationDocumentExampleButton
                documentType={CreditApplicationDocumentRep.DocumentType.BalanceSheet}
              />
            </div>
          }
          type={CreditApplicationDocumentRep.DocumentType.BalanceSheet}
        />
        <UploadDocumentsSection
          subheading={
            <div className={styles.uploadDocumentsWithExampleButton}>
              <Text size={14} color={colors.grey[900]} weight="medium">
                AP & AR aging reports
              </Text>
              <CreditApplicationDocumentExampleButton
                documentType={CreditApplicationDocumentRep.DocumentType.ApArAgingReport}
              />
            </div>
          }
          type={CreditApplicationDocumentRep.DocumentType.ApArAgingReport}
        />
        <UploadDocumentsSection
          subheading={
            <Text size={14} color={colors.grey[900]} weight="medium">
              (Optional) Any additional documents that might be useful (Ex. Term sheets, org.
              charts)
            </Text>
          }
          type={CreditApplicationDocumentRep.DocumentType.Unknown}
        />
        <CheckboxLabel
          label="I do not have these documents at this time, and will email them to Highbeam later to complete my application."
          checked={noDocumentsToUpload}
          textWeight="regular"
          onChange={onNoDocumentsToUpload}
        />
      </div>

      <MultiStep.Section.Spacer />

      {noDocumentsToUpload && (
        <div className={styles.noDocumentsReason}>
          <MultiStep.Section.Header.Heading>Reason:</MultiStep.Section.Header.Heading>

          {skipFanancialsRadioValues.map((val) => (
            <RadioWithLabel
              key={val.reason}
              label={val.explanation}
              checked={val.reason === skipFinancialsReason}
              onChange={() => onSetSkipFinancialsReason(val.reason)}
            />
          ))}

          {skipFinancialsReason === "Other" && (
            <TextInput
              onChange={onSetSkipFinancialsExplanation}
              value={skipFinancialsExplanation}
              placeholder="I haven’t gotten these documents from my accounting partner."
            />
          )}
        </div>
      )}
    </>
  );
};

export default UploadDocumentsTab;
