import { useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { BASE_CHARGE_CARD_ACCOUNT_QUERY_KEY } from "./useChargeCardAccount";

type Params = {
  unitCoCreditAccountId: string;
};

const useChargeCardAccountByUnitCoCreditAccountIdQueryOptions = ({
  unitCoCreditAccountId,
}: Params) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  return useQueryOptions({
    queryKey: [BASE_CHARGE_CARD_ACCOUNT_QUERY_KEY, { businessGuid, unitCoCreditAccountId }],
    queryFn: () => {
      return highbeamApi.chargeCardAccount.getByUnitCoCreditAccountId(unitCoCreditAccountId);
    },
  });
};

const useChargeCardAccountByUnitCoCreditAccountId = <TRequired extends boolean>({
  required,
  ...params
}: Params & RequiredParam<TRequired>) => {
  const { data } = useSuspenseQuery(
    useChargeCardAccountByUnitCoCreditAccountIdQueryOptions(params)
  );

  if (required && !data) {
    throw new RequiredButNotFoundError();
  }

  return data as DataRequired<typeof data, TRequired>;
};

export default useChargeCardAccountByUnitCoCreditAccountId;
