import { useQuery } from "@tanstack/react-query";
import emptyImg from "assets/empty-state.svg";
import classNames from "classnames";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import { ComponentProps, FC } from "react";
import { useNavigate } from "react-router-dom";
import { useBankAccountsQuery } from "resources/bank-accounts/queries/useBankAccounts";
import { useBusinessMembersQuery } from "resources/business-members/queries/useBusinessMembers";
import getBusinessMemberByUserGuid from "resources/business-members/utils/getBusinessMemberByUserGuid";
import { usePayeeQuery } from "resources/payees/queries/usePayee";
import usePaymentApprovalsQueryOptions, {
  PaymentApprovalStatus,
} from "resources/payment-approvals/queries/usePaymentApprovalsQueryOptions";
import getPaymentApprovalMethodName from "resources/payment-approvals/utils/getPaymentApprovalMethodName";
import {
  ApprovablePaymentRequest,
  InternationalPaymentRequest,
} from "resources/payment-requests/types/paymentRequest";
import colors from "styles/colors";
import Avatar from "ui/data-display/Avatar";
import BankAccount from "ui/data-display/BankAccount";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Shimmer from "ui/feedback/Shimmer";
import Button from "ui/inputs/Button";
import DateTimeCell from "ui/table/DateTimeCell";
import EmptyState from "ui/table/EmptyState";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text, { TextProps } from "ui/typography/Text";
import { isNotNull } from "utils/array";
import { useIsMobile } from "utils/device/useMediaQuery";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";

import styles from "./DraftedPaymentsTable.module.scss";

const TextCell: FC<TextProps> = (props) => (
  <Text size={14} color={colors.grey[800]} weight="regular" {...props} />
);

type DraftedPaymentsTableCellProps = {
  payment: ApprovablePaymentRequest;
};

const SubmittedCell: FC<DraftedPaymentsTableCellProps> = ({ payment: { createdAt } }) => {
  return <DateTimeCell date={createdAt} />;
};

const CreatedByCell: FC<DraftedPaymentsTableCellProps> = ({ payment: { createdByUserGuid } }) => {
  const { data: businessMembers, isLoading } = useBusinessMembersQuery();

  if (isLoading) {
    return <Shimmer />;
  }

  const businessMember = !businessMembers
    ? null
    : getBusinessMemberByUserGuid(businessMembers, createdByUserGuid);

  if (!businessMember) {
    return <NotFoundCell />;
  }

  return (
    <TextCell className={styles.createdByCell}>
      <Avatar color="purple-light" size={32} initials={businessMember.initials} />

      {businessMember.displayName}
    </TextCell>
  );
};

const RecipientCell: FC<DraftedPaymentsTableCellProps> = ({ payment }) => {
  const { data: payee } = usePayeeQuery(payment.payeeGuid);

  if (payee === undefined) {
    return <Shimmer />;
  }

  return payee ? <TextCell>{payee.name}</TextCell> : <NotFoundCell />;
};

const DepositAccountCell: FC<DraftedPaymentsTableCellProps> = ({ payment }) => {
  const { data: bankAccounts = [], isLoading } = useBankAccountsQuery({ status: "open" });

  const bankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.guid === payment.bankAccountGuid
  );

  if (isLoading) {
    return <Shimmer />;
  }

  if (!bankAccount) {
    return <NotFoundCell />;
  }

  const shortMethodName = getPaymentApprovalMethodName(payment).short;

  return <BankAccount accountName={bankAccount.name} shortMethodName={shortMethodName} />;
};

const BaseMoneyAmountCell: FC<ComponentProps<typeof MoneyAmount>> = (props) => (
  <MoneyAmount weight="medium" color={colors.grey[800]} {...props} />
);

const ReviewDraftedPaymentButton: FC = () => {
  return (
    <Button variant="tertiary" size="sm">
      Review
    </Button>
  );
};

type AmountCellProps = DraftedPaymentsTableCellProps & {
  showReviewButton: boolean;
};

const AmountCell: FC<AmountCellProps> = ({ payment, showReviewButton }) => {
  // NB(alex): matches <Table> breakpoint. Should delete this once <Table> is refactored.
  const isMobile = useIsMobile();

  return (
    <div className={classNames(showReviewButton && styles.amountAndReviewCell)}>
      {payment.type === "international-wire" ? (
        <InternationalAmountCell paymentRequest={payment} />
      ) : (
        <BaseMoneyAmountCell cents={payment.amount} />
      )}

      {!isMobile && showReviewButton && <ReviewDraftedPaymentButton />}
    </div>
  );
};

type InternationalAmountCellProps = {
  paymentRequest: InternationalPaymentRequest;
};

const InternationalAmountCell: FC<InternationalAmountCellProps> = ({ paymentRequest }) => {
  return paymentRequest.fixedSide === "Send" ? (
    <BaseMoneyAmountCell cents={paymentRequest.amount} />
  ) : (
    <BaseMoneyAmountCell
      cents={paymentRequest.receiveAmount}
      currency={paymentRequest.receiveCurrency}
      trailingCurrencyCode={paymentRequest.receiveCurrency !== "USD"}
    />
  );
};

type Props = {
  paymentApprovalStatus: PaymentApprovalStatus;
};

const DraftedPaymentsTable: FC<Props> = ({ paymentApprovalStatus }) => {
  const { data: paymentApprovals } = useQuery(
    usePaymentApprovalsQueryOptions(paymentApprovalStatus)
  );
  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();

  const navigate = useNavigate();

  const openPaymentApprovalFlexpane = (paymentApprovalGuid: string) => {
    navigate(`/payments/payment-approvals/${paymentApprovalGuid}`, {
      state: DISABLE_SCROLL_TO_TOP_STATE,
    });
  };

  const showReviewButton = isAllowedToApprovePayments && paymentApprovalStatus === "Open";

  // NB(alex): matches <Table> breakpoint. Should delete this once <Table> is refactored.
  const isMobile = useIsMobile();

  const columns: Column<ApprovablePaymentRequest>[] = [
    {
      title: "Submitted",
      cellRender: (paymentRequest: ApprovablePaymentRequest) => (
        <SubmittedCell payment={paymentRequest} />
      ),
    },
    {
      title: "By",
      cellRender: (paymentRequest: ApprovablePaymentRequest) => (
        <CreatedByCell payment={paymentRequest} />
      ),
    },
    {
      title: "To",
      cellRender: (paymentRequest: ApprovablePaymentRequest) => (
        <RecipientCell payment={paymentRequest} />
      ),
    },
    {
      title: "Account",
      cellRender: (paymentRequest: ApprovablePaymentRequest) => (
        <DepositAccountCell payment={paymentRequest} />
      ),
    },
    {
      title: "Amount",
      align: showReviewButton ? undefined : TableColumnAlignment.RIGHT, // align right when review button is not visible
      cellRender: (paymentRequest: ApprovablePaymentRequest) => (
        <AmountCell payment={paymentRequest} showReviewButton={showReviewButton} />
      ),
    },
    showReviewButton && isMobile
      ? {
          title: "Action",
          cellRender: () => <ReviewDraftedPaymentButton />,
        }
      : null,
  ].filter(isNotNull);

  if (paymentApprovals && paymentApprovals.length === 0) {
    return <EmptyState image={emptyImg} primaryText="No drafted payments" secondaryText={""} />;
  }

  return (
    <Table
      data={paymentApprovals}
      rowKey={({ guid }) => guid}
      columns={columns}
      onRowClick={
        // NB(alex): should be clickable once we do https://linear.app/highbeam/issue/HB-3674/allow-bookkeeper-to-cancel-drafted-payment
        isAllowedToApprovePayments && paymentApprovalStatus === "Open"
          ? ({ guid }) => {
              openPaymentApprovalFlexpane(guid);
            }
          : undefined
      }
    />
  );
};

export default DraftedPaymentsTable;
