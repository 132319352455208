import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
  name: z.string().min(1, { message: "This field is required." }),
});

export type EditCapitalAccountNameFormInputs = z.infer<typeof schema>;

type Params = {
  defaultValues: EditCapitalAccountNameFormInputs;
};

const useEditCapitalAccountNameForm = ({ defaultValues }: Params) => {
  return useForm<EditCapitalAccountNameFormInputs>({
    defaultValues,
    resolver: zodResolver(schema),
  });
};

export default useEditCapitalAccountNameForm;
