import { ArrowDown, Star } from "@phosphor-icons/react";
import { useRecoilValue } from "recoil";
import BankAccountRep from "reps/BankAccountRep";
import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import { useHasActiveCapitalAccount } from "resources/capital-accounts/hooks/useActiveCapitalAccounts";
import colors from "styles/colors";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import ModalV3 from "ui/overlay/ModalV3";
import Text from "ui/typography/Text";

import useSetPrimaryAccountNameMutation from "./hooks/useSetPrimaryAccountNameMutation";
import styles from "./SetAsPrimaryAccountModal.module.scss";
import setPrimaryAccountModalState, {
  useSetPrimaryAccountModal,
} from "./state/setPrimaryAccountModalState";

const SET_PRIMARY_ACCOUNT_FORM = "set-primary-account-form";

type Props = {
  account: BankAccountRep.Complete;
  onClose: () => void;
};

const Form: React.FC<Props> = ({ account, onClose }) => {
  const { mutate: setPrimaryAccount, isPending } = useSetPrimaryAccountNameMutation();
  const { close: closeSetPrimaryAccountModal } = useSetPrimaryAccountModal();
  const currentPrimaryBankAccount = usePrimaryBankAccount({ required: true });
  const hasActiveCapitalAccount = useHasActiveCapitalAccount();

  return (
    <form
      id={SET_PRIMARY_ACCOUNT_FORM}
      onSubmit={(e) => {
        e.preventDefault();
        setPrimaryAccount({
          accountGuid: account.guid,
        });
        closeSetPrimaryAccountModal();
      }}
    >
      <ModalV3.Header onClose={onClose}>Set new primary account</ModalV3.Header>

      <ModalV3.Body>
        <div className={styles.bankAccountsContainer}>
          <div className={styles.bankAccountCard}>
            <BankAccountV2
              bankIcon={
                <HighbeamAccountIcon
                  highbeamTypeName={currentPrimaryBankAccount.highbeamType.name}
                />
              }
              accountName={currentPrimaryBankAccount.name}
              accountNumber={currentPrimaryBankAccount.accountNumber}
            />
            <div className={styles.primaryStarContainer}>
              <Star size={16} />
              <Text size={14}>Current primary</Text>
            </div>
          </div>
          <div className={styles.arrowContainer}>
            <ArrowDown size={24} color={colors.grey[400]} />
          </div>
          <div className={styles.bankAccountCard}>
            <BankAccountV2
              bankIcon={<HighbeamAccountIcon highbeamTypeName={account.highbeamType.name} />}
              accountName={account.name}
              accountNumber={account.accountNumber}
            />
          </div>
        </div>

        <div className={styles.stepsContainer}>
          <ModalV3.Body.Text weight="medium">Please consider:</ModalV3.Body.Text>
          <div className={styles.step}>
            <Text size={14} weight="medium" align="center" className={styles.stepNumber}>
              1
            </Text>
            <ModalV3.Body.Text>
              Your incoming international transfers will land in the new primary account
            </ModalV3.Body.Text>
          </div>

          {hasActiveCapitalAccount && (
            <div className={styles.step}>
              <Text size={14} weight="medium" align="center" className={styles.stepNumber}>
                2
              </Text>
              <ModalV3.Body.Text>
                {/* TODO(alex): CAPITAL_AUDIT */}
                Your Highbeam line of credit auto-payments will switch to the new primary account
              </ModalV3.Body.Text>
            </div>
          )}
        </div>
      </ModalV3.Body>

      <ModalV3.Footer>
        <ModalV3.Footer.SubmitButton
          variant="primary"
          autoFocus
          form={SET_PRIMARY_ACCOUNT_FORM}
          isLoading={isPending}
        >
          Set new primary account
        </ModalV3.Footer.SubmitButton>

        <ModalV3.Footer.CloseButton onClick={onClose} />
      </ModalV3.Footer>
    </form>
  );
};

const SetAsPrimaryAccountModal = () => {
  const state = useRecoilValue(setPrimaryAccountModalState);
  const { isOpen, close: onClose } = useSetPrimaryAccountModal();

  return (
    <ModalV3 isOpen={isOpen} onClose={onClose}>
      {state.isOpen && <Form account={state.bankAccount} onClose={onClose} />}
    </ModalV3>
  );
};

export default SetAsPrimaryAccountModal;
