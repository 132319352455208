import getSymbolFromCurrency from "currency-symbol-map";
import { FC } from "react";
import { formatValue } from "react-currency-input-field";
import useBillLineItems from "resources/bill-line-items/queries/useBillLineItems";
import inferBillLineItemCurrencyFromBill from "resources/bill-line-items/utils/inferBillLineItemCurrency";
import useBill from "resources/bills/queries/useBill";
import Text from "ui/typography/Text";
import { parseMoneyFloat } from "utils/money";
import getCurrencyDecimalPlaces from "utils/money/getCurrencyDecimalPlaces";

import styles from "./BillLineItemsTotal.module.scss";

type Props = {
  billId: string;
};

const BillLineItemsTotal: FC<Props> = ({ billId }) => {
  const billLineItems = useBillLineItems(billId);
  const bill = useBill(billId, { required: true });
  const currency = inferBillLineItemCurrencyFromBill(bill);

  // NB(lev): We should move line item total calculations to the backend. By summing the amounts here like this
  // (with floats) we are losing precision.
  const totalAmount = billLineItems.reduce(
    (acc, { amount }) => acc + parseMoneyFloat(amount.amount),
    0
  );
  const formattedTotalAmount = formatValue({
    value: `${totalAmount}`,
    decimalScale: getCurrencyDecimalPlaces(currency),
  });

  return (
    <Text as="span">
      <span className={styles.totalLabel}>Total:</span>{" "}
      <strong className={styles.totalValue}>
        {getSymbolFromCurrency(currency)}
        {formattedTotalAmount} {currency}
      </strong>
    </Text>
  );
};

export default BillLineItemsTotal;
