import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const PLAID_ACCOUNTS_QUERY_KEY = "plaid-accounts";
const PLAID_ACCOUNTS_EXPIRATION_MINS = 30;

const usePlaidAccountsQueryOptions = (accountType?: PlaidBankAccountRep.AccountType) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [PLAID_ACCOUNTS_QUERY_KEY, { businessGuid }],
    queryFn: async () => {
      return highbeamApi.plaid.getPlaidBankAccountsByBusiness(businessGuid);
    },
    staleTime: PLAID_ACCOUNTS_EXPIRATION_MINS * 60 * 1000,
    select: (accounts) =>
      accountType ? accounts.filter((account) => account.accountType === accountType) : accounts,
  });
};

export default usePlaidAccountsQueryOptions;
