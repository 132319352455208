import MetadataList from "components/MetadataList";
import { Link } from "react-router-dom";
import { useCardQuery } from "resources/cards/queries/useCard";
import Section from "ui/data-display/Section";
import Shimmer from "ui/feedback/Shimmer";
import Button from "ui/inputs/Button";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { getCardholderName, getCardName } from "utils/cards";
import {
  HighbeamCardTransaction,
  HighbeamPurchaseTransaction,
} from "utils/types/transactionsTypes";

type Props = {
  transaction: HighbeamCardTransaction | HighbeamPurchaseTransaction;
};

const CardTransactionDetailsSection: React.FC<Props> = ({ transaction }) => {
  const { data: card } = useCardQuery({ cardId: transaction.cardId });

  return (
    <Section>
      <Heading3>{transaction.longMethodName} details</Heading3>
      <MetadataList>
        <MetadataList.Item label="Card">
          {card ? (
            <Text size={14} weight="medium">
              {getCardName(card)}
            </Text>
          ) : (
            <Shimmer />
          )}
        </MetadataList.Item>
        <MetadataList.Item label="Cardholder’s name">
          {card ? (
            <Text size={14} weight="medium">
              {getCardholderName(card)}
            </Text>
          ) : (
            <Shimmer />
          )}
        </MetadataList.Item>
        <MetadataList.Item label="Description">
          <Text size={14} weight="medium">
            {transaction.summary}
          </Text>
        </MetadataList.Item>
        {transaction.merchantCategory && (
          <MetadataList.Item label="Merchant type">
            <Text size={14} weight="medium">
              {transaction.merchantCategory}
            </Text>
          </MetadataList.Item>
        )}
      </MetadataList>
      <Link to={`/cards/${transaction.cardId}`}>
        <Button variant="tertiary" size="sm">
          View full card details
        </Button>
      </Link>
    </Section>
  );
};

export default CardTransactionDetailsSection;
