import { FC } from "react";
import LargeDrawdownBanner from "resources/capital-drawdown-approvals/components/LargeDrawdownBanner";
import useCapitalDrawdownApproval from "resources/capital-drawdown-approvals/queries/useCapitalDrawdownApproval";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import InfoBox from "ui/data-display/InfoBox";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";

import styles from "./DrawdownDetails.module.scss";

type Props = {
  capitalAccountGuid: string;
  pendingDrawdownGuid: string;
};

const PendingDrawdownDetails: FC<Props> = ({ capitalAccountGuid, pendingDrawdownGuid }) => {
  const pendingDrawdown = useCapitalDrawdownApproval({
    capitalAccountGuid,
    pendingDrawdownGuid,
    required: true,
  });

  return (
    <div>
      {pendingDrawdown.note ? (
        <InfoBox
          variant="info"
          iconSize={24}
          text={
            <>
              This drawdown is under review by Highbeam to verify that the drawdown is for inventory
              spend. <strong>Review can take up to 3 business days.</strong>
            </>
          }
        />
      ) : (
        <LargeDrawdownBanner />
      )}
      <Divider />
      <MoneyAmount
        cents={pendingDrawdown.amount}
        withCents={pendingDrawdown.amount % 100 !== 0}
        direction="negative"
        weight="medium"
        size={20}
      />
      <Divider />
      <div className={styles.drawdownDetails}>
        <Text size={16} color={colors.grey[900]} weight="bold">
          Drawdown details
        </Text>
        <div className={styles.drawdownDetailsDescription}>
          <Text size={14} color={colors.grey[600]}>
            Description
          </Text>
          <Text size={14} color={colors.grey[900]} weight="medium">
            {pendingDrawdown.note}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default PendingDrawdownDetails;
