import IconWithTooltip from "components/common/IconWithTooltip";
import { CashBackInfoIconWithTooltip } from "resources/capital-accounts/constants";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import ChargeCardOfferRepaymentPeriodContent from "resources/charge-card-offer/components/ChargeCardOfferRepaymentPeriodContent";
import { StatementCycleExplanation } from "resources/charge-card-offer/constants/repayment-periods";
import useChargeCardOffer from "resources/charge-card-offer/queries/useChargeCardOffer";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SummaryArray from "ui/data-display/SummaryArray";
import Text from "ui/typography/Text";

import styles from "./LineOfCreditSummaryItems.module.scss";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardSummaryItems = ({ capitalAccountSummaryWithChargeCard }: Props) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  return (
    <SummaryArray className={styles.summaryArray}>
      <SummaryArray.Item className={styles.summaryArrayItem}>
        <div className={styles.headingContainer}>
          <Text size={14}>Limit</Text>
        </div>
        <CashDisplay cents={capitalAccountSummaryWithChargeCard.details.limit} />
      </SummaryArray.Item>
      {chargeCardOffer.maxCashback > 0 && (
        <SummaryArray.Item className={styles.summaryArrayItem}>
          <div className={styles.headingContainer}>
            <Text size={14}>Cash back</Text>
            <CashBackInfoIconWithTooltip />
          </div>
          <Text size={28} weight="medium">
            {chargeCardOffer.maxCashback}%
          </Text>
        </SummaryArray.Item>
      )}
      <SummaryArray.Item className={styles.summaryArrayItem}>
        <Text size={14} className={styles.headingContainer}>
          Repayment period
          <IconWithTooltip
            color="dark"
            content={<StatementCycleExplanation chargeCardOffer={chargeCardOffer} />}
          />
        </Text>
        <Text size={28} weight="medium">
          <ChargeCardOfferRepaymentPeriodContent
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
          />
        </Text>
      </SummaryArray.Item>
    </SummaryArray>
  );
};

export default ChargeCardSummaryItems;
