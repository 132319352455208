import MultiStep from "components/layouts/MultiStep";
import PlaidConnectionsList from "modules/plaid/components/PlaidConnectionsList";
import { Link, useNavigate } from "react-router-dom";
import usePlaidConnections from "resources/plaid-connections/queries/usePlaidConnections";

import CreditApplicationBackButton from "../components/CreditApplicationBackButton";
import useGetNextPathname from "../hooks/useGetNextPathname";

import styles from "./CreditApplicationViews.module.scss";

const CONNECT_BANKS_AND_CARDS_FORM_ID = "connect-banks-and-cards-form";

const ConnectBankAndCardsView = () => {
  const navigate = useNavigate();
  const nextPathname = useGetNextPathname();

  const plaidConnections = usePlaidConnections();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <>
      <MultiStep.Form id={CONNECT_BANKS_AND_CARDS_FORM_ID} onSubmit={handleSubmit}>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            Connected banks and cards
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
        <PlaidConnectionsList cardSize={"small"} />

        <MultiStep.Controls>
          <CreditApplicationBackButton />

          <MultiStep.Controls.NextButton
            form={CONNECT_BANKS_AND_CARDS_FORM_ID}
            disabled={plaidConnections.length === 0}
          >
            Save and continue
          </MultiStep.Controls.NextButton>

          {nextPathname && (
            <Link to={nextPathname}>
              <MultiStep.Controls.SkipButton className={styles.skipButton}>
                I don’t have other banks or cards.
              </MultiStep.Controls.SkipButton>
            </Link>
          )}
        </MultiStep.Controls>
      </MultiStep.Form>
    </>
  );
};

export default ConnectBankAndCardsView;
