import classNames from "classnames";
import { CSSProperties, FC, ReactNode } from "react";

import styles from "./Pill.module.scss";

export type PillColor =
  | "blue"
  | "green"
  | "green-dark"
  | "grey"
  | "grey-light"
  | "orange"
  | "orange-red"
  | "pink"
  | "pink-dark"
  | "purple"
  | "purple-dark"
  | "purple-light"
  | "red"
  | "yellow"
  | "white";

export type PillSize = 24 | 20;

type Props = {
  children: ReactNode;
  color: PillColor;
  className?: string;
  size?: PillSize;
  bordered?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
};

const Pill: FC<Props> = ({ children, color, className, style, size = 24, bordered, onClick }) => {
  return (
    <span
      style={style}
      className={classNames(
        className,
        styles.pill,
        styles[color],
        styles[`size-${size}`],
        bordered && styles.bordered,
        onClick && "cursor-pointer"
      )}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default Pill;
