import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";

const useHasBothPartnerBankAccounts = () => {
  const bankAccounts = useBankAccounts({ status: "open" });
  const hasThreadAccount = bankAccounts.some((account) => account.threadAccount);
  const hasBlueRidgeAccount = bankAccounts.some((account) => account.blueRidgeAccount);

  return Boolean(hasThreadAccount && hasBlueRidgeAccount);
};

export default useHasBothPartnerBankAccounts;
