import AuthenticationRequired from "AuthenticationRequired";
import ChatWidget from "components/ChatWidget";
import PlaidConnectionExistsModal from "components/PlaidConnectionExistsModal";
import AddToHomeScreenInstructionsSheet from "modules/add-to-home-screen/AddToHomeScreenInstructionsSheet";
import AddToHomeScreenPage from "modules/add-to-home-screen/AddToHomeScreenPage";
import { SIGN_OUT_ROUTE } from "modules/auth/constants";
import BusinessDisambiguator from "modules/business-disambiguator/BusinessDisambiguator";
import CommandPalette from "modules/command-palette/CommandPalette";
import useCanUseCommandPalette from "modules/command-palette/useCanUseCommandPalette";
import BusinessSwitcher from "modules/superuser/businessSwitcher/BusinessSwitcher";
import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import CreditComparisonPage from "pages/capital/CreditComparisonPage";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import CardLimitModal from "pages/CardLimitModal";
import ConfirmationModal from "pages/ConfirmationModal";
import InvitationPage from "pages/InvitationPage";
import LoadingPage from "pages/LoadingPage";
import PayoutInstructionModal from "pages/PayoutInstructionModal";
import ReferralPage from "pages/ReferralPage";
import LoadingShopify from "pages/settings/SettingsPage/Integrations/ConnectShopify/LoadingShopify";
import ShopifyRouter from "pages/settings/SettingsPage/Integrations/ConnectShopify/ShopifyRouter";
import SignInPage from "pages/SignInPage";
import SignOutPage from "pages/SignOutPage";
import SignUpPage from "pages/SignUpPage";
import UserProvider from "providers/AuthProvider/UserProvider";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useInsightsApp from "resources/insights-app/queries/useInsightsApp";
import AuthenticatedRouter from "routes/AuthenticatedRouter";
import isPlaidExistingAccountModalOpenState from "state/plaid/isExistingAccountModalOpen";
import { useIsTablet } from "utils/device/useMediaQuery";

const RootRouter = () => {
  const loadingPage = <LoadingPage location={RootRouter.name} />;
  const isPlaidExistingAccountModalOpen = useRecoilValue(isPlaidExistingAccountModalOpenState);
  const isTablet = useIsTablet();

  const canSuperuser = useCanSuperuser();
  const canUseCommandPalette = useCanUseCommandPalette();

  const isInsightsApp = useInsightsApp();

  return (
    <Suspense fallback={loadingPage}>
      <Routes>
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path={SIGN_OUT_ROUTE} element={<SignOutPage />} />
        <Route path="/invitation/:invitationSlug" element={<InvitationPage />} />
        <Route path="/referral/:referralSlug" element={<ReferralPage />} />
        <Route path={`${CREDIT_COMPARISON_PATH}/*`} element={<CreditComparisonPage />} />
        {/* /loading-shopify is used for redirect from Shopify OAuth */}
        <Route path="/loading-shopify/*" element={<LoadingShopify />} />
        {isInsightsApp ? <Route path="/shopify/*" element={<ShopifyRouter />} /> : null}
        <Route path="/loading" element={<LoadingPage location={RootRouter.name} />} />
        <Route path="/mobile" element={<AddToHomeScreenPage />} />
        <Route
          path="/*"
          element={
            <AuthenticationRequired>
              <BusinessDisambiguator>
                <UserProvider fallback={loadingPage}>
                  <AuthenticatedRouter />
                  <CardLimitModal />
                  <PayoutInstructionModal />
                  <ConfirmationModal />
                  {isTablet && <AddToHomeScreenInstructionsSheet />}
                  {isPlaidExistingAccountModalOpen && <PlaidConnectionExistsModal />}
                  <ChatWidget />
                </UserProvider>

                {/* NB(alex): Will delete `SuperuserTools` soon. Basically ⌘k for superuser and ⌘^k for command palette. */}
                {canSuperuser && <BusinessSwitcher />}
                {canUseCommandPalette && <CommandPalette />}
              </BusinessDisambiguator>
            </AuthenticationRequired>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default RootRouter;
