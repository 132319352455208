import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

import { BASE_CAPITAL_ACCOUNTS_QUERY_KEY } from "./useRefreshCapitalAccountsQueries";

export const useCapitalAccountSummaryQueryOptions = (capitalAccountGuid: string) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [BASE_CAPITAL_ACCOUNTS_QUERY_KEY, businessGuid, capitalAccountGuid],
    queryFn: () => {
      return highbeamApi.capitalAccount.getSummary(businessGuid, capitalAccountGuid);
    },
  });
};

export const useRefreshCapitalAccountSummary = (capitalAccountGuid: string) => {
  return useRefreshQuery(useCapitalAccountSummaryQueryOptions(capitalAccountGuid).queryKey);
};

export const useCapitalAccountSummaryQuery = (capitalAccountGuid: string) => {
  return useQuery(useCapitalAccountSummaryQueryOptions(capitalAccountGuid));
};

const useCapitalAccountSummary = <TRequired extends boolean>(
  capitalAccountGuid: string,
  params?: RequiredParam<TRequired>
) => {
  const { data: capitalAccountSummary } = useSuspenseQuery(
    useCapitalAccountSummaryQueryOptions(capitalAccountGuid)
  );

  if (params?.required && !capitalAccountSummary) {
    throw new RequiredButNotFoundError();
  }

  return capitalAccountSummary as DataRequired<typeof capitalAccountSummary, TRequired>;
};

export default useCapitalAccountSummary;
