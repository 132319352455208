import { ArrowRight } from "@phosphor-icons/react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import useNonTerminatedCapitalAccounts from "resources/capital-accounts/hooks/useNonTerminatedCapitalAccounts";
import { useCapitalAccountSummaryQueryOptions } from "resources/capital-accounts/queries/useCapitalAccountSummary";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import PlatformIcon from "ui/icons/PlatformIcon";
import Table, { TableColumnAlignment } from "ui/table/Table";
import TextCell from "ui/table/TextCell";
import Text from "ui/typography/Text";
import { useIsMobile } from "utils/device/useMediaQuery";

import styles from "./CapitalAccountsTable.module.scss";

const CapitalAccountUsageCell: FC<{ capitalAccountGuid: string }> = ({ capitalAccountGuid }) => {
  const { data: capitalAccount } = useSuspenseQuery(
    useCapitalAccountSummaryQueryOptions(capitalAccountGuid)
  );

  return (
    <Text color={colors.grey[800]} size={14} weight="medium" numeric align="right">
      <MoneyAmount
        cents={capitalAccount.available}
        withCents={capitalAccount.available !== capitalAccount.details.limit} // Do not show cents if full amount is available.
      />

      <Text as="span" color={colors.grey[400]}>
        {" / "}
        <MoneyAmount cents={capitalAccount.details.limit} withCents={false} />
      </Text>
    </Text>
  );
};

const CapitalAccountsTable = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const nonTerminatedCapitalAccounts = useNonTerminatedCapitalAccounts();

  // TODO: Handle empty state https://highbeamco.slack.com/archives/C03R625F068/p1715788676966769

  return (
    <Table
      data={nonTerminatedCapitalAccounts}
      columns={[
        {
          title: "Account",
          cellRender: ({ name }) => (
            <TextCell weight="medium" color={colors.grey[900]}>
              <PlatformIcon platformName="highbeam-capital" />
              {name}
            </TextCell>
          ),
        },
        {
          title: "Available",
          align: TableColumnAlignment.RIGHT,
          cellRender: (capitalAccount) => (
            <CapitalAccountUsageCell capitalAccountGuid={capitalAccount.guid} />
          ),
        },
        {
          title: !isMobile ? "Account details" : "",
          align: TableColumnAlignment.RIGHT,
          cellRender: () => (
            <div className={styles.details}>
              <Text size={14} align="right">
                View account details
              </Text>
              <ArrowRight size={16} />
            </div>
          ),
        },
      ]}
      rowKey={(capitalAccount) => capitalAccount.guid}
      onRowClick={(capitalAccount) => navigate(`/capital/${capitalAccount.guid}`)}
    />
  );
};

export default CapitalAccountsTable;
