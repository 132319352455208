import backgroundLogo from "assets/onboarding-background.svg";
import FullScreenLayout from "components/layouts/FullScreen";
import React, { CSSProperties } from "react";
import { useRecoilValue } from "recoil";
import { onboardingProgressState } from "state/onboarding/progress";
import colors from "styles/colors";
import ProgressBar from "ui/data-visualization/ProgressBar";

import styles from "./Onboarding.module.scss";

type Props = {
  style?: CSSProperties;
  showProgressBar?: boolean;
} & React.PropsWithChildren;

const OnboardingLayout: React.FC<Props> = ({
  children,
  style = { backgroundImage: `url(${backgroundLogo})` }, // Keeping this until we deprecate the old onboarding flow
  showProgressBar = true,
}) => {
  const progress = useRecoilValue(onboardingProgressState);
  return (
    <>
      <FullScreenLayout className={styles.container} style={style}>
        {children}
      </FullScreenLayout>
      {showProgressBar && (
        <ProgressBar
          className={styles.progressBarContainer}
          percent={progress}
          color={colors.purple[500]}
        />
      )}
    </>
  );
};
export default OnboardingLayout;
