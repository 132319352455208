import { CalendarBlank, CircleHalf, Clock, CurrencyDollar } from "@phosphor-icons/react";
import { FC } from "react";
import BillSummaryRep from "reps/BillSummaryRep";
import Pill from "ui/data-display/Pill";

type Props = {
  bill: BillSummaryRep.Complete;
};

const BillPaymentStatusPill: FC<Props> = ({ bill }) => {
  switch (bill.paymentStatus) {
    case BillSummaryRep.PaymentStatus.Overdue:
      return (
        <Pill color="orange">
          <Clock size={14} /> Overdue
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.Paid:
      return (
        <Pill color="green">
          <CurrencyDollar size={14} />
          Paid
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.PartiallyPaid:
      // TODO(alex): Use % once backend gives percentage.
      return (
        <Pill color="grey-light">
          <CircleHalf /> Partially paid
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.Scheduled:
      return (
        <Pill color="purple-light">
          <CalendarBlank size={16} />
          Scheduled
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.Pending:
      return (
        <Pill color="white" bordered>
          In progress
        </Pill>
      );
    case BillSummaryRep.PaymentStatus.Unpaid:
      return <Pill color="grey-light">Unpaid</Pill>;
  }
};

export default BillPaymentStatusPill;
