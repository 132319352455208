import { migrateToThreadActionItemConstants } from "modules/migrate-to-thread/migrateToThreadActionItems/constants";
import useMigrateToThreadActionItems from "modules/migrate-to-thread/queries/useMigrateToThreadActionItems";
import getActionItem from "resources/action-items/utils/getActionItem";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";

const useForceMigrateToThreadView = () => {
  const bankAccounts = useBankAccounts({ status: "open" });
  const isForceMigrateToThreadEnabled = useFeatureFlag("FORCE_MIGRATE_TO_THREAD");
  const { data: migrateToThreadActionItems } = useMigrateToThreadActionItems();
  const activeThreadViewActionItem = getActionItem(
    migrateToThreadActionItems,
    migrateToThreadActionItemConstants.activateThreadView.actionItemName
  );
  const hasThreadAccount = bankAccounts.some((account) => account.threadAccount);
  const hasCompletedActivateThreadView = activeThreadViewActionItem?.state === "Complete";

  return (
    (!hasThreadAccount && isForceMigrateToThreadEnabled && !hasCompletedActivateThreadView) ||
    bankAccounts.length === 0
  );
};

export default useForceMigrateToThreadView;
