import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import useOpenBankAccount from "resources/bank-accounts/queries/useOpenBankAccount";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";

type Params = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const useCapitalAccountRepaymentBankAccount = ({ capitalAccountSummary }: Params) => {
  const primaryBankAccount = usePrimaryBankAccount({ required: true });

  const repaymentBankAccountGuid =
    capitalAccountSummary.details.repayment.bankAccountGuid ?? primaryBankAccount.guid;

  return useOpenBankAccount(repaymentBankAccountGuid, {
    required: true,
  });
};

export default useCapitalAccountRepaymentBankAccount;
