import { useMutation } from "@tanstack/react-query";
import { useRefreshBusinessMembersQuery } from "resources/business-members/queries/useBusinessMembers";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";

type Variables = {
  businessGuid: string;
  memberGuid: string;
};

const useRemoveBusinessMemberMutation = () => {
  const highbeamApi = useHighbeamApi();
  const refreshBusinessMembersQuery = useRefreshBusinessMembersQuery();
  const { segmentTrack } = useSegment();

  return useMutation({
    mutationFn: async ({ businessGuid, memberGuid }: Variables) => {
      return highbeamApi.businessMember.delete(businessGuid, memberGuid);
    },
    onError: () => {
      notify("error", "Failed to remove user");
    },
    onSuccess: async () => {
      await refreshBusinessMembersQuery();
      notify("success", "User removed");
      segmentTrack(SEGMENT_EVENTS.BUSINESS_MEMBER_REMOVED);
    },
  });
};

export default useRemoveBusinessMemberMutation;
