import { Account, Card, CardLimits, DepositAccount, State } from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState, useCallback } from "react";
import BankAccountRep from "reps/BankAccountRep";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CardRep from "reps/CardRep";
import { useMilestoneActionItems } from "resources/action-items/hooks/useMilestoneActionItems";
import { GetStartedSetupGuideStep, Milestone } from "resources/action-items/types";
import useUnitAuthorizedBusinessMembers from "resources/business-members/queries/useUnitAuthorizedBusinessMembers";
import useActiveCapitalAccountsWithChargeCard from "resources/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import { CARDS_QUERY_KEY } from "resources/cards/queries/useCardsQueryOptions";
import { CardBudgetType, CardVirtualOrPhysical } from "resources/cards/types";
import useChargeCardOffer from "resources/charge-card-offer/queries/useChargeCardOffer";
import useChargeCardAccount from "resources/charge-cards/queries/useChargeCardAccount";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useUnitCoCustomer from "resources/unit-co-customer/queries/useUnitCoCustomer";
import useUser from "resources/user/queries/useUser";
import { useUsersTableDataQuery } from "resources/users-table-data/queries/useUsersTableData";
import { notify } from "ui/feedback/Toast";
import { Option } from "ui/inputs/Dropdown";
import { getAccountDisplayName } from "utils/account";
import { Address, INITIAL_US_ADDRESS } from "utils/address";
import { getCardType, isDebitCard } from "utils/cards";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useSegment, { SEGMENT_EVENTS } from "utils/customHooks/useSegment";
import notEmpty from "utils/notEmpty";
import useRefreshQuery from "utils/react-query/useRefreshQuery";
import { AccountTags } from "utils/types/transactionsTypes";
import { v4 as uuidv4 } from "uuid";

import BusinessMemberDropdownLabel from "./BusinessMemberDropdownLabel";

const MAX_CARD_NAME_LENGTH = 40;

export enum Step {
  SELECT_VIRTUAL_CARD,
  SELECT_PHYSICAL_CARD,
  CHARGE_CARD_ONBOARDING,
  CREATE_VIRTUAL_DEBIT_CARD,
  CREATE_PHYSICAL_DEBIT_CARD,
  CREATE_VIRTUAL_CREDIT_CARD,
  CREATE_PHYSICAL_CREDIT_CARD,
  SHIPPING_ADDRESS,
  CONFIRMATION,
}

export type ValidationErrors = {
  addressLine1?: string;
  city?: string;
  zipCode?: string;
  state?: string;
  account?: string;
  cardName?: string;
  dateOfBirth?: string;
};

export const budgetOptions: Option[] = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
] satisfies { label: string; value: CardBudgetType }[]; // NB(alex): Hack to ensure type-safety while we still use Dropdown v1's `Option`.

type StepInfo = {
  title: string;
  buttonText: string;
  showCancel: boolean;
  reloadPage: boolean;
};

export const stepInfoMap: Record<Step, StepInfo> = {
  [Step.SELECT_VIRTUAL_CARD]: {
    title: "Create virtual card",
    buttonText: "Next",
    showCancel: true,
    reloadPage: false,
  },
  [Step.SELECT_PHYSICAL_CARD]: {
    title: "Create physical card",
    buttonText: "Next",
    showCancel: true,
    reloadPage: false,
  },
  [Step.CHARGE_CARD_ONBOARDING]: {
    title: "Create virtual Highbeam Card",
    buttonText: "Next",
    showCancel: true,
    reloadPage: false,
  },
  [Step.CREATE_VIRTUAL_DEBIT_CARD]: {
    title: "Create virtual debit card",
    buttonText: "Create card",
    showCancel: true,
    reloadPage: false,
  },
  [Step.CREATE_PHYSICAL_DEBIT_CARD]: {
    title: "Create new physical card",
    buttonText: "Next",
    showCancel: true,
    reloadPage: false,
  },
  [Step.CREATE_VIRTUAL_CREDIT_CARD]: {
    title: "Create virtual debit card",
    buttonText: "Create card",
    showCancel: true,
    reloadPage: false,
  },
  [Step.CREATE_PHYSICAL_CREDIT_CARD]: {
    title: "Create new physical Highbeam Card",
    buttonText: "Next",
    showCancel: true,
    reloadPage: false,
  },
  [Step.SHIPPING_ADDRESS]: {
    title: "Create new card",
    buttonText: "Create card",
    showCancel: true,
    reloadPage: false,
  },
  [Step.CONFIRMATION]: {
    title: "Card created!",
    buttonText: "Close",
    showCancel: false,
    reloadPage: true,
  },
};

const determineInitialStep = ({
  createCardModalType,
  isAbleToCreateChargeCards,
}: {
  createCardModalType: CardVirtualOrPhysical;
  isAbleToCreateChargeCards: boolean;
}): Step => {
  if (createCardModalType === "physical" && isAbleToCreateChargeCards) {
    return Step.SELECT_PHYSICAL_CARD;
  }

  if (createCardModalType === "physical") {
    return Step.CREATE_PHYSICAL_DEBIT_CARD;
  }

  if (isAbleToCreateChargeCards) {
    return Step.SELECT_VIRTUAL_CARD;
  }

  return Step.CREATE_VIRTUAL_DEBIT_CARD;
};

const defaultCardType = {
  physical: "PHYSICAL",
  virtual: "VIRTUAL",
} as const;

export const getBudgetLabel = (budgetType: CardBudgetType) => {
  return budgetType === "daily"
    ? "Daily spend budget (optional)"
    : "Monthly spend budget (optional)";
};

export const useCreateCardModal = (
  createCardModalType: CardVirtualOrPhysical,
  defaultCardName: string | undefined,
  handleClose: (reload: boolean) => void
) => {
  const user = useUser();
  const unitApi = useUnitApi();

  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();

  const { segmentTrack } = useSegment();

  const [selectedCapitalAccount, setSelectedCapitalAccount] =
    useState<CapitalAccountRep.Complete | null>(null);
  const activeCapitalAccountsWithChargeCard = useActiveCapitalAccountsWithChargeCard();
  const chargeCardOffer = useChargeCardOffer(selectedCapitalAccount?.guid);
  const chargeCardAccount = useChargeCardAccount(selectedCapitalAccount?.guid);

  const isAbleToCreateChargeCards = activeCapitalAccountsWithChargeCard.length > 0;

  const initialStep = determineInitialStep({
    createCardModalType,
    isAbleToCreateChargeCards,
  });

  const initialCardName = defaultCardName || user.firstName ? `${user.firstName}’s card` : "";
  const initialHasPressedNextAfter = {
    [Step.CREATE_VIRTUAL_DEBIT_CARD]: false,
    [Step.CREATE_PHYSICAL_DEBIT_CARD]: false,
    [Step.CREATE_VIRTUAL_CREDIT_CARD]: false,
    [Step.CREATE_PHYSICAL_CREDIT_CARD]: false,
    [Step.SHIPPING_ADDRESS]: false,
    [Step.CONFIRMATION]: false,
  };
  const [idempotencyKey] = useState(uuidv4());
  const [accounts, setAccounts] = useState<BankAccountRep.Complete[]>([]);

  const [step, setStep] = useState(initialStep);
  const [cardType, setCardType] = useState<CardRep.CardType>(defaultCardType[createCardModalType]);
  const [cardName, setCardName] = useState(initialCardName);
  const [selectedAccount, setSelectedAccount] = useState<Option | null>(null);
  const [selectedUser, setSelectedUser] = useState<Option | null>(null);

  const [selectedUserDateOfBirth, setSelectedUserDateOfBirth] = useState<Dayjs | null>(null);
  const [selectedUserHasDateOfBirth, setSelectedUserHasDateOfBirth] = useState(false);

  // NB(alex): This should always be valid if it isn't null. Leaving here for legacy purposes, but we can delete it once we refactor the modal.
  const isValidDateOfBirth = Boolean(selectedUserDateOfBirth);

  const [dailyLimit, setDailyLimit] = useState<number | null>(null);
  const [monthlyLimit, setMonthlyLimit] = useState<number | null>(null);
  const [address, setAddress] = useState<Address>(INITIAL_US_ADDRESS);
  const [createdCard, setCreatedCard] = useState<Card>();
  const [createdCardLimit, setCreatedCardLimit] = useState<CardLimits>();
  const [createdCardRelatedAccounts, setCreatedCardRelatedAccounts] =
    useState<BankAccountRep.Complete[]>();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [budgetType, setBudgetType] = useState<CardBudgetType>("monthly");
  const [hasPressedNextAfter, setHasPressedNextAfter] = useState(initialHasPressedNextAfter);
  const { finishItemByStep } = useMilestoneActionItems(Milestone.GetStarted);

  const unitCoCustomer = useUnitCoCustomer();
  const isBusinessCustomer = unitCoCustomer?.type === "businessCustomer" || false;

  const getValidationErrors = useCallback((): ValidationErrors => {
    switch (step) {
      case Step.SHIPPING_ADDRESS:
        return {
          addressLine1: !address.addressLine1?.value ? "Address is required" : undefined,
          city: !address.city ? "City is required" : undefined,
          zipCode: !address.zipCode ? "ZIP is required" : undefined,
          state: !address.state ? "State is required" : undefined,
        };
      case Step.CREATE_VIRTUAL_DEBIT_CARD:
      case Step.CREATE_PHYSICAL_DEBIT_CARD:
        return {
          account:
            !isLoading && accounts.length === 0 && selectedAccount === null
              ? "Must select an account"
              : undefined,
          cardName:
            cardName && cardName.length > MAX_CARD_NAME_LENGTH
              ? `The card name must be less than ${MAX_CARD_NAME_LENGTH} characters.`
              : undefined,
          dateOfBirth:
            isBusinessCustomer && !isValidDateOfBirth ? "Date of birth is invalid" : undefined,
        };
      case Step.CREATE_VIRTUAL_CREDIT_CARD:
      case Step.CREATE_PHYSICAL_CREDIT_CARD:
        return {
          cardName:
            cardName && cardName.length > MAX_CARD_NAME_LENGTH
              ? `The card name must be less than ${MAX_CARD_NAME_LENGTH} characters.`
              : undefined,
          dateOfBirth:
            isBusinessCustomer && !isValidDateOfBirth ? "Date of birth is invalid" : undefined,
        };
      case Step.CONFIRMATION:
      default:
        return {};
    }
  }, [
    address,
    selectedAccount,
    step,
    cardName,
    isLoading,
    accounts,
    isValidDateOfBirth,
    isBusinessCustomer,
  ]);

  const checkForValidationErrors = useCallback(() => {
    setValidationErrors(getValidationErrors());
  }, [setValidationErrors, getValidationErrors]);

  const accountIdToAccount = useMemo(
    () =>
      accounts?.reduce(
        (map: Record<string, BankAccountRep.Complete>, account: BankAccountRep.Complete) => ({
          ...map,
          [account.unitCoDepositAccountId]: account,
        }),
        {}
      ) || {},
    [accounts]
  );

  useEffect(() => {
    if (defaultCardName) {
      setCardName(defaultCardName);
    }
  }, [defaultCardName]);

  const unitAuthorizedBusinessMembers = useUnitAuthorizedBusinessMembers();

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const accounts =
        (await highbeamApi.bankAccount.getOpenByBusiness(businessGuid))?.filter(
          (account) => account.highbeamType.supportsDebitCards
        ) ?? [];
      setAccounts(accounts);

      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step === Step.SHIPPING_ADDRESS && !hasPressedNextAfter[Step.SHIPPING_ADDRESS]) return;
    checkForValidationErrors();
  }, [address, checkForValidationErrors, step, hasPressedNextAfter]);

  const accountOptions: Option[] = useMemo(
    () =>
      accounts.map((account: BankAccountRep.Complete) => ({
        value: account.unitCoDepositAccountId,
        label: getAccountDisplayName(account),
      })) || [],
    [accounts]
  );

  const { data: users } = useUsersTableDataQuery();

  const userOptions: Option[] = useMemo(() => {
    if (!isBusinessCustomer || !users) {
      return [];
    }

    return (
      users.map((user) => {
        const appendToNameText = user.isInvited ? " (has not accepted invite yet)" : "";

        return {
          value: user.guid,
          label: (
            <BusinessMemberDropdownLabel
              fullName={
                <>
                  {user.fullName}
                  {appendToNameText && <strong>{appendToNameText}</strong>}
                </>
              }
              userRoleName={user.userRoleName}
              isOnboarded={!user.isInvited && user.isOnboarded}
            />
          ),
          isDisabled: user.isInvited || !user.isOnboarded,
        };
      }) || []
    );
  }, [users, isBusinessCustomer]);

  useEffect(() => {
    setSelectedAccount(accountOptions?.[0] || null);
    checkForValidationErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountOptions]);

  // Sets selectedCapitalAccount to the first capital account with a charge card if none is selected
  useEffect(() => {
    if (selectedCapitalAccount === null) {
      setSelectedCapitalAccount(activeCapitalAccountsWithChargeCard?.[0] || null);
    }
  }, [activeCapitalAccountsWithChargeCard, selectedCapitalAccount]);

  // Set user's date of birth on change
  const newlySelectedUser = unitAuthorizedBusinessMembers.find(
    ({ guid }) => selectedUser && selectedUser.value === guid
  );

  useEffect(() => {
    if (!newlySelectedUser) return;

    const userDateOfBirth = newlySelectedUser.dateOfBirth
      ? dayjs(newlySelectedUser.dateOfBirth)
      : null;

    setSelectedUserDateOfBirth(userDateOfBirth);
    setSelectedUserHasDateOfBirth(Boolean(userDateOfBirth));

    if (newlySelectedUser.fullName) {
      setCardName(`${newlySelectedUser.fullName}’s card`);
    }
  }, [newlySelectedUser]);

  // Set default `selectedUser`
  useEffect(() => {
    if (userOptions.length > 0 && !selectedUser) {
      const user = userOptions?.find((option: Option) => !option.isDisabled);
      if (user) {
        setSelectedUser(user || null);
        checkForValidationErrors();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userOptions, selectedUser]);

  const refreshCardsQuery = useRefreshQuery([CARDS_QUERY_KEY]);

  const handleSuccessfulCardCreate = async (cardId: string) => {
    const [{ data: card, included: relatedResources }, { data: cardLimit }] = await Promise.all([
      unitApi.cards.get(cardId.toString(), "account"),
      unitApi.cards.limits(cardId.toString()),
    ]);
    const relatedBankAccounts = isDebitCard(getCardType(card))
      ? await Promise.all(
          (relatedResources as Account[]).map((relatedResource: Account) => {
            const tags = (relatedResource as DepositAccount).attributes.tags as AccountTags;
            return highbeamApi.bankAccount.get(tags.bankAccountGuid!);
          }) ?? []
        ).then((accounts) => accounts.filter(notEmpty))
      : [];

    await finishItemByStep(GetStartedSetupGuideStep.CreateVirtualCard, "Complete");
    refreshCardsQuery();
    setCreatedCard(card);
    setCreatedCardLimit(cardLimit);
    setCreatedCardRelatedAccounts(relatedBankAccounts);
    setStep(Step.CONFIRMATION);
  };

  const createVirtualCard = async () => {
    if (selectedAccount === null) {
      return;
    }

    const response = await highbeamApi.card.create({
      type: "VIRTUAL",
      businessGuid,
      accountGuid: accountIdToAccount[selectedAccount.value].guid,
      name: cardName,
      associatedAccountId: selectedAccount.value,
      dailyPurchaseLimit: dailyLimit ? dailyLimit * 100 : null,
      monthlyPurchaseLimit: monthlyLimit ? monthlyLimit * 100 : null,
      ...(selectedUser && selectedUserDateOfBirth
        ? {
            identifiableInfo: {
              memberGuid: selectedUser.value,
              dateOfBirth: selectedUserDateOfBirth.format("YYYY-MM-DD"),
            },
          }
        : {}),
    });
    const cardId = response.cardId;
    handleSuccessfulCardCreate(cardId);
    segmentTrack(SEGMENT_EVENTS.CARD_CREATED, {
      type: "virtual charge",
      cardId,
    });
  };

  const createPhysicalCard = async () => {
    if (selectedAccount === null) {
      return;
    }

    const response = await highbeamApi.card.create({
      type: "PHYSICAL",
      businessGuid,
      accountGuid: accountIdToAccount[selectedAccount.value].guid,
      name: cardName,
      associatedAccountId: selectedAccount.value,
      dailyPurchaseLimit: dailyLimit ? dailyLimit * 100 : null,
      monthlyPurchaseLimit: monthlyLimit ? monthlyLimit * 100 : null,
      ...(selectedUser && selectedUserDateOfBirth
        ? {
            identifiableInfo: {
              memberGuid: selectedUser.value,
              dateOfBirth: selectedUserDateOfBirth.format("YYYY-MM-DD"),
            },
          }
        : {}),
      shippingAddress: {
        street: address.addressLine1?.value!,
        street2: address.addressLine2,
        city: address.city,
        state: address.state?.value as State,
        postalCode: address.zipCode,
        country: address.country?.value!,
      },
    });
    const cardId = response.cardId;
    handleSuccessfulCardCreate(cardId);
    segmentTrack(SEGMENT_EVENTS.CARD_CREATED, {
      type: "physical",
      cardId,
    });
  };

  const createVirtualChargeCard = async () => {
    if (!isAbleToCreateChargeCards) return;
    if (!chargeCardAccount) {
      throw new Error("Unable to create Highbeam Card.");
    }
    const { guid: creditAccountGuid, unitCoCreditAccountId } = chargeCardAccount;

    const response = await highbeamApi.card.create({
      type: "VIRTUAL_CREDIT",
      businessGuid,
      accountGuid: creditAccountGuid,
      name: cardName,
      associatedAccountId: unitCoCreditAccountId,
      dailyPurchaseLimit: dailyLimit ? dailyLimit * 100 : null,
      monthlyPurchaseLimit: monthlyLimit ? monthlyLimit * 100 : null,
      ...(selectedUser && selectedUserDateOfBirth
        ? {
            identifiableInfo: {
              memberGuid: selectedUser.value,
              dateOfBirth: selectedUserDateOfBirth.format("YYYY-MM-DD"),
            },
          }
        : {}),
    });
    const cardId = response.cardId;
    handleSuccessfulCardCreate(cardId);
    segmentTrack(SEGMENT_EVENTS.CARD_CREATED, {
      type: "virtual charge",
      cardId,
    });
  };

  const createPhysicalChargeCard = async () => {
    if (!isAbleToCreateChargeCards) return;
    if (!chargeCardAccount) {
      throw new Error("Unable to create Highbeam Card.");
    }
    const { guid: creditAccountGuid, unitCoCreditAccountId } = chargeCardAccount;

    const response = await highbeamApi.card.create({
      type: "PHYSICAL_CREDIT",
      businessGuid,
      accountGuid: creditAccountGuid,
      name: cardName,
      associatedAccountId: unitCoCreditAccountId,
      dailyPurchaseLimit: dailyLimit ? dailyLimit * 100 : null,
      monthlyPurchaseLimit: monthlyLimit ? monthlyLimit * 100 : null,
      ...(selectedUser && selectedUserDateOfBirth
        ? {
            identifiableInfo: {
              memberGuid: selectedUser.value,
              dateOfBirth: selectedUserDateOfBirth.format("YYYY-MM-DD"),
            },
          }
        : {}),
      shippingAddress: {
        street: address.addressLine1?.value!,
        street2: address.addressLine2,
        city: address.city,
        state: address.state?.value as State,
        postalCode: address.zipCode,
        country: address.country?.value!,
      },
    });
    const cardId = response.cardId;
    handleSuccessfulCardCreate(cardId);
    segmentTrack(SEGMENT_EVENTS.CARD_CREATED, {
      type: "physical",
      cardId,
    });
  };

  const updateBudgetLimit = (val: string) => {
    const parsedValue = parseFloat(val) ? parseFloat(val) : null;
    if (budgetType === "daily") {
      setDailyLimit(parsedValue);
      setMonthlyLimit(null);
    } else if (budgetType === "monthly") {
      setMonthlyLimit(parsedValue);
      setDailyLimit(null);
    }
  };

  const handleNext = async (currentCardType: CardRep.CardType = cardType) => {
    setHasPressedNextAfter((previous) => ({
      ...previous,
      [step]: true,
    }));

    const errors = getValidationErrors();
    if (Object.values(errors).filter(Boolean).length > 0) {
      setValidationErrors(errors);
      return;
    }
    switch (step) {
      case Step.SELECT_VIRTUAL_CARD:
        if (currentCardType === "VIRTUAL_CREDIT") {
          setStep(Step.CHARGE_CARD_ONBOARDING);
        }
        if (currentCardType === "VIRTUAL") {
          setStep(Step.CREATE_VIRTUAL_DEBIT_CARD);
        }
        break;
      case Step.CHARGE_CARD_ONBOARDING:
        setBudgetType("monthly");
        if (chargeCardAccount && chargeCardAccount.maxCreditLimit) {
          setMonthlyLimit(chargeCardAccount.maxCreditLimit / 100 / 4);
          setDailyLimit(chargeCardAccount.maxCreditLimit / 100 / 4);
        } else if (chargeCardOffer && chargeCardOffer.maxCreditLimit) {
          setMonthlyLimit(chargeCardOffer.maxCreditLimit / 100 / 4);
          setDailyLimit(chargeCardOffer.maxCreditLimit / 100 / 4);
        } else if (selectedCapitalAccount) {
          setMonthlyLimit(selectedCapitalAccount.details.limit / 100 / 4);
          setDailyLimit(selectedCapitalAccount.details.limit / 100 / 4);
        }
        if (currentCardType === "PHYSICAL_CREDIT") {
          setStep(Step.CREATE_PHYSICAL_CREDIT_CARD);
        }
        if (currentCardType === "VIRTUAL_CREDIT") {
          setStep(Step.CREATE_VIRTUAL_CREDIT_CARD);
        }

        break;
      case Step.SELECT_PHYSICAL_CARD:
        if (currentCardType === "PHYSICAL") {
          setStep(Step.CREATE_PHYSICAL_DEBIT_CARD);
        }
        if (currentCardType === "PHYSICAL_CREDIT") {
          setStep(Step.CHARGE_CARD_ONBOARDING);
        }
        break;
      case Step.CREATE_VIRTUAL_DEBIT_CARD:
      case Step.CREATE_VIRTUAL_CREDIT_CARD:
        setIsLoading(true);
        try {
          // NB(zafin): Refactor so that it works based off of Step instead of cardType.
          // Some state issues don't allow us to do that right now.
          if (currentCardType === "VIRTUAL") {
            await createVirtualCard();
          }
          if (currentCardType === "VIRTUAL_CREDIT") {
            await createVirtualChargeCard();
          }
          setIsLoading(false);
        } catch (e) {
          notify("error", "Something went wrong! Please try again.");
          captureException(e);
          setIsLoading(false);
        }
        break;
      case Step.CREATE_PHYSICAL_DEBIT_CARD:
      case Step.CREATE_PHYSICAL_CREDIT_CARD: {
        setStep(Step.SHIPPING_ADDRESS);
        break;
      }
      case Step.SHIPPING_ADDRESS: {
        setIsLoading(true);
        try {
          if (cardType === "PHYSICAL") {
            await createPhysicalCard();
          }
          if (cardType === "PHYSICAL_CREDIT") {
            await createPhysicalChargeCard();
          }
        } catch (e) {
          notify("error", "Something went wrong! Please try again.");
          captureException(e);
          setIsLoading(false);
        }
        break;
      }
      case Step.CONFIRMATION: {
        handleClose(true);
        break;
      }
    }
  };

  return {
    step,
    handleNext,
    idempotencyKey,
    cardType,
    cardName,
    setCardName,
    setCardType,
    selectedAccount,
    setSelectedAccount,
    selectedCapitalAccount,
    setSelectedCapitalAccount,
    selectedUser,
    setSelectedUser,
    userOptions,
    selectedUserDateOfBirth,
    setSelectedUserDateOfBirth,
    selectedUserHasDateOfBirth,
    accountOptions,
    dailyLimit,
    setDailyLimit,
    budgetType,
    setBudgetType,
    updateBudgetLimit,
    monthlyLimit,
    setMonthlyLimit,
    address,
    setAddress,
    createdCard,
    createdCardLimit,
    createdCardRelatedAccounts,
    isLoading,
    validationErrors,
    checkForValidationErrors,
  };
};
