import { WirePayment, WireTransaction } from "@highbeam/unit-node-sdk";
import MetadataList from "components/MetadataList";
import TraceNumberLabel from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneDetailsSection/TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";
import { useUnitCoTransaction } from "resources/unit-co-transactions/queries/useUnitCoTransactionQueryOptions";
import Section from "ui/data-display/Section";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import { HighbeamWireImadOmad } from "utils/types/transactionsTypes";

type Props = {
  payment: WirePayment;
};

const WirePaymentDetailsSection: React.FC<Props> = ({ payment }) => {
  const transaction = useUnitCoTransaction<WireTransaction>({
    accountId: payment.relationships.account.data.id,
    transactionId: payment.relationships.transaction?.data.id,
  });

  const imadOmad = transaction?.attributes.imadOmad as HighbeamWireImadOmad;

  return (
    <Section>
      <Heading3>Wire transfer details</Heading3>
      <MetadataList>
        {payment.attributes.description && (
          <MetadataList.Item label="Description">
            <Text size={14} weight="medium">
              {payment.attributes.description}
            </Text>
          </MetadataList.Item>
        )}
        <MetadataList.Item label="Routing number">
          <Text size={14} weight="medium">
            {payment.attributes.counterparty.routingNumber}
          </Text>
        </MetadataList.Item>
        <MetadataList.Item label="Account number">
          <Text size={14} weight="medium">
            {payment.attributes.counterparty.accountNumber}
          </Text>
        </MetadataList.Item>
        {transaction?.attributes.counterparty.accountType && (
          <MetadataList.Item label="Account type">
            <Text size={14} weight="medium">
              {transaction.attributes.counterparty.accountType}
            </Text>
          </MetadataList.Item>
        )}
        {imadOmad && (
          <MetadataList.Item label={<TraceNumberLabel />}>
            <Text size={14} weight="medium">
              {`IMAD: ${imadOmad.imad}, OMAD: ${imadOmad.omad}`}
            </Text>
          </MetadataList.Item>
        )}
      </MetadataList>
    </Section>
  );
};

export default WirePaymentDetailsSection;
