import CardRep from "reps/CardRep";
import ModalV2 from "ui/overlay/ModalV2";
import { isChargeCard } from "utils/cards";

import ChargeCardOnboarding from "./ChargeCardOnboarding";
import ChargeCardCreationProgress from "./ChargeCardOnboarding/ChargeCardCreationProgress";
import Confirmation from "./Confirmation";
import CreateCard from "./CreateCard";
import styles from "./CreateCardModal.module.scss";
import SelectCard from "./SelectCard";
import ShippingAddress from "./ShippingAddress";
import { Step, stepInfoMap, useCreateCardModal } from "./utils";

const getHeading = (cardType: CardRep.CardType, step: Step): string => {
  if (
    step === Step.SELECT_VIRTUAL_CARD &&
    (cardType === "VIRTUAL_CREDIT" || cardType === "VIRTUAL")
  ) {
    return "Create a virtual card";
  }
  if (
    step === Step.SELECT_PHYSICAL_CARD &&
    (cardType === "PHYSICAL_CREDIT" || cardType === "PHYSICAL")
  ) {
    return "Create a physical card";
  }
  switch (cardType) {
    case "PHYSICAL":
      return "Create a new physical debit card";
    case "VIRTUAL":
      return "Create a virtual debit card";
    case "PHYSICAL_CREDIT":
      return "Create a new physical Highbeam Card";
    case "VIRTUAL_CREDIT":
      return "Create a virtual Highbeam Card";
  }
};

const getFormId = (step: Step): string => {
  switch (step) {
    case Step.SELECT_VIRTUAL_CARD:
    case Step.SELECT_PHYSICAL_CARD:
      return "card-creation-select-card-type-form";
    case Step.CHARGE_CARD_ONBOARDING:
      return "card-creation-charge-card-onboarding-step-1-form";
    case Step.CREATE_VIRTUAL_DEBIT_CARD:
    case Step.CREATE_PHYSICAL_DEBIT_CARD:
    case Step.CREATE_VIRTUAL_CREDIT_CARD:
    case Step.CREATE_PHYSICAL_CREDIT_CARD:
      return "card-creation-details-form";
    case Step.SHIPPING_ADDRESS:
      return "card-creation-shipping-address-form";
    case Step.CONFIRMATION:
      return "card-creation-confirmation-form";
  }
};

// NB(alex): This is a hack that would not be necessary if we used context, but we are instead drilling state props with `useCreateCardModal` so this is the simplest solution for now.
type Props = ReturnType<typeof useCreateCardModal> & {
  onClickClose: () => void;
};

const CreateCardModalContent: React.FC<Props> = ({
  cardType,
  setCardType,
  step,
  handleNext,
  cardName,
  setCardName,
  selectedAccount,
  setSelectedAccount,
  selectedCapitalAccount,
  setSelectedCapitalAccount,
  selectedUser,
  setSelectedUser,
  userOptions,
  selectedUserDateOfBirth,
  setSelectedUserDateOfBirth,
  selectedUserHasDateOfBirth,
  accountOptions,
  dailyLimit,
  setDailyLimit,
  updateBudgetLimit,
  budgetType,
  setBudgetType,
  monthlyLimit,
  setMonthlyLimit,
  address,
  setAddress,
  createdCard,
  createdCardLimit,
  createdCardRelatedAccounts,
  isLoading,
  validationErrors,
  checkForValidationErrors,
  onClickClose,
}) => {
  const hasValidationErrors = Object.values(validationErrors).filter(Boolean).length > 0;

  // NB(alex): We should refactor `useCreateCardModal` to contain form handler logic but we can put it here for now.
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    handleNext();
  };

  const header = (
    <ModalV2.Content.Header heading={getHeading(cardType, step)} onClickClose={onClickClose} />
  );

  switch (step) {
    case Step.SELECT_VIRTUAL_CARD:
      return (
        <ModalV2.Content
          className={styles.selectCardModalContent}
          element="form"
          onSubmit={handleSubmit}
          id={getFormId(step)}
        >
          {header}
          <ModalV2.Content.Body>
            <SelectCard
              createCardType="virtual"
              handleNext={handleNext}
              setCardType={setCardType}
            />
          </ModalV2.Content.Body>
        </ModalV2.Content>
      );
    case Step.SELECT_PHYSICAL_CARD:
      return (
        <ModalV2.Content
          className={styles.selectCardModalContent}
          element="form"
          onSubmit={handleSubmit}
          id={getFormId(step)}
        >
          {header}
          <ModalV2.Content.Body>
            <SelectCard
              createCardType="physical"
              handleNext={handleNext}
              setCardType={setCardType}
            />
          </ModalV2.Content.Body>
        </ModalV2.Content>
      );
    case Step.CHARGE_CARD_ONBOARDING:
      return (
        <ModalV2.Content
          className={styles.chargeCardOnboardingModalContent}
          element="form"
          onSubmit={handleSubmit}
          id={getFormId(step)}
        >
          {header}
          <ChargeCardOnboarding
            step={step}
            selectedCapitalAccount={selectedCapitalAccount}
            setSelectedCapitalAccount={setSelectedCapitalAccount}
          />
          <ModalV2.Content.Footer>
            <ModalV2.Content.Footer.CancelButton onClick={onClickClose} />
            <ModalV2.Content.Footer.SubmitButton form={getFormId(step)}>
              Next
            </ModalV2.Content.Footer.SubmitButton>
          </ModalV2.Content.Footer>
        </ModalV2.Content>
      );
    case Step.CREATE_VIRTUAL_DEBIT_CARD:
    case Step.CREATE_PHYSICAL_DEBIT_CARD:
    case Step.CREATE_VIRTUAL_CREDIT_CARD:
    case Step.CREATE_PHYSICAL_CREDIT_CARD:
      return (
        <ModalV2.Content element="form" onSubmit={handleSubmit} id={getFormId(step)}>
          {header}
          {isChargeCard(cardType) && <ChargeCardCreationProgress step={step} />}
          <ModalV2.Content.Body>
            <CreateCard
              cardType={cardType}
              cardName={cardName}
              setCardName={setCardName}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              accountOptions={accountOptions}
              userOptions={userOptions}
              selectedUserDateOfBirth={selectedUserDateOfBirth}
              setSelectedUserDateOfBirth={setSelectedUserDateOfBirth}
              selectedUserHasDateOfBirth={selectedUserHasDateOfBirth}
              isLoading={isLoading}
              dailyLimit={dailyLimit}
              setDailyLimit={setDailyLimit}
              updateBudgetLimit={updateBudgetLimit}
              budgetType={budgetType}
              setBudgetType={setBudgetType}
              monthlyLimit={monthlyLimit}
              setMonthlyLimit={setMonthlyLimit}
              validationErrors={validationErrors}
              updateValidationErrors={checkForValidationErrors}
            />
          </ModalV2.Content.Body>
          <ModalV2.Content.Footer>
            <ModalV2.Content.Footer.CancelButton onClick={onClickClose} />

            <ModalV2.Content.Footer.SubmitButton
              isLoading={isLoading}
              disabled={hasValidationErrors}
              form={getFormId(step)}
            >
              {stepInfoMap[step].buttonText}
            </ModalV2.Content.Footer.SubmitButton>
          </ModalV2.Content.Footer>
        </ModalV2.Content>
      );
    case Step.SHIPPING_ADDRESS:
      return (
        <ModalV2.Content element="form" onSubmit={handleSubmit} id={getFormId(step)}>
          {header}
          {isChargeCard(cardType) && <ChargeCardCreationProgress step={step} />}
          <ModalV2.Content.Body>
            <ShippingAddress
              address={address}
              setAddress={setAddress}
              validationErrors={validationErrors}
            />
          </ModalV2.Content.Body>
          <ModalV2.Content.Footer>
            <ModalV2.Content.Footer.CancelButton onClick={onClickClose} />

            <ModalV2.Content.Footer.SubmitButton
              isLoading={isLoading}
              disabled={hasValidationErrors}
              form={getFormId(step)}
            >
              Next
            </ModalV2.Content.Footer.SubmitButton>
          </ModalV2.Content.Footer>
        </ModalV2.Content>
      );
    case Step.CONFIRMATION:
      return (
        <ModalV2.Content element="form" onSubmit={handleSubmit} id={getFormId(step)}>
          <ModalV2.Content.Body>
            <Confirmation
              cardType={cardType}
              createdCard={createdCard}
              createdCardLimit={createdCardLimit}
              createdCardRelatedAccounts={createdCardRelatedAccounts}
              selectedCapitalAccount={selectedCapitalAccount}
            />
          </ModalV2.Content.Body>
          <ModalV2.Content.Footer>
            <ModalV2.Content.Footer.CancelButton onClick={onClickClose} />
          </ModalV2.Content.Footer>
        </ModalV2.Content>
      );
  }
};

export default CreateCardModalContent;
