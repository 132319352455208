import { captureException } from "@sentry/react";
import { useRefreshBillLineItemsQuery } from "resources/bill-line-items/queries/useBillLineItems";
import { useRefreshBillQuery } from "resources/bills/queries/useBill";
import { notify } from "ui/feedback/Toast";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

const useDeleteBillApprovalMutation = (
  billLineItemId: string,
  billId: string,
  additionalOptions?: MutationAdditionalOptions<void, void>
) => {
  const highbeamApi = useHighbeamApi();
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);

  return useMutationWithDefaults(
    {
      mutationFn: async () => {
        await highbeamApi.billLineItem.delete(billLineItemId);
      },
      onSuccess: async () => {
        await Promise.all([refreshBillQuery(), refreshBillLineItemsQuery()]);
      },
      onError: (error) => {
        captureException(error);
        notify("error", error.message ?? "Something went wrong! Please try again.");
      },
    },
    additionalOptions || {}
  );
};

export default useDeleteBillApprovalMutation;
