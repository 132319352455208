import { DownloadSimple, Eyeglasses, Note } from "@phosphor-icons/react";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { FC, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { LineOfCreditAgreementType } from "reps/LineOfCreditAgreementMetadataRep";
import CapitalAccountBreadcrumbItem from "resources/capital-accounts/components/CapitalAccountBreadcrumbItem";
import { useCapitalAccountSummaryQuery } from "resources/capital-accounts/queries/useCapitalAccountSummary";
import { CAPITAL_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL } from "resources/capital/constants";
import useLineOfCreditAgreement from "resources/line-of-credit-agreement/queries/useLineOfCreditAgreement";
import useAcceptCreditOfferMutation from "resources/line-of-credit/mutations/useAcceptCreditOfferMutation";
import useDownloadLineOfCreditAgreementMutation from "resources/line-of-credit/mutations/useDownloadLineOfCreditAgreementMutation";
import useUpdateLineOfCreditUserActionsMetadataMutation from "resources/line-of-credit/mutations/useUpdateLineOfCreditUserActionsMetadataMutation";
import { useIsSuperusering } from "state/auth/isSuperusering";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import IconTile from "ui/icons/IconTile";
import Button from "ui/inputs/Button";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import Text from "ui/typography/Text";
import useIsAllowedToDownloadCreditOfferAgreement from "utils/permissions/useIsAllowedToDownloadCreditOfferAgreement";

import { NotAllowedToDownloadCreditAgreement } from "../CapitalAccountPage/constants";

import useReviewUpdatedLineAgreementForm from "./forms/useReviewUpdatedLineAgreementForm";
import styles from "./ReviewUpdatedLineAgreementPage.module.scss";

const REVIEW_UPDATED_AGREEMENT_FORM_ID = "review-updated-agreement-form";

type ReviewUpdatedLineAgreementPageContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const ReviewUpdatedLineAgreementPageContent: FC<ReviewUpdatedLineAgreementPageContentProps> = ({
  capitalAccountSummary,
}) => {
  const lineOfCreditAgreement = useLineOfCreditAgreement(capitalAccountSummary.guid);
  const isSecuredOrVariableAprAgreementType =
    lineOfCreditAgreement?.terms.agreementType === LineOfCreditAgreementType.SecuredV1 ||
    lineOfCreditAgreement?.terms.agreementType ===
      LineOfCreditAgreementType.VariableAprRestrictedV1;

  const isAllowedToDownloadCreditOfferAgreement = useIsAllowedToDownloadCreditOfferAgreement();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutateAsync: downloadLineOfCreditAgreementMutation, isPending } =
    useDownloadLineOfCreditAgreementMutation({ onSuccess: () => setAgreementDownloaded(true) });
  const { mutateAsync: updateLineOfCreditUserActionsMetadataMutation } =
    useUpdateLineOfCreditUserActionsMetadataMutation();
  const { mutateAsync: acceptCreditOffer, isPending: isAcceptCreditOfferLoading } =
    useAcceptCreditOfferMutation({ capitalAccountGuid: capitalAccountSummary.guid });
  const isSuperusering = useIsSuperusering();
  const form = useReviewUpdatedLineAgreementForm();
  const readAgreementConfirmedInputValue = form.watch("readAgreementConfirmed");
  const [agreementDownloaded, setAgreementDownloaded] = useState(false);

  const onDownloadAgreement = async () => {
    await downloadLineOfCreditAgreementMutation({
      lineOfCreditGuid: capitalAccountSummary.guid,
      unsignedAgreementGuid: lineOfCreditAgreement?.unsignedAgreementGuid,
      signedAgreementGuid: lineOfCreditAgreement?.signedAgreementGuid,
    });

    if (!lineOfCreditAgreement?.unsignedAgreementOpenedAt && !isSuperusering) {
      await updateLineOfCreditUserActionsMetadataMutation({
        capitalAccountGuid: capitalAccountSummary.guid,
      });
    }
  };

  if (!lineOfCreditAgreement) {
    return <Navigate to="/capital" />;
  }
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await acceptCreditOffer();
    // Determine the return path. If the user came from the LineOfCreditDrawdownButtonArrayButton component,
    // navigate them back to the draw-down page. Otherwise, navigate them to capital account page.
    const returnPath = location.state?.from || `/capital/${capitalAccountSummary.guid}`;
    navigate(returnPath);
  };

  return (
    <FormProvider {...form}>
      <form id={REVIEW_UPDATED_AGREEMENT_FORM_ID} onSubmit={handleSubmit}>
        <DashboardPage>
          <DashboardPage.Section className={styles.container}>
            <DashboardPage.Section.Body className={styles.body}>
              <IconTile icon={<Note />} />
              <DashboardPage.Section.Header className={styles.header}>
                <Text size={20} weight="bold">
                  We’ve updated our line of credit agreement
                </Text>
                <Text size={16} color={colors.grey[500]}>
                  Please take a moment to review and accept our updated agreement to continue using
                  your Highbeam line of credit.
                </Text>
              </DashboardPage.Section.Header>
              <div className={styles.agreementSummary}>
                <div className={styles.agreementSummaryTitle}>
                  <Eyeglasses size={24} color={colors.grey[700]} />
                  <Text size={14} color={colors.grey[600]} weight="medium">
                    Plain language summary
                  </Text>
                </div>
                <Divider margin={0} />
                {isSecuredOrVariableAprAgreementType && (
                  <div className={styles.agreementSummaryContent}>
                    <Text size={14} color={colors.grey[500]}>
                      <ul className={styles.agreementSummaryList}>
                        <li>
                          Your new line limit will be{" "}
                          <MoneyAmount
                            cents={lineOfCreditAgreement.terms.limit}
                            withCents={false}
                          />{" "}
                          {lineOfCreditAgreement.terms.apr > 0 && (
                            <>with a {lineOfCreditAgreement.terms.apr * 100}% APR</>
                          )}
                          .
                        </li>
                        <li>
                          Highbeam must be your primary operating account. That is, sales, payroll,
                          inventory, marketing and cards must flow through your Highbeam bank
                          accounts
                        </li>
                        <li>
                          Please maintain at least 6 months of cash runway, and taking on additional
                          financing without Highbeam approval will affect your line of credit
                        </li>
                        <li>
                          Highbeam will ask for financials from time to time, at least once a
                          quarter. You’ll have two weeks upon request to provide your financials
                        </li>
                        <li>
                          All financial connections must remain active including: other bank
                          accounts, cards, as well as accounting software
                        </li>
                        {lineOfCreditAgreement.terms.agreementType ===
                          LineOfCreditAgreementType.SecuredV1 && (
                          <li>All assets are used as collateral against this line</li>
                        )}
                      </ul>
                    </Text>
                  </div>
                )}
              </div>
              <div className={styles.downloadAgreementButtonContainer}>
                <Button
                  variant="primary"
                  onClick={onDownloadAgreement}
                  isLoading={isPending}
                  disabled={!isAllowedToDownloadCreditOfferAgreement}
                  tooltip={
                    !isAllowedToDownloadCreditOfferAgreement && (
                      <NotAllowedToDownloadCreditAgreement />
                    )
                  }
                >
                  <DownloadSimple size={24} />
                  Download full agreement
                </Button>
                <Text size={14} color={colors.grey[500]}>
                  Required
                </Text>
              </div>

              <Divider margin={0} />

              <Controller
                name="readAgreementConfirmed"
                control={form.control}
                render={({ field }) => (
                  <CheckboxLabel
                    label={CAPITAL_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL}
                    checked={field.value}
                    textWeight="regular"
                    onChange={field.onChange}
                    disabled={!agreementDownloaded}
                  />
                )}
              />

              <Button
                variant="primary"
                type="submit"
                tooltip={
                  !readAgreementConfirmedInputValue && (
                    <Tooltip.Content>Please download and read the agreement first.</Tooltip.Content>
                  )
                }
                form={REVIEW_UPDATED_AGREEMENT_FORM_ID}
                disabled={!readAgreementConfirmedInputValue}
                isLoading={isAcceptCreditOfferLoading}
              >
                I agree
              </Button>
            </DashboardPage.Section.Body>
          </DashboardPage.Section>
        </DashboardPage>
      </form>
    </FormProvider>
  );
};

const ReviewUpdatedLineAgreementPage = () => {
  const capitalAccountGuid = useParams().capitalAccountGuid!; // NB(alex): This is non-nullable but react-router-dom doesn't let us specify this. Might make sense to pass it in as a prop?
  const { data: capitalAccountSummary } = useCapitalAccountSummaryQuery(capitalAccountGuid);

  if (!capitalAccountGuid) {
    return <Navigate to="/capital" />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/capital">Capital</Breadcrumbs.Item>

          <CapitalAccountBreadcrumbItem
            capitalAccountSummary={capitalAccountSummary}
            isCurrentItem={false}
          />

          <Breadcrumbs.CurrentItem>Review updated agreement</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      {capitalAccountSummary ? (
        <ReviewUpdatedLineAgreementPageContent capitalAccountSummary={capitalAccountSummary} />
      ) : (
        <DashboardPage.DotsLoader />
      )}
    </>
  );
};

export default ReviewUpdatedLineAgreementPage;
