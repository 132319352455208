import { Card } from "@highbeam/unit-node-sdk";
import { capitalize as lodashCapitalize } from "lodash-es";
import CardRep from "reps/CardRep";
import { getCardType } from "utils/cards";

import { CardAttributes } from "../types";

import getCardAttributesByCardType from "./getCardAttributesByCardType";

type GetCardLabelByAttributesParams = CardAttributes & {
  capitalize?: boolean;
};

export const getCardLabelByAttributes = ({
  creditOrDebit,
  virtualOrPhysical,
  capitalize,
}: GetCardLabelByAttributesParams) => {
  const label = `${virtualOrPhysical === "virtual" ? "virtual " : ""}${creditOrDebit === "credit" ? "Highbeam Card" : "debit card"}`;
  return capitalize ? lodashCapitalize(label) : label;
};

type GetCardLabelByCardTypeParams = {
  cardType: CardRep.CardType;
  capitalize?: boolean;
};

export const getCardLabelByCardType = ({ cardType, capitalize }: GetCardLabelByCardTypeParams) => {
  const cardAttributes = getCardAttributesByCardType(cardType);
  return getCardLabelByAttributes({ ...cardAttributes, capitalize });
};

type GetCardLabelByCardParams = {
  card: Card;
  capitalize?: boolean;
};

export const getCardLabelByUnitCoCard = ({ card, capitalize }: GetCardLabelByCardParams) => {
  const cardType = getCardType(card);
  return getCardLabelByCardType({ cardType, capitalize });
};
