import { ComponentProps, FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import BankAccountV2 from "ui/data-display/BankAccountV2";

import CapitalAccountIcon from "../CapitalAccountIcon";

type Props = Omit<ComponentProps<typeof BankAccountV2>, "bankIcon" | "accountName"> & {
  capitalAccount: CapitalAccountRep.Complete | CapitalAccountSummaryRep.Complete;
};

const CapitalAccountLabel: FC<Props> = ({ capitalAccount, ...props }) => {
  return (
    <BankAccountV2
      bankIcon={<CapitalAccountIcon capitalAccountType={capitalAccount.type} />}
      accountName={capitalAccount.name}
      {...props}
    />
  );
};

export default CapitalAccountLabel;
