import { useSuspenseQuery } from "@tanstack/react-query";
import IconWithTooltip, { InfoIconColor } from "components/common/IconWithTooltip";
import dayjs from "dayjs";
import { FC, useState } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { useLineOfCreditInterestFeeQueryOptions } from "resources/line-of-credit/queries/useLineOfCreditInterestFee";
import calculateAccruedInterestFromDailyInterestFees from "resources/line-of-credit/utils/calculateAccruedInterestFromDailyInterestFees";
import HighlightItem, {
  HighlightItemSideRuleColor,
  HighlightItemSize,
} from "ui/data-display/HighlightItem";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { roundEpsilon } from "utils/math";

import styles from "./CapitalAccountInterestDueHighlightItem.module.scss";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
  size?: HighlightItemSize;
  sideRuleColor?: HighlightItemSideRuleColor;
  infoIconColor?: InfoIconColor;
};

const CapitalAccountInterestDueHighlightItem: FC<Props> = ({
  capitalAccountSummary,
  size,
  sideRuleColor,
  infoIconColor,
}) => {
  const [now] = useState(dayjs());

  const isTodayBeforeTuesdayThisWeek = now.day() < 2;
  const accruedInterestSince = isTodayBeforeTuesdayThisWeek ? now.weekday(-5) : now.weekday(2);
  const accruedInterestDueDate = isTodayBeforeTuesdayThisWeek
    ? now.weekday(2)
    : now.add(1, "week").weekday(2);

  const { data: accruedInterestAmountInCents } = useSuspenseQuery({
    ...useLineOfCreditInterestFeeQueryOptions({
      lineOfCreditGuid: capitalAccountSummary.guid,
      since: accruedInterestSince,
      untilInclusive: now.endOf("day"),
    }),
    select: (dailyInterestFees) => calculateAccruedInterestFromDailyInterestFees(dailyInterestFees),
  });

  const isCapitalPromotionalRateEnabled = useFeatureFlag("CAPITAL_PROMOTIONAL_RATES");

  return (
    <HighlightItem
      size={size}
      sideRuleColor={sideRuleColor}
      label={
        <>
          Interest due {accruedInterestDueDate.format("MMM D")}
          <IconWithTooltip
            color={infoIconColor}
            content={
              <Text size={14}>
                Interest accrues daily, and the total amount will be paid automatically from your
                primary account every Tuesday.
                {isCapitalPromotionalRateEnabled && (
                  <> Promotional rates are applied on the first Tuesday of each month. </>
                )}
              </Text>
            }
          />
        </>
      }
      value={
        <MoneyAmount
          size={size === "sm" ? 16 : 20}
          weight="medium"
          centsTextSize={16}
          centsTextWeight={size === "sm" ? "medium" : "regular"}
          cents={accruedInterestAmountInCents}
        />
      }
      subtext={
        capitalAccountSummary.details.apr > 0 && (
          <>
            {isCapitalPromotionalRateEnabled ? (
              <div className={styles.promotionalAprItem}>
                <Text>10.00% APR</Text>
              </div>
            ) : (
              <Text>{roundEpsilon(capitalAccountSummary.details.apr * 100, 2)}% APR</Text>
            )}
          </>
        )
      }
    />
  );
};

export default CapitalAccountInterestDueHighlightItem;
