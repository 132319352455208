import { Archive, Cards, Plus, PlusCircle } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import HighbeamCard from "components/HighbeamCard";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import useMfa from "modules/mfa/useMfa";
import { Suspense, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import CreateCardModalV2 from "resources/cards/dialogs/CreateCardModalV2";
import useGetMaximumNumberOfCards from "resources/cards/hooks/useGetMaximumNumberOfCards";
import useCardsQueryOptions from "resources/cards/queries/useCardsQueryOptions";
import { CardVirtualOrPhysical } from "resources/cards/types";
import getHasChargeCards from "resources/cards/utils/getHasChargeCards";
import {
  checkIsPhysicalCreditCard,
  checkIsPhysicalDebitCard,
  checkIsVirtualChargeCard,
  checkIsVirtualDebitCard,
} from "resources/cards/utils/typeguards";
import Divider from "ui/data-display/Divider";
import Shimmer from "ui/feedback/Shimmer";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Text from "ui/typography/Text";
import { cardFilterByTab } from "utils/cards";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useHasPermission from "utils/permissions/useHasPermission";
import useIsAllowedToCreateCards from "utils/permissions/useIsAllowedToCreateCards";
import { pluralize } from "utils/string";

import CardLimit from "./CardBanners/CardLimits";
import CardPlaceholder, { EmptyCardPlaceholder } from "./CardPlaceholder";
import { NotAllowedToCreateCardsTooltipContent } from "./CardPlaceholder/constants";
import styles from "./CardsPage.module.scss";
import CardTabs from "./CardTabs";
import InactivePhysicalCard from "./components/InactivePhysicalCard";
import { ViewArchivedCards } from "./constants";
import CreateCardModal from "./CreateCardModal";
import { useActiveTabSearchParam } from "./hooks/useCardsOverviewSearchParams";
import MigrateToThreadDebitCardBanner from "./MigrateToThreadDebitCardBanner";
import useShouldShowMigrateToThreadDebitCardBanner from "./MigrateToThreadDebitCardBanner/hooks/useShouldShowMigrateToThreadDebitCardBanner";

type CreateCardModalState = {
  defaultCardVirtualOrPhysical: CardVirtualOrPhysical; // Prefixed with "default" because `CreateCardModalV2` allows changing the type.
  defaultCardName?: string;
};

type LocationState = {
  openVirtualCard: boolean;
};

const CardsPageContent = () => {
  const { mfa } = useMfa();
  const locationState = useLocation().state as LocationState | undefined;

  const [activeTab] = useActiveTabSearchParam();
  const isFilteringCards = activeTab !== "All";

  const [createCardModalState, setCreateCardModalState] = useState<CreateCardModalState | null>(
    null
  );

  const isCreateCardModalV2Enabled = useFeatureFlag("CARD_CREATION_MODAL_V2");

  const { data: allCards = [], isLoading, refetch } = useQuery(useCardsQueryOptions());

  const cards = allCards.filter((card) => cardFilterByTab(activeTab, card));

  const isAllowedToCreateCards = useIsAllowedToCreateCards();

  const openVirtualCardModal = () => {
    if (!canCreateVirtualCard) {
      notify(
        "warning",
        `Only ${maximumNumberOfVirtualCards} virtual cards can be active at a time. Contact support to archive an active card to create a new one.`
      );
      return;
    }

    if (!isAllowedToCreateCards) {
      return;
    }

    mfa().then(() => {
      setCreateCardModalState({
        defaultCardVirtualOrPhysical: "virtual",
      });
    });
  };

  useEffect(() => {
    if (locationState?.openVirtualCard) {
      openVirtualCardModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  const handleCreateCardModalClose = (reload: boolean) => {
    setCreateCardModalState(null);
    if (reload) refetch();
  };

  const virtualCards = cards.filter((card) => {
    return checkIsVirtualChargeCard(card) || checkIsVirtualDebitCard(card);
  });

  const physicalCards = cards.filter((card) => {
    return checkIsPhysicalDebitCard(card) || checkIsPhysicalCreditCard(card);
  });

  const { maximumNumberOfVirtualCards, maximumNumberOfPhysicalCards } =
    useGetMaximumNumberOfCards();

  const canCreateVirtualCard = virtualCards.length < maximumNumberOfVirtualCards;
  const canCreatePhysicalCard = physicalCards.length < maximumNumberOfPhysicalCards;

  const canReadAnyOrCreateCards = useHasPermission(["card:readAny", "card:create"]);

  const showCreateVirtualCardPlaceholder =
    canReadAnyOrCreateCards &&
    canCreateVirtualCard &&
    virtualCards.length < Math.min(maximumNumberOfVirtualCards, 2);

  const showCreatePhysicalCardPlaceholder =
    canReadAnyOrCreateCards && canCreatePhysicalCard && physicalCards.length < 1;

  const shouldShowMigrateToThreadDebitCardBanner = useShouldShowMigrateToThreadDebitCardBanner();

  return (
    <>
      {isCreateCardModalV2Enabled && createCardModalState && (
        <CreateCardModalV2
          defaultCardVirtualOrPhysical={createCardModalState.defaultCardVirtualOrPhysical}
          defaultCardCreditOrDebit="credit"
          defaultCardName={createCardModalState.defaultCardName}
          onClose={() => setCreateCardModalState(null)}
        />
      )}

      {!isCreateCardModalV2Enabled && createCardModalState && (
        <Suspense fallback={null}>
          <CreateCardModal
            handleClose={handleCreateCardModalClose}
            defaultCardName={createCardModalState.defaultCardName ?? ""}
            createCardModalType={createCardModalState.defaultCardVirtualOrPhysical}
          />
        </Suspense>
      )}

      <DashboardPage.Header
        actions={
          <>
            {isCreateCardModalV2Enabled && (
              <Button
                onClick={() =>
                  setCreateCardModalState({
                    defaultCardVirtualOrPhysical: "virtual",
                  })
                }
                variant="secondary"
              >
                <PlusCircle size={20} weight="light" />
                Create card
              </Button>
            )}
            <Link to="/cards/archived">
              <Button isSquare tooltip={<ViewArchivedCards />} variant="tertiary">
                <Archive size={20} />
              </Button>
            </Link>
          </>
        }
      >
        <DashboardPage.Header.IconTile icon={<Cards />} />
        <DashboardPage.Header.Title>Cards</DashboardPage.Header.Title>
      </DashboardPage.Header>

      {shouldShowMigrateToThreadDebitCardBanner && <MigrateToThreadDebitCardBanner />}

      <DashboardPage.Section className={styles.container}>
        {getHasChargeCards(allCards) && <CardTabs />}

        <section>
          <CardLimit cards={cards} />
        </section>

        <section>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <Text size={16} weight="bold">
                Virtual cards
              </Text>
              {isLoading ? (
                <Shimmer />
              ) : (
                <Text size={14}>{pluralize(virtualCards.length, "card")}</Text>
              )}
            </div>

            {!isCreateCardModalV2Enabled && canReadAnyOrCreateCards && (
              <Button
                variant="tertiary"
                onClick={openVirtualCardModal}
                disabled={!isAllowedToCreateCards}
                tooltip={!isAllowedToCreateCards && <NotAllowedToCreateCardsTooltipContent />}
              >
                <Plus size={16} />
                Create virtual card
              </Button>
            )}
          </div>

          {isLoading && (
            <div className={classNames(styles.cards, styles.cardsShimmer)}>
              <Shimmer
                additionalShimmerWrapperClassName={styles.card}
                additionalShimmerClassName={[styles.card]}
              />
              <Shimmer
                additionalShimmerWrapperClassName={styles.card}
                additionalShimmerClassName={[styles.card]}
              />
            </div>
          )}

          {!isLoading && (
            <div className={styles.cards}>
              {virtualCards.map((card) => (
                <Link key={card.id} to={`/cards/${card.id}`}>
                  <HighbeamCard card={card} areDetailsAvailable containerClickable />
                </Link>
              ))}

              {!canReadAnyOrCreateCards && virtualCards.length === 0 && (
                <EmptyCardPlaceholder
                  heading="No virtual cards found"
                  subheading="To create a card please contact an admin."
                />
              )}

              {showCreateVirtualCardPlaceholder && (
                <CardPlaceholder
                  ctaIcon={Plus}
                  primaryText={isFilteringCards ? "No cards found" : "Create a virtual card"}
                  secondaryText={
                    isFilteringCards ? undefined : "Create virtual cards for all your online needs."
                  }
                  ctaText="Create virtual card"
                  ctaOnClick={() =>
                    mfa().then(() => {
                      setCreateCardModalState({
                        defaultCardVirtualOrPhysical: "virtual",
                      });
                    })
                  }
                  onPromptClick={(prompt) => {
                    if (!isAllowedToCreateCards) {
                      return;
                    }
                    setCreateCardModalState({
                      defaultCardVirtualOrPhysical: "virtual",
                      defaultCardName: prompt,
                    });
                  }}
                  prompts={["Marketing card", "Software card", "Vendor card", "Meal card"]}
                  className={styles.card}
                  disablePrimaryAction={!isAllowedToCreateCards}
                  disabledPrimaryText={<NotAllowedToCreateCardsTooltipContent />}
                />
              )}
            </div>
          )}
        </section>

        <Divider />

        <section>
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <Text size={16} weight="bold">
                Physical cards
              </Text>
              {isLoading ? (
                <Shimmer />
              ) : (
                <Text size={14}>{pluralize(physicalCards.length, "card")}</Text>
              )}
            </div>

            {!isCreateCardModalV2Enabled && canReadAnyOrCreateCards && (
              <Button
                variant="tertiary"
                onClick={() => {
                  if (!canCreatePhysicalCard) {
                    notify(
                      "warning",
                      `Only 1 physical card per user can be active at a time. Contact support to archive the active card to create a new one.`
                    );
                    return;
                  }
                  mfa().then(() =>
                    setCreateCardModalState({
                      defaultCardVirtualOrPhysical: "physical",
                    })
                  );
                }}
                disabled={!isAllowedToCreateCards}
                tooltip={!isAllowedToCreateCards && <NotAllowedToCreateCardsTooltipContent />}
              >
                <Plus size={16} />
                Create physical card
              </Button>
            )}
          </div>

          {isLoading && (
            <div className={classNames(styles.cards, styles.cardsShimmer)}>
              <Shimmer
                additionalShimmerWrapperClassName={styles.card}
                additionalShimmerClassName={[styles.card]}
              />
              <Shimmer
                additionalShimmerWrapperClassName={styles.card}
                additionalShimmerClassName={[styles.card]}
              />
            </div>
          )}

          {!isLoading && (
            <div className={styles.cards}>
              {physicalCards.map((card) =>
                card.attributes.status === "Inactive" ? (
                  <InactivePhysicalCard key={card.id} card={card} />
                ) : (
                  <Link key={card.id} to={`/cards/${card.id}`}>
                    <HighbeamCard card={card} areDetailsAvailable containerClickable />
                  </Link>
                )
              )}

              {!canReadAnyOrCreateCards && physicalCards.length === 0 && (
                <EmptyCardPlaceholder
                  heading="No physical cards found"
                  subheading="To create a card please contact an admin."
                />
              )}

              {showCreatePhysicalCardPlaceholder && (
                <CardPlaceholder
                  ctaIcon={Plus}
                  primaryText={isFilteringCards ? "No cards found" : "Create a physical card"}
                  secondaryText={isFilteringCards ? undefined : "We will ship you a physical card."}
                  ctaText="Create physical card"
                  ctaOnClick={() =>
                    mfa().then(() => {
                      setCreateCardModalState({
                        defaultCardVirtualOrPhysical: "physical",
                      });
                    })
                  }
                  className={styles.card}
                  disablePrimaryAction={!isAllowedToCreateCards}
                  disabledPrimaryText={<NotAllowedToCreateCardsTooltipContent />}
                />
              )}
            </div>
          )}
        </section>
      </DashboardPage.Section>
    </>
  );
};

const CardsPage = () => {
  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.CurrentItem>Cards</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <CardsPageContent />
      </DashboardPage>
    </>
  );
};

export default CardsPage;
