import React, { type PropsWithChildren } from "react";
import useBusinessMember from "resources/business-members/queries/useBusinessMember";
import useBusiness from "resources/business/queries/useBusiness";
import colors from "styles/colors";
import Avatar from "ui/data-display/Avatar";
import Text from "ui/typography/Text";
import getBusinessSafeDisplayName from "utils/business/getBusinessSafeDisplayName";

import styles from "./DashboardUserMenuButton.module.scss";

type Props = {
  onOpenUserMenu: () => void;
};

const DashboardUserMenuButton: React.FC<Props> = ({ onOpenUserMenu: handleOpenUserMenu }) => {
  const business = useBusiness();
  const businessMember = useBusinessMember();

  const businessDisplayName = getBusinessSafeDisplayName(business);
  const businessMemberDisplayName = businessMember?.displayName;
  const businessMemberInitials = businessMember?.initials ?? "";

  return (
    <div role="button" tabIndex={0} className={styles.button} onClick={handleOpenUserMenu}>
      <div className={styles.text}>
        {businessMemberDisplayName ? (
          <BusinessMemberDisplayName>{businessMemberDisplayName}</BusinessMemberDisplayName>
        ) : null}
        <BusinessDisplayName>{businessDisplayName}</BusinessDisplayName>
      </div>
      <Avatar color="purple-light" size={32} initials={businessMemberInitials} />
    </div>
  );
};

export default DashboardUserMenuButton;

const BusinessMemberDisplayName: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size={12} weight="medium">
      {children}
    </Text>
  );
};

const BusinessDisplayName: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text size={12} weight="regular" color={colors.grey[600]}>
      {children}
    </Text>
  );
};
