import {
  Card,
  BusinessCreditCard,
  BusinessDebitCard,
  BusinessVirtualCreditCard,
  BusinessVirtualDebitCard,
  IndividualDebitCard,
  IndividualVirtualDebitCard,
} from "@highbeam/unit-node-sdk";
import CardRep from "reps/CardRep";

type BusinessCard =
  | BusinessDebitCard
  | BusinessVirtualDebitCard
  | BusinessVirtualCreditCard
  | BusinessCreditCard;

export const checkIsBusinessCard = (card: Card): card is BusinessCard =>
  card.type === "businessDebitCard" ||
  card.type === "businessVirtualDebitCard" ||
  card.type === "businessVirtualCreditCard" ||
  card.type === "businessCreditCard";

export const checkIsVirtualDebitCard = (
  card: Card
): card is IndividualDebitCard | BusinessVirtualDebitCard => {
  return card.type === "individualDebitCard" || card.type === "businessVirtualDebitCard";
};

export const checkIsVirtualChargeCard = (card: Card): card is BusinessVirtualCreditCard => {
  return card.type === "businessVirtualCreditCard";
};

export const checkIsPhysicalDebitCard = (
  card: Card
): card is IndividualDebitCard | BusinessDebitCard => {
  return card.type === "individualDebitCard" || card.type === "businessDebitCard";
};

export const checkIsPhysicalCreditCard = (card: Card): card is BusinessCreditCard => {
  return card.type === "businessCreditCard";
};

export type DebitCard =
  | IndividualDebitCard
  | BusinessDebitCard
  | IndividualVirtualDebitCard
  | BusinessVirtualDebitCard;

export const checkIsDebitCard = (card: Card): card is DebitCard => {
  return (
    card.type === "individualDebitCard" ||
    card.type === "businessDebitCard" ||
    card.type === "individualVirtualDebitCard" ||
    card.type === "businessVirtualDebitCard"
  );
};

export type CreditCard = BusinessVirtualCreditCard | BusinessCreditCard;

export const checkIsCreditCard = (card: Card): card is CreditCard => {
  return card.type === "businessVirtualCreditCard" || card.type === "businessCreditCard";
};

//
// Highbeam `CardRep.CardType` typeguards
//

export const checkIsDebitCardType = (
  cardType: CardRep.CardType
): cardType is "PHYSICAL" | "VIRTUAL" => {
  return cardType === "PHYSICAL" || cardType === "VIRTUAL";
};

export const checkIsCreditCardType = (
  cardType: CardRep.CardType
): cardType is "PHYSICAL_CREDIT" | "VIRTUAL_CREDIT" => {
  return cardType === "PHYSICAL_CREDIT" || cardType === "VIRTUAL_CREDIT";
};

export const checkIsVirtualCardType = (
  cardType: CardRep.CardType
): cardType is "VIRTUAL" | "VIRTUAL_CREDIT" => {
  return cardType === "VIRTUAL" || cardType === "VIRTUAL_CREDIT";
};

export const checkIsPhysicalCardType = (
  cardType: CardRep.CardType
): cardType is "PHYSICAL" | "PHYSICAL_CREDIT" => {
  return cardType === "PHYSICAL" || cardType === "PHYSICAL_CREDIT";
};
