import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useBankAccounts from "./useBankAccounts";

const useOpenBankAccount = <TRequired extends boolean>(
  bankAccountGuid: string,
  params?: RequiredParam<TRequired>
) => {
  const openBankAccount = useBankAccounts({
    status: "open",
    select: (bankAccounts) => {
      return bankAccounts.find((bankAccount) => bankAccount.guid === bankAccountGuid);
    },
  });

  if (params?.required && !openBankAccount) {
    throw new RequiredButNotFoundError();
  }

  return openBankAccount as DataRequired<typeof openBankAccount, TRequired>;
};

export default useOpenBankAccount;
