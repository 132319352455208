import {
  ArrowFatLinesDown,
  ArrowsClockwise,
  Cards,
  ChartLine,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import isCapitalAccountSummaryWithChargeCard, {
  CapitalAccountSummaryWithChargeCard,
} from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useChargeCardOffer from "resources/charge-card-offer/queries/useChargeCardOffer";
import getChargeCardOfferRepaymentUpToDays from "resources/charge-card-offer/utils/getChargeCardOfferRepaymentUpToDays";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { pluralize } from "utils/string";

import styles from "./HowItWorksBulletPoints.module.scss";

type ChargeCardOfferDetailsProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const HowItWorksChargeCardBulletPoints: FC<ChargeCardOfferDetailsProps> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  return (
    <div className={styles.howItWorksItemContainer}>
      <div className={styles.howItWorksItem}>
        <div className={styles.howItWorksItemIcon}>
          <ChartLine size={20} color={colors.purple[500]} />
        </div>
        <Text size={14} color={colors.grey[900]}>
          Your limit is based on your current and historical debt, assets, and sales.
        </Text>
      </div>
      <div className={styles.howItWorksItem}>
        <div className={styles.howItWorksItemIcon}>
          <Cards size={20} color={colors.purple[500]} />
        </div>
        <Text size={14} color={colors.grey[900]}>
          After activating this card offer, create Highbeam Cards and connect them to your card
          limit.
        </Text>
      </div>
      <div className={styles.howItWorksItem}>
        <div className={styles.howItWorksItemIcon}>
          <CurrencyCircleDollar size={20} color={colors.purple[500]} />
        </div>
        {chargeCardOffer.maxCashback > 0 ? (
          <Text size={14} color={colors.grey[900]}>
            Earn up to <strong>{chargeCardOffer.maxCashback}% cash back</strong> across all approved
            vendors
          </Text>
        ) : (
          <Text size={14} color={colors.grey[900]}>
            Get up to {pluralize(getChargeCardOfferRepaymentUpToDays(chargeCardOffer), "day")}{" "}
            interest-free days to manage your cash flow.
          </Text>
        )}
      </div>
    </div>
  );
};

const HowItWorksCashAccessOnlyBulletPoints = () => (
  <div className={styles.howItWorksItemContainer}>
    <div className={styles.howItWorksItem}>
      <div className={styles.howItWorksItemIcon}>
        <ChartLine size={20} color={colors.purple[500]} />
      </div>
      <Text size={14} color={colors.grey[900]}>
        Your limit is based on your current and historical debt, assets, and sales
      </Text>
    </div>
    <div className={styles.howItWorksItem}>
      <div className={styles.howItWorksItemIcon}>
        <ArrowFatLinesDown size={20} color={colors.purple[500]} />
      </div>
      <Text size={14} color={colors.grey[900]}>
        Draw down funds instantly and repay your line at any time
      </Text>
    </div>
    <div className={styles.howItWorksItem}>
      <div className={styles.howItWorksItemIcon}>
        <ArrowsClockwise size={20} color={colors.purple[500]} />
      </div>
      <Text size={14} color={colors.grey[900]}>
        After every drawdown you’ll start repaying it with auto-pay
      </Text>
    </div>
  </div>
);

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const HowItWorksBulletPoints: FC<Props> = ({ capitalAccountSummary }) => {
  return (
    <>
      {isCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
        <HowItWorksChargeCardBulletPoints
          capitalAccountSummaryWithChargeCard={capitalAccountSummary}
        />
      ) : (
        <HowItWorksCashAccessOnlyBulletPoints />
      )}
    </>
  );
};

export default HowItWorksBulletPoints;
