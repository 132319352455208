import { capitalize } from "lodash-es";
import BusinessMemberDropdownLabel from "pages/cards/CardsPage/CreateCardModal/BusinessMemberDropdownLabel";
import { FC } from "react";
import { Controller } from "react-hook-form";
import BankAccountsDropdown from "resources/bank-accounts/components/BankAccountsDropdown";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import { CardCreditOrDebit } from "resources/cards/types";
import useUsersTableData from "resources/users-table-data/queries/useUsersTableData";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import CurrencyInputV3 from "ui/inputs/CurrencyInputV3";
import DropdownV2, { convertStringValueToDropdownOption } from "ui/inputs/DropdownV2";
import Helper from "ui/inputs/Helper";
import SwitchWithLabel from "ui/inputs/SwitchWithLabel";
import TextInputV2 from "ui/inputs/TextInputV2";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";
import { FormSubmitHandlerProps } from "utils/react-hook-form/GetUseFormSubmitHandler";

import styles from "./CreateCardModalCardDetailsForm.module.scss";
import useCreateCardModalCardDetailsForm, {
  SPEND_LIMIT_INTERVAL_OPTIONS,
} from "./useCreateCardModalCardDetailsForm";

type Props = FormSubmitHandlerProps<typeof useCreateCardModalCardDetailsForm> & {
  onBack: () => void;
  nextButtonText: string;
  isSubmitLoading: boolean;
  creditOrDebit: CardCreditOrDebit;
  defaultCardName?: string;
};

const CreateCardModalCardDetailsForm: FC<Props> = ({
  onValid,
  onInvalid,
  onBack,
  nextButtonText,
  isSubmitLoading,
  creditOrDebit,
  defaultCardName,
}) => {
  // TODO(alex): Will trigger suspense & set defaults accordingly upstack. This will allow us to remove the `useEffect` in `useCreateCardModalCardDetailsForm`.
  const users = useUsersTableData();
  const openBankAccounts = useBankAccounts({ status: "open" });

  const form = useCreateCardModalCardDetailsForm({
    defaultValues: {
      cardholder: users[0],
      cardName: defaultCardName ?? `${users[0].fullName}’s card`,
      spendLimit: {
        // TODO(alex): Need to figure out how to set default spend limits. Will do upstack.
        enabled: false,
      },
      associatedBankAccount: openBankAccounts[0],
    },
  });

  const spendLimitEnabled = form.watch("spendLimit.enabled");

  return (
    <ModalV4.Form onSubmit={form.handleSubmit(onValid, onInvalid)}>
      <ModalV4.Body className={styles.body}>
        <div>
          <Text color={colors.grey[900]} size={16} weight="bold" className={styles.sectionHeading}>
            Cardholder
          </Text>

          <Controller
            control={form.control}
            name="cardholder"
            render={({ field }) => (
              <DropdownV2
                {...field}
                options={users}
                isOptionDisabled={(option) => option.isInvited || !option.isOnboarded}
                renderOption={(optionProps) => {
                  const user = optionProps.data;
                  return (
                    <DropdownV2.Option {...optionProps}>
                      <BusinessMemberDropdownLabel
                        fullName={
                          <>
                            {user.fullName}
                            {user.isInvited && <strong> (has not accepted invite yet)</strong>}
                          </>
                        }
                        userRoleName={user.userRoleName}
                        isOnboarded={!user.isInvited && user.isOnboarded}
                      />
                    </DropdownV2.Option>
                  );
                }}
                components={{
                  SingleValue: (singleValueProps) => {
                    const user = singleValueProps.data;

                    return (
                      <DropdownV2.SingleValue {...singleValueProps}>
                        <BusinessMemberDropdownLabel
                          fullName={
                            <>
                              {user.fullName}
                              {user.isInvited && <strong> (has not accepted invite yet)</strong>}
                            </>
                          }
                          userRoleName={user.userRoleName}
                          isOnboarded={!user.isInvited && user.isOnboarded}
                        />
                      </DropdownV2.SingleValue>
                    );
                  },
                }}
              />
            )}
          />
        </div>

        <div>
          <Text color={colors.grey[900]} size={16} weight="bold" className={styles.sectionHeading}>
            Card details
          </Text>

          <Controller
            control={form.control}
            name="cardName"
            render={({ field, fieldState: { error } }) => {
              return (
                <div>
                  <TextInputV2 label="Card name" {...field} />
                  {error && <Helper icon={<Helper.Icon variant="error" />}>{error.message}</Helper>}
                </div>
              );
            }}
          />

          {creditOrDebit === "debit" && (
            <Controller
              control={form.control}
              name="associatedBankAccount"
              render={({ field }) => {
                return (
                  <div className={styles.associatedBankAccountContainer}>
                    <BankAccountsDropdown
                      options={openBankAccounts}
                      label="Associated account"
                      {...field}
                    />
                    <Helper>The funds will be debited from the associated account.</Helper>
                  </div>
                );
              }}
            />
          )}

          <div className={styles.spendLimitContainer}>
            <Controller
              control={form.control}
              name="spendLimit.enabled"
              render={({ field }) => {
                return <SwitchWithLabel label="Spend limit" {...field} />;
              }}
            />

            {spendLimitEnabled && (
              <div className={styles.spendLimitInputsContainer}>
                <Controller
                  control={form.control}
                  name="spendLimit.interval"
                  defaultValue="monthly"
                  render={({ field }) => {
                    return (
                      <DropdownV2
                        options={SPEND_LIMIT_INTERVAL_OPTIONS.map(
                          convertStringValueToDropdownOption
                        )}
                        {...field}
                        className={styles.intervalDropdown}
                        getOptionLabel={(option) => capitalize(option.label)}
                        value={convertStringValueToDropdownOption(field.value)}
                        onChange={(value) => {
                          if (value) field.onChange(value.value);
                        }}
                      />
                    );
                  }}
                />
                <Controller
                  control={form.control}
                  name="spendLimit.amount"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <div className={styles.amountInputContainer}>
                        <CurrencyInputV3 label="Daily spend budget" startAdornment="$" {...field} />
                        {error && (
                          <Helper icon={<Helper.Icon variant="error" />}>{error.message}</Helper>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.Footer.SubmitButton isLoading={isSubmitLoading}>
          {nextButtonText}
        </ModalV4.Footer.SubmitButton>
        <Button onClick={onBack} variant="ghost">
          Back
        </Button>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

export default CreateCardModalCardDetailsForm;
