import { useSuspenseQuery } from "@tanstack/react-query";
import usePlaidAccountsQueryOptions from "resources/plaid-connections/queries/usePlaidAccountsQueryOptions";

const useHasInactivePlaidDepositoryAccounts = (): boolean => {
  const { data: plaidDepositoryAccounts } = useSuspenseQuery(
    usePlaidAccountsQueryOptions("DEPOSITORY")
  );
  return (
    plaidDepositoryAccounts.filter((account) => !account.isActive || !account.isConnectionActive)
      .length !== 0
  );
};

export default useHasInactivePlaidDepositoryAccounts;
