import { useSuspenseQuery } from "@tanstack/react-query";
import DashboardPage from "components/layouts/DashboardPage";
import PlaidCreditCardsTable from "pages/accounts/AccountsOverviewPage/components/PlaidCreditCardsTable";
import { useEffect } from "react";
import usePlaidAccountsQueryOptions from "resources/plaid-connections/queries/usePlaidAccountsQueryOptions";
import { notify } from "ui/feedback/Toast";

const notifyPlaidCreditCardsError = () => {
  notify("error", "Something went wrong while loading your connected credit cards.");
};

const PlaidCreditCardsSection = () => {
  const { data: plaidCreditCards, error: plaidCreditCardsError } = useSuspenseQuery(
    usePlaidAccountsQueryOptions("CREDIT")
  );

  // Show an error toast if one of the queries fails.
  useEffect(() => {
    if (plaidCreditCardsError) {
      notifyPlaidCreditCardsError();
    }
  }, [plaidCreditCardsError]);

  if (!plaidCreditCards || plaidCreditCards.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Connected cards</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <PlaidCreditCardsTable accounts={plaidCreditCards} />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default PlaidCreditCardsSection;
