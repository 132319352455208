import AddressAutocompleteDropdown from "modules/address-autocomplete/AddressAutocompleteDropdown";
import { FC } from "react";
import { Controller } from "react-hook-form";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import DropdownV2 from "ui/inputs/DropdownV2";
import FieldsetV2 from "ui/inputs/FieldsetV2";
import TextInputV2 from "ui/inputs/TextInputV2";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";
import { stateOptions, states } from "utils/address";
import { FormSubmitHandlerProps } from "utils/react-hook-form/GetUseFormSubmitHandler";

import styles from "./CreateCardModalShippingAddressForm.module.scss";
import useCreateCardModalShippingAddressForm from "./useCreateCardModalShippingAddressForm";

type Props = FormSubmitHandlerProps<typeof useCreateCardModalShippingAddressForm> & {
  onBack: () => void;
  isSubmitLoading: boolean;
};

const CreateCardModalShippingAddressForm: FC<Props> = ({
  onValid,
  onInvalid,
  onBack,
  isSubmitLoading,
}) => {
  const form = useCreateCardModalShippingAddressForm({
    defaultValues: {
      // TODO(alex): Will do upstack.
      address: {
        autocompleteOption: null,
        street1: "",
        street2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "US",
      },
    },
  });

  return (
    <ModalV4.Form onSubmit={form.handleSubmit(onValid, onInvalid)}>
      <ModalV4.Body className={styles.body}>
        <div className={styles.sectionHeader}>
          <Text color={colors.grey[900]} size={16} weight="bold">
            Shipping address
          </Text>
          <Text color={colors.grey[700]} size={14} weight="regular">
            We will send this card to the following address.
          </Text>
        </div>

        <FieldsetV2>
          <Controller
            control={form.control}
            name="address.autocompleteOption"
            render={({ field }) => {
              return (
                <AddressAutocompleteDropdown
                  componentRestrictionsCountry="US"
                  onSelectAddress={(address) => {
                    form.reset({
                      ...form.getValues(),
                      address,
                    });
                  }}
                  {...field}
                />
              );
            }}
          />

          <Controller
            control={form.control}
            name="address.street2"
            render={({ field }) => {
              return <TextInputV2 label="Address line 2" {...field} />;
            }}
          />

          <FieldsetV2.Row columns={3} className={styles.fieldsetRow}>
            <Controller
              control={form.control}
              name="address.city"
              render={({ field }) => {
                return <TextInputV2 label="City" {...field} />;
              }}
            />

            <Controller
              control={form.control}
              name="address.state"
              render={({ field: { value, onChange, ...field } }) => {
                return (
                  <DropdownV2
                    label="State"
                    options={stateOptions}
                    value={value ? { label: states[value], value: value } : null}
                    getOptionLabel={(option) => option.label}
                    onChange={(option) => {
                      onChange(option?.value ?? null);
                    }}
                    {...field}
                  />
                );
              }}
            />

            <Controller
              control={form.control}
              name="address.postalCode"
              render={({ field }) => {
                return <TextInputV2 label="ZIP" {...field} />;
              }}
            />
          </FieldsetV2.Row>
        </FieldsetV2>
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.Footer.SubmitButton isLoading={isSubmitLoading}>
          Create card
        </ModalV4.Footer.SubmitButton>
        <Button onClick={onBack} variant="ghost">
          Back
        </Button>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

export default CreateCardModalShippingAddressForm;
