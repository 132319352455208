import { useSuspenseQuery } from "@tanstack/react-query";
import DashboardPage from "components/layouts/DashboardPage";
import { useEffect } from "react";
import usePlaidAccountsQueryOptions from "resources/plaid-connections/queries/usePlaidAccountsQueryOptions";
import { notify } from "ui/feedback/Toast";

import PlaidBankAccountsTable from "../../components/PlaidBankAccountsTable";

const notifyPlaidBankAccountsError = () => {
  notify("error", "Something went wrong while loading your connected accounts.");
};

const PlaidBankAccountsSection = () => {
  const { data: plaidBankAccounts, error: plaidBankAccountsError } = useSuspenseQuery(
    usePlaidAccountsQueryOptions("DEPOSITORY")
  );

  // Show an error toast if one of the queries fails.
  useEffect(() => {
    if (plaidBankAccountsError) {
      notifyPlaidBankAccountsError();
    }
  }, [plaidBankAccountsError]);

  if (!plaidBankAccounts || plaidBankAccounts.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>
          Connected bank accounts
        </DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <DashboardPage.Section.Body>
        <PlaidBankAccountsTable accounts={plaidBankAccounts} />
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default PlaidBankAccountsSection;
