import { CreditCard } from "@phosphor-icons/react";
import dayjs from "dayjs";
import ChangeAutoPayAccountButton from "pages/capital/CapitalAccountPage/components/ChangeAutoPayAccountButton";
import { NotAllowedToRepayCapital } from "pages/capital/CapitalAccountPage/constants";
import { FC } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { Link } from "react-router-dom";
import useOpenBankAccount from "resources/bank-accounts/queries/useOpenBankAccount";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import useChargeCardAccount from "resources/charge-cards/queries/useChargeCardAccount";
import useChargeCardRepaymentInfo from "resources/charge-cards/queries/useChargeCardRepaymentInfo";
import HighlightCard from "ui/data-display/HighlightCard";
import useIsAllowedToRepayCapital from "utils/permissions/useIsAllowedtoRepayCapital";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import ChargeCardAutoPayHighlightItem from "./ChargeCardAutoPayHighlightItem";
import ChargeCardCurrentBalanceHighlightItem from "./ChargeCardCurrentBalanceHighlightItem";
import ChargeCardHighlightCardRepayChargeCardsButton from "./ChargeCardHighlightCardRepayChargeCardsButton";
import ChargeCardRepaymentHighlightItem from "./ChargeCardRepaymentHighlightItem";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardHighlightCard: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const chargeCardAccount = useChargeCardAccount(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });
  const isAllowedToRepayCapital = useIsAllowedToRepayCapital();
  const chargeCardRepaymentInfo = useChargeCardRepaymentInfo(chargeCardAccount.guid);
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccount(
    chargeCardAccount.repaymentBankAccountGuid ?? primaryAccount.guid,
    { required: true }
  );

  // TODO(alex): figure out a cleaner way to handle null & loading states
  if (!chargeCardRepaymentInfo) {
    return null;
  }

  return (
    <HighlightCard
      header={
        <HighlightCard.Header icon={<CreditCard weight="light" />}>Card spend</HighlightCard.Header>
      }
      body={
        <>
          <ChargeCardCurrentBalanceHighlightItem
            cardBalanceSettledInCents={0 - capitalAccountSummaryWithChargeCard.cardBalance}
            cardBalancePendingInCents={0 - capitalAccountSummaryWithChargeCard.cardPending}
          />
          <ChargeCardRepaymentHighlightItem
            balanceDueDate={dayjs(chargeCardRepaymentInfo.nextRepaymentDueDate)}
            balanceDueAmountInCents={chargeCardRepaymentInfo.remainingAmountDue}
          />
          <ChargeCardAutoPayHighlightItem
            balanceDueAmountInCents={chargeCardRepaymentInfo.remainingAmountDue}
            autoPayAccountBalanceInCents={repaymentAccount.availableBalance}
            autoPayHighbeamAccountTypeName={repaymentAccount.highbeamType.name}
            autoPayAccountAccountName={repaymentAccount.name}
            autoPayAccountAccountNumber={repaymentAccount.accountNumber}
          />
        </>
      }
      footer={
        <>
          <Link
            to={`/capital/${capitalAccountSummaryWithChargeCard.guid}/cards/repay`}
            className="full-width"
          >
            <ChargeCardHighlightCardRepayChargeCardsButton
              disabled={
                !isAllowedToRepayCapital || capitalAccountSummaryWithChargeCard.cardBalance >= 0
              }
              tooltip={!isAllowedToRepayCapital && <NotAllowedToRepayCapital />}
            />
          </Link>
          <ChangeAutoPayAccountButton
            fullWidth
            capitalAccountSummary={capitalAccountSummaryWithChargeCard}
          />
        </>
      }
    />
  );
};

export default withErrorBoundary(ChargeCardHighlightCard, {
  fallbackRender: ({ error }) => {
    if (error instanceof RequiredButNotFoundError) {
      return null;
    } else {
      throw error;
    }
  },
});
