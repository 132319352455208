import classNames from "classnames";
import { ComponentProps, FC, ReactNode, useRef, useState } from "react";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import { formatBankingDate } from "utils/date";

import TextCell from "../TextCell";

import styles from "./DateCell.module.scss";

type Props = ComponentProps<typeof TextCell> & {
  date: string;
  tooltipContent?: ReactNode;
  hideCurrentYear?: boolean;
};

const DateCell: FC<Props> = ({
  date,
  className,
  hideCurrentYear = false,
  tooltipContent = null,
  ...textCellProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div
      ref={containerRef}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      className={styles.container}
    >
      {tooltipContent && containerRef.current && isHovered && (
        <Tooltip
          closeTooltip={() => setIsHovered(false)}
          openTooltip={() => setIsHovered(true)}
          referenceElement={containerRef.current}
        >
          {tooltipContent}
        </Tooltip>
      )}
      <TextCell
        className={classNames(className, tooltipContent && styles.tooltipTriggerText)}
        {...textCellProps}
      >
        {formatBankingDate(date, hideCurrentYear)}
      </TextCell>
    </div>
  );
};

export default DateCell;
