import TransactionsV2 from "components/TransactionsV2";
import { FC } from "react";
import ChargeCardRepaymentRep from "reps/ChargeCardRepaymentRep";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import { useChargeCardAccountQuery } from "resources/charge-cards/queries/useChargeCardAccount";
import { useChargeCardRepaymentsQuery } from "resources/charge-cards/queries/useChargeCardRepayments";
import Shimmer from "ui/feedback/Shimmer";
import AmountCell from "ui/table/AmountCell";
import DateTimeCell from "ui/table/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";

import ChargeCardRepaymentType from "./ChargeCardRepaymentType";
import useDownloadChargeCardTransactionsCsvMutation from "./hooks/useDownloadChargeCardTransactionsCsvMutation";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const columns: Column<ChargeCardRepaymentRep.Complete>[] = [
  {
    title: "Date",
    cellRender: ({ createdAt }) => <DateTimeCell date={createdAt} />,
  },
  {
    title: "Transaction type",
    cellRender: () => <ChargeCardRepaymentType />,
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: ({ amount }) => (
      <AmountCell cents={amount} direction={amount >= 0 ? "positive" : "negative"} />
    ),
  },
];

const ChargeCardRepayments: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  const { data: chargeCardAccount } = useChargeCardAccountQuery(
    capitalAccountSummaryWithChargeCard.guid
  );
  const { data: repayments = [], isLoading } = useChargeCardRepaymentsQuery(
    chargeCardAccount?.guid
  );

  const { mutate: downloadCsv, isPending: isExportLoading } =
    useDownloadChargeCardTransactionsCsvMutation();

  // NB(alex): Looks like we don't currently get actual pagination info?
  const count = repayments.length;

  return (
    <TransactionsV2
      title={<TransactionsV2.Title title="Highbeam Card repayments" />}
      filters={
        <TransactionsV2.Filters
          paginationInfo={
            isLoading ? (
              <Shimmer />
            ) : (
              <TransactionsV2.Filters.PaginationInfo count={count} totalCount={count} />
            )
          }
          exportButton={
            chargeCardAccount ? (
              <TransactionsV2.Filters.ExportButton
                isLoading={isExportLoading}
                onClick={() => {
                  downloadCsv(chargeCardAccount.guid);
                }}
              />
            ) : null
          }
        />
      }
      table={
        isLoading ? (
          <Table.Loading columns={columns.map(({ title }) => ({ title }))} />
        ) : (
          <TransactionsV2.TransactionsTable
            data={repayments}
            rowKey={({ unitCoTransactionId }) => unitCoTransactionId}
            columns={columns}
          />
        )
      }
    />
  );
};

export default ChargeCardRepayments;
