import { RecurringPayment } from "@highbeam/unit-node-sdk";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import BankAccount from "ui/data-display/BankAccount";
import { getAccountDisplayName } from "utils/account";

import styles from "./RecurringPaymentInfoFlexpaneToFromSection.module.scss";

type Props = {
  recurringPayment: RecurringPayment;
};

const BankAccountDetail: React.FC<Props> = ({ recurringPayment }) => {
  const bankAccounts = useBankAccounts({ status: "open" });
  const bankAccount = bankAccounts.find(
    (account) => account.unitCoDepositAccountId === recurringPayment.relationships.account.data.id
  );

  return (
    <BankAccount
      accountName={bankAccount ? getAccountDisplayName(bankAccount) : ""}
      className={styles.bankAccount}
    />
  );
};

export default BankAccountDetail;
