import { Check } from "@phosphor-icons/react";
import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import React from "react";
import { useSetRecoilState } from "recoil";
import BusinessRep from "reps/BusinessRep";
import useBusinessesByMemberUser from "resources/businesses/queries/useBusinessesByMemberUser";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import businessGuidState from "state/auth/businessGuid";
import isBusinessSwitcherOpen from "state/superuser/isBusinessSwitcherOpen";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import getBusinessSafeDisplayName from "utils/business/getBusinessSafeDisplayName";

import styles from "./DashboardUserMenuDropdownBusinesses.module.scss";

const DashboardUserMenuDropdownBusinesses: React.FC = () => {
  const setBusinessGuid = useSetRecoilState(businessGuidState);
  const canSuperuser = useCanSuperuser();
  const businesses = useBusinessesByMemberUser();

  const selectBusiness = (businessGuid: string) => {
    setBusinessGuid(businessGuid);
    window.location.reload(); // Intended to force a refresh.
  };

  if (businesses.length <= 1 && !canSuperuser) return null;

  return (
    <div className={styles.businesses}>
      <div className={styles.header}>
        <Text size={12} weight="medium" color={colors.grey[500]}>
          Business
        </Text>
      </div>
      <BusinessSwitcher />
      {businesses
        .toSorted((a, b) =>
          getBusinessSafeDisplayName(a).localeCompare(getBusinessSafeDisplayName(b))
        )
        .map((business) => {
          return (
            <Business
              key={business.guid}
              business={business}
              onSelect={() => selectBusiness(business.guid)}
            />
          );
        })}
    </div>
  );
};

export default DashboardUserMenuDropdownBusinesses;

const BusinessSwitcher: React.FC = () => {
  const canSuperuser = useCanSuperuser();
  const setIsBusinessSwitcherOpen = useSetRecoilState(isBusinessSwitcherOpen);

  if (!canSuperuser) return null;

  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.business}
      onClick={() => setIsBusinessSwitcherOpen(true)}
    >
      <Text size={14} weight="medium">
        [SUPERUSER] Business switcher
      </Text>
    </div>
  );
};

const Business: React.FC<{
  business: BusinessRep.Complete;
  onSelect: () => void;
}> = ({ business, onSelect: handleSelect }) => {
  const businessGuid = useBusinessGuid();
  const isSelected = business.guid === businessGuid;
  const color = isSelected ? colors.purple[500] : undefined;

  return (
    <div role="button" tabIndex={0} className={styles.business} onClick={handleSelect}>
      <Text size={14} weight="medium" color={color}>
        {getBusinessSafeDisplayName(business)}
      </Text>
      {isSelected ? <Check size={16} color={colors.purple[500]} /> : null}
    </div>
  );
};
