import FullScreenLayout from "components/layouts/FullScreen";
import React from "react";
import BusinessRep from "reps/BusinessRep";
import Card from "ui/data-display/Card";
import Text from "ui/typography/Text";

import BusinessDisambiguatorBusinesses from "./BusinessDisambiguatorBusinesses";
import styles from "./BusinessDisambiguatorLayout.module.scss";

type Props = {
  businesses: BusinessRep.Complete[];
};

const BusinessDisambiguatorLayout: React.FC<Props> = ({ businesses }) => {
  return (
    <FullScreenLayout>
      <Card className={styles.card}>
        <Text className={styles.title} size={20} weight="bold">
          Select business
        </Text>
        <div className={styles.container}>
          <BusinessDisambiguatorBusinesses businesses={businesses} />
        </div>
      </Card>
    </FullScreenLayout>
  );
};

export default BusinessDisambiguatorLayout;
