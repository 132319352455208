import { SIGN_OUT_ROUTE } from "modules/auth/constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "./DashboardUserMenuDropdownSignOut.module.scss";

const DashboardUserMenuDropdownSignOut: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(SIGN_OUT_ROUTE);
  };

  return (
    <div role="button" tabIndex={0} className={styles.button} onClick={handleClick}>
      <Text size={14} weight="medium" color={colors.red[600]}>
        Sign out
      </Text>
    </div>
  );
};

export default DashboardUserMenuDropdownSignOut;
