import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import IconWithTooltip from "components/common/IconWithTooltip";
import MultiStep from "components/layouts/MultiStep";
import dayjs from "dayjs";
import { useState } from "react";
import CreditApplicationDocumentRep from "reps/CreditApplicationDocumentRep";
import CreditApplicationRep from "reps/CreditApplicationRep";
import useCreditApplicationDocumentsQueryOptions from "resources/credit-application/queries/useCreditApplicationDocumentsQueryOptions";
import useCreditApplicationQueryOptions from "resources/credit-application/queries/useCreditApplicationQueryOptions";
import colors from "styles/colors";
import InfoBox from "ui/data-display/InfoBox";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import CurrencyInput from "ui/inputs/CurrencyInput";
import Dropdown, { Option } from "ui/inputs/Dropdown";
import DropdownV2, { convertStringValueToDropdownOption } from "ui/inputs/DropdownV2";
import RadioWithLabel from "ui/inputs/RadioWithLabel";
import TextArea from "ui/inputs/TextArea";
import TextInput, { Filter } from "ui/inputs/TextInput";
import Text from "ui/typography/Text";
import sleep from "utils/async/sleep";
import { getCentsFromDollars } from "utils/money";
import getObjectKeys from "utils/ts/getObjectKeys";

import CreditApplicationBackButton from "../components/CreditApplicationBackButton";
import CreditApplicationDocumentExampleButton from "../components/CreditApplicationDocumentExampleButton";
import CreditApplicationLegalFootnote from "../components/CreditApplicationLegalFootnote";
import UploadDocumentsSection from "../components/UploadDocumentsSection";
import useSubmitCreditApplication from "../hooks/useSubmitCreditApplication";

import styles from "./CreditApplicationViews.module.scss";
import { getCountByDocumentType } from "./UploadFinancialDocumentsView";

const BUSINESS_INFO_FORM_ID = "business-info-form";

type ReasonForRequest = "inventory" | "marketing" | "other";
// A mapping from labels to Duration values for repaymentTermsPreference
const repaymentTermMapping = {
  "30 days": dayjs.duration({ days: 30 }).asDays(),
  "60 days": dayjs.duration({ days: 60 }).asDays(),
  "90 days": dayjs.duration({ days: 90 }).asDays(),
  "120 days": dayjs.duration({ days: 120 }).asDays(),
  "Not sure": null,
} as const;

type RepaymentTermsPreference = keyof typeof repaymentTermMapping;

type Props = { setShowLoading: (val: boolean) => void };

const BusinessInfoView: React.FC<Props> = ({ setShowLoading }) => {
  const { mutateAsync: submitCreditApplication, isPending: isSubmitCreditApplicationLoading } =
    useSubmitCreditApplication();
  const { data: creditApplicationData } = useSuspenseQuery(useCreditApplicationQueryOptions());
  const { data: creditApplicationDocumentsData } = useQuery(
    useCreditApplicationDocumentsQueryOptions()
  );

  const [requestedAmountDollars, setRequestedAmountDollars] = useState<string>("");
  const [reasonForRequest, setReasonForRequest] = useState<ReasonForRequest>();
  const [otherReasonForRequest, setOtherReasonForRequest] = useState<string>("");
  const [additionalInformation, setAdditionalInformation] = useState<string>("");
  const [hasSecuredLenders, setHasSecuredLenders] = useState(false);
  const [securedLendersExplanation, setSecuredLendersExplanation] = useState("");
  const [subsidiaries, setSubsidiaries] = useState("");
  const [isSingleEntityBusiness, setIsSingleEntityBusiness] = useState(false);
  const [isRetailPresence, setIsRetailPresence] = useState(false);
  const [retailLocations, setRetailLocations] = useState("");
  const [industry, setIndustry] = useState<Option>();
  const [repaymentTermsPreference, setRepaymentTermsPreference] =
    useState<RepaymentTermsPreference | null>();
  const [otherIndustry, setOhterIndustry] = useState<string>();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    // Show the loading page
    setShowLoading(true);
    await sleep(2000);

    await submitCreditApplication({
      userProvidedDetails: {
        ...creditApplicationData?.userProvidedDetails,
        userNotes: additionalInformation,
        requestedAmount: getCentsFromDollars(requestedAmountDollars),
        reasonForRequest: Boolean(otherReasonForRequest) ? otherReasonForRequest : reasonForRequest,
        numberOfRetailLocations: Number(retailLocations),
        industry: industry?.value as CreditApplicationRep.CreditApplicationIndustry,
        otherIndustry,
        securedLenders: hasSecuredLenders,
        securedLendersExplanation,
        orgStructure: subsidiaries,
        singleEntityBusiness: isSingleEntityBusiness,
        ...(repaymentTermsPreference
          ? { repaymentTermsPreference: repaymentTermMapping[repaymentTermsPreference] }
          : {}),
      },
    });

    setShowLoading(false);
  };

  const orgStructureDocsCount = getCountByDocumentType(
    creditApplicationDocumentsData || [],
    CreditApplicationDocumentRep.DocumentType.OrgStructure
  );

  const paymentTermOptions = getObjectKeys(repaymentTermMapping).map(
    convertStringValueToDropdownOption
  );

  const isSubmitDisabled =
    !requestedAmountDollars ||
    !reasonForRequest ||
    !industry ||
    (industry.value === "Other" && !otherIndustry) ||
    (isSingleEntityBusiness ? false : !subsidiaries && orgStructureDocsCount === 0) ||
    (hasSecuredLenders && !securedLendersExplanation);

  return (
    <MultiStep.Form id={BUSINESS_INFO_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Business information</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>

        <div className={styles.container}>
          <Text size={14} weight="medium">
            What industry is your business in?
          </Text>
          <Dropdown
            placeholder="Select industry"
            value={industry ?? null}
            onChange={setIndustry}
            options={Object.entries(CreditApplicationRep.CreditApplicationIndustry).map(
              ([key, value]) => ({
                value: key,
                label: value,
              })
            )}
          />
          {industry?.value === "Other" && (
            <TextInput
              value={otherIndustry}
              placeholder="Please specify your industry"
              onChange={(val) => setOhterIndustry(val)}
            />
          )}
        </div>

        <MultiStep.Section.Spacer />

        <div className={styles.container}>
          <Text size={14} weight="medium">
            How much are you looking to borrow?
          </Text>

          <CurrencyInput
            prefixValue="$"
            value={requestedAmountDollars}
            onChange={setRequestedAmountDollars}
            allowDecimals={false}
          />
        </div>

        <MultiStep.Section.Spacer />

        <div className={styles.container}>
          <Text size={14} weight="medium">
            What do you need capital for?
          </Text>

          <div className={styles.radioLabelVertical}>
            <RadioWithLabel
              label="Inventory"
              checked={reasonForRequest === "inventory"}
              onChange={() => {
                setOtherReasonForRequest("");
                setReasonForRequest("inventory");
              }}
            />
            <RadioWithLabel
              label="Marketing"
              checked={reasonForRequest === "marketing"}
              onChange={() => {
                setOtherReasonForRequest("");
                setReasonForRequest("marketing");
              }}
            />
            <RadioWithLabel
              label="Other"
              checked={reasonForRequest === "other"}
              onChange={() => setReasonForRequest("other")}
            />
          </div>

          {reasonForRequest === "other" && (
            <TextInput
              value={otherReasonForRequest}
              placeholder="Please explain"
              onChange={(val) => {
                setOtherReasonForRequest(val);
              }}
            />
          )}
        </div>
      </MultiStep.Section>

      <div className={styles.container}>
        <Text size={14} weight="medium">
          What payment terms would you like?
        </Text>
        <DropdownV2
          placeholder="Select payment terms"
          value={
            repaymentTermsPreference
              ? convertStringValueToDropdownOption(repaymentTermsPreference)
              : null
          }
          onChange={(val) => setRepaymentTermsPreference(val ? val.value : null)}
          options={paymentTermOptions}
        />
      </div>

      <MultiStep.Section.Spacer />

      <div className={styles.container}>
        <Text size={14} weight="medium">
          Affiliated entities
        </Text>
        <div className={styles.uploadDocumentsWithExampleButton}>
          <Text size={14} color={colors.grey[600]}>
            Please list any parent or sibling companies, as well as all subsidiaries, and/or upload
            a company organizational chart that shows it.
          </Text>
          <CreditApplicationDocumentExampleButton
            documentType={CreditApplicationDocumentRep.DocumentType.OrgStructure}
          />
        </div>

        <div className={styles.container}>
          <TextInput
            value={subsidiaries}
            placeholder="List companies here"
            onChange={setSubsidiaries}
          />
          <Text size={16} color={colors.grey[500]} className={styles.centeredText}>
            or
          </Text>
          <UploadDocumentsSection type={CreditApplicationDocumentRep.DocumentType.OrgStructure} />
          <CheckboxLabel
            label="My company does not have any parent companies or subsidiaries"
            checked={isSingleEntityBusiness}
            textWeight="regular"
            onChange={() => setIsSingleEntityBusiness(!isSingleEntityBusiness)}
          />
        </div>
      </div>

      <MultiStep.Section.Spacer />
      <div className={styles.container}>
        <Text size={14} weight="medium">
          Do you have a retail presence?
        </Text>

        <div className={styles.radioLabel}>
          <RadioWithLabel
            label="No"
            checked={!isRetailPresence}
            onChange={() => {
              setIsRetailPresence(!isRetailPresence);
              setRetailLocations("");
            }}
          />
          <RadioWithLabel
            label="Yes"
            checked={isRetailPresence}
            onChange={() => setIsRetailPresence(!isRetailPresence)}
          />
        </div>

        {isRetailPresence && (
          <TextInput
            value={retailLocations}
            placeholder="Number of store locations"
            inputFilter={Filter.DIGITS}
            onChange={(val) => {
              setRetailLocations(val);
              setIsRetailPresence(Boolean(val));
            }}
          />
        )}
      </div>

      <MultiStep.Section.Spacer />
      <div className={styles.container}>
        <div className={styles.titleWithTooltip}>
          <Text size={14} weight="medium">
            Do you have any secured lenders or personal guarantees?
          </Text>
          <IconWithTooltip
            color="primary"
            content="Secured lenders are lenders that hold collateral on business or personal assets."
          />
        </div>

        <div className={styles.radioLabel}>
          <RadioWithLabel
            label="No"
            checked={!hasSecuredLenders}
            onChange={() => {
              setHasSecuredLenders(!hasSecuredLenders);
            }}
          />
          <RadioWithLabel
            label="Yes"
            checked={hasSecuredLenders}
            onChange={() => {
              setHasSecuredLenders(!hasSecuredLenders);
            }}
          />
        </div>

        {hasSecuredLenders && (
          <TextArea
            value={securedLendersExplanation}
            placeholder="Please specify which of your lenders are secured by collateral or personal guarantees"
            onChange={(val) => {
              setSecuredLendersExplanation(val);
              setHasSecuredLenders(Boolean(val));
            }}
          />
        )}
      </div>
      <MultiStep.Section.Spacer />

      <UploadDocumentsSection
        heading="(Optional) Additional information"
        subheading="Please add any additional information here that you think might be helpful."
        type={CreditApplicationDocumentRep.DocumentType.Other}
      >
        <MultiStep.Section.Spacer />

        <TextArea
          label=" (Optional) Is there anything else we should know?"
          value={additionalInformation}
          onChange={setAdditionalInformation}
        />
      </UploadDocumentsSection>

      <InfoBox
        variant="info"
        iconSize={24}
        text="Submitting your capital application will not affect your credit score."
      />

      <MultiStep.Controls>
        <CreditApplicationBackButton />

        <MultiStep.Controls.NextButton
          form={BUSINESS_INFO_FORM_ID}
          disabled={isSubmitDisabled}
          isLoading={isSubmitCreditApplicationLoading}
        >
          Submit application
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>

      <CreditApplicationLegalFootnote />
    </MultiStep.Form>
  );
};

export default BusinessInfoView;
