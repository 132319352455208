import { ArrowRight, LinkBreak } from "@phosphor-icons/react";
import classNames from "classnames";
import AccountLabel from "components/Accounts/AccountLabel";
import styles from "pages/accounts/AccountsOverviewPage/components/PlaidBankAccountsTable/PlaidBankAccountsTable.module.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import colors from "styles/colors";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import { useIsMobile } from "utils/device/useMediaQuery";
import { getDollarsFromCents } from "utils/money";
import { formatAmount } from "utils/string";

type Props = {
  accounts: PlaidBankAccountRep.Complete[];
};

const PlaidBankAccountsTable: React.FC<Props> = ({ accounts }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Table
      columns={createColumns({ isMobile })}
      rowKey={(account) => account.plaidAccountId}
      data={accounts}
      onRowClick={() => navigate("/settings/banks-and-cards")}
    />
  );
};
export default PlaidBankAccountsTable;

function createColumns({
  isMobile,
}: {
  isMobile: boolean;
}): Column<PlaidBankAccountRep.Complete>[] {
  return [
    {
      title: "Account",
      cellRender: (account) => <AccountLabel plaidAccount={account} showConnectedStores />,
    },
    {
      title: "Available",
      align: TableColumnAlignment.RIGHT,
      cellRender: (account) => {
        return (
          <div className={styles.balance}>
            <Text size={14} color={colors.grey[800]} weight="medium" numeric align="right">
              {formatAmount(getDollarsFromCents(account.availableBalance))}
            </Text>
          </div>
        );
      },
    },
    {
      title: !isMobile ? "Account details" : "",
      align: TableColumnAlignment.RIGHT,
      cellRender: (account) => {
        if (account.isActive && account.isConnectionActive) {
          return (
            <div className={styles.details}>
              View account details
              <ArrowRight size={16} />
            </div>
          );
        }

        return (
          <div className={classNames(styles.details, styles.detailsInactive)}>
            <LinkBreak size={16} />
            Disconnected
            <ArrowRight size={16} />
          </div>
        );
      },
    },
  ];
}
