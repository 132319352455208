import { AchReceivedPayment } from "@highbeam/unit-node-sdk";
import ReceivedPaymentInfoFlexpane from "dialogs/ReceivedPaymentInfoFlexpane";
import useFailedDebitPayments from "modules/failed-debits/queries/useFailedDebitPayments";
import { FC } from "react";
import useAllBankAccountsByUnitCoDepositAccountId from "resources/bank-accounts/queries/useAllBankAccountsByUnitCoDepositAccountId";
import BankAccount from "ui/data-display/BankAccount";
import Pill from "ui/data-display/Pill";
import Button from "ui/inputs/Button";
import AmountCell from "ui/table/AmountCell";
import CounterpartyCell from "ui/table/CounterpartyCell";
import NotFoundCell from "ui/table/NotFoundCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import TextCell from "ui/table/TextCell";
import { getAccountDisplayName } from "utils/account";
import { formatBankingDate } from "utils/date";
import { getReceivedAchPaymentCounterparty } from "utils/receivedPayments";
import useSearchParamValue from "utils/searchParams/useSearchParamValue";

import styles from "./FailedDebitsTable.module.scss";

const ReceivedPaymentsTableCounterpartyCell = ({
  receivedPayment,
}: {
  receivedPayment: AchReceivedPayment;
}) => {
  const counterparty = getReceivedAchPaymentCounterparty(receivedPayment);
  return (
    <div className={styles.toFromStatus}>
      <CounterpartyCell>{counterparty.formattedName ?? counterparty.name}</CounterpartyCell>
      <Pill className={styles.pill} bordered color="pink-dark">
        Failed
      </Pill>
    </div>
  );
};

const ReceivedPaymentsTableBankAccountCell = ({
  receivedPayment,
}: {
  receivedPayment: AchReceivedPayment;
}) => {
  const bankAccountsByUnitCoDepositAccountId = useAllBankAccountsByUnitCoDepositAccountId();
  const account = bankAccountsByUnitCoDepositAccountId.get(
    receivedPayment.relationships.account.data.id
  );
  const accountName = account ? getAccountDisplayName(account) : "";

  return <BankAccount accountName={accountName} shortMethodName={"ACH"} />;
};

const columns: Column<AchReceivedPayment>[] = [
  {
    title: "Date",
    key: "formattedCreatedAt",
    cellRender: (receivedPayment) => {
      if ("createdAt" in receivedPayment.attributes && receivedPayment.attributes.createdAt) {
        return <TextCell>{formatBankingDate(receivedPayment.attributes.createdAt)}</TextCell>;
      } else {
        return <NotFoundCell />;
      }
    },
    width: 120,
  },
  {
    title: "From",
    cellRender: (receivedPayment) => {
      return <ReceivedPaymentsTableCounterpartyCell receivedPayment={receivedPayment} />;
    },
  },
  {
    title: "Account",
    cellTextWeight: "medium",
    key: "accountName",
    cellRender: (receivedPayment) => {
      return <ReceivedPaymentsTableBankAccountCell receivedPayment={receivedPayment} />;
    },
  },
  {
    title: "Amount",
    align: TableColumnAlignment.RIGHT,
    cellRender: (payment) => {
      return (
        <AmountCell
          cents={payment.attributes.amount}
          direction={payment.attributes.direction === "Debit" ? "negative" : "positive"}
        />
      );
    },
  },
  {
    title: "Action",
    align: TableColumnAlignment.RIGHT,
    width: 120,
    cellRender: () => {
      return (
        <div className={styles.retryButtonContainer}>
          <Button variant="tertiary" size="sm">
            Retry
          </Button>
        </div>
      );
    },
  },
];

const FailedDebitsTable: FC = () => {
  const failedDebits = useFailedDebitPayments();
  const [selectedReceivedPayment, setReceivedSelectedPayment] = useSearchParamValue("debitId");

  return (
    <>
      <ReceivedPaymentInfoFlexpane
        receivedPaymentId={
          selectedReceivedPayment && selectedReceivedPayment !== ""
            ? selectedReceivedPayment
            : undefined
        }
        onClose={() => setReceivedSelectedPayment("")}
      />
      <Table
        columns={columns}
        data={failedDebits}
        rowKey={(payment) => payment.id}
        cellClassName={styles.cell}
        onRowClick={(receivedPayment) => {
          setReceivedSelectedPayment(receivedPayment.id);
        }}
      />
    </>
  );
};

export default FailedDebitsTable;
