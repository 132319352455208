import { useQuery, UseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";
import CapitalAccountRep from "reps/CapitalAccountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions, { UseQueryAdditionalOptions } from "utils/react-query/useQueryOptions";

import { BASE_CAPITAL_ACCOUNTS_QUERY_KEY } from "./useRefreshCapitalAccountsQueries";

// NB(alex): Experimenting with extending query options. Please do not assume the patterns in this file are correct.

const makeQueryKey = (businessGuid: string) => [BASE_CAPITAL_ACCOUNTS_QUERY_KEY, { businessGuid }];

type Params<TData> = UseQueryAdditionalOptions<
  CapitalAccountRep.Complete[],
  unknown,
  TData,
  ReturnType<typeof makeQueryKey>
>;

export const useCapitalAccountsQueryOptions = <TData = CapitalAccountRep.Complete[]>({
  ...additionalOptions
}: Params<TData> = {}) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: makeQueryKey(businessGuid),
    queryFn: () => {
      return highbeamApi.capitalAccount.getAll(businessGuid);
    },
    ...additionalOptions,
  });
};

type UseQueryOnlyOptions<TData> = Pick<
  UseQueryOptions<CapitalAccountRep.Complete[], unknown, TData, ReturnType<typeof makeQueryKey>>,
  "enabled" | "throwOnError" | "placeholderData"
> &
  Params<TData>;

export const useCapitalAccountsQuery = <TData = CapitalAccountRep.Complete[]>({
  enabled,
  throwOnError,
  placeholderData,
  ...params
}: UseQueryOnlyOptions<TData> = {}) => {
  return useQuery({
    ...useCapitalAccountsQueryOptions(params),
    enabled,
    throwOnError,
    placeholderData,
  });
};

const useCapitalAccounts = <TData = CapitalAccountRep.Complete[]>(params?: Params<TData>) => {
  return useSuspenseQuery(useCapitalAccountsQueryOptions(params)).data;
};

export default useCapitalAccounts;
