import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import getPrimaryBankAccount from "../utils/getPrimaryBankAccount";

import useBankAccounts from "./useBankAccounts";

const usePrimaryBankAccount = <TRequired extends boolean>(params?: RequiredParam<TRequired>) => {
  const primaryBankAccount = useBankAccounts({
    status: "all",
    select: (bankAccounts) => getPrimaryBankAccount(bankAccounts),
  });

  if (params?.required && !primaryBankAccount) {
    throw new RequiredButNotFoundError();
  }

  return primaryBankAccount as DataRequired<typeof primaryBankAccount, TRequired>;
};

export default usePrimaryBankAccount;
