import { captureException } from "@sentry/react";
import BillLineItemRep from "reps/BillLineItemRep";
import { useRefreshBillLineItemsQuery } from "resources/bill-line-items/queries/useBillLineItems";
import { useRefreshBillQuery } from "resources/bills/queries/useBill";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables = Omit<BillLineItemRep.Creator, "billId" | "businessGuid">;

const useCreateBillLineItemMutation = (
  billId: string,
  additionalOptions?: MutationAdditionalOptions<BillLineItemRep.Complete, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillLineItemsQuery = useRefreshBillLineItemsQuery(billId);

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        return highbeamApi.billLineItem.create({
          billId,
          businessGuid,
          ...variables,
        });
      },

      onSuccess: async () => {
        await Promise.all([refreshBillQuery(), refreshBillLineItemsQuery()]);
      },
      onError: (error) => {
        captureException(error);
      },
    },
    additionalOptions || {}
  );
};

export default useCreateBillLineItemMutation;
