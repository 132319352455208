import { PlusCircle as PlusCircleIcon } from "@phosphor-icons/react";
import { FC, useCallback } from "react";
import useCreateBillLineItemMutation from "resources/bill-line-items/mutations/useCreateBillLineItemMutation";
import inferBillLineItemCurrencyFromBill from "resources/bill-line-items/utils/inferBillLineItemCurrency";
import useBill from "resources/bills/queries/useBill";
import Button from "ui/inputs/Button";

import BillLineItemsTable from "../BillLineItemsTable";

import styles from "./BillLineItemsContent.module.scss";

type Props = {
  billId: string;
};

const BillLineItemsContent: FC<Props> = ({ billId }) => {
  const bill = useBill(billId, { required: true });
  const currency = inferBillLineItemCurrencyFromBill(bill);

  const { mutate: createBillLineItem, isPending: isCreatingBillLineItem } =
    useCreateBillLineItemMutation(billId);

  const addItem = useCallback(() => {
    createBillLineItem({
      description: "",
      amount: {
        amount: "0",
        currency,
      },
      accountingAccountId: "",
    });
  }, [createBillLineItem, currency]);

  return (
    <div>
      <BillLineItemsTable billId={billId} />

      <div className={styles.footer}>
        <Button
          variant="bare"
          size="sm"
          isLoading={isCreatingBillLineItem}
          onClick={() => addItem()}
        >
          <PlusCircleIcon size={16} />
          Add item
        </Button>
      </div>
    </div>
  );
};

export default BillLineItemsContent;
