import { Archive, DotsThree, Pencil } from "@phosphor-icons/react";
import useMfa from "modules/mfa/useMfa";
import { useState } from "react";
import Button from "ui/inputs/Button";
import Menu from "ui/overlay/Menu";

import ArchiveCardModal from "../../ArchiveCardModal";
import EditCardModal from "../../EditCardModal";
import { useCardDetailsPageContext } from "../../providers/CardDetailsPageProvider";

const CardDetailsHeaderMenu = () => {
  const { card, cardLimits } = useCardDetailsPageContext();
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [showArchiveCardModal, setShowArchiveCardModal] = useState(false);
  const { mfa } = useMfa();

  return (
    <>
      {showEditCardModal && (
        <EditCardModal
          card={card}
          cardLimit={cardLimits}
          closeModal={() => setShowEditCardModal(false)}
        />
      )}

      <ArchiveCardModal
        card={card}
        isOpen={showArchiveCardModal}
        onClose={() => setShowArchiveCardModal(false)}
      />

      <Menu
        button={
          <Button variant="tertiary" isSquare>
            <DotsThree size={16} />
          </Button>
        }
      >
        <Menu.Item
          icon={<Pencil size={16} />}
          onClick={() => mfa().then(() => setShowEditCardModal(true))}
        >
          Edit details
        </Menu.Item>
        <Menu.Item
          icon={<Archive size={16} />}
          variant="danger"
          onClick={() => mfa().then(() => setShowArchiveCardModal(true))}
        >
          Close card
        </Menu.Item>
      </Menu>
    </>
  );
};

export default CardDetailsHeaderMenu;
