import { FileText } from "@phosphor-icons/react";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import CapitalDrawdownApprovalRep from "reps/CapitalDrawdownApprovalRep";
import { useCapitalDrawdownApprovalsQuery } from "resources/capital-drawdown-approvals/queries/useCapitalDrawdownApprovals";
import Pill from "ui/data-display/Pill";
import AmountCell from "ui/table/AmountCell";
import DateTimeCell from "ui/table/DateTimeCell";
import Table, { Column, TableColumnAlignment } from "ui/table/Table";
import { isNotFalsy } from "utils/array";

const columns = ({ showInvoiceColumn }: { showInvoiceColumn: boolean }) => {
  const columns: Column<CapitalDrawdownApprovalRep.Complete>[] = [
    {
      title: "Initiated",
      cellRender: ({ createdAt }: CapitalDrawdownApprovalRep.Complete) => (
        <DateTimeCell date={createdAt} />
      ),
    },
    {
      title: "Status",
      cellRender: () => <Pill color="orange">Pending</Pill>,
    },
    showInvoiceColumn && {
      title: "Invoice",
      align: TableColumnAlignment.RIGHT,
      cellRender: () => (
        <div>
          <FileText size={20} />
        </div>
      ),
    },
    {
      title: "Amount",
      align: TableColumnAlignment.RIGHT,
      cellRender: ({ amount }: CapitalDrawdownApprovalRep.Complete) => (
        <AmountCell cents={amount} withCents={amount % 100 !== 0} direction="negative" />
      ),
    },
  ].filter(isNotFalsy);

  return columns;
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const PendingDrawdownsTable: FC<Props> = ({ capitalAccountSummary }) => {
  const { data: pendingDrawdowns = [], isLoading } = useCapitalDrawdownApprovalsQuery({
    capitalAccountGuid: capitalAccountSummary.guid,
  });

  const hasNotes = pendingDrawdowns.some((drawdown) => drawdown.note);

  const navigate = useNavigate();

  const openPendingDrawdownFlexpane = (pendingDrawdownGuid: string) => {
    navigate(`/capital/${capitalAccountSummary.guid}/pending-drawdowns/${pendingDrawdownGuid}`, {
      state: DISABLE_SCROLL_TO_TOP_STATE,
    });
  };

  return (
    <Table
      columns={columns({ showInvoiceColumn: hasNotes })}
      isLoading={isLoading}
      isLoadingRows={3}
      data={pendingDrawdowns}
      rowKey={(drawdown) => drawdown.guid}
      onRowClick={(drawdown) => openPendingDrawdownFlexpane(drawdown.guid)}
    />
  );
};

export default PendingDrawdownsTable;
