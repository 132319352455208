import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import BusinessMemberRep from "reps/BusinessMemberRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

// NB(alex): Experimental - experimenting with using similarly named variables, such as `BASE_QUERY_KEY`, `makeQueryKey`, `useQueryVariables`, `useQueryFn`. This might let us create a generic factory for all queries.

export const BASE_QUERY_KEY = "businessMembers";

type Params<TData = BusinessMemberRep.Complete[]> = {
  select?: (data: BusinessMemberRep.Complete[]) => TData;
};

export const useQueryVariables = () => {
  const businessGuid = useBusinessGuid();
  return { businessGuid };
};

export const makeQueryKey = (variables: ReturnType<typeof useQueryVariables>) => {
  return [BASE_QUERY_KEY, variables];
};

export const useQueryFn = () => {
  const highbeamApi = useHighbeamApi();
  const { businessGuid } = useQueryVariables();
  return () => highbeamApi.businessMember.getByBusiness(businessGuid);
};

export const useBusinessMembersQueryOptions = <TData = BusinessMemberRep.Complete[]>({
  select,
}: Params<TData> = {}) => {
  const queryVariables = useQueryVariables();
  const queryFn = useQueryFn();

  return useQueryOptions({
    queryKey: makeQueryKey(queryVariables),
    queryFn: queryFn,
    select: select,
  });
};

export const useRefreshBusinessMembersQuery = <TData = BusinessMemberRep.Complete[]>(
  params?: Params<TData>
) => {
  return useRefreshQuery(useBusinessMembersQueryOptions(params).queryKey);
};

export const useBusinessMembersQuery = <TData = BusinessMemberRep.Complete[]>(
  params?: Params<TData>
) => {
  return useQuery(useBusinessMembersQueryOptions(params));
};

const useBusinessMembers = <TData = BusinessMemberRep.Complete[]>(params?: Params<TData>) => {
  const { data } = useSuspenseQuery(useBusinessMembersQueryOptions(params));
  return data;
};

export default useBusinessMembers;
