import { useSuspenseQuery } from "@tanstack/react-query";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

export const useBusinessesByMemberUserQueryOptions = () => {
  const highbeamApi = useHighbeamApi();
  const userGuid = useUserGuid();

  return useQueryOptions({
    queryKey: ["businessesByMemberUser", { userGuid }],
    queryFn: () => {
      if (!userGuid) return [];
      return highbeamApi.business.getByMemberUser(userGuid);
    },
  });
};

const useBusinessesByMemberUser = () => {
  return useSuspenseQuery(useBusinessesByMemberUserQueryOptions()).data;
};

export default useBusinessesByMemberUser;
