import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import useAllBankAccountsByUnitCoDepositAccountId from "resources/bank-accounts/queries/useAllBankAccountsByUnitCoDepositAccountId";
import colors from "styles/colors";
import BankAccountV2 from "ui/data-display/BankAccountV2";
import Text from "ui/typography/Text";
import { formatBankingDate } from "utils/date";

import { usePaymentInfoFlexpaneContext } from "../../context/PaymentInfoFlexpaneProvider";

const BankAccountDetail = () => {
  const { payment } = usePaymentInfoFlexpaneContext();

  const bankAccountsByUnitId = useAllBankAccountsByUnitCoDepositAccountId();
  const account = bankAccountsByUnitId.get(payment.relationships.account.data.id);
  const isOutgoingPayment = payment.attributes.direction === "Credit";

  return (
    <>
      <BankAccountV2
        bankIcon={
          <HighbeamAccountIcon highbeamTypeName={account?.highbeamType.name || "DepositAccount"} />
        }
        accountName={account?.name || ""}
        accountNumber={account?.accountNumber || ""}
      />
      {isOutgoingPayment && (
        <Text size={14} color={colors.grey[500]}>
          {formatBankingDate(payment.attributes.createdAt)}
        </Text>
      )}
      {payment.type === "achPayment" && payment.attributes.settlementDate && (
        <Text size={14} color={colors.grey[500]}>
          {"Estimated " + formatBankingDate(payment.attributes.settlementDate)}
        </Text>
      )}
    </>
  );
};

export default BankAccountDetail;
