import { CheckDeposit, Payment, Relationship } from "@highbeam/unit-node-sdk";
import { useQuery } from "@tanstack/react-query";
import AccountLabel from "components/Accounts/AccountLabel";
import { debounce } from "debounce";
import { useCallback, useEffect } from "react";
import BankAccountRep from "reps/BankAccountRep";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import useBusiness from "resources/business/queries/useBusiness";
import useUnitCoCheckDepostsQueryOptions from "resources/unit-co-check-deposits/queries/useUnitCoCheckDepostsQueryOptions";
import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import useUnitCoPaymentsQueryOptions from "resources/unit-co-payments/queries/useUnitCoPaymentsQueryOptions";
import { Pagination } from "ui/navigation/PageIndicator";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import {
  AccountOption,
  ALL_ACCOUNTS_OPTION,
  useHighbeamTransactions,
} from "utils/customHooks/useHighbeamTransactions";
import { startOfBankingDay } from "utils/date";
import { debounceMs } from "utils/debounce";
import {
  convertHighbeamFailedPaymentToHighbeamTransaction,
  convertUnitPaymentToHighbeamPayment,
} from "utils/payments";
import useSearchParamOption from "utils/searchParams/useSearchParamOption";
import { convertToHighbeamTransaction, getPaymentMetadataByGuid } from "utils/transactions";
import { HighbeamTransaction } from "utils/types/transactionsTypes";

// HACK(alex): Fixing this https://github.com/highbeamco/highbeam/pull/12724#pullrequestreview-2243655213
export const FAILED = "failed";

export const accountTransactionsTabs = [
  {
    id: "all",
    label: "All",
  },
  {
    id: FAILED,
    label: "Failed",
  },
];

export const useAccountsTransactions = (accountId?: string) => {
  const {
    transactions,
    setTransactions,
    isLoading,
    setIsLoading,
    accountOptions,
    setAccountOptions,
    selectedAccount,
    setSelectedAccount,
    searchQuery,
    setSearchQuery,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    pagination,
  } = useHighbeamTransactions();

  const [activeTab, setActiveTab] = useSearchParamOption(
    "tab",
    accountTransactionsTabs.map(({ id }) => id),
    accountTransactionsTabs[0].id
  );

  const highbeamApi = useHighbeamApi();
  const unitApi = useUnitApi();
  const bankAccounts = useBankAccounts({ status: "all" });
  const { guid: businessGuid, unitCoCustomerId: customerId } = useBusiness();

  // Go to the first page when filters change. Search is intentionally excluded because search is implemented locally.
  useEffect(() => {
    pagination.setCurrentPage(0);
  }, [selectedAccount, fromDate, toDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: unitPayments } = useQuery(
    useUnitCoPaymentsQueryOptions({
      limit: 100,
      offset: pagination.offset,
      status: ["Rejected"],
      ...(accountId !== undefined || (selectedAccount && selectedAccount.value !== "all")
        ? { accountId: accountId || selectedAccount!.value }
        : {}),
      since: fromDate ? startOfBankingDay(fromDate).format() : undefined,
      until: toDate ? startOfBankingDay(toDate).add(1, "day").format() : undefined,
    })
  );

  const { data: unitCheckDeposits } = useQuery(
    useUnitCoCheckDepostsQueryOptions({
      limit: 100,
      offset: pagination.offset,
      status: ["Rejected"],
      ...(accountId !== undefined || (selectedAccount && selectedAccount.value !== "all")
        ? { accountId: accountId || selectedAccount!.value }
        : {}),
    })
  );

  // Had to move recoil state into useEffect to allow pagination state and recoil state to update properly.
  // Please fix after moving transactions to recoil.
  useEffect(() => {
    if (!unitPayments || !unitCheckDeposits) {
      setIsLoading(true);
    } else if (activeTab === "failed") {
      const filteredUnitCheckDeposits = unitCheckDeposits.filter(
        (checkDeposit) =>
          startOfBankingDay(checkDeposit.attributes.createdAt).isAfter(fromDate) &&
          startOfBankingDay(checkDeposit.attributes.createdAt).isBefore(toDate)
      );
      pagination.setTotalCount(0);

      const relatedAccountsByUnitId = new Map<string, BankAccountRep.Complete>(
        bankAccounts.map((account) => [account.unitCoDepositAccountId, account])
      );
      const highbeamPayments = unitPayments
        .map((payment: Payment) =>
          convertHighbeamFailedPaymentToHighbeamTransaction(
            convertUnitPaymentToHighbeamPayment(payment, relatedAccountsByUnitId)
          )
        )
        .concat(
          filteredUnitCheckDeposits.map((checkDeposit: CheckDeposit) =>
            convertHighbeamFailedPaymentToHighbeamTransaction(
              convertUnitPaymentToHighbeamPayment(checkDeposit, relatedAccountsByUnitId)
            )
          )
        )
        .sort((a: HighbeamTransaction, b: HighbeamTransaction) =>
          startOfBankingDay(b.createdAt).diff(a.createdAt)
        );

      setTransactions(highbeamPayments);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitPayments, activeTab, pagination.currentPage, fromDate, toDate]);

  // TODO: Move transaction data to Recoil.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateTransactions = useCallback(
    debounce(
      (
        searchQuery: string,
        fromDate: Date | null,
        toDate: Date | null,
        pagination: Pagination,
        selectedAccount: AccountOption | null,
        activeTab: string,
        accountId: string | undefined
      ) => {
        (async () => {
          // For the failed tab we use the payments endpoint to populate the data which is handled above.
          // So we skip the logic below entirely.
          if (activeTab === "failed") {
            setIsLoading(false);
            return;
          }

          const from = fromDate
            ? startOfBankingDay(fromDate, { keepLocalTime: true }).format()
            : undefined;
          const to = toDate
            ? startOfBankingDay(toDate, { keepLocalTime: true }).add(1, "day").format()
            : undefined;
          const santizedSearchQuery = searchQuery.replaceAll(",", "");
          const [{ data, meta }, { data: pendingPayments }] = await Promise.all([
            unitApi.transactions.list({
              customerId: customerId!,
              include: "account",
              query: santizedSearchQuery || undefined,
              ...(from ? { since: from } : {}),
              ...(to ? { until: to } : {}),
              accountType: "deposit",
              limit: pagination.pageSize,
              offset: pagination.offset,
              ...(accountId !== undefined || (selectedAccount && selectedAccount.value !== "all")
                ? { accountId: accountId || selectedAccount!.value }
                : {}),
            }),
            unitApi.payments.list({
              status: ["Pending", "PendingReview", "Clearing"],
              ...(from ? { since: from } : {}),
              ...(to ? { until: to } : {}),
            }),
          ]);

          const paymentMetadataByGuid = await getPaymentMetadataByGuid(
            businessGuid,
            data,
            highbeamApi
          );

          const relatedAccountsByUnitId = new Map<string, BankAccountRep.Complete>(
            bankAccounts.map((account) => [account.unitCoDepositAccountId, account])
          );

          const paymentsById = new Map<string, Payment>(
            pendingPayments.map((payment) => [payment.id, payment])
          );

          const transactions = data.map((transaction) => {
            const paymentId = (transaction.relationships.payment as Relationship)?.data.id;
            const payment = paymentId ? paymentsById.get(paymentId) : undefined;
            return convertToHighbeamTransaction(
              transaction,
              relatedAccountsByUnitId,
              paymentMetadataByGuid[transaction.attributes.tags?.paymentMetadataGuid ?? ""],
              payment ?? undefined
            );
          });

          setTransactions(transactions);
          pagination.setTotalCount(meta.pagination.total);
          setAccountOptions(
            [ALL_ACCOUNTS_OPTION].concat(
              bankAccounts.map((account) => ({
                label: AccountLabel({ bankAccount: account }),
                value: account.unitCoDepositAccountId,
                balance: account.availableBalance,
              }))
            )
          );
          setIsLoading(false);
        })();
      },
      debounceMs
    ),
    []
  );

  const refreshTransactions = () => {
    setIsLoading(true);
    updateTransactions(
      searchQuery,
      fromDate,
      toDate,
      pagination,
      selectedAccount,
      activeTab,
      accountId
    );
  };

  // TODO: Move transaction data to Recoil.
  useEffect(() => {
    refreshTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchQuery,
    fromDate,
    toDate,
    pagination.currentPage,
    selectedAccount,
    activeTab,
    accountId,
  ]);

  return {
    transactions,
    isLoading,
    activeTab,
    setActiveTab,
    accountOptions,
    selectedAccount,
    setSelectedAccount,
    searchQuery,
    setSearchQuery,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    pagination,
    refreshTransactions,
  };
};
