import logo from "assets/highbeam-logo.svg";
import { SIGN_OUT, SIGN_OUT_ROUTE } from "modules/auth/constants";
import { useRecoilValue, useResetRecoilState } from "recoil";
import isAuthenticatedState from "state/auth/isAuthenticated";
import isSuperuseringState, { useIsSuperusering } from "state/auth/isSuperusering";

import styles from "./ErrorPageHeader.module.scss";

const ErrorPageHeader = () => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const isSuperusering = useIsSuperusering();
  const resetIsSuperusering = useResetRecoilState(isSuperuseringState);

  const refreshPage = () => window.location.reload();

  const onExitSuperuser = () => {
    resetIsSuperusering();
    refreshPage();
  };

  return (
    <header className={styles.header}>
      <img src={logo} className={styles.logo} alt="logo" />

      <div className={styles.headerLinks}>
        {isSuperusering && <button onClick={onExitSuperuser}>Exit superuser</button>}
        {isAuthenticated && <a href={SIGN_OUT_ROUTE}>{SIGN_OUT}</a>}
      </div>
    </header>
  );
};

export default ErrorPageHeader;
