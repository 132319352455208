import { FC } from "react";
import { Controller } from "react-hook-form";
import CapitalAccountLabel from "resources/capital-accounts/components/CapitalAccountLabel";
import { ActiveCapitalAccountWithChargeCard } from "resources/capital-accounts/hooks/useActiveCapitalAccountsWithChargeCard";
import { useCapitalAccountSummaryQuery } from "resources/capital-accounts/queries/useCapitalAccountSummary";
import getCapitalAccountAvailablePercentage from "resources/capital-accounts/utils/getCapitalAccountAvailablePercentage";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import BarChart from "ui/data-visualization/BarChart";
import Shimmer from "ui/feedback/ShimmerV2";
import Button from "ui/inputs/Button";
import DropdownV2 from "ui/inputs/DropdownV2";
import ModalV4 from "ui/overlay/ModalV4";
import Text from "ui/typography/Text";
import { FormSubmitHandlerProps } from "utils/react-hook-form/GetUseFormSubmitHandler";

import styles from "./CreateCardModalSelectCapitalAccountForm.module.scss";
import useCreateCardModalSelectCapitalAccountForm from "./useCreateCardModalSelectCapitalAccountForm";

type AvailableBalanceHelperProps = {
  capitalAccountGuid: string;
};

const AvailableBalanceHelper: FC<AvailableBalanceHelperProps> = ({ capitalAccountGuid }) => {
  const { data: capitalAccountSummary } = useCapitalAccountSummaryQuery(capitalAccountGuid);

  return (
    <div className={styles.barChartContainer}>
      {capitalAccountSummary ? (
        <BarChart size={10} backgroundVariant="striped">
          <BarChart.Bar
            color="green"
            widthPercentage={getCapitalAccountAvailablePercentage(capitalAccountSummary)}
          />
        </BarChart>
      ) : (
        <Shimmer className={styles.barChartShimmer} />
      )}

      <div className={styles.barChartLabel}>
        <Text color={colors.grey[900]} weight="medium" size={14}>
          Available
        </Text>

        <div className={styles.usageFractionContainer}>
          {capitalAccountSummary ? (
            <MoneyAmount
              cents={capitalAccountSummary.available}
              color={colors.grey[800]}
              size={14}
              weight="medium"
              withCents={capitalAccountSummary.available !== capitalAccountSummary.details.limit}
            />
          ) : (
            <Shimmer width={64} />
          )}

          <Text size={14} color={colors.grey[500]} weight="medium">
            {" / "}
          </Text>

          {capitalAccountSummary ? (
            <MoneyAmount
              weight="medium"
              cents={capitalAccountSummary.details.limit}
              color={colors.grey[500]}
              size={14}
              withCents={capitalAccountSummary.details.limit % 100 !== 0}
            />
          ) : (
            <Shimmer width={64} />
          )}
        </div>
      </div>
    </div>
  );
};

type Props = FormSubmitHandlerProps<typeof useCreateCardModalSelectCapitalAccountForm> & {
  onBack: () => void;
  activeCapitalAccountWithChargeCard: ActiveCapitalAccountWithChargeCard;
};

const CreateCardModalSelectCapitalAccountForm: FC<Props> = ({
  onValid,
  onInvalid,
  onBack,
  activeCapitalAccountWithChargeCard,
}) => {
  // NB(alex): We were thinking of allowing users to select between capital accounts if they have multiple capital accounts with the same credit terms.
  // Filtering by this is hard so for now we select the capital account in the previous step and only render that single option here. https://highbeamco.slack.com/archives/C03R625F068/p1728420007446949
  const filteredActiveCapitalAccountsWithChargeCard = [activeCapitalAccountWithChargeCard];

  const form = useCreateCardModalSelectCapitalAccountForm({
    defaultValues: {
      capitalAccount: filteredActiveCapitalAccountsWithChargeCard[0],
    },
  });

  return (
    <ModalV4.Form onSubmit={form.handleSubmit(onValid, onInvalid)}>
      <ModalV4.Body>
        <div className={styles.sectionHeader}>
          <Text color={colors.grey[900]} size={16} weight="bold">
            Capital account
          </Text>
          <Text color={colors.grey[700]} size={14} weight="regular">
            All spend on this card will be made from the associated account.
          </Text>
        </div>

        <Controller
          control={form.control}
          name="capitalAccount"
          render={({ field }) => {
            return (
              <div>
                <DropdownV2
                  label="Account"
                  options={filteredActiveCapitalAccountsWithChargeCard}
                  disabled={filteredActiveCapitalAccountsWithChargeCard.length === 1}
                  {...field}
                  renderOption={(optionProps) => {
                    return (
                      <DropdownV2.Option {...optionProps}>
                        <CapitalAccountLabel capitalAccount={optionProps.data} />
                      </DropdownV2.Option>
                    );
                  }}
                  components={{
                    // NB(alex): Should probably move this to `renderSingleValue`.
                    SingleValue: (singleValueProps) => {
                      return (
                        <DropdownV2.SingleValue {...singleValueProps}>
                          <CapitalAccountLabel
                            capitalAccount={singleValueProps.data}
                            className={styles.capitalAccountLabel}
                          />
                        </DropdownV2.SingleValue>
                      );
                    },
                  }}
                />

                <AvailableBalanceHelper capitalAccountGuid={field.value.guid} />
              </div>
            );
          }}
        />
      </ModalV4.Body>

      <ModalV4.Footer>
        <ModalV4.Footer.SubmitButton>Next</ModalV4.Footer.SubmitButton>
        <Button onClick={onBack} variant="ghost">
          Back
        </Button>
      </ModalV4.Footer>
    </ModalV4.Form>
  );
};

export default CreateCardModalSelectCapitalAccountForm;
