import Invoice from "components/Invoice";
import { FC } from "react";
import useCapitalDrawdownApproval from "resources/capital-drawdown-approvals/queries/useCapitalDrawdownApproval";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import Text from "ui/typography/Text";

import styles from "./PendingDrawdownInvoice.module.scss";

type Props = {
  capitalAccountGuid: string;
  pendingDrawdownGuid: string;
};

const PendingDrawdownInvoice: FC<Props> = ({ capitalAccountGuid, pendingDrawdownGuid }) => {
  const pendingDrawdown = useCapitalDrawdownApproval({
    capitalAccountGuid,
    pendingDrawdownGuid,
    required: true,
  });

  // NB(alex): We should move this logic outside of here, but the current flexpane pattern makes this difficult. We can fix this once we have a better pattern for using `useQuery` + flexpane shimmer states.
  if (!pendingDrawdown.note) {
    return null;
  }

  return (
    <>
      <Divider />

      <div className={styles.container}>
        <Text size={16} color={colors.grey[900]} weight="bold">
          Invoice
        </Text>
        <Invoice invoiceUrl={pendingDrawdown.attachmentUrl} />
      </div>
    </>
  );
};

export default PendingDrawdownInvoice;
