import highbeamCard from "assets/highbeam-card.svg";
import warningIcon from "assets/warning.svg";
import classNames from "classnames";
import useCard from "resources/cards/queries/useCard";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import { getCardName, getFormattedCardType } from "utils/cards";

import styles from "./SetPinModal.module.scss";
import { useSetPinModal } from "./utils";

type Props = {
  closeModal: () => void;
  cardId: string;
};

const SetPinModal: React.FC<Props> = ({ closeModal, cardId }) => {
  const card = useCard({ cardId, required: true });
  const cardName = getCardName(card);

  const { createPin, handleReset, isLoading, formError } = useSetPinModal(
    closeModal,
    cardName,
    cardId
  );
  const cardType = card ? getFormattedCardType(card) : "";

  return (
    <Modal
      title="Set PIN"
      showCancel
      buttonText="Set PIN"
      isLoading={isLoading}
      isPrimaryButtonDisabled={isLoading}
      isCancelButtonDisabled={isLoading}
      onClick={createPin}
      onClose={() => {
        handleReset();
        closeModal();
      }}
    >
      <div className={styles["set-pin"]}>
        <div className={styles["set-pin__card-info"]}>
          <img src={highbeamCard} alt="card" />
          <div>
            <Text weight="medium" size={14}>
              {cardName}
            </Text>
            <Text className={styles["set-pin__card-type"]} size={12}>
              {cardType} card
            </Text>
          </div>
        </div>
        <form className={styles["set-pin__form"]}>
          <div className={styles["set-pin__row"]}>
            <Text size={16} weight="bold">
              Set a 4 digit PIN code for this card.
            </Text>
            <Text className={styles["set-pin__description"]} size={14}>
              A PIN code is a quick and easy way to identify that it’s you using the card. You may
              get prompted to enter your PIN while using ATMs or during purchases.
            </Text>
            <Text className={styles["set-pin__label"]} weight="bold" size={14}>
              PIN code
            </Text>
            <div
              id="cc-pin"
              className={classNames({
                [styles["set-pin__vgs-input"]]: true,
                [styles["set-pin__vgs-input-error"]]: formError !== undefined,
              })}
            />
          </div>
        </form>
        {formError && (
          <div className={styles["set-pin__warning"]}>
            <img src={warningIcon} alt="warning" />
            <Text size={14}>{formError}</Text>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SetPinModal;
