import { CheckSquare, FlagCheckered } from "@phosphor-icons/react";
import chase from "assets/bank-logos/chase.png";
import highbeamAndPlaidLogos from "assets/highbeam-and-plaid-logos.webp";
import classNames from "classnames";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import usePlaidLinkToken from "resources/plaid-connections/queries/usePlaidLinkToken";
import Button from "ui/inputs/Button";
import useModalContext from "ui/overlay/ModalV4/context/useModalContext";
import Text from "ui/typography/Text";
import useHighbeamPlaidLink, {
  HighbeamPlaidLinkOnSuccess,
} from "utils/customHooks/useHighbeamPlaidLink";

import styles from "./PlaidLinkModalContents.module.scss";

type Props = {
  existingConnection?: PlaidConnectionRep.Complete;
  onLinkSuccess?: HighbeamPlaidLinkOnSuccess;
};

const PlaidLinkContinueButton: React.FC<Props> = ({ existingConnection, onLinkSuccess }) => {
  const linkToken = usePlaidLinkToken(existingConnection?.guid);
  const { onClose } = useModalContext();
  const { openPlaid, isPlaidReady } = useHighbeamPlaidLink({
    linkToken: linkToken ?? null,
    onSuccess: (...args) => {
      onClose();
      onLinkSuccess?.(...args);
    },
    onError: onClose,
    existingConnectionBeingUpdated: existingConnection,
  });

  return (
    <Button
      type="submit"
      variant="tertiary"
      isLoading={!linkToken || !isPlaidReady}
      className={styles.button}
      onClick={openPlaid}
      fullWidth
    >
      Continue
    </Button>
  );
};

function shouldShowChaseInfo(existingConnection?: PlaidConnectionRep.Complete): boolean {
  return !existingConnection || existingConnection.institutionId === "ins_56";
}

const PlaidLinkModalContents: React.FC<Props> = ({ existingConnection, onLinkSuccess }) => (
  <div className={styles.container}>
    <img
      src={highbeamAndPlaidLogos}
      className={styles.highbeamAndPlaidLogos}
      alt="Highbeam and Plaid logos"
    />
    <Text size={16} align="center">
      Please read these instructions before connecting your account with Plaid.
    </Text>
    <div className={styles.row}>
      <div className={styles.rowIcon}>
        <CheckSquare size={20} className={styles.rowIcon} />
      </div>
      <div>
        <Text size={16} weight="medium">
          Selecting accounts
        </Text>
        {existingConnection ? (
          <Text size={14}>
            Make sure to select any previously connected accounts and cards to avoid disconnecting
            them.
          </Text>
        ) : (
          <Text size={14}>
            Make sure to select all bank accounts and cards that you want to connect.
          </Text>
        )}
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.rowIcon}>
        <FlagCheckered size={20} />
      </div>
      <div>
        <Text size={16} weight="medium">
          Finish the flow
        </Text>
        {existingConnection ? (
          <Text size={14}>
            Please complete the Plaid connection steps through to the end, otherwise the connection
            will disconnect.
          </Text>
        ) : (
          <Text size={14}>
            Please complete the Plaid connection steps through to the end, otherwise the connection
            will not succeed.
          </Text>
        )}
      </div>
    </div>
    {shouldShowChaseInfo(existingConnection) && (
      <div className={classNames(styles.row, styles.chase)}>
        <div className={styles.rowIcon}>
          <img src={chase} alt="Chase logo" />
        </div>
        <div>
          <Text size={16} weight="medium">
            Connecting a Chase account?
          </Text>
          <Text size={14}>
            Make sure you are the person who first created the account, or the connection will not
            succeed.
          </Text>
        </div>
      </div>
    )}

    <PlaidLinkContinueButton
      existingConnection={existingConnection}
      onLinkSuccess={onLinkSuccess}
    />
  </div>
);

export default PlaidLinkModalContents;
