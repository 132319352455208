import { useQuery } from "@tanstack/react-query";
import env from "env";
import useCanSuperuser from "modules/superuser/hooks/useCanSuperuser";
import { useBusinessQueryOptions } from "resources/business/queries/useBusiness";
import useBusinessesQueryOptions from "resources/businesses/queries/useBusinessesQueryOptions";
import useJwt from "resources/jwt/queries/useJwt";
import { useIsSuperusering } from "state/auth/isSuperusering";
import { isNotNull } from "utils/array";

import { SuperuserCommandPaletteOption } from "./types";

export const useSuperuserCommandPaletteOptions = () => {
  const canSuperuser = useCanSuperuser();
  const isSuperusering = useIsSuperusering();

  const { data: businesses = [] } = useQuery({
    ...useBusinessesQueryOptions(),
    enabled: canSuperuser,
  });

  const { data: business } = useQuery({
    ...useBusinessQueryOptions(),
    enabled: canSuperuser,
  });

  const jwtToken = useJwt({ loginRequired: true });

  if (!canSuperuser) {
    return [];
  }

  const superuserBusinessCommandPaletteOptions = businesses.map((business) => {
    const businessName = business.dba ? `${business.name} (${business.dba})` : business.name;

    return {
      category: "superuser",
      data: business,
      label: `Superuser: ${businessName}`,
      value: {
        commandName: "superuser",
        payload: {
          businessGuid: business.guid,
        },
      },
    } satisfies SuperuserCommandPaletteOption;
  });

  const otherSuperuserCommandPaletteOptions = (
    [
      // NB(alex): Do we still need `Refresh list`?

      isSuperusering
        ? {
            category: "superuser",
            isSuggested: true,
            label: "Exit superuser",
            value: {
              commandName: "superuser",
              payload: {
                businessGuid: null,
              },
            },
          }
        : null,

      canSuperuser && jwtToken
        ? {
            category: "superuser",
            isSuggested: true,
            label: "Copy JWT",
            value: {
              commandName: "copy-text",
              payload: {
                text: jwtToken.rawToken,
                showText: false,
              },
            },
          }
        : null,

      business
        ? {
            category: "superuser",
            isSuggested: true,
            label: `Copy business guid: ${business.guid}`,
            value: {
              commandName: "copy-text",
              payload: {
                text: business.guid,
                showText: true,
              },
            },
          }
        : null,

      business
        ? {
            category: "superuser",
            isSuggested: true,
            label: `Open superblocks: ${business.guid}`,
            value: {
              commandName: "navigate",
              payload: {
                to: `${env.SUPERBLOCKS_BUSINESS_LOOKUP_APP_LINK}?businessGuid=${business.guid}`,
                openInNewTab: true,
              },
            },
          }
        : null,

      business
        ? {
            category: "superuser",
            isSuggested: true,
            label: `Open unit: ${business.unitCoCustomerId}`,
            value: {
              commandName: "navigate",
              payload: {
                to: `${env.UNIT_APP_LINK}/customers/${business.unitCoCustomerId}`,
                openInNewTab: true,
              },
            },
          }
        : null,
    ] satisfies (SuperuserCommandPaletteOption | null)[]
  ).filter(isNotNull);

  return [...superuserBusinessCommandPaletteOptions, ...otherSuperuserCommandPaletteOptions];
};
