import { ArrowRight, Buildings } from "@phosphor-icons/react";
import React from "react";
import BusinessRep from "reps/BusinessRep";
import useUserGuid from "resources/jwt/queries/useUserGuid";
import useUserRoleMembershipDisplayName from "resources/user-roles/queries/useUserRoleMembershipDisplayName";
import colors from "styles/colors";
import Pill from "ui/data-display/Pill";
import Text from "ui/typography/Text";
import getBusinessSafeDisplayName from "utils/business/getBusinessSafeDisplayName";

import styles from "./BusinessDisambiguatorBusiness.module.scss";

type Props = {
  business: BusinessRep.Complete;
  onSelect: () => void;
};

const BusinessDisambiguatorBusinesses: React.FC<Props> = ({ business, onSelect: handleSelect }) => {
  const userRoleMembershipDisplayName = useUserRoleMembershipDisplayName(
    business.guid,
    useUserGuid()
  );

  return (
    <div role="button" tabIndex={0} className={styles.business} onClick={handleSelect}>
      <Buildings size={48} color={colors.purple[500]} className={styles.icon} />
      <div className={styles.name}>
        <Text size={16}>{getBusinessSafeDisplayName(business)}</Text>
        <Pill size={20} color="grey-light">
          {userRoleMembershipDisplayName}
        </Pill>
      </div>
      <ArrowRight size={24} color={colors.purple[500]} className={styles.arrow} />
    </div>
  );
};

export default BusinessDisambiguatorBusinesses;
