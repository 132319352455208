import { useReducer } from "react";

import { CreateCardModalCardDetailsFormOutputs } from "./CreateCardModalCardDetailsForm/useCreateCardModalCardDetailsForm";
import { CreateCardModalSelectCapitalAccountFormOutputs } from "./CreateCardModalSelectCapitalAccountForm/useCreateCardModalSelectCapitalAccountForm";
import { CreateCardModalSelectCardTypeFormOutputs } from "./CreateCardModalSelectCardTypeForm/useCreateCardModalSelectCardTypeForm";
import { CreateCardModalShippingAddressFormOutputs } from "./CreateCardModalShippingAddressForm/useCreateCardModalShippingAddressForm";

type FormName = "select-card-type" | "select-capital-account" | "card-details" | "shipping-address";

type StepName = FormName | "card-created-successfully";

export type State = {
  activeStep: StepName;
  formData: {
    "select-card-type": CreateCardModalSelectCardTypeFormOutputs | null;
    "select-capital-account": CreateCardModalSelectCapitalAccountFormOutputs | null;
    "card-details": CreateCardModalCardDetailsFormOutputs | null;
    "shipping-address": CreateCardModalShippingAddressFormOutputs | null;
  };
};

const initialState: State = {
  activeStep: "select-card-type",
  formData: {
    "select-card-type": null,
    "select-capital-account": null,
    "card-details": null,
    "shipping-address": null,
  },
};

type Action =
  | {
      type: "set-active-step";
      payload: StepName;
    }
  | {
      type: "set-form-values";
      payload: {
        formName: FormName;
        formData: State["formData"][FormName];
      };
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "set-active-step":
      return { ...state, activeStep: action.payload };
    case "set-form-values":
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.formName]: action.payload.formData,
        },
      };
  }
};

type Methods = {
  setActiveStep: (activeStep: StepName) => void;
  setFormValues: (formName: FormName, formData: State["formData"][FormName]) => void;
};

const useCreateCardModalStore = (): { state: State; methods: Methods } => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setActiveStep = (activeStep: StepName) => {
    dispatch({ type: "set-active-step", payload: activeStep });
  };

  const setFormValues = (formName: FormName, formData: State["formData"][FormName]) => {
    dispatch({ type: "set-form-values", payload: { formName, formData } });
  };

  return {
    state,
    methods: {
      setActiveStep,
      setFormValues,
    },
  };
};

export default useCreateCardModalStore;
