import { useNavigate } from "react-router-dom";
import Banner from "ui/data-display/Banner";
import InfoIcon from "ui/icons/InfoIcon";
import Button from "ui/inputs/Button";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";

import styles from "./ConnectPlaidBanner.module.scss";

type Props = {
  onDismiss: () => void;
};

const ConnectPlaidBanner: React.FC<Props> = ({ onDismiss }) => {
  const navigate = useNavigate();
  const redirectToSettingsBanksAndCards = () => {
    navigate("/settings/banks-and-cards");
  };
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();

  if (!isAllowedToConnectBankAccounts) {
    return null;
  }

  return (
    <Banner
      padding="compact"
      color="white"
      icon={<InfoIcon variant="info" />}
      title="Connect other accounts"
      body="Connect your other bank accounts for easy transfers between them and Highbeam."
      footer={
        <Button variant="primary" size="sm" onClick={redirectToSettingsBanksAndCards}>
          Connect an account
        </Button>
      }
      className={styles.banner}
      onDismiss={onDismiss}
    />
  );
};

export default ConnectPlaidBanner;
