import { List } from "@phosphor-icons/react";
import logo from "assets/highbeam-logo.svg";
import classNames from "classnames";
import AddToHomeScreenBanner from "modules/add-to-home-screen/AddToHomeScreenBanner";
import useShouldShowAddToHomeScreenBanner from "modules/add-to-home-screen/AddToHomeScreenBanner/hooks/useShouldShowAddToHomeScreenBanner";
import React from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import isSidebarOpenState from "state/sidebar";
import colors from "styles/colors";
import { useIsTablet } from "utils/device/useMediaQuery";

import styles from "./DashboardHeader.module.scss";
import DashboardUserMenu from "./DashboardUserMenu/DashboardUserMenu";

const DashboardHeader: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isTablet = useIsTablet();
  const setIsSidebarOpen = useSetRecoilState(isSidebarOpenState);
  const handleOpenSidebar = () => setIsSidebarOpen(true);
  const shouldShowAddToHomeScreenBanner = useShouldShowAddToHomeScreenBanner();

  return (
    <>
      <header
        className={classNames(styles.header, {
          [styles["header--no-breadcrumbs"]]: !children,
        })}
      >
        {!isTablet && children}
        <Link to="/" className={styles.logoContainer}>
          <img src={logo} className={styles.logo} alt="logo" />
        </Link>

        <div className={styles.profileInfo}>
          <DashboardUserMenu />
          {isTablet && (
            <div onClick={handleOpenSidebar} className={styles.hamburgerMenu}>
              <List size={24} color={colors.grey[600]} />
            </div>
          )}
        </div>
      </header>

      {shouldShowAddToHomeScreenBanner && <AddToHomeScreenBanner />}

      {isTablet && children ? <div className={styles.breadcrumbsTablet}>{children}</div> : null}
    </>
  );
};

export default DashboardHeader;
