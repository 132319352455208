import React from "react";
import { useSetRecoilState } from "recoil";
import BusinessRep from "reps/BusinessRep";
import businessGuidState from "state/auth/businessGuid";

import BusinessDisambiguatorBusiness from "./BusinessDisambiguatorBusiness";

type Props = {
  businesses: BusinessRep.Complete[];
};

const BusinessDisambiguatorBusinesses: React.FC<Props> = ({ businesses }) => {
  const setBusinessGuid = useSetRecoilState(businessGuidState);

  const selectBusiness = (businessGuid: string) => {
    setBusinessGuid(businessGuid);
    window.location.reload(); // Intended to force a refresh.
  };

  return (
    <>
      {businesses.map((business) => {
        return (
          <BusinessDisambiguatorBusiness
            key={business.guid}
            business={business}
            onSelect={() => selectBusiness(business.guid)}
          />
        );
      })}
    </>
  );
};

export default BusinessDisambiguatorBusinesses;
