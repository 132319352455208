import CapitalAccountControlsRep from "./CapitalAccountControlsRep";
import CapitalAccountDetailsRep from "./CapitalAccountDetailsRep";

namespace CapitalAccountRep {
  export enum State {
    Active = "Active",
    Terminated = "Terminated",
    Offered = "Offered",
    OfferAccepted = "OfferAccepted",
  }

  export enum Type {
    CashAccessOnly = "CashAccessOnly",
    ChargeCardAndCashAccess = "ChargeCardAndCashAccess",
    ChargeCardOnly = "ChargeCardOnly",
  }

  export enum CapitalLender {
    Highbeam = "Highbeam",
    Ember = "Ember",
    Sandbox = "Sandbox",
  }

  export interface Update {
    readonly name?: string;
  }

  interface BaseComplete {
    readonly guid: string;
    readonly type: Type;
    readonly name: string;
    readonly businessGuid: string;
    readonly details: CapitalAccountDetailsRep.Complete;
    readonly state: State;
    readonly lender: CapitalLender;
    readonly controls: CapitalAccountControlsRep.Complete;
    readonly activatedAt: string | null; // ZonedDateTime
  }

  export interface CashAccessOnlyComplete extends BaseComplete {
    readonly type: Type.CashAccessOnly;
  }

  export interface ChargeCardAndCashAccessComplete extends BaseComplete {
    readonly type: Type.ChargeCardAndCashAccess;
  }

  export interface ChargeCardOnlyComplete extends BaseComplete {
    readonly type: Type.ChargeCardOnly;
  }

  export type Complete =
    | CashAccessOnlyComplete
    | ChargeCardAndCashAccessComplete
    | ChargeCardOnlyComplete;
}

export default CapitalAccountRep;
