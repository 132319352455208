import { useEffect, useState } from "react";
import UserExistenceRep from "reps/UserExistenceRep";
import UserInvitationRep from "reps/UserInvitationRep";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

export function useInvitation(invitationSlug: string | undefined) {
  const highbeamApi = useHighbeamApi({ authMode: "unauthenticated" });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [invitation, setInvitation] = useState<[UserInvitationRep.Complete, UserExistenceRep]>();

  useEffect(() => {
    (async () => {
      if (!invitationSlug) return;
      try {
        const invitation = await highbeamApi.userInvitation.getBySlug(invitationSlug);
        setInvitation(
          invitation
            ? [
                invitation,
                await highbeamApi.userExistence.getByEmailAddress(invitation.emailAddress),
              ]
            : undefined
        );
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [highbeamApi, invitationSlug]);
  return {
    isLoading,
    error,
    invitation,
  };
}
