import CardIcon from "resources/cards/components/CardIcon";
import useCard from "resources/cards/queries/useCard";
import { getCardLabelByUnitCoCard } from "resources/cards/utils/get-card-label";
import colors from "styles/colors";
import Text from "ui/typography/Text";
import { maskAccountNumber } from "utils/account";
import { getCardName, getCardType } from "utils/cards";

import styles from "./CardInfo.module.scss";

type CardInfoProps = {
  cardId: string;
  showIcon?: boolean;
  showLabel?: boolean;
};

const CardInfo = ({ cardId, showIcon = true, showLabel = true }: CardInfoProps) => {
  const card = useCard({ cardId, required: true });
  const last4digits = card?.attributes.last4Digits;
  const cardLabel = getCardLabelByUnitCoCard({ card, capitalize: true });

  return (
    <div className={styles.cardCellContainer}>
      {showIcon && (
        <div className={styles.iconContainer}>
          <CardIcon cardType={getCardType(card)} size="sm" />
        </div>
      )}
      <div>
        <Text size={14} weight="medium" color={colors.grey[800]} className={styles.cardLabel}>
          {`${getCardName(card)} ${last4digits && maskAccountNumber(last4digits)}`}
        </Text>
        {showLabel && (
          <Text size={12} weight="medium" color={colors.grey[400]} className={styles.cardLabel}>
            {cardLabel}
          </Text>
        )}
      </div>
    </div>
  );
};

export default CardInfo;
