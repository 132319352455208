import { Account, DepositAccount } from "@highbeam/unit-node-sdk";
import HighbeamApi from "api/HighbeamApi";
import BankAccountRep from "reps/BankAccountRep";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import PlaidConnectionRep from "reps/PlaidConnectionRep";
import notEmpty from "utils/notEmpty";
import { isDigitsOnly, removeTextFromEndAndTrim, toTitleCase } from "utils/string";
import { AccountTags } from "utils/types/transactionsTypes";

export function getAccountDisplayName(account: BankAccountRep.Complete) {
  if (!account.highbeamType.showDetailsToUser) return account.name;

  const defaultDisplay = `${account.name} •• ${account.accountNumber.slice(-4)}`;
  if (account.status === BankAccountRep.Status.CLOSED) return defaultDisplay + " (Closed)";
  if (account.status === BankAccountRep.Status.FROZEN) return defaultDisplay + " (Frozen)";

  return defaultDisplay;
}

export const getAccountHeaderName = (bankAccountRep: BankAccountRep.Complete) => {
  const name = bankAccountRep.name;
  if (bankAccountRep.status === BankAccountRep.Status.FROZEN) return name + " (Frozen)";
  return name;
};

const getPlaidAccountDisplayNameWithoutMask = (account: PlaidBankAccountRep.Complete) => {
  const accountMask = account.accountMask || "";

  const originalAccountName = account.accountName;
  const withoutBankMask = removeTextFromEndAndTrim(originalAccountName, accountMask);
  const withoutLongMask = removeOptionalDigitFromEnd(withoutBankMask);
  const withoutDash = removeTextFromEndAndTrim(withoutLongMask, "-");
  const withoutDots = removeTextFromEndAndTrim(withoutDash, "...");

  return toTitleCase(withoutDots);
};

const removeOptionalDigitFromEnd = (str: string): string => {
  const lastChar = str.charAt(str.length - 1);
  if (isDigitsOnly(lastChar)) {
    return str.slice(0, -1);
  }
  return str;
};

export function getPlaidBankAccountDisplayName(account: PlaidBankAccountRep.Complete) {
  return `${getPlaidAccountDisplayNameWithoutMask(account)} •• ${account.accountMask}`;
}

export function getPartnerBankNameForAccount(
  account: BankAccountRep.Complete | undefined
): BankAccountRep.PartnerBank {
  if (account?.threadAccount) {
    return BankAccountRep.PartnerBank.THREAD;
  }
  return BankAccountRep.PartnerBank.BLUE_RIDGE;
}

export async function getHighbeamAccountsForUnitAccounts(
  highbeamApi: HighbeamApi,
  unitAccounts: Account[]
) {
  return await Promise.all(
    unitAccounts?.map((unitAccount) => {
      const tags = (unitAccount as DepositAccount).attributes.tags as AccountTags;
      if (!tags?.bankAccountGuid) {
        return null;
      }

      return highbeamApi.bankAccount.get(tags.bankAccountGuid!);
    }) ?? []
  ).then((accounts) => accounts.filter(notEmpty));
}

export function getAccountType(account: BankAccountRep.Complete): string {
  switch (account.highbeamType.name) {
    case "DepositAccount":
      return "Business checking";
    case "HighYield":
      return "High yield";
    case "LineOfCredit":
      return "Line of credit";
  }
}

export const getAccountPartnerBank = (account: BankAccountRep.Complete): string => {
  if (account.threadAccount) {
    return "Thread Bank";
  } else {
    return "Blue Ridge Bank";
  }
};

export const getPartnerBankAddress = (account: BankAccountRep.Complete): string => {
  if (account.threadAccount) {
    return "210 E Main St";
  } else {
    return "17 W Main St";
  }
};

export const getPartnerBankAddressCityState = (account: BankAccountRep.Complete): string => {
  if (account.threadAccount) {
    return "Rogersville, TN 37857";
  } else {
    return "Luray, VA 22835";
  }
};

export function sortAccounts(a: BankAccountRep.Complete, b: BankAccountRep.Complete) {
  // Order by Thread accounts and then Blue Ridge accounts
  if (a.blueRidgeAccount && !b.blueRidgeAccount) return 1;
  if (!a.blueRidgeAccount && b.blueRidgeAccount) return -1;

  // Force primary to the top
  if (isPseudoPrimary(a) && !isPseudoPrimary(b)) return -1;
  if (!isPseudoPrimary(a) && isPseudoPrimary(b)) return 1;

  // Force High Yield account to the bottom
  if (a.highbeamType.name === "HighYield" && b.highbeamType.name !== "HighYield") return 1;
  if (a.highbeamType.name !== "HighYield" && b.highbeamType.name === "HighYield") return -1;

  return a.name.localeCompare(b.name);
}

// TODO: We currently only allow 1 primary account. But we need to support one on
// each of our partner banks.
function isPseudoPrimary(account: BankAccountRep.Complete): boolean {
  return account.isPrimary || /primary/i.test(account.name);
}

export function sortPlaidAccounts(
  a: PlaidBankAccountRep.Complete,
  b: PlaidBankAccountRep.Complete
) {
  return (
    Number(b.isActive) - Number(a.isActive) ||
    Number(b.isConnectionActive) - Number(a.isConnectionActive) ||
    b.connectionGuid.localeCompare(a.connectionGuid)
  );
}

export function sortPlaidConnections(
  a: PlaidConnectionRep.Complete,
  b: PlaidConnectionRep.Complete
) {
  return (
    Number(b.isActive) - Number(a.isActive) || a.institutionName.localeCompare(b.institutionName)
  );
}

export const maskAccountNumber = (accountNumber: string): string => {
  return `•• ${accountNumber.slice(-4)}`;
};

export const maskBankAccountAccountNumber = (
  bankAccount: BankAccountRep.Complete
): BankAccountRep.Complete => {
  return {
    ...bankAccount,
    accountNumber: maskAccountNumber(bankAccount.accountNumber),
  };
};
