import logo from "assets/highbeam-logo.svg";
import classNames from "classnames";
import { SIGN_OUT, SIGN_OUT_ROUTE } from "modules/auth/constants";
import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";

import styles from "./FullScreen.module.scss";

type Props = {
  className?: string;
  style?: CSSProperties;
} & React.PropsWithChildren;

const FullScreenLayout: React.FC<Props> = ({ className, style, children }) => {
  return (
    <div className={classNames(styles.container, className)} style={style}>
      <header className={styles.header}>
        <img src={logo} className={styles.logo} alt="logo" />
        <Link className={styles.signOut} to={SIGN_OUT_ROUTE}>
          {SIGN_OUT}
        </Link>
      </header>
      <div className={styles.body}>{children}</div>
    </div>
  );
};
export default FullScreenLayout;
