import { ComponentProps, FC } from "react";
import CardRep from "reps/CardRep";

import physicalCreditIcon from "./assets/physical-credit.svg";
import physicalDebitIcon from "./assets/physical-debit.svg";
import virtualCreditIcon from "./assets/virtual-credit.svg";
import virtualDebitIcon from "./assets/virtual-debit.svg";
import styles from "./CardIcon.module.scss";

const cardTypeToIcon: { [key in CardRep.CardType]: string } = {
  VIRTUAL: virtualDebitIcon,
  VIRTUAL_CREDIT: virtualCreditIcon,
  PHYSICAL: physicalDebitIcon,
  PHYSICAL_CREDIT: physicalCreditIcon,
};

export type CardIconSize = "md" | "sm";

type Props = Omit<ComponentProps<"img">, "src" | "alt"> & {
  cardType: CardRep.CardType;
  size?: CardIconSize;
};

const CardIcon: FC<Props> = ({ cardType, size = "md", ...imgProps }) => {
  return (
    <img
      src={cardTypeToIcon[cardType]}
      alt={cardType}
      {...imgProps}
      className={styles[`size--${size}`]}
    />
  );
};

export default CardIcon;
