import useRefreshQuery from "utils/react-query/useRefreshQuery";

export const BASE_CAPITAL_ACCOUNTS_QUERY_KEY = "capital-accounts";

const useRefreshCapitalAccountsQueries = () => {
  const refreshCapitalAccountsQueries = useRefreshQuery([BASE_CAPITAL_ACCOUNTS_QUERY_KEY]);
  return refreshCapitalAccountsQueries;
};

export default useRefreshCapitalAccountsQueries;
