import DashboardPage from "components/layouts/DashboardPage";
import { DISABLE_SCROLL_TO_TOP_STATE } from "components/navigation/ScrollToTopOnNavigate";
import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import CapitalAvailableSection from "../../components/CapitalAvailableSection";
import CapitalAvailableHighlightItems from "../../components/CapitalAvailableSection/CapitalAvailableHighlightItems";
import CapitalAccountBanners from "../../sections/CapitalAccountBanners";
import CapitalAccountCreditTransactionsSection from "../../sections/CapitalAccountCreditTransactionsSection";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";
import CapitalAccountPendingDrawdownsSection from "../../sections/CapitalAccountPendingDrawdownsSection";
import PendingDrawdownFlexpane from "../../sections/CapitalAccountPendingDrawdownsSection/PendingDrawdownFlexpane";

import LineOfCreditDetailsSection from "./LineOfCreditDetailsSection";
import styles from "./LineOfCreditView.module.scss";

type Props = {
  capitalAccountSummaryWithCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const LineOfCreditView: FC<Props> = ({ capitalAccountSummaryWithCashAccessOnly }) => {
  const navigate = useNavigate();
  const { pendingDrawdownGuid } = useParams();
  return (
    <>
      <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => {
          // NB(alex): Necessary for resetting the error boundary after navigation takes place.
          resetErrorBoundary();

          if (error instanceof RequiredButNotFoundError) {
            return (
              <Navigate
                to={`/capital/${capitalAccountSummaryWithCashAccessOnly.guid}`}
                state={DISABLE_SCROLL_TO_TOP_STATE}
              />
            );
          }

          throw error;
        }}
      >
        <PendingDrawdownFlexpane
          capitalAccountGuid={capitalAccountSummaryWithCashAccessOnly.guid}
          pendingDrawdownGuid={pendingDrawdownGuid}
          onClose={() => {
            navigate(`/capital/${capitalAccountSummaryWithCashAccessOnly.guid}`, {
              state: DISABLE_SCROLL_TO_TOP_STATE,
            });
          }}
        />
      </ErrorBoundary>

      <CapitalAccountBanners capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly} />

      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly} />

      <DashboardPage.Section className={styles.section}>
        <div className={styles.sectionHalf}>
          <CapitalAvailableSection
            showAvailableOutstandingBalance
            capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly}
          />
          <CapitalAvailableHighlightItems
            capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly}
          />
        </div>

        <div className={styles.sectionHalf}>
          <LineOfCreditDetailsSection
            capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly}
          />
        </div>
      </DashboardPage.Section>

      <CapitalAccountPendingDrawdownsSection
        capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly}
      />

      <CapitalAccountCreditTransactionsSection
        capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly}
      />
    </>
  );
};

export default LineOfCreditView;
