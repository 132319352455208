import { FC, ReactNode, createContext } from "react";

type PendingDrawdownFlexpaneContextState = {
  pendingDrawdownGuid: string;
  close: () => void;
};

const PendingDrawdownFlexpaneContext = createContext<PendingDrawdownFlexpaneContextState>(
  {} as PendingDrawdownFlexpaneContextState
);

type Props = {
  children: ReactNode;
  pendingDrawdownGuid: string;
  close: () => void;
};

const PendingDrawdownFlexpaneProvider: FC<Props> = ({ children, pendingDrawdownGuid, close }) => {
  return (
    <PendingDrawdownFlexpaneContext.Provider
      value={{
        pendingDrawdownGuid,
        close: close,
      }}
    >
      {children}
    </PendingDrawdownFlexpaneContext.Provider>
  );
};

export default PendingDrawdownFlexpaneProvider;
