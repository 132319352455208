import { FC } from "react";
import useBillLineItems from "resources/bill-line-items/queries/useBillLineItems";
import CountBadge from "ui/data-display/CountBadge";

type Props = {
  billId: string;
};

const BillLineItemsCountBadge: FC<Props> = ({ billId }) => {
  const billLineItems = useBillLineItems(billId);

  return <CountBadge count={billLineItems.length} color="grey-200" />;
};

export default BillLineItemsCountBadge;
