import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { useCapitalDrawdownApprovalsQuery } from "resources/capital-drawdown-approvals/queries/useCapitalDrawdownApprovals";

import PendingDrawdownsTable from "./PendingDrawdownsTable";

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountPendingDrawdownsSection: FC<Props> = ({ capitalAccountSummary }) => {
  const { data: pendingDrawdowns = [] } = useCapitalDrawdownApprovalsQuery({
    capitalAccountGuid: capitalAccountSummary.guid,
  });

  if (pendingDrawdowns.length === 0) {
    return null;
  }

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header>
        <DashboardPage.Section.HeaderHeading>Pending drawdowns</DashboardPage.Section.HeaderHeading>
      </DashboardPage.Section.Header>

      <PendingDrawdownsTable capitalAccountSummary={capitalAccountSummary} />
    </DashboardPage.Section>
  );
};

export default CapitalAccountPendingDrawdownsSection;
