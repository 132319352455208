import { ArrowRight, Plus } from "@phosphor-icons/react";
import AccountLabel from "components/Accounts/AccountLabel";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import useMfa from "modules/mfa/useMfa";
import ActivateThreadBanner from "modules/migrate-to-thread/ActivateThreadBanner";
import useShouldShowActivateThreadBanner from "modules/migrate-to-thread/ActivateThreadBanner/hooks/useShouldShowActivateThreadBanner";
import CloseBankAccountModal from "pages/accounts/CloseBankAccountModal";
import EditBankAccountModal from "pages/accounts/EditAccountNameModal";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import Button from "ui/inputs/Button";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import Tabs, { Tab } from "ui/navigation/Tabs";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import Table, { TableColumnAlignment } from "ui/table/Table";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import { getDollarsFromCents } from "utils/money";
import useIsAllowedToCreateBankAccounts from "utils/permissions/useIsAllowedToCreateBankAccounts";
import { formatAmount } from "utils/string";

import CreateAccountModal from "./CreateAccountModal";
import { AccountsTabOption, useActiveTabSearchParam } from "./hooks/useAccountsSearchParams";
import styles from "./ManageAccountsPage.module.scss";

const MAX_BANK_ACCOUNTS_ALLOWED = 10;

const tabs: Tab<AccountsTabOption>[] = [
  { id: "Active", label: "Active accounts" },
  { id: "Closed", label: "Closed accounts" },
];

const CreateNewAccountButton = () => {
  const { mfa } = useMfa();
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState(false);
  const shouldShowActivateThreadBanner = useShouldShowActivateThreadBanner();
  const activeBankAccounts = useBankAccounts({ status: "open" });

  const isPreventDacaDepositAccountCreationEnabled = Boolean(
    useFeatureFlag("PREVENT_DACA_DEPOSIT_ACCOUNT_CREATION")
  );
  const isPreventAllDepositAccountCreationEnabled = Boolean(
    useFeatureFlag("PREVENT_ALL_DEPOSIT_ACCOUNT_CREATION")
  );
  const cannotCreateMoreBankAccounts =
    isPreventDacaDepositAccountCreationEnabled ||
    isPreventAllDepositAccountCreationEnabled ||
    activeBankAccounts.length >= MAX_BANK_ACCOUNTS_ALLOWED;

  return (
    <>
      {isCreateAccountModalOpen && (
        <CreateAccountModal onClose={() => setIsCreateAccountModalOpen(false)} />
      )}

      <Button
        variant="secondary"
        tooltip={
          cannotCreateMoreBankAccounts && (
            <Tooltip.Content className={styles.tooltipContent}>
              Please reach out to Highbeam support to create additional accounts.
            </Tooltip.Content>
          )
        }
        disabled={shouldShowActivateThreadBanner || cannotCreateMoreBankAccounts}
        onClick={() => mfa().then(() => setIsCreateAccountModalOpen(true))}
      >
        <Plus weight="bold" />
        Create new account
      </Button>
    </>
  );
};

const AccountTableColumns = [
  {
    key: "account",
    title: "Account",
    cellRender: (account: BankAccountRep.Complete) => (
      <AccountLabel bankAccount={account} showConnectedStores />
    ),
  },
  {
    align: TableColumnAlignment.RIGHT,
    key: "balance",
    title: "Balance",
    cellRender: (datum: BankAccountRep.Complete) => (
      <Text size={14}>{formatAmount(getDollarsFromCents(datum.availableBalance))}</Text>
    ),
  },
  {
    align: TableColumnAlignment.RIGHT,
    key: "account-details",
    title: "Account details",
    cellRender: () => (
      <div className={styles.accountDetails}>
        <Text size={14} align="right">
          View account details
        </Text>
        <ArrowRight size={16} />
      </div>
    ),
  },
];

const ManageAccountsTable = () => {
  const navigate = useNavigate();
  const activeBankAccounts = useBankAccounts({ status: "open" });
  const closedBankAccounts = useBankAccounts({ status: "closed" });
  const [activeTab, setActiveTab] = useActiveTabSearchParam();
  const bankAccountsToShow = activeTab === "Active" ? activeBankAccounts : closedBankAccounts;

  return (
    <>
      {closedBankAccounts.length > 0 && (
        <Tabs
          className={styles.tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
          variant="rounded"
          noBorder
        />
      )}

      <Table
        rowKey={(datum: BankAccountRep.Complete) => datum.accountNumber}
        onRowClick={(datum) => navigate(`/accounts/${datum.guid}`)}
        columns={AccountTableColumns}
        data={bankAccountsToShow}
        size={14}
      />
    </>
  );
};

const ManageAccountsPage = () => {
  const isAllowedToCreateBankAccounts = useIsAllowedToCreateBankAccounts();

  if (!isAllowedToCreateBankAccounts) {
    return <Navigate to={"/accounts"} />;
  }

  return (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>Manage accounts</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Section>
          <ActivateThreadBanner headerText="Activate Thread to create new bank accounts" />
        </DashboardPage.Section>

        <DashboardPage.Header actions={<CreateNewAccountButton />}>
          <DashboardPage.Header.BackCaret to="/accounts" />
          <DashboardPage.Header.Title>Manage accounts</DashboardPage.Header.Title>
        </DashboardPage.Header>

        <ManageAccountsTable />
      </DashboardPage>
      <EditBankAccountModal />
      <CloseBankAccountModal />
    </>
  );
};

export default ManageAccountsPage;
