import { AnalyticsBrowser } from "@segment/analytics-next";
import env from "env";
import { useCallback } from "react";
import useBusiness from "resources/business/queries/useBusiness";
import useUser from "resources/user/queries/useUser";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useMountEffect from "utils/customHooks/useMountEffect";

export enum SEGMENT_EVENTS {
  ACTIVATE_THREAD_CLICKED = "Activate Thread accounts clicked",

  BILL_PAY_BILL_SAVED = "Bill saved",
  BILL_PAY_PAYEE_CREATED = "Bill payee created",
  BILL_PAY_PAYMENT_CREATED = "Bill payment created",

  BUSINESS_ADDRESS_UPDATED = "Business address updated",
  BUSINESS_DETAILS_UPDATED = "Business details updated",

  CARD_CREATED = "Card created",
  CARD_ACTIVATED = "Card activated",

  CHECK_DEPOSIT_STARTED = "Check deposit started",
  CHECK_DEPOSIT_COMPLETED = "Check deposit completed",

  CREDIT_APPLICATION_REQUIREMENTS_MODAL_OPENED = "Credit application requirements modal opened",
  CREDIT_APPLICATION_STARTED = "Credit application started",

  INTERNATIONAL_WIRE_PAYEE_CREATION_FAILED = "International wire payee creation failed",

  MFA_POPUP_OPEN_FAILED = "MFA popup open failed",

  PLAID_LINK_FAILED = "Plaid link failed",
  PLAID_LINK_DUPLICATE = "Plaid link duplicate detected",
  PLAID_OAUTH_FLOW_USED = "Plaid oauth flow used",

  ONBOARDING_STORES_SKIPPED = 'Onboarding "connect stores" skipped',
  ONBOARDING_COMPLETE = "Onboarding complete",

  PAYMENT_APPROVAL_CREATED = "Payment approval created",

  REFERRAL_LINK_VISITED = "Referral link visited",

  RUTTER_CONNECT_COMPLETED = "Rutter link completed",

  SHOPIFY_CONNECT_COMPLETED = "Shopify connect completed",

  USER_INVITE_STARTED = "User invite started",
  USER_INVITE_COMPLETED = "User invite completed",
  OWNERSHIP_TRANSFERRED = "Business ownership transferred",
  BUSINESS_MEMBER_REMOVED = "Business member removed",

  CAPITAL_CREDIT_COMPARISON_COMPARED = "Credit comparison final screen visited",

  INSIGHTS_SPEND_BY_VENDOR_VISITED = "Spend by vendor visited",
  INSIGHTS_SPEND_BY_VENDOR_RECATEGORIZED = "Spend by vendor recategorized",
  INSIGHTS_SPEND_BY_VENDOR_RECATEGORIZATION_FAILED = "Spend by vendor recategorization failed",
}

const analytics = env.SEGMENT_ENABLED ? AnalyticsBrowser.load({ writeKey: env.SEGMENT_ID }) : null;

/**
 * Keep this independent so it doesn't depend on the presence of a recoil `userState`.
 */
export const segmentClear = () => {
  if (!env.SEGMENT_ENABLED || !analytics) {
    return;
  }
  analytics.reset();
};

const useSegment = () => {
  const user = useUser({ loginRequired: false });
  const business = useBusiness({ loginRequired: false });
  const isSuperusering = useIsSuperusering();

  const segmentIdentify = useCallback(() => {
    if (!env.SEGMENT_ENABLED || !analytics || !user || !business) {
      return;
    }
    analytics.identify(user.guid, {
      name: user.displayName,
      email: user.emailAddress,
    });
    if (!isSuperusering) {
      // Don't associate superuser with business group
      analytics.group(business.guid, {
        name: business.internalName,
      });
    }
  }, [business, user, isSuperusering]);

  const segmentTrack = useCallback(
    (event: SEGMENT_EVENTS, data: Record<string, any> = {}) => {
      const properties = {
        userId: user?.guid,
        userName: user?.displayName,
        userEmail: user?.emailAddress,
        businessId: business?.guid,
        businessName: business?.internalName,
        ...data,
      };
      if (env.SEGMENT_ENABLED && analytics && !isSuperusering) {
        analytics.track?.(event, properties);
      } else {
        // eslint-disable-next-line no-console
        console.debug("Segment event", event, properties);
      }
    },
    [business, user, isSuperusering]
  );

  const segmentPage = useCallback(() => {
    if (!env.SEGMENT_ENABLED || !analytics || isSuperusering) {
      return;
    }
    analytics.page?.();
  }, [isSuperusering]);

  return {
    segmentIdentify,
    segmentTrack,
    segmentPage,
  };
};

export default useSegment;

/**
 * Tracking page mounts as Segment events is easy using this nifty hook!
 * It leverages {@see useMountEffect} to avoid firing multiple times.
 */
export const useTrackPageMount = (event: SEGMENT_EVENTS, data: Record<string, any> = {}) => {
  const { segmentTrack } = useSegment();

  useMountEffect(() => {
    segmentTrack(event, data);
  });
};
