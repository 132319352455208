import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import BankAccountRep from "reps/BankAccountRep";
import {
  BusinessMemberUsersTableDatum,
  UsersTableDatum,
} from "resources/users-table-data/queries/useUsersTableData";
import { getCentsFromDollars } from "utils/money";
import { z } from "zod";

export const SPEND_LIMIT_INTERVAL_OPTIONS = ["daily", "monthly"] as const;

const schema = z.object({
  cardholder: z
    .custom<UsersTableDatum | null>()
    .refine((data) => data !== null, {
      message: "Cardholder is required",
    })
    .refine((data): data is BusinessMemberUsersTableDatum => !data.isInvited),
  cardName: z.string().min(1, "Please enter a card name."),

  // NB(alex): Technically we should check `creditOrDebit` to see if we want to validate this field, but we can just make it non-nullable and ignore the field if `credit`, so this is fine.
  associatedBankAccount: z.custom<BankAccountRep.Complete>(),

  spendLimit: z.union([
    z.object({
      enabled: z.literal(true),
      interval: z.enum(SPEND_LIMIT_INTERVAL_OPTIONS),
      amount: z
        .string()
        .transform((data) => getCentsFromDollars(data))
        .refine((data) => data > 0, {
          message: "Please enter an amount.",
        }),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
});

export type CreateCardModalCardDetailsFormInputs = z.input<typeof schema>;
export type CreateCardModalCardDetailsFormOutputs = z.output<typeof schema>;

type Params = {
  defaultValues: CreateCardModalCardDetailsFormInputs;
};

const useCreateCardModalCardDetailsForm = ({ defaultValues }: Params) => {
  return useForm<
    CreateCardModalCardDetailsFormInputs,
    object,
    CreateCardModalCardDetailsFormOutputs
  >({
    resolver: zodResolver(schema),
    defaultValues,
  });
};

export default useCreateCardModalCardDetailsForm;
