import { CardStatus } from "@highbeam/unit-node-sdk";
import { useQuery } from "@tanstack/react-query";
import BankAccountRep from "reps/BankAccountRep";
import CapitalAccountRep from "reps/CapitalAccountRep";
import { useBankAccountsQuery } from "resources/bank-accounts/queries/useBankAccounts";
import { useCapitalAccountsQueryOptions } from "resources/capital-accounts/queries/useCapitalAccounts";
import useCardsQueryOptions from "resources/cards/queries/useCardsQueryOptions";
import { useActivePayeesQuery } from "resources/payees/queries/usePayees";
import { getAccountDisplayName } from "utils/account";
import { getCardName } from "utils/cards";

import { NavigationCommandPaletteOption } from "./types";

const BANK_ACCOUNTS_STATUS_SORT_ORDER = [
  BankAccountRep.Status.OPEN,
  BankAccountRep.Status.FROZEN,
  BankAccountRep.Status.CLOSED,
];

const CARDS_STATUS_SORT_ORDER = [
  "Active",
  "Frozen",
  "Stolen",
  "Lost",
  "SuspectedFraud",
  "ClosedByCustomer",
  "Inactive",
] as CardStatus[];

const CAPITAL_ACCOUNT_STATE_SORT_ORDER = [
  CapitalAccountRep.State.Active,
  CapitalAccountRep.State.Terminated,
  CapitalAccountRep.State.Offered,
  CapitalAccountRep.State.OfferAccepted,
];

export const useNavigationCommandPaletteOptions = () => {
  const { data: bankAccounts = [] } = useBankAccountsQuery({
    status: "all",
    select: (data) => {
      return data.sort((a, b) => {
        return (
          BANK_ACCOUNTS_STATUS_SORT_ORDER.indexOf(a.status) -
          BANK_ACCOUNTS_STATUS_SORT_ORDER.indexOf(b.status)
        );
      });
    },
  });

  const { data: cards = [] } = useQuery({
    ...useCardsQueryOptions({ status: CARDS_STATUS_SORT_ORDER }),
    select: (data) => {
      return data.sort((a, b) => {
        return (
          CARDS_STATUS_SORT_ORDER.indexOf(a.attributes.status) -
          CARDS_STATUS_SORT_ORDER.indexOf(b.attributes.status)
        );
      });
    },
  });

  const { data: payees = [] } = useActivePayeesQuery();

  const { data: capitalAccounts = [] } = useQuery({
    ...useCapitalAccountsQueryOptions(),
    select: (data) => {
      return data.sort((a, b) => {
        return (
          CAPITAL_ACCOUNT_STATE_SORT_ORDER.indexOf(a.state) -
          CAPITAL_ACCOUNT_STATE_SORT_ORDER.indexOf(b.state)
        );
      });
    },
  });

  // TODO(alex): Check permissions & FFs

  return [
    {
      category: "navigation",
      isSuggested: true,
      label: "Home",
      value: {
        commandName: "navigate",
        payload: {
          to: "/",
        },
      },
    },
    {
      category: "navigation",
      isSuggested: true,
      label: "Send money",
      value: {
        commandName: "navigate",
        payload: {
          to: "/send-money",
        },
      },
    },
    {
      category: "navigation",
      isSuggested: true,
      label: "Transfer money",
      value: {
        commandName: "navigate",
        payload: {
          to: "/transfer-money",
        },
      },
    },
    {
      category: "navigation",
      label: "Deposit check",
      value: {
        commandName: "navigate",
        payload: {
          to: "/deposit-check",
        },
      },
    },
    {
      category: "navigation",
      label: "Bank accounts",
      value: {
        commandName: "navigate",
        payload: {
          to: "/accounts",
        },
      },
    },
    {
      category: "navigation",
      label: "Bank accounts → Transactions",
      value: {
        commandName: "navigate",
        payload: {
          to: "/accounts/transactions",
        },
      },
    },
    {
      category: "navigation",
      label: "Bank accounts → Documents",
      value: {
        commandName: "navigate",
        payload: {
          to: "/accounts/transactions",
        },
      },
    },
    ...bankAccounts.map((bankAccount) => {
      return {
        category: "navigation",
        label: `Bank account → ${getAccountDisplayName(bankAccount)}`,
        value: {
          commandName: "navigate",
          payload: {
            to: `/accounts/${bankAccount.guid}`,
          },
        },
        type: "bank-account",
        data: bankAccount,
      } satisfies NavigationCommandPaletteOption;
    }),
    {
      category: "navigation",
      isSuggested: true,
      label: "Cards",
      value: {
        commandName: "navigate",
        payload: {
          to: "/cards",
        },
      },
    },
    {
      category: "navigation",
      label: "Cards → Transactions",
      value: {
        commandName: "navigate",
        payload: {
          to: "/cards/transactions",
        },
      },
    },
    ...cards.map((card) => {
      return {
        category: "navigation",
        label: `Cards → ${getCardName(card)}`,
        value: {
          commandName: "navigate",
          payload: {
            to: `/cards/${card.id}`,
          },
        },
        type: "card",
        data: card,
      } satisfies NavigationCommandPaletteOption;
    }),
    {
      category: "navigation",
      isSuggested: true,
      label: "Payments",
      value: {
        commandName: "navigate",
        payload: {
          to: "/payments",
        },
      },
    },
    {
      category: "navigation",
      label: "Payments → Bills",
      value: {
        commandName: "navigate",
        payload: {
          to: "/payments/bills",
        },
      },
    },
    {
      category: "navigation",
      label: "Payments → Payees",
      value: {
        commandName: "navigate",
        payload: {
          to: "/payments/payees",
        },
      },
    },
    ...payees.map((payee) => {
      return {
        category: "navigation",
        label: `Payments → Payees → ${payee.name}`,
        value: {
          commandName: "navigate",
          payload: {
            to: `/payments/payees/${payee.guid}`,
          },
        },
        type: "payee",
        data: payee,
      } satisfies NavigationCommandPaletteOption;
    }),

    {
      category: "navigation",
      isSuggested: true,
      label: "Capital",
      value: {
        commandName: "navigate",
        payload: {
          to: "/capital",
        },
      },
    },
    {
      category: "navigation",
      label: "Capital → Documents",
      value: {
        commandName: "navigate",
        payload: {
          to: "/capital/documents",
        },
      },
    },
    {
      category: "navigation",
      label: "Capital → Compare other offers",
      value: {
        commandName: "navigate",
        payload: {
          to: "/whatstheapr",
          openInNewTab: true,
        },
      },
    },
    {
      category: "navigation",
      label: "What's the APR?",
      value: {
        commandName: "navigate",
        payload: {
          to: "/whatstheapr",
          openInNewTab: true,
        },
      },
    },
    ...capitalAccounts.map((capitalAccount) => {
      return {
        category: "navigation",
        label: `Capital → ${capitalAccount.name}`,
        value: {
          commandName: "navigate",
          payload: {
            to: `/capital/${capitalAccount.guid}`,
          },
        },
        type: "capital-account",
        data: capitalAccount,
      } satisfies NavigationCommandPaletteOption;
    }),
    {
      category: "navigation",
      label: "Cash flow",
      value: {
        commandName: "navigate",
        payload: {
          to: "/cash-flow",
        },
      },
    },
    {
      category: "navigation",
      isSuggested: true,
      label: "Settings",
      value: {
        commandName: "navigate",
        payload: {
          to: "/settings",
        },
      },
    },
    {
      category: "navigation",
      label: "Settings → Business information",
      value: {
        commandName: "navigate",
        payload: {
          to: "/settings/business",
        },
      },
    },
    {
      category: "navigation",
      label: "Settings → Security",
      value: {
        commandName: "navigate",
        payload: {
          to: "/settings/security",
        },
      },
    },
    {
      category: "navigation",
      label: "Settings → Users",
      value: {
        commandName: "navigate",
        payload: {
          to: "/settings/users",
        },
      },
    },
    {
      category: "navigation",
      label: "Settings → Stores",
      value: {
        commandName: "navigate",
        payload: {
          to: "/settings/stores",
        },
      },
    },
    {
      category: "navigation",
      label: "Settings → Banks and cards",
      value: {
        commandName: "navigate",
        payload: {
          to: "/settings/banks-and-cards",
        },
      },
    },
    {
      category: "navigation",
      label: "Settings → Accounting software",
      value: {
        commandName: "navigate",
        payload: {
          to: "/settings/accounting-software",
        },
      },
    },
  ] satisfies NavigationCommandPaletteOption[];
};
