import { ArrowsClockwise, CalendarBlank, Timer } from "@phosphor-icons/react";
import { useSuspenseQueries } from "@tanstack/react-query";
import IconWithTooltip from "components/common/IconWithTooltip";
import MultiStep from "components/layouts/MultiStep";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import BankAccountsDropdown from "resources/bank-accounts/components/BankAccountsDropdown";
import useBankAccounts, {
  useBankAccountsQueryOptions,
} from "resources/bank-accounts/queries/useBankAccounts";
import useOpenBankAccount from "resources/bank-accounts/queries/useOpenBankAccount";
import usePrimaryBankAccount from "resources/bank-accounts/queries/usePrimaryBankAccount";
import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";
import useCapitalAccountRepaymentBankAccount from "resources/capital-accounts/hooks/useCapitalAccountRepaymentBankAccount";
import isCapitalAccountSummaryWithChargeCard, {
  CapitalAccountSummaryWithChargeCard,
} from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import ChargeCardExampleStatementPeriod from "resources/charge-card-offer/components/ChargeCardExampleStatementPeriod";
import { StatementCycleExplanation } from "resources/charge-card-offer/constants/repayment-periods";
import useChargeCardOffer from "resources/charge-card-offer/queries/useChargeCardOffer";
import usePlaidAccountsQueryOptions from "resources/plaid-connections/queries/usePlaidAccountsQueryOptions";
import colors from "styles/colors";
import Banner from "ui/data-display/Banner";
import Divider from "ui/data-display/Divider";
import MoneyAmount from "ui/data-display/money/MoneyAmount";
import Dropdown from "ui/inputs/Dropdown";
import Text from "ui/typography/Text";
import { pluralize } from "utils/string";

import ReviewLineOfferBackButton from "../../components/ReviewLineOfferBackButton";
import { useReviewLineOfferContext } from "../../context/ReviewLineOfferProvider";

import AutoPayScheduleSteps from "./AutoPayScheduleSteps";
import styles from "./AutoPayView.module.scss";

const AUTO_PAY_FORM_ID = "auto-pay-form";

type AutoPayChargeCardDetailsProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const AutoPayChargeCardDetails: FC<AutoPayChargeCardDetailsProps> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const repaymentAccount = useCapitalAccountRepaymentBankAccount({
    capitalAccountSummary: capitalAccountSummaryWithChargeCard,
  });

  const [{ data: openBankAccounts }, { data: plaidBankAccounts }] = useSuspenseQueries({
    queries: [
      useBankAccountsQueryOptions({ status: "open" }),
      usePlaidAccountsQueryOptions("DEPOSITORY"),
    ],
  });

  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });
  const targetRepaymentDays = capitalAccountSummaryWithChargeCard.details.targetRepaymentDays;

  return (
    <div className={styles.multistepContainer}>
      <Text size={16} color={colors.grey[600]}>
        <StatementCycleExplanation chargeCardOffer={chargeCardOffer} />
      </Text>

      <BankAccountsDropdown
        label="Auto-pay account"
        value={
          openBankAccounts.find((option) => {
            return option.guid === repaymentAccount.guid;
          }) ?? openBankAccounts[0]
        }
        options={openBankAccounts}
        disabled
      />

      <div className={styles.backupAccountsContainer}>
        <ArrowsClockwise size={14} />
        <Text weight="medium" color={colors.grey[800]} size={14}>
          {openBankAccounts.length + plaidBankAccounts.length} backup auto-pay accounts.
        </Text>
        <IconWithTooltip
          content={`Your ${pluralize(openBankAccounts.length, "Highbeam account")} and ${pluralize(plaidBankAccounts.length, "connected account")} are used as backup auto-pay accounts.`}
        />
      </div>

      <div className={styles.exampleStatementContainer}>
        <Text as="p" size={14} weight="medium">
          Example statement period:
        </Text>
        <ChargeCardExampleStatementPeriod chargeCardCreditTerm={chargeCardOffer.creditTerms} />
      </div>
      <Banner
        padding="normal"
        color="purple"
        icon={<Timer />}
        title="Overdue balances"
        body={
          <Text size={14}>
            After the due date, any overdue balance will accrue interest daily at{" "}
            {capitalAccountSummaryWithChargeCard.details.apr * 100}% APR and Highbeam will
            auto-debit from your auto-pay accounts over the following {targetRepaymentDays} days
            until the balance is repaid in full.
          </Text>
        }
      />
    </div>
  );
};

type AutoPayCashAccessOnlyDetailsProps = {
  capitalAccountSummaryWithCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const AutoPayCashAccessOnlyDetails: FC<AutoPayCashAccessOnlyDetailsProps> = ({
  capitalAccountSummaryWithCashAccessOnly,
}) => {
  const primaryAccount = usePrimaryBankAccount({ required: true });
  const repaymentAccount = useOpenBankAccount(primaryAccount.guid, {
    required: true,
  });
  const accountOptions = useBankAccounts({
    status: "open",
    select: (bankAccounts) => bankAccounts.map(mapTransferOption),
  });

  const targetRepaymentDays = capitalAccountSummaryWithCashAccessOnly.details.targetRepaymentDays;

  return (
    <div className={styles.multistepContainer}>
      <Text size={16} color={colors.grey[900]}>
        Each drawdown will be repaid in equal daily installments over {targetRepaymentDays} days.
      </Text>
      <div className={styles.autopayScheduleCard}>
        <div className={styles.autopayScheduleSubtext}>
          <div className={styles.subtextItem}>
            <CalendarBlank size={20} color={colors.grey[800]} />
            <Text size={14} color={colors.grey[800]}>
              Example auto-pay schedule
            </Text>
          </div>
          <Text size={14} color={colors.grey[800]}>
            Daily payment
          </Text>
        </div>
        <Divider margin={0} />
        <div className={styles.autopayScheduleSteps}>
          <AutoPayScheduleSteps capitalAccountSummary={capitalAccountSummaryWithCashAccessOnly} />

          <Text size={14}>
            <MoneyAmount
              cents={capitalAccountSummaryWithCashAccessOnly.details.limit / targetRepaymentDays}
              currency={"USD"}
              weight="medium"
            />
            {" / "}
            day
          </Text>
        </div>
      </div>
      <Dropdown
        label="Auto-pay account"
        value={
          accountOptions.find((option) => {
            return option.guid === repaymentAccount.guid;
          }) ?? accountOptions[0]
        }
        options={accountOptions}
        disabled
      />
    </div>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AutoPayView: FC<Props> = ({ capitalAccountSummary }) => {
  const navigate = useNavigate();
  const { nextPathname } = useReviewLineOfferContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <MultiStep.Form id={AUTO_PAY_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>Auto-pay</MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
      </MultiStep.Section>

      <MultiStep.Section>
        {isCapitalAccountSummaryWithChargeCard(capitalAccountSummary) ? (
          <AutoPayChargeCardDetails capitalAccountSummaryWithChargeCard={capitalAccountSummary} />
        ) : (
          <AutoPayCashAccessOnlyDetails
            capitalAccountSummaryWithCashAccessOnly={capitalAccountSummary}
          />
        )}
      </MultiStep.Section>

      <MultiStep.Controls>
        <ReviewLineOfferBackButton />
        <MultiStep.Controls.NextButton form={AUTO_PAY_FORM_ID}>Next</MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default AutoPayView;
