import BusinessRep from "reps/BusinessRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BusinessApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(businessGuid: string): Promise<BusinessRep.Complete> {
    const url = `/businesses/${businessGuid}`;
    return (await this.api.get<BusinessRep.Complete>(url))!;
  }

  async getByMemberUser(userGuid: string): Promise<BusinessRep.Complete[]> {
    const queryParams = new URLSearchParams({ userGuid });
    const url = `/businesses?${queryParams}`;
    return (await this.api.get<BusinessRep.Complete[]>(url))!;
  }

  async getAll(): Promise<BusinessRep.Complete[]> {
    const url = "/businesses";
    return (await this.api.get<BusinessRep.Complete[]>(url))!;
  }
}
