import { Minus } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { useBusinessMembersQuery } from "resources/business-members/queries/useBusinessMembers";
import getBusinessMemberByUserGuid from "resources/business-members/utils/getBusinessMemberByUserGuid";
import usePaymentApproval from "resources/payment-approvals/queries/usePaymentApproval";
import colors from "styles/colors";
import Avatar from "ui/data-display/Avatar";
import Shimmer from "ui/feedback/Shimmer";
import Text from "ui/typography/Text";

import { usePaymentApprovalFlexpaneContext } from "../../context/PaymentApprovalFlexpaneProvider";

import styles from "./PaymentApprovalSubmittedBy.module.scss";

const PaymentApprovalSubmittedBy = () => {
  const { paymentApprovalGuid } = usePaymentApprovalFlexpaneContext();
  const paymentApproval = usePaymentApproval(paymentApprovalGuid, { required: true });
  const { data: businessMembers, isLoading } = useBusinessMembersQuery();

  if (isLoading) {
    return <Shimmer />;
  }

  const businessMember = !businessMembers
    ? null
    : getBusinessMemberByUserGuid(businessMembers, paymentApproval.createdByUserGuid);

  return (
    <div className={styles.container}>
      {businessMember && (
        <Avatar color="purple-light" size={32} initials={businessMember.initials} />
      )}
      <Text size={16} weight="regular" color={colors.grey[900]} className={styles.submittedByText}>
        Submitted by{" "}
        {businessMember ? (
          <Text as="strong" weight="medium">
            {businessMember.displayName}
          </Text>
        ) : (
          <Minus size={16} className={styles.minus} />
        )}{" "}
        on {dayjs(paymentApproval.createdAt).format("MMM D, YYYY")}
      </Text>
    </div>
  );
};

export default PaymentApprovalSubmittedBy;
