import { DownloadSimple, Eyeglasses } from "@phosphor-icons/react";
import MultiStep from "components/layouts/MultiStep";
import { NotAllowedToDownloadCreditAgreement } from "pages/capital/CapitalAccountPage/constants";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { CAPITAL_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL } from "resources/capital/constants";
import useLineOfCreditAgreement from "resources/line-of-credit-agreement/queries/useLineOfCreditAgreement";
import useDownloadLineOfCreditAgreementMutation from "resources/line-of-credit/mutations/useDownloadLineOfCreditAgreementMutation";
import useUpdateLineOfCreditUserActionsMetadataMutation from "resources/line-of-credit/mutations/useUpdateLineOfCreditUserActionsMetadataMutation";
import { useIsSuperusering } from "state/auth/isSuperusering";
import colors from "styles/colors";
import Divider from "ui/data-display/Divider";
import Button from "ui/inputs/Button";
import CheckboxLabel from "ui/inputs/CheckboxLabel";
import Tooltip from "ui/overlay/Tooltip/Tooltip";
import Text from "ui/typography/Text";
import useIsAllowedToDownloadCreditOfferAgreement from "utils/permissions/useIsAllowedToDownloadCreditOfferAgreement";

import ReviewLineOfferBackButton from "../../components/ReviewLineOfferBackButton";
import {
  useReviewLineOfferAgreementFormContext,
  useReviewLineOfferContext,
} from "../../context/ReviewLineOfferProvider";

import AgreementPlainLanguageSummary from "./AgreementPlainLanguageSummary";
import styles from "./AgreementView.module.scss";

const AGREEMENT_FORM_ID = "agreement-form";

type AgreementViewContentProps = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AgreementViewContent: FC<AgreementViewContentProps> = ({ capitalAccountSummary }) => {
  const { control } = useReviewLineOfferAgreementFormContext();
  const lineOfCreditAgreement = useLineOfCreditAgreement(capitalAccountSummary.guid);
  const isAllowedToDownloadCreditOfferAgreement = useIsAllowedToDownloadCreditOfferAgreement();
  const { agreementDownloaded, setAgreementDownloaded } = useReviewLineOfferContext();
  const { mutateAsync: downloadLineOfCreditAgreementMutation, isPending } =
    useDownloadLineOfCreditAgreementMutation({
      onSuccess: () => setAgreementDownloaded(true),
    });
  const { mutateAsync: updateLineOfCreditUserActionsMetadataMutation } =
    useUpdateLineOfCreditUserActionsMetadataMutation();
  const isSuperusering = useIsSuperusering();

  const onDownloadAgreement = async () => {
    await downloadLineOfCreditAgreementMutation({
      lineOfCreditGuid: capitalAccountSummary.guid,
      unsignedAgreementGuid: lineOfCreditAgreement?.unsignedAgreementGuid,
      signedAgreementGuid: lineOfCreditAgreement?.signedAgreementGuid,
    });

    if (!lineOfCreditAgreement?.unsignedAgreementOpenedAt && !isSuperusering) {
      await updateLineOfCreditUserActionsMetadataMutation({
        capitalAccountGuid: capitalAccountSummary.guid,
      });
    }
  };

  return (
    <div className={styles.multistepContainer}>
      <Text size={16} color={colors.grey[900]}>
        Please review and download the capital agreement.
      </Text>

      <div className={styles.agreementSummary}>
        <div className={styles.agreementSummaryTitle}>
          <Eyeglasses size={24} color={colors.grey[700]} />
          <Text size={14} color={colors.grey[600]} weight="medium">
            Plain language summary
          </Text>
        </div>
        <Divider margin={0} />
        <AgreementPlainLanguageSummary capitalAccountSummary={capitalAccountSummary} />
      </div>

      <div className={styles.downloadAgreementButtonContainer}>
        <Button
          variant="primary"
          onClick={onDownloadAgreement}
          isLoading={isPending}
          disabled={!isAllowedToDownloadCreditOfferAgreement}
          tooltip={
            !isAllowedToDownloadCreditOfferAgreement && <NotAllowedToDownloadCreditAgreement />
          }
        >
          <DownloadSimple size={24} />
          Download full agreement
        </Button>
        <Text size={14} color={colors.grey[500]}>
          Required
        </Text>
      </div>

      <Divider margin={0} />

      <Controller
        name="chargeCardAgreementConfirmed"
        control={control}
        render={({ field }) => (
          <CheckboxLabel
            label={
              <>
                I agree to the{" "}
                <a
                  href={"https://www.highbeam.co/legal/card-terms-of-use"}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.termsLink}
                >
                  Highbeam Card Terms of Use
                </a>
                {" and "}
                <a
                  href={"https://www.highbeam.co/legal/card-authorized-user-terms-of-use"}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.termsLink}
                >
                  Highbeam Authorized User
                </a>
                {" terms."}
              </>
            }
            checked={field.value}
            textWeight="regular"
            onChange={field.onChange}
            disabled={!agreementDownloaded}
          />
        )}
      />

      <Controller
        name="readAgreementConfirmed"
        control={control}
        render={({ field }) => (
          <CheckboxLabel
            label={CAPITAL_AGREEMENT_CONFIRMATION_CHECKBOX_LABEL}
            checked={field.value}
            textWeight="regular"
            onChange={field.onChange}
            disabled={!agreementDownloaded}
          />
        )}
      />
    </div>
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const AgreementView: FC<Props> = ({ capitalAccountSummary }) => {
  const navigate = useNavigate();
  const { formState } = useReviewLineOfferAgreementFormContext();
  const { nextPathname } = useReviewLineOfferContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (nextPathname) {
      navigate(nextPathname);
    }
  };

  return (
    <MultiStep.Form id={AGREEMENT_FORM_ID} onSubmit={handleSubmit}>
      <MultiStep.Section>
        <MultiStep.Section.Header>
          <MultiStep.Section.Header.Heading>
            Review capital agreement
          </MultiStep.Section.Header.Heading>
        </MultiStep.Section.Header>
      </MultiStep.Section>

      <MultiStep.Section>
        <AgreementViewContent capitalAccountSummary={capitalAccountSummary} />
      </MultiStep.Section>

      <MultiStep.Controls>
        <ReviewLineOfferBackButton />
        <MultiStep.Controls.NextButton
          form={AGREEMENT_FORM_ID}
          tooltip={
            !formState.isValid && (
              <Tooltip.Content>
                Please download, read the agreement and agree to the terms.
              </Tooltip.Content>
            )
          }
          disabled={!formState.isValid}
        >
          Next
        </MultiStep.Controls.NextButton>
      </MultiStep.Controls>
    </MultiStep.Form>
  );
};

export default AgreementView;
