import { FC, useState } from "react";
import ModalV4 from "ui/overlay/ModalV4";

import BillLineItemsContent from "../BillLineItemsContent";
import BillLineItemsHeading from "../BillLineItemsHeading";
import BillLineItemsTotal from "../BillLineItemsTotal";

import styles from "./BillLineItemsMobile.module.scss";

type BillLineItemsModalProps = {
  billId: string;
  onClose: () => void;
};

const BillLineItemsModal: FC<BillLineItemsModalProps> = ({ billId, onClose }) => (
  <ModalV4 onClose={onClose}>
    <ModalV4.Header>
      <span className={styles.modalHeaderContent}>
        <BillLineItemsHeading billId={billId} as="span" />
        <BillLineItemsTotal billId={billId} />
      </span>
    </ModalV4.Header>
    <ModalV4.Body>
      <BillLineItemsContent billId={billId} />
    </ModalV4.Body>
  </ModalV4>
);

type Props = {
  billId: string;
};

const BillLineItemsMobile: FC<Props> = ({ billId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={styles.headerContainer}
        onClick={() => setIsModalOpen(true)}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            event.currentTarget.click();
          }
        }}
      >
        <BillLineItemsHeading billId={billId} />
        <BillLineItemsTotal billId={billId} />
      </div>

      {isModalOpen && <BillLineItemsModal billId={billId} onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default BillLineItemsMobile;
