import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import Flexpane from "ui/overlay/Flexpane";

import { useReceivedPaymentInfoFlexpaneContext } from "../../context/ReceivedPaymentInfoFlexpaneProvider";

import BankAccountDetail from "./BankAccountDetail";
import CounterpartyDetail from "./CounterpartyDetail";
import PaymentDetailBubble from "./ReceivedPaymentDetailBubble";

const ReceivedPaymentInfoFlexpaneToFromSection = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();
  const positive = receivedPayment.attributes.direction === "Credit";

  return (
    <Flexpane.Section>
      <TransactionFlexpaneParties
        from={positive ? <CounterpartyDetail /> : <BankAccountDetail />}
        status={<PaymentDetailBubble />}
        to={positive ? <BankAccountDetail /> : <CounterpartyDetail />}
      />
    </Flexpane.Section>
  );
};

export default ReceivedPaymentInfoFlexpaneToFromSection;
