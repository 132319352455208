import mapTransferOption from "resources/bank-accounts/utils/mapTransferOption";

import useBankAccounts from "./useBankAccounts";

const usePaymentEnabledBankAccountOptions = () => {
  return useBankAccounts({
    status: "open",
    select: (bankAccounts) => {
      return bankAccounts
        .map(mapTransferOption) // DEPRECATED: Do not copy this pattern. We must use this pattern for now until our `Dropdown` component is composable.
        .filter(
          (account) =>
            (account.supportsSendMoney && account.isThreadAccount) ||
            ("isPrimary" in account && account.isPrimary)
        );
    },
  });
};

export default usePaymentEnabledBankAccountOptions;
