import PlaidBankAccountRep from "reps/PlaidBankAccountRep";

export function comparePlaidBankAccounts(
  a: PlaidBankAccountRep.Complete,
  b: PlaidBankAccountRep.Complete
): number {
  // Deposit accounts always go before credit
  if (a.accountType === "DEPOSITORY" && b.accountType !== "DEPOSITORY") {
    return -1;
  }
  if (a.accountType !== "DEPOSITORY" && b.accountType === "DEPOSITORY") {
    return 1;
  }
  // Then sort by accountName
  const nameComparison = a.accountName.localeCompare(b.accountName);
  if (nameComparison !== 0) {
    return nameComparison;
  }
  // Then by accountMask
  if (a.accountMask && b.accountMask) {
    const maskComparison = a.accountMask.localeCompare(b.accountMask);
    if (maskComparison !== 0) {
      return maskComparison;
    }
  } else if (a.accountMask) {
    return -1; // account with accountMask comes first
  } else if (b.accountMask) {
    return 1; // account with accountMask comes first
  }
  // Finally sort by plaidAccountId
  return a.plaidAccountId.localeCompare(b.plaidAccountId);
}
