import { Warning } from "@phosphor-icons/react";
import { budgetOptions, getBudgetLabel } from "pages/cards/CardsPage/CreateCardModal/utils";
import { useState } from "react";
import { CardBudgetType } from "resources/cards/types";
import Banner from "ui/data-display/Banner";
import CurrencyInput from "ui/inputs/CurrencyInput";
import Dropdown from "ui/inputs/Dropdown";
import Switch from "ui/inputs/Switch";
import Text from "ui/typography/Text";

import styles from "./CardBudgetManager.module.scss";

type CardBudgetManagerProps = {
  budgetType: CardBudgetType;
  setBudgetType: (type: CardBudgetType) => void;
  dailyLimit: number | null;
  setDailyLimit: (limit: number | null) => void;
  dailyPurchases: number | null;
  monthlyLimit: number | null;
  setMonthlyLimit: (limit: number | null) => void;
  monthlyPurchases: number | null;
  updateBudgetLimit: (val: string) => void;
  newLimitExceedsPurchases: boolean;
  setNewLimitExceedsPurchases: (val: boolean) => void;
  isLoading: boolean;
};

const CardBudgetManager: React.FC<CardBudgetManagerProps> = ({
  budgetType,
  setBudgetType,
  dailyLimit,
  setDailyLimit,
  dailyPurchases,
  monthlyLimit,
  setMonthlyLimit,
  monthlyPurchases,
  updateBudgetLimit,
  newLimitExceedsPurchases,
  setNewLimitExceedsPurchases,
  isLoading,
}) => {
  const [spendBudgetIsToggledOn, setSpendBudgetIsToggledOn] = useState<boolean>(
    Boolean(dailyLimit || monthlyLimit)
  );
  const limit = budgetType === "daily" ? dailyLimit : monthlyLimit;
  const purchaseAmount = budgetType === "daily" ? dailyPurchases : monthlyPurchases;
  const budgetTypeUnit = budgetType === "daily" ? "day" : "month";

  return (
    <>
      <div className={styles.swithAndInputContainer}>
        <div className={styles.switchWithTextContainer}>
          <Switch
            value={spendBudgetIsToggledOn}
            onChange={(value) => {
              setSpendBudgetIsToggledOn(value);
              if (!value) {
                setDailyLimit(null);
                setMonthlyLimit(null);
                setNewLimitExceedsPurchases(false);
              }
            }}
          />
          <div className={styles.textContainer}>
            <div className={styles.text}>Spend budget</div>
          </div>
        </div>
        {spendBudgetIsToggledOn && (
          <div className={styles.budgetDropdownAndInputContainer}>
            <Dropdown
              id="spend-budget-type"
              onChange={(option) => {
                setBudgetType(option.value as CardBudgetType);
              }}
              value={budgetOptions.find((option) => option.value === budgetType) || null}
              options={budgetOptions}
              disabled={isLoading}
              isLoading={isLoading}
              hideCursor
            />
            <div className={styles.currencyInputContainer}>
              <CurrencyInput
                prefixValue="$"
                label={limit ? getBudgetLabel(budgetType) : ""}
                value={limit ? limit.toString() : ""}
                onChange={(val) => updateBudgetLimit(val)}
                placeholder={getBudgetLabel(budgetType)}
                maxLength={15}
              />
            </div>
          </div>
        )}
      </div>

      {newLimitExceedsPurchases && limit && (
        <Banner
          icon={<Warning size={16} />}
          padding="compact"
          title={
            <Text size={12} weight="bold">
              The current {budgetType.toLowerCase()} spend on this card is ${purchaseAmount}. To set
              a spend budget of ${limit}, please wait until the end of the {budgetTypeUnit}.
            </Text>
          }
          color="orange"
        />
      )}
    </>
  );
};

export default CardBudgetManager;
