import { ShieldWarning } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import colors from "styles/colors";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";

import styles from "./InactiveConnectionWarningBanner.module.scss";

const InactiveConnectionWarningBanner = () => (
  <div className={styles.container}>
    <div className={styles.bannerHeader}>
      <div className={styles.contentContainer}>
        <div className={styles.iconContainer}>
          <ShieldWarning size={24} color={colors.grey[800]} />
        </div>
        <div className={styles.textContainer}>
          <Text size={14} color={colors.grey[800]} weight="bold">
            Reconnection required
          </Text>
          <Text size={14} color={colors.grey[800]} weight="regular">
            One or more of your connected bank accounts or cards need to be reconnected. Please
            reconnect these accounts as having disconnected accounts for a long period of time can
            result in a limit decrease.
          </Text>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Link to="/settings/banks-and-cards">
          <Button variant="tertiary">
            <Text size={14} color={colors.grey[800]} weight="medium">
              Reconnect
            </Text>
          </Button>
        </Link>
      </div>
    </div>
  </div>
);

export default InactiveConnectionWarningBanner;
