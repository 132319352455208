import { useRef } from "react";
import { CardVirtualOrPhysical } from "resources/cards/types";
import ModalV2, { ModalRefValue } from "ui/overlay/ModalV2";

import CreateCardModalContent from "./CreateCardModalContent";
import { stepInfoMap, useCreateCardModal } from "./utils";

type Props = {
  createCardModalType: CardVirtualOrPhysical;
  defaultCardName: string;
  handleClose: (reload: boolean) => void;
};

const CreateCardModal: React.FC<Props> = ({
  createCardModalType,
  defaultCardName,
  handleClose,
}) => {
  const createCardModalProps = useCreateCardModal(
    createCardModalType,
    defaultCardName,
    handleClose
  );

  const modalRef = useRef<ModalRefValue>(null);
  const closeModal = () => modalRef.current?.close();

  return (
    <ModalV2
      isOpen
      onClose={() => handleClose(stepInfoMap[createCardModalProps.step].reloadPage)}
      modalRef={modalRef}
    >
      <CreateCardModalContent onClickClose={closeModal} {...createCardModalProps} />
    </ModalV2>
  );
};

export default CreateCardModal;
