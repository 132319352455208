import { Card } from "@highbeam/unit-node-sdk";
import { captureException } from "@sentry/react";
import classNames from "classnames";
import CardholderInitialsAndFullName from "components/CardholderInitialsAndFullName";
import isMfaInProgressState from "modules/mfa/isMfaInProgressState";
import useMfa, { MfaCanceledError } from "modules/mfa/useMfa";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import {
  checkIsPhysicalCreditCard,
  checkIsPhysicalDebitCard,
  checkIsVirtualChargeCard,
  checkIsVirtualDebitCard,
} from "resources/cards/utils/typeguards";
import { notify } from "ui/feedback/Toast";
import Text from "ui/typography/Text";
import { getCardName, getCardType } from "utils/cards";
import useIsAllowedToReadCardNumber from "utils/permissions/useIsAllowedToReadCardNumber";

import styles from "./HighbeamCard.module.scss";
import HighbeamCardArchived from "./HighbeamCardArchived";
import HighbeamCardCvv from "./HighbeamCardCvv";
import HighbeamCardDetailsButton from "./HighbeamCardDetailsButton";
import HighbeamCardExpiry from "./HighbeamCardExpiry";
import HighbeamCardFraud from "./HighbeamCardFraud";
import HighbeamCardInactive from "./HighbeamCardInactive";
import HighbeamCardLocked from "./HighbeamCardLocked";
import HighbeamCardLogo from "./HighbeamCardLogo";
import HighbeamCardNumber from "./HighbeamCardNumber";
import HighbeamCardTypePill from "./HighbeamCardTypePill";
import VisaLogo from "./VisaLogo";

type Props = {
  card: Card;
  areDetailsAvailable: boolean;
  singleCardDetailedView?: boolean;
  onClick?: () => void;
  showActivateCardButton?: boolean;
  containerClickable?: boolean;
};

const HighbeamCard: React.FC<Props> = ({
  card,
  areDetailsAvailable,
  singleCardDetailedView,
  onClick,
  showActivateCardButton = false,
  containerClickable = Boolean(onClick),
}) => {
  const isAllowedToReadCardNumber = useIsAllowedToReadCardNumber(card);

  const { mfa } = useMfa();
  const isMfaInProgress = useRecoilValue(isMfaInProgressState);

  const isLoading = isAllowedToReadCardNumber && isMfaInProgress;
  const isLocked = card.attributes.status === "Frozen";
  const isActive = card.attributes.status === "Active";
  const isFraud = card.attributes.status === "SuspectedFraud";
  const isArchived = card.attributes.status === "ClosedByCustomer";

  const shouldFetchSensitiveData = isActive || isLocked;

  const [isRevealed, setIsRevealed] = useState(false);

  const handleHide = () => {
    setIsRevealed(false);
  };

  const handleReveal = async () => {
    try {
      // `isMfaComplete` triggers sensitive data fetching in `HighbeamCardSensitiveData`.
      await mfa();
      setIsRevealed(true);
    } catch (error) {
      if (!(error instanceof MfaCanceledError)) {
        notify("error", "Something went wrong! Please try again.");
        captureException(error);
      }
    }
  };

  const isPhysicalCard = checkIsPhysicalDebitCard(card) || checkIsPhysicalCreditCard(card);
  const textColor = isPhysicalCard ? "white" : "black";
  const showArchived = !areDetailsAvailable && isArchived;

  return (
    <div className={classNames(containerClickable && styles.containerClickable)}>
      <div
        className={classNames(
          styles.container,
          checkIsPhysicalDebitCard(card) && styles.containerBorderPhysical,
          checkIsPhysicalCreditCard(card) && styles.containerBorderPhysicalCredit,
          (checkIsVirtualDebitCard(card) || checkIsVirtualChargeCard(card)) &&
            styles.containerBorderVirtual,
          checkIsVirtualChargeCard(card) && styles.containerChargeCard,
          checkIsVirtualDebitCard(card) && styles.containerDebitCard,
          styles.noSelect
        )}
        onClick={onClick}
      >
        <div>
          {singleCardDetailedView && (
            <div className={styles.rowWithSpace}>
              <HighbeamCardLogo isPhysicalCard={isPhysicalCard} />
              {isAllowedToReadCardNumber && areDetailsAvailable && (
                <HighbeamCardDetailsButton
                  isPhysicalCard={isPhysicalCard}
                  isRevealed={isRevealed}
                  isLoading={isLoading}
                  onHide={handleHide}
                  onReveal={handleReveal}
                />
              )}
              {showArchived && <HighbeamCardArchived />}
            </div>
          )}
          {!singleCardDetailedView && (
            <div className={styles.rowWithSpace}>
              <Text
                size={14}
                weight="bold"
                className={isPhysicalCard ? styles.textColorPhysical : styles.textColorVirtual}
              >
                {getCardName(card)}
              </Text>
              {isFraud && <HighbeamCardFraud />}
              {!isFraud && isLocked && <HighbeamCardLocked />}
              {isArchived && <HighbeamCardArchived />}
              {showActivateCardButton && <HighbeamCardInactive />}
            </div>
          )}
        </div>
        <div className={styles.details}>
          <HighbeamCardTypePill cardType={getCardType(card)} />
          <div className={styles.detailsRow}>
            <HighbeamCardNumber
              textColor={textColor}
              isLoading={isLoading}
              isRevealed={isRevealed}
              isAvailable={shouldFetchSensitiveData}
              card={card}
            />
          </div>
          <div className={styles.detailsRow}>
            <HighbeamCardExpiry
              textColor={textColor}
              card={card}
              isAvailable={shouldFetchSensitiveData}
            />
            <HighbeamCardCvv
              textColor={textColor}
              isLoading={isLoading}
              isRevealed={isRevealed}
              isAvailable={shouldFetchSensitiveData}
              card={card}
            />
          </div>
        </div>
        <div className={styles.rowWithSpace}>
          <CardholderInitialsAndFullName cardId={card.id} />
          <VisaLogo isPhysicalCard={isPhysicalCard} />
        </div>
      </div>
    </div>
  );
};

export default HighbeamCard;
