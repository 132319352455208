import CapitalAccountRep from "reps/CapitalAccountRep";
import { Simplify } from "type-fest";

import useCapitalAccounts from "../queries/useCapitalAccounts";

export type CapitalAccountWithChargeCard = Simplify<
  CapitalAccountRep.ChargeCardAndCashAccessComplete | CapitalAccountRep.ChargeCardOnlyComplete
>;

export const checkIsCapitalAccountWithChargeCard = (
  capitalAccount: CapitalAccountRep.Complete
): capitalAccount is CapitalAccountWithChargeCard => {
  return (
    capitalAccount.type === CapitalAccountRep.Type.ChargeCardOnly ||
    capitalAccount.type === CapitalAccountRep.Type.ChargeCardAndCashAccess
  );
};

const useCapitalAccountsWithChargeCard = () => {
  return useCapitalAccounts({
    select: (capitalAccounts) => capitalAccounts.filter(checkIsCapitalAccountWithChargeCard),
  });
};

export default useCapitalAccountsWithChargeCard;
