import logo from "assets/highbeam-logo.svg";
import LoadingPage from "pages/LoadingPage";
import { useParams } from "react-router-dom";

import InvalidInvitation from "./InvalidInvitation";
import Invitation from "./Invitation";
import styles from "./InvitationPage.module.scss";
import { useInvitation } from "./utils";

const InvitationPage = () => {
  const { invitationSlug } = useParams();

  const { isLoading, error, invitation } = useInvitation(invitationSlug);

  if (isLoading) return <LoadingPage location={InvitationPage.name} />;

  return (
    <div className={styles.container}>
      <img src={logo} className={styles.logo} alt="logo" />
      <div className={styles.content}>
        {!error && invitation && (
          <Invitation invitation={invitation[0]} userExistence={invitation[1]} />
        )}
        {(error || !invitation) && <InvalidInvitation />}
      </div>
    </div>
  );
};

export default InvitationPage;
