import classNames from "classnames";
import { ForwardRefRenderFunction, forwardRef, useRef, useState } from "react";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Tooltip from "ui/overlay/Tooltip/Tooltip";

import styles from "./Button.module.scss";

// Exemplar: usage of `forwardRef`

export type ButtonVariant =
  | "default"
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "ghost"
  | "plain"
  | "bare";

export type ButtonSize = "md" | "sm" | "xs";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant;
  size?: ButtonSize;
  className?: string;
  isSquare?: boolean; // this prop is to set equal padding horizontally and vertically mostly for icons we need this
  fullWidth?: boolean;
  isLoading?: boolean;
  tooltip?: React.ReactNode;
};

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    variant = "default",
    className,
    size = "md",
    isSquare = false,
    fullWidth,
    isLoading,
    disabled,
    children,
    tooltip,
    onMouseOver,
    onMouseOut,
    ...props
  },
  ref
) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const hasForwardedRef = ref && "current" in ref && ref.current;
  const referenceElement = hasForwardedRef ? ref.current : buttonRef.current;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseOver = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsHovered(true);
    onMouseOver && onMouseOver(e);
  };

  const handleMouseOut = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsHovered(false);
    onMouseOut && onMouseOut(e);
  };

  return (
    <>
      {tooltip && referenceElement && isHovered && (
        <Tooltip
          closeTooltip={() => setIsHovered(false)}
          openTooltip={() => setIsHovered(true)}
          referenceElement={referenceElement}
          rootElementId={"tooltip"}
        >
          {typeof tooltip === "string" ? <Tooltip.Content>{tooltip}</Tooltip.Content> : tooltip}
        </Tooltip>
      )}

      <button
        type="button"
        ref={ref ? ref : buttonRef}
        className={classNames(
          styles.button,
          styles[`size-${size}`],
          styles[`variant-${variant}`],
          fullWidth && styles.fullWidth,
          isSquare && styles.square,
          className
        )}
        disabled={disabled || isLoading}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        {...props}
      >
        {isLoading && <AnimatedSpinner />}
        {children}
      </button>
    </>
  );
};

// This ensures the component name is correct when inferred by storybook.
Button.displayName = "Button";

export default forwardRef(Button);
