import {
  AchPayment,
  OriginatedAchTransaction,
  ReceivedAchTransaction,
} from "@highbeam/unit-node-sdk";
import MetadataList from "components/MetadataList";
import TraceNumberLabel from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneDetailsSection/TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";
import { useUnitCoTransaction } from "resources/unit-co-transactions/queries/useUnitCoTransactionQueryOptions";
import Section from "ui/data-display/Section";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";

type Props = {
  payment: AchPayment;
};

type AchTransactionTypes = OriginatedAchTransaction | ReceivedAchTransaction;

const AchPaymentDetailsSection: React.FC<Props> = ({ payment }) => {
  const transaction = useUnitCoTransaction<AchTransactionTypes>({
    accountId: payment.relationships.account.data.id,
    transactionId: payment.relationships.transaction?.data.id,
  });

  return (
    <Section>
      <Heading3>ACH transaction details</Heading3>
      <MetadataList>
        {payment.attributes.addenda && (
          <MetadataList.Item label="Description">
            <Text size={14} weight="medium">
              {payment.attributes.addenda}
            </Text>
          </MetadataList.Item>
        )}
        <MetadataList.Item label="Routing number">
          <Text size={14} weight="medium">
            {payment.attributes.counterparty.routingNumber}
          </Text>
        </MetadataList.Item>
        <MetadataList.Item label="Account number">
          <Text size={14} weight="medium">
            {payment.attributes.counterparty.accountNumber}
          </Text>
        </MetadataList.Item>
        <MetadataList.Item label="Account type">
          <Text size={14} weight="medium">
            {payment.attributes.counterparty.accountType}
          </Text>
        </MetadataList.Item>
        {transaction?.attributes.traceNumber && (
          <MetadataList.Item label={<TraceNumberLabel />}>
            <Text size={14} weight="medium">
              {transaction?.attributes.traceNumber}
            </Text>
          </MetadataList.Item>
        )}
      </MetadataList>
    </Section>
  );
};

export default AchPaymentDetailsSection;
