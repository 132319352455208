import useBusiness from "resources/business/queries/useBusiness";
import { useUnitCoCustomerQuery } from "resources/unit-co-customer/queries/useUnitCoCustomer";

import { useBusinessMembersQuery } from "./useBusinessMembers";

// NB(alex): Experimental - experimenting with combining queries into one aggregator hook.

const useUnitAuthorizedBusinessMembers = () => {
  const { data: businessMembers = [] } = useBusinessMembersQuery();
  const { data: unitCoCustomer } = useUnitCoCustomerQuery();
  const authorizedUsers = unitCoCustomer?.attributes.authorizedUsers ?? [];
  const { ownerUserGuid } = useBusiness();

  return businessMembers.filter((member) => {
    const hasMatchingEmail = authorizedUsers.find(
      (user) => user.email.toLowerCase() === member.emailAddress?.toLowerCase()
    );
    const isOwner = ownerUserGuid === member.userGuid;
    return (hasMatchingEmail || isOwner) && member.firstName && member.lastName;
  });
};

export default useUnitAuthorizedBusinessMembers;
