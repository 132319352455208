import { Address } from "@highbeam/unit-node-sdk";
import dayjs from "dayjs";
import { CardBudgetType } from "resources/cards/types";
import { AddressProps } from "ui/data-display/Address";
import { getUsersLocalTimezoneAbbreviation } from "utils/date";

export const convertUnitAddressToAddressComponentProp = (address: Address): AddressProps => ({
  addressLine1: address.street,
  addressLine2: address.street2,
  city: address.city,
  state: address.state!,
  country: address.country,
  zipCode: address.postalCode,
});

const formatTimeLeft = (milliseconds: number): string => {
  const hours = milliseconds / (1000 * 60 * 60);
  if (hours >= 24) {
    const days = Math.floor(hours / 24);
    return days === 1 ? `${days} day` : `${days} days`;
  } else if (hours >= 1) {
    const roundedHours = Math.floor(hours);
    return roundedHours === 1 ? `${roundedHours} hour` : `${roundedHours} hours`;
  } else {
    const minutes = Math.floor(milliseconds / (1000 * 60));
    return `${minutes} minutes`;
  }
};

const getTargetDate = (period: CardBudgetType): dayjs.Dayjs => {
  switch (period) {
    case "daily":
      return dayjs().tz("America/Chicago").endOf("day").add(1, "second");
    case "monthly":
      return dayjs().tz("America/Chicago").endOf("month").add(1, "second");
    default:
      throw new Error(`Unsupported period: ${period}`);
  }
};

const getLocalTimeWhenReset = (period: CardBudgetType, localDate: dayjs.Dayjs): string => {
  const timezoneAbbreviation = getUsersLocalTimezoneAbbreviation(localDate.toDate());

  switch (period) {
    case "daily":
      return localDate.format(`hh:mm A [${timezoneAbbreviation}]`);
    case "monthly":
      return localDate.format("MM/DD/YY");
    default:
      throw new Error(`Unsupported period: ${period}`);
  }
};

export const getTimeLeftTillReset = (
  period: CardBudgetType
): {
  timeLeftTillResetCT: string;
  localTimeWhenReset: string;
} => {
  const now = dayjs();
  const targetDate = getTargetDate(period);

  const timeLeft = targetDate.diff(now);

  const localDate = targetDate.local();
  const localTimeWhenReset = getLocalTimeWhenReset(period, localDate);

  return {
    timeLeftTillResetCT: formatTimeLeft(timeLeft),
    localTimeWhenReset,
  };
};

export const getCopyForTimeLeft = (period: CardBudgetType): string => {
  const { timeLeftTillResetCT, localTimeWhenReset } = getTimeLeftTillReset(period);

  if (period === "daily" && timeLeftTillResetCT.endsWith("minutes")) {
    return `${period} budget resets in ${timeLeftTillResetCT} at ${localTimeWhenReset}`;
  } else if (period === "daily") {
    return `${period} budget resets in ${timeLeftTillResetCT} at ${localTimeWhenReset}`;
  } else if (period === "monthly" && timeLeftTillResetCT.endsWith("hours")) {
    return `${period} budget resets in ${timeLeftTillResetCT} on ${localTimeWhenReset}`;
  }

  return `${period} budget resets in ${timeLeftTillResetCT} on ${localTimeWhenReset}`;
};
