import { Card } from "@highbeam/unit-node-sdk";
import CardIcon from "resources/cards/components/CardIcon";
import { getCardLabelByUnitCoCard } from "resources/cards/utils/get-card-label";
import Text from "ui/typography/Text";
import { getCardName, getCardType } from "utils/cards";

import styles from "./CardModalInfo.module.scss";

type Props = {
  card: Card | undefined;
};

const CardModalInfo: React.FC<Props> = ({ card }) => {
  if (!card) return null;

  const cardName = getCardName(card);
  const label = getCardLabelByUnitCoCard({ card, capitalize: true });

  return (
    <div className={styles.info}>
      <CardIcon cardType={getCardType(card)} size="sm" />
      <div>
        <Text weight="medium" size={14}>
          {cardName ?? "Highbeam Card"}
        </Text>
        <Text className={styles.type} size={12}>
          {label}
        </Text>
      </div>
    </div>
  );
};

export default CardModalInfo;
