import AccountLabel from "components/Accounts/AccountLabel";
import TransactionFlexpaneParties from "components/common/transaction-flexpane/TransactionFlexpaneParties";
import { FC } from "react";
import useOpenBankAccount from "resources/bank-accounts/queries/useOpenBankAccount";
import CapitalAccountIcon from "resources/capital-accounts/components/CapitalAccountIcon";
import useCapitalAccountSummary from "resources/capital-accounts/queries/useCapitalAccountSummary";
import useCapitalDrawdownApproval from "resources/capital-drawdown-approvals/queries/useCapitalDrawdownApproval";
import Text from "ui/typography/Text";

import styles from "./DrawdownTransferDetails.module.scss";
import DrawdownTransferStatus from "./DrawdownTransferStatus";

type Props = {
  capitalAccountGuid: string;
  pendingDrawdownGuid: string;
};

const PendingDrawdownTransferDetails: FC<Props> = ({ capitalAccountGuid, pendingDrawdownGuid }) => {
  const pendingDrawdown = useCapitalDrawdownApproval({
    capitalAccountGuid: capitalAccountGuid,
    pendingDrawdownGuid: pendingDrawdownGuid,
    required: true,
  });
  const bankAccount = useOpenBankAccount(pendingDrawdown.bankAccountGuid);
  const capitalAccountSummary = useCapitalAccountSummary(capitalAccountGuid, { required: true });

  if (!bankAccount) {
    return null;
  }

  return (
    <TransactionFlexpaneParties
      from={
        <div className={styles.drawdownFrom}>
          <CapitalAccountIcon capitalAccountType={capitalAccountSummary.type} size={20} />
          <Text size={14} weight="medium">
            {capitalAccountSummary.name}
          </Text>
        </div>
      }
      status={<DrawdownTransferStatus />}
      to={<AccountLabel bankAccount={bankAccount} />}
    />
  );
};

export default PendingDrawdownTransferDetails;
