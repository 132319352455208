import { ListDashes as ListDashesIcon } from "@phosphor-icons/react";
import { FC } from "react";
import Heading3 from "ui/typography/Heading3";

import BillLineItemsCountBadge from "../BillLineItemsCountBadge";

import styles from "./BillLineItemsHeading.module.scss";

type Props = {
  billId: string;
  as?: "h3" | "span";
};

const BillLineItemsHeading: FC<Props> = ({ billId, as = "h3" }) => {
  const content = (
    <>
      <ListDashesIcon size={16} />
      <span className={styles.text}>Line items</span>
      <BillLineItemsCountBadge billId={billId} />
    </>
  );

  return as === "h3" ? (
    <Heading3 className={styles.container}>{content}</Heading3>
  ) : (
    <span className={styles.container}>{content}</span>
  );
};

export default BillLineItemsHeading;
