import { captureException } from "@sentry/react";
import BillApprovalRep from "reps/BillApprovalRep";
import { useRefreshBillApprovalsQuery } from "resources/bill-approvals/queries/useBillApprovals";
import { useRefreshBillQuery } from "resources/bills/queries/useBill";
import useBusinessMember from "resources/business-members/queries/useBusinessMember";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { useIsSuperusering } from "state/auth/isSuperusering";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useMutationWithDefaults, {
  MutationAdditionalOptions,
} from "utils/react-query/useMutationWithDefaults";

type Variables = Omit<
  BillApprovalRep.Creator,
  "billId" | "businessGuid" | "requestedByBusinessMemberGuid"
>;

const useCreateBillApprovalMutation = (
  billId: string,
  additionalOptions?: MutationAdditionalOptions<BillApprovalRep.Complete, Variables>
) => {
  const highbeamApi = useHighbeamApi();
  const businessGuid = useBusinessGuid();
  const isSuperusering = useIsSuperusering();
  // NB(lev): `requestedByBusinessMemberGuid` is a required field for the mutation, but
  // we won't have a reference to the current business member if the user is
  // superusering. The caller of this mutation is responsible for ensuring
  // that bill comment creation is not allowed when the user is superusering.
  const currentBusinessMember = useBusinessMember();
  const refreshBillQuery = useRefreshBillQuery(billId);
  const refreshBillApprovalsQuery = useRefreshBillApprovalsQuery(billId);

  return useMutationWithDefaults(
    {
      mutationFn: (variables) => {
        if (isSuperusering) {
          throw new Error("Cannot create bill approvals while superusering.");
        }

        return highbeamApi.billApproval.create({
          billId,
          businessGuid,
          requestedByBusinessMemberGuid: currentBusinessMember!.guid,
          ...variables,
        });
      },

      onSuccess: async () => {
        // Invalidate the relevant bill summary (as well as the bill approvals)
        // since the bill summary will now have a new requested approval count.
        await Promise.all([refreshBillQuery(), refreshBillApprovalsQuery()]);
      },
      onError: (error) => {
        captureException(error);
      },
    },
    additionalOptions || {}
  );
};

export default useCreateBillApprovalMutation;
