import useUnitApi from "resources/unit-co-customer-token/queries/useUnitApi";
import { startOfBankingDay } from "utils/date";
import useQueryOptions from "utils/react-query/useQueryOptions";

import getHistoryWithTodayForSingleAccount from "../utils/getHistoryWithTodayForSingleAccount";

import useBankAccounts from "./useBankAccounts";

const BANK_ACCOUNT_NUM_DAYS_HISTORY = 30;

const useBankAccountBalanceHistoryQueryOptions = (accountId: string) => {
  const unitApi = useUnitApi();
  const bankAccounts = useBankAccounts({ status: "all" });
  const currentBankAccount = bankAccounts.find(
    (bankAccount) => bankAccount.unitCoDepositAccountId === accountId
  );
  const currentBankAccountBalance = currentBankAccount?.availableBalance;

  return useQueryOptions({
    queryKey: ["bankAccountBalanceHistory", { accountId }],
    queryFn: async () => {
      const accountsEndOfDay = await unitApi.accountsEndOfDay.list({
        since: startOfBankingDay()
          .subtract(BANK_ACCOUNT_NUM_DAYS_HISTORY, "days")
          .format("YYYY-MM-DD"),
        accountId: accountId,
      });
      const historyWithToday =
        accountsEndOfDay?.data || currentBankAccountBalance
          ? getHistoryWithTodayForSingleAccount(
              accountsEndOfDay?.data || [],
              currentBankAccountBalance || 0
            )
          : [];
      return historyWithToday;
    },
  });
};

export default useBankAccountBalanceHistoryQueryOptions;
