import { Info } from "@phosphor-icons/react";
import { Suspense } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import usePlaidAccountsQueryOptions from "resources/plaid-connections/queries/usePlaidAccountsQueryOptions";
import usePlaidConnectionsQueryOptions from "resources/plaid-connections/queries/usePlaidConnectionsQueryOptions";
import usePlaidLinkToken from "resources/plaid-connections/queries/usePlaidLinkToken";
import duplicatePlaidConnectionGuidState from "state/plaid/duplicateConnectionGuid";
import isPlaidExistingAccountModalOpenState from "state/plaid/isExistingAccountModalOpen";
import colors from "styles/colors";
import Modal from "ui/overlay/Modal";
import Text from "ui/typography/Text";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";
import useRefreshQuery from "utils/react-query/useRefreshQuery";

const PlaidConnectionExistsModal = () => {
  const setIsPlaidExistingAccountModalOpen = useSetRecoilState(
    isPlaidExistingAccountModalOpenState
  );
  const duplicateConnectionGuid = useRecoilValue(duplicatePlaidConnectionGuidState);
  const refreshPlaidConnectionsQuery = useRefreshQuery(usePlaidConnectionsQueryOptions().queryKey);
  const refreshPlaidAccountsQuery = useRefreshQuery(usePlaidAccountsQueryOptions().queryKey);
  const linkToken = usePlaidLinkToken(duplicateConnectionGuid);

  const closeModal = () => {
    setIsPlaidExistingAccountModalOpen(false);
    refreshPlaidConnectionsQuery();
    refreshPlaidAccountsQuery();
  };
  const { openPlaid } = useHighbeamPlaidLink({
    linkToken,
    onSuccess: closeModal,
  });

  return (
    <Suspense fallback={null}>
      <Modal
        icon={<Info weight="fill" color={colors.blue} size={24} />}
        title="Connection already exists"
        buttonText="Continue"
        focusPrimaryButton
        showCancel
        onClick={openPlaid}
        isLoading={false}
        isPrimaryButtonDisabled={false}
        onClose={closeModal}
      >
        <Text size={14}>
          You have already connected this bank. Please click continue to add any new accounts to the
          existing connection.
        </Text>
      </Modal>
    </Suspense>
  );
};

export default PlaidConnectionExistsModal;
