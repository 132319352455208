import { captureException } from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import CardRep from "reps/CardRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";

import { useRefreshCardQuery } from "../queries/useCard";
import { useRefreshCardLimitsQuery } from "../queries/useCardLimits";
import { CardBudgetType } from "../types";

const getPurchaseLimitParams = (
  budgetType: CardBudgetType,
  limit: number | null
): Pick<CardRep.Update, "dailyPurchaseLimit" | "monthlyPurchaseLimit"> => {
  if (!limit) {
    return {
      dailyPurchaseLimit: null,
      monthlyPurchaseLimit: null,
    };
  }

  switch (budgetType) {
    case "daily":
      return {
        dailyPurchaseLimit: limit * 100,
        monthlyPurchaseLimit: null,
      };
    case "monthly":
      return {
        dailyPurchaseLimit: null,
        monthlyPurchaseLimit: limit * 100,
      };
  }
};

type Variables = {
  cardType: CardRep.CardType;
  cardName: string;
  budgetType: CardBudgetType;
  limit: number | null;
};

const useEditCardLimitMutation = (cardId: string) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const refreshCardQuery = useRefreshCardQuery({ cardId });
  const refreshCardLimitQuery = useRefreshCardLimitsQuery({ cardId });

  return useMutation({
    mutationFn: async ({ cardType, cardName, budgetType, limit }: Variables) => {
      return highbeamApi.card.update(businessGuid, cardId, {
        type: cardType,
        name: cardName,
        ...getPurchaseLimitParams(budgetType, limit),
      });
    },
    onError: (err) => {
      captureException(err);
    },
    onSuccess: () => {
      refreshCardQuery();
      refreshCardLimitQuery();
    },
  });
};

export default useEditCardLimitMutation;
