import logo from "assets/highbeam-logo.svg";
import backgroundLogo from "assets/onboarding-background.svg";
import env from "env";
import LoadingPage from "pages/LoadingPage";
import { useParams } from "react-router-dom";
import { useReferralQuery } from "resources/user-referrals/queries/useReferral";

import InvalidReferral from "./InvalidReferral";
import Referral from "./Referral";
import styles from "./ReferralPage.module.scss";

const ReferralPage = () => {
  const { referralSlug } = useParams();

  const { isLoading, error, data: referral } = useReferralQuery({ referralSlug });

  if (isLoading) return <LoadingPage location={ReferralPage.name} />;

  const marketingSiteUrl = env.HIGHBEAM_MARKETING_SITE_URL;

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${backgroundLogo})` }}>
      <a href={marketingSiteUrl} target="_blank" rel="noreferrer">
        <img src={logo} className={styles.logo} alt="logo" />
      </a>
      <div className={styles.content}>
        {!error && referral && <Referral referral={referral} />}
        {(error || !referral) && <InvalidReferral />}
      </div>
    </div>
  );
};

export default ReferralPage;
