import CapitalAccountRep from "reps/CapitalAccountRep";

const isCapitalAccountWithChargeCard = (capitalAccountSummary: CapitalAccountRep.Complete) => {
  return (
    capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly ||
    capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardAndCashAccess
  );
};

export default isCapitalAccountWithChargeCard;
