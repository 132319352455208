import { DataRequired, RequiredParam } from "utils/react-query/require-data";
import RequiredButNotFoundError from "utils/react-query/RequiredButNotFoundError";

import useBankAccounts from "./useBankAccounts";

const useBankAccountByUnitCoDepositAccountId = <TRequired extends boolean = false>(
  unitCoDepositAccountId: string,
  params?: RequiredParam<TRequired>
) => {
  const bankAccount = useBankAccounts({
    status: "all",
    select: (bankAccounts) => {
      return bankAccounts.find(
        (bankAccount) => bankAccount.unitCoDepositAccountId === unitCoDepositAccountId
      );
    },
  });

  if (params?.required && !bankAccount) {
    throw new RequiredButNotFoundError();
  }

  return bankAccount as DataRequired<typeof bankAccount, TRequired>;
};

export default useBankAccountByUnitCoDepositAccountId;
