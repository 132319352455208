import { useMemo } from "react";
import HighbeamAccountIcon from "resources/bank-accounts/components/HighbeamAccountIcon";
import useBankAccounts from "resources/bank-accounts/queries/useBankAccounts";
import BankAccountV2 from "ui/data-display/BankAccountV2";

import { useReceivedPaymentInfoFlexpaneContext } from "../../context/ReceivedPaymentInfoFlexpaneProvider";

import styles from "./ReceivedPaymentInfoFlexpaneToFromSection.module.scss";

const BankAccountDetail = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();

  const bankAccounts = useBankAccounts({ status: "all" });

  const bankAccountsByUnitId = useMemo(() => {
    return new Map(bankAccounts.map((account) => [account.unitCoDepositAccountId, account]));
  }, [bankAccounts]);
  const account = bankAccountsByUnitId.get(receivedPayment.relationships.account.data.id);
  if (!account) {
    return null;
  }
  return (
    <BankAccountV2
      bankIcon={<HighbeamAccountIcon highbeamTypeName={account.highbeamType.name} />}
      accountName={account.name}
      accountNumber={account.accountNumber}
      className={styles.bankAccount}
    />
  );
};

export default BankAccountDetail;
