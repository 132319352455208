import MetadataList from "components/MetadataList";
import TraceNumberLabel from "components/Transactions/TransactionInfoFlexpane/TransactionInfoFlexpaneDetailsSection/TransactionInfoFlexpaneUtils/TraceNumberLabel/TraceNumberLabel";
import { useReceivedPaymentInfoFlexpaneContext } from "dialogs/ReceivedPaymentInfoFlexpane/context/ReceivedPaymentInfoFlexpaneProvider";
import Flexpane from "ui/overlay/Flexpane";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";

const ReceivedPaymentInfoFlexpaneDetailsSection = () => {
  const { receivedPayment } = useReceivedPaymentInfoFlexpaneContext();

  return (
    <Flexpane.Section>
      <Heading3>ACH details</Heading3>

      <MetadataList>
        <MetadataList.Item label="Description">
          <Text size={14} weight="medium">
            {receivedPayment.attributes.description}
          </Text>
        </MetadataList.Item>
        {receivedPayment.attributes.addenda && (
          <MetadataList.Item label="Additional details">
            <Text size={14} weight="medium">
              {receivedPayment.attributes.addenda}
            </Text>
          </MetadataList.Item>
        )}
        <MetadataList.Item label="Routing number">
          <Text size={14} weight="medium">
            {receivedPayment.attributes.counterpartyRoutingNumber}
          </Text>
        </MetadataList.Item>
        <MetadataList.Item label={<TraceNumberLabel />}>
          <Text size={14} weight="medium">
            {receivedPayment.attributes.traceNumber}
          </Text>
        </MetadataList.Item>
      </MetadataList>
    </Flexpane.Section>
  );
};

export default ReceivedPaymentInfoFlexpaneDetailsSection;
