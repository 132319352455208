import CardRep from "reps/CardRep";

import PhysicalCreditCardPill from "./PhysicalCreditCardPill";
import PhysicalDebitCardPill from "./PhysicalDebitCardPill";
import VirtualCreditCardPill from "./VirtualCreditCardPill";
import VirtualDebitCardPill from "./VirtualDebitCardPill";

type HighbeamCardTypePillProps = {
  cardType: CardRep.CardType;
};

const HighbeamCardTypePill: React.FC<HighbeamCardTypePillProps> = ({ cardType }) => {
  switch (cardType) {
    case "PHYSICAL":
      return <PhysicalDebitCardPill />;
    case "VIRTUAL":
      return <VirtualDebitCardPill />;
    case "PHYSICAL_CREDIT":
      return <PhysicalCreditCardPill />;
    case "VIRTUAL_CREDIT":
      return <VirtualCreditCardPill />;
    default:
      return null;
  }
};

export default HighbeamCardTypePill;
