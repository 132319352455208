import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import { CapitalAccountSummaryWithChargeCard } from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";

import CapitalAvailableSection from "../../components/CapitalAvailableSection";
import ChargeCardRepayments from "../../components/ChargeCardRepayments";
import CapitalAccountBanners from "../../sections/CapitalAccountBanners";
import CapitalAccountCreditTransactionsSection from "../../sections/CapitalAccountCreditTransactionsSection";
import CapitalAccountHeader from "../../sections/CapitalAccountHeader";

import ChargeCardHighlightCard from "./ChargeCardHighlightCard";
import LineOfCreditHighlightCard from "./LineOfCreditHighlightCard";

type Props = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardView: FC<Props> = ({ capitalAccountSummaryWithChargeCard }) => {
  return (
    <>
      <CapitalAccountBanners capitalAccountSummary={capitalAccountSummaryWithChargeCard} />

      <CapitalAccountHeader capitalAccountSummary={capitalAccountSummaryWithChargeCard} />

      <DashboardPage.Section>
        <CapitalAvailableSection
          capitalAccountSummary={capitalAccountSummaryWithChargeCard}
          showViewCardsButton
        />

        <DashboardPage.Section.Body>
          <ChargeCardHighlightCard
            capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
          />
        </DashboardPage.Section.Body>

        <DashboardPage.Section.Body>
          <LineOfCreditHighlightCard capitalAccountSummary={capitalAccountSummaryWithChargeCard} />
        </DashboardPage.Section.Body>
      </DashboardPage.Section>

      <DashboardPage.Section>
        <ChargeCardRepayments
          capitalAccountSummaryWithChargeCard={capitalAccountSummaryWithChargeCard}
        />
      </DashboardPage.Section>

      <CapitalAccountCreditTransactionsSection
        capitalAccountSummary={capitalAccountSummaryWithChargeCard}
      />
    </>
  );
};

export default ChargeCardView;
