import { captureMessage } from "@sentry/react";
import MetadataList from "components/MetadataList";
import dayjs from "dayjs";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import { useLineOfCreditInterestFee } from "resources/line-of-credit/queries/useLineOfCreditInterestFee";
import colors from "styles/colors";
import MoneyAmount from "ui/data-display/money/MoneyAmount";

type Props = {
  capitalAccountSummaryWithCashAccessOnly: CapitalAccountSummaryRep.CashAccessOnlyComplete;
};

const CapitalAccountCardTodaysInterestItem: FC<Props> = ({
  capitalAccountSummaryWithCashAccessOnly,
}) => {
  const capitalAccountGuid = capitalAccountSummaryWithCashAccessOnly.guid;

  const interestFees = useLineOfCreditInterestFee({
    lineOfCreditGuid: capitalAccountGuid,
    since: dayjs(),
    untilInclusive: dayjs(),
  });

  const todaysInterest = interestFees[0];

  if (!todaysInterest) {
    captureMessage(
      `Alert(alex): today's interest not found for capital account ${capitalAccountGuid}. (this should not happen)`
    );
    return null;
  }

  return (
    <MetadataList.Item label="Today’s interest">
      <MoneyAmount
        cents={todaysInterest.amount}
        size={14}
        color={colors.grey[800]}
        weight="medium"
      />
    </MetadataList.Item>
  );
};

export default CapitalAccountCardTodaysInterestItem;
