import useBankAccounts from "./useBankAccounts";

const useAllBankAccountsByUnitCoDepositAccountId = () => {
  return useBankAccounts({
    status: "all",
    select: (bankAccounts) => {
      return new Map(bankAccounts.map((account) => [account.unitCoDepositAccountId, account]));
    },
  });
};

export default useAllBankAccountsByUnitCoDepositAccountId;
