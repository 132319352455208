import {
  ArrowLineUpRight,
  ArrowsLeftRight,
  Cards,
  ChartLine,
  ChatTeardropDots,
  Coins,
  Gear,
  Money,
  X,
} from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import logo from "assets/highbeam-logo.svg";
import classNames from "classnames";
import { useChatWidget } from "components/ChatWidget";
import { CREDIT_COMPARISON_PATH } from "pages/capital/paths";
import { Suspense } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useDraftBillsQuery } from "resources/bills/queries/useBills";
import { useHasActiveCapitalAccountQuery } from "resources/capital-accounts/hooks/useActiveCapitalAccounts";
import usePaymentApprovalsQueryOptions from "resources/payment-approvals/queries/usePaymentApprovalsQueryOptions";
import isSidebarOpenState from "state/sidebar";
import CountBadge from "ui/data-display/CountBadge";
import Spacer from "ui/data-display/Spacer";
import ChequeIcon from "ui/icons/ChequeIcon";
import PaymentIcon from "ui/icons/PaymentIcon";
import Text from "ui/typography/Text";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useIsAllowedToNavigateToAccountsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToAccountsRoutes";
import useIsAllowedToNavigateToBillPayRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToBillPayRoutes";
import useIsAllowedToNavigateToCapitalRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToCapitalRoutes";
import useIsAllowedToNavigateToCardTransactionsPage from "utils/permissions/navigation/useIsAllowedToNavigateToCardTransactionsPage";
import useIsAllowedToNavigateToDepositCheckPage from "utils/permissions/navigation/useIsAllowedToNavigateToDepositCheckPage";
import useIsAllowedToNavigateToMoveMoneyRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToMoveMoneyRoutes";
import useIsAllowedToNavigateToPaymentsRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToPaymentsRoutes";
import useIsAllowedToNavigateToSpendRoutes from "utils/permissions/navigation/useIsAllowedToNavigateToSpendRoutes";
import useIsAllowedToNavigateToTransferMoney from "utils/permissions/navigation/useIsAllowedToNavigateToTransferMoney";
import useIsAllowedToApprovePayments from "utils/permissions/useIsAllowedToApprovePayments";
import useIsAllowedToCreateDraftPayments from "utils/permissions/useIsAllowedToCreateDraftPayments";
import useIsAllowedToViewSendMoney from "utils/permissions/useIsAllowedToViewSendMoney";

import ActionButton from "./ActionButton";
import styles from "./DashboardSidebar.module.scss";
import GetStartedSetupGuide from "./GetStartedSetupGuide";
import useCloseSidebarOnNavigateEffect from "./hooks/useCloseSidebarOnNavigateEffect";
import MenuItem from "./MenuItem";
import MenuSubItem from "./MenuSubItem";
import SidebarMenu from "./SidebarMenu";

const getCountBadgeColor = (isActive: boolean) => (isActive ? "purple-500" : "grey-200");

const DashboardSidebar = () => {
  const [show, setIsSidebarOpen] = useRecoilState(isSidebarOpenState);
  const onClose = () => setIsSidebarOpen(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const chat = useChatWidget();

  const isAllowedToNavigateToMoveMoneyRoutes = useIsAllowedToNavigateToMoveMoneyRoutes();
  const isAllowedToNavigateToTransferMoney = useIsAllowedToNavigateToTransferMoney();
  const isAllowedToNavigateToDepositCheckPage = useIsAllowedToNavigateToDepositCheckPage();
  const isAllowedToNavigateToAccountsRoutes = useIsAllowedToNavigateToAccountsRoutes();
  const isAllowedToNavigateToPaymentsRoutes = useIsAllowedToNavigateToPaymentsRoutes();
  const isAllowedToNavigateToBillPayRoutes = useIsAllowedToNavigateToBillPayRoutes();
  const isAllowedToNavigateToCapitalRoutes = useIsAllowedToNavigateToCapitalRoutes();
  const isAllowedToNavigateToSpendRoutes = useIsAllowedToNavigateToSpendRoutes();
  const isAllowedToNavigateToCardTransactionsPage = useIsAllowedToNavigateToCardTransactionsPage();

  const isAllowedToApprovePayments = useIsAllowedToApprovePayments();
  const isAllowedToCreatePaymentDrafts = useIsAllowedToCreateDraftPayments();
  const isAllowedToViewSendMoney = useIsAllowedToViewSendMoney();

  const insightsCashFlowViewEnabled = useFeatureFlag("CASH_FLOW_VIEW");
  const billPayEnabled = useFeatureFlag("BILL_PAY_UI");

  const { data: paymentApprovals } = useQuery({
    ...usePaymentApprovalsQueryOptions("Open"),
    enabled: isAllowedToApprovePayments || isAllowedToCreatePaymentDrafts,
  });
  const paymentApprovalsCount = paymentApprovals ? paymentApprovals.length : 0;

  const { data: draftBills } = useDraftBillsQuery();
  const billsEmailInboxCount = draftBills?.length;

  const { data: hasActiveCapitalAccount } = useHasActiveCapitalAccountQuery();

  useCloseSidebarOnNavigateEffect();

  return (
    <div className={classNames({ [styles.container]: true, [styles.closed]: !show })}>
      <div>
        <div className={styles.header}>
          <div className={styles.subHeader}>
            <Link to="/" className={styles.homeLink} onClick={onClose}>
              <img src={logo} className={styles.logo} alt="logo" />
            </Link>
            <X onClick={onClose} size={24} className={styles.close} />
          </div>
        </div>

        <div className={styles.actionButton}>
          {isAllowedToNavigateToMoveMoneyRoutes && (
            <SidebarMenu
              customButton={<ActionButton text="Move money" icon={<ArrowsLeftRight />} />}
            >
              {isAllowedToApprovePayments && (
                <SidebarMenu.Item
                  label="Send money"
                  icon={<ArrowLineUpRight />}
                  subText="to pay bills, etc."
                  onClick={() => {
                    // Hack to reload send money page if already on send money page. https://linear.app/highbeam/issue/HB-4999/nothing-happens-on-clicking-send-money-right-after-making-a-payment
                    if (pathname === "/send-money") {
                      window.location.reload();
                    } else {
                      navigate("/send-money");
                    }
                  }}
                />
              )}
              {!isAllowedToApprovePayments && isAllowedToCreatePaymentDrafts && (
                <SidebarMenu.Item
                  label="Draft a payment"
                  icon={<ArrowLineUpRight />}
                  subText="for admin approval"
                  onClick={() => {
                    navigate("/send-money");
                  }}
                />
              )}
              {isAllowedToNavigateToTransferMoney && (
                <SidebarMenu.Item
                  label="Transfer money"
                  icon={<ArrowsLeftRight />}
                  subText="between bank accounts"
                  onClick={() => {
                    navigate("/transfer-money");
                  }}
                />
              )}
              {isAllowedToNavigateToDepositCheckPage && (
                <SidebarMenu.Item
                  label="Deposit check"
                  icon={<ChequeIcon />}
                  subText="to your Highbeam account"
                  onClick={() => {
                    navigate("/deposit-check");
                  }}
                />
              )}
            </SidebarMenu>
          )}
        </div>

        {isAllowedToNavigateToAccountsRoutes && (
          <MenuItem text="Accounts" icon={<Money />} path="/accounts" onClick={onClose}>
            <MenuSubItem text="Overview" path="/accounts" onClose={onClose} />
            <MenuSubItem text="Transactions" path="/accounts/transactions" onClose={onClose} />
            <MenuSubItem text="Documents" path="/accounts/documents" onClose={onClose} />
          </MenuItem>
        )}

        <MenuItem text="Cards" icon={<Cards />} path="/cards" onClick={onClose}>
          <MenuSubItem text="Overview" path="/cards" onClose={onClose} />
          {isAllowedToNavigateToCardTransactionsPage && (
            <MenuSubItem text="Transactions" path="/cards/transactions" onClose={onClose} />
          )}
        </MenuItem>

        {isAllowedToNavigateToPaymentsRoutes && (
          <MenuItem
            text="Payments"
            icon={<PaymentIcon />}
            path="/payments"
            onClick={onClose}
            badge={({ isActive }) => {
              const totalCount =
                (billsEmailInboxCount ?? 0) +
                (isAllowedToViewSendMoney ? paymentApprovalsCount : 0);

              if (totalCount) {
                return <CountBadge count={totalCount} color={getCountBadgeColor(isActive)} />;
              }
            }}
          >
            <MenuSubItem
              key="overview"
              text="Overview"
              path="/payments"
              onClose={onClose}
              badge={({ isActive }) => {
                if (isAllowedToViewSendMoney && paymentApprovalsCount) {
                  return (
                    <CountBadge
                      count={paymentApprovalsCount}
                      color={getCountBadgeColor(isActive)}
                    />
                  );
                }
              }}
            />
            {billPayEnabled && isAllowedToNavigateToBillPayRoutes && (
              <MenuSubItem
                key="bill-pay"
                text="Bills"
                path="/payments/bills"
                onClose={onClose}
                badge={({ isActive }) => {
                  if (billsEmailInboxCount) {
                    return (
                      <CountBadge
                        count={billsEmailInboxCount}
                        color={getCountBadgeColor(isActive)}
                      />
                    );
                  }
                }}
              />
            )}
            <MenuSubItem key="payees" text="Payees" path="/payments/payees" onClose={onClose} />
          </MenuItem>
        )}

        {isAllowedToNavigateToCapitalRoutes && (
          <MenuItem text="Capital" icon={<Coins />} path="/capital" onClick={onClose}>
            <MenuSubItem text={"Overview"} path="/capital" onClose={onClose} />

            {hasActiveCapitalAccount && (
              <MenuSubItem text="Documents" path="/capital/documents" onClose={onClose} />
            )}
            <MenuSubItem
              text="Compare other offers"
              path={CREDIT_COMPARISON_PATH}
              onClose={onClose}
            />
          </MenuItem>
        )}

        {isAllowedToNavigateToSpendRoutes && insightsCashFlowViewEnabled && (
          <MenuItem text="Cash flow" icon={<ChartLine />} path="/cash-flow" onClick={onClose} />
        )}

        <MenuItem text="Settings" icon={<Gear />} path="/settings" onClick={onClose} />

        <Spacer height={44} />
      </div>

      <Spacer height={16} />

      <div className={styles.footer}>
        <Spacer height={16} />
        <Suspense fallback={null}>
          <div className={styles.getStartedGuideContainer}>
            <GetStartedSetupGuide buttonClassName={styles.getStartedGuideButton} />
          </div>
        </Suspense>
        <div className={styles.contactusWrapper} onClick={() => chat.show()}>
          <ChatTeardropDots size={14} />
          <Text size={12} weight="bold">
            Contact support
          </Text>
        </div>
        <Spacer height={16} />
        <div className={styles.copyrightContainer}>
          <Text size={12} className={styles.copyrightText}>
            &copy; {new Date().getFullYear()} Highbeam Inc.
          </Text>
        </div>
        <Spacer height={16} />
      </div>
    </div>
  );
};

export default DashboardSidebar;
