import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import BankAccountRep from "reps/BankAccountRep";
import useBusinessGuid from "resources/jwt/queries/useBusinessGuid";
import { maskBankAccountAccountNumber, sortAccounts } from "utils/account";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useHasPermission from "utils/permissions/useHasPermission";
import useIsAllowedToViewAccountNumbers from "utils/permissions/useIsAllowedToViewAccountNumbers";
import useQueryOptions from "utils/react-query/useQueryOptions";
import useRefreshQuery, { UseRefreshQueryOptions } from "utils/react-query/useRefreshQuery";

export const BASE_BANK_ACCOUNTS_QUERY_KEY = "bankAccounts";

type Status = "all" | "closed" | "open";

const getStatuses = (status: Status): BankAccountRep.Status[] => {
  switch (status) {
    case "all":
      return [
        BankAccountRep.Status.OPEN,
        BankAccountRep.Status.CLOSED,
        BankAccountRep.Status.FROZEN,
      ];
    case "closed":
      return [BankAccountRep.Status.CLOSED];
    case "open":
      return [BankAccountRep.Status.OPEN, BankAccountRep.Status.FROZEN];
  }
};

type Params<TData = BankAccountRep.Complete[]> = {
  status: Status;
  select?: (data: BankAccountRep.Complete[]) => TData;
};

export const useBankAccountsQueryOptions = <TData = BankAccountRep.Complete[]>({
  status,
  select,
}: Params<TData>) => {
  const businessGuid = useBusinessGuid();
  const highbeamApi = useHighbeamApi();
  const isAllowedToViewAccountNumbers = useIsAllowedToViewAccountNumbers();
  const statuses = getStatuses(status);

  // NB(alex): Unsure about this pattern, please don't copy it yet.
  const hasPermission = useHasPermission("bankAccount:read");

  return useQueryOptions({
    queryKey: [BASE_BANK_ACCOUNTS_QUERY_KEY, { businessGuid, statuses, hasPermission }],
    queryFn: async () => {
      if (!hasPermission) {
        return [];
      }

      const bankAccounts = await highbeamApi.bankAccount.getByBusiness(businessGuid);

      // NB(alex): We should mask account numbers on the backend, but this simulates the behavior for now.
      const bankAccountsWithMaskedAccountNumbers = isAllowedToViewAccountNumbers
        ? bankAccounts
        : bankAccounts.map(maskBankAccountAccountNumber);

      const bankAccountsFilteredByStatus = bankAccountsWithMaskedAccountNumbers.filter(
        (bankAccount) => statuses.includes(bankAccount.status)
      );

      return bankAccountsFilteredByStatus.sort(sortAccounts);
    },
    select: select,
  });
};

export const useRefreshBankAccountsQueries = (options?: UseRefreshQueryOptions) => {
  return useRefreshQuery([BASE_BANK_ACCOUNTS_QUERY_KEY], options);
};

export const useRefreshBankAccountsQuery = (params: Params, options?: UseRefreshQueryOptions) => {
  return useRefreshQuery(useBankAccountsQueryOptions(params).queryKey, options);
};

export const useBankAccountsQuery = <TData = BankAccountRep.Complete[]>(params: Params<TData>) => {
  return useQuery(useBankAccountsQueryOptions(params));
};

export const useBankAccounts = <TData = BankAccountRep.Complete[]>(params: Params<TData>) => {
  return useSuspenseQuery(useBankAccountsQueryOptions(params)).data;
};

export default useBankAccounts;
