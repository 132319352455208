import JwtClaims from "jwt-claims";
import decodeJwt from "jwt-decode";
import Jwt from "reps/Jwt";
import { decodeAcl } from "reps/Jwt/JwtPermission";

export function getJwtFromAccessToken(jwtString: string): Jwt {
  const jwtData = decodeJwt<any>(jwtString);

  return {
    expiresAt: jwtData[JwtClaims.expiresAt],

    acl: decodeAcl(jwtData[JwtClaims.acl]),
    businesses: jwtData[JwtClaims.businesses],
    mfa: jwtData[JwtClaims.mfa],
    user: jwtData[JwtClaims.user],
    roles: jwtData[JwtClaims.roles],

    rawToken: jwtString,
  };
}

export const isLastMfaValidForLargePayment = (jwt: Jwt): boolean => {
  if (!jwt.mfa) return false;
  const lastMfa = jwt.mfa.lastMfa;
  return !hasMfaExpiredForLargePayment(lastMfa);
};

export const isLastMfaValid = (jwt: Jwt): boolean => {
  if (!jwt.mfa) return false;
  const lastMfa = jwt.mfa.lastMfa;
  return !hasMfaExpired(lastMfa);
};

const hasMfaExpired = (lastMfa: string): boolean => {
  const timeSinceLastMfa = Date.now() - Date.parse(lastMfa);
  // MFA is valid for 24 hours, but we use 23 hours so that there's a buffer.
  return timeSinceLastMfa > 23 * 60 * 60 * 1000;
};

const hasMfaExpiredForLargePayment = (lastMfa: string): boolean => {
  const timeSinceLastMfa = Date.now() - Date.parse(lastMfa);
  // MFA for large payments is valid for 2 minutes
  return timeSinceLastMfa > 120 * 1000;
};
